import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CarrierListItem, CarrierListService } from '../carrier-list.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackBarService } from '../../../../shared/services/snackbar.service';
import { FusedObservable } from '../../../../shared/utilities/fusedObservable';
import { map } from 'rxjs/operators';
import { CarrierTouchpointService } from '../../../../shared/components/carrier-touchpoint/carrier-touchpoint.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { NetworkableDestroyableComponent } from '../../../../shared/components/networkable-destroyable.component';

interface GenericContact {
  id: string;
  name: string;
  email: string;
  phone: string;
}

@Component({
  selector: 'td-assign-carrier-point-of-contact',
  templateUrl: './assign-carrier-point-of-contact.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignCarrierPointOfContactComponent extends NetworkableDestroyableComponent {
  public searchControl = new FormControl<string>(null);
  public contactControl = new FormControl<GenericContact>(null, []);
  private carrier$ = new BehaviorSubject<CarrierListItem>(null);
  private userContacts$ = this.carrierTouchpointService.carrierContacts$.pipe(
    map((contacts) => contacts?.filter((contact) => !!contact.id) || []),
  );
  private profileContacts$ = this.carrier$.pipe(map((carrier) => carrier?.profileData?.dispatchers || []));
  private combinedContacts$: Observable<GenericContact[]> = combineLatest([
    this.userContacts$,
    this.profileContacts$,
  ]).pipe(
    map(([userContacts, profileContacts]) => [
      ...userContacts.map((contact) => {
        return {
          id: `${contact.id}`,
          name: contact.name,
          email: contact.email,
          phone: contact.phone,
        };
      }),
      ...profileContacts.map((contact) => {
        return {
          id: `profileContact ${contact.name} ${contact.email} ${contact.phoneNumber}`,
          name: contact.name,
          email: contact.email,
          phone: contact.phoneNumber,
        };
      }),
    ]),
  );
  public contacts$: Observable<GenericContact[]> = new FusedObservable(
    this.combinedContacts$,
    this.searchControl.valueChanges,
    [['name']],
  ).fused$;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { carrier: CarrierListItem },
    private carrierTouchpointService: CarrierTouchpointService,
    private carrierListService: CarrierListService,
    private ref: MatDialogRef<AssignCarrierPointOfContactComponent>,
    private snackbar: SnackBarService,
  ) {
    super();
    this.carrier$.next(this.data.carrier);
  }

  public async reassignCarrierPointOfContact() {
    if (this.networkActive$$.value || !this.data.carrier.id) {
      return;
    }

    try {
      this.networkActive$$.next(true);

      let contact = this.contactControl.value;
      const success = await this.carrierListService.reAssignCarrierPointOfContact(
        this.data.carrier.id,
        contact?.id?.includes('profileContact') ? null : contact?.id,
        contact?.name,
        contact?.phone,
        contact?.email,
      );
      if (success) {
        this.snackbar.showMessage('New Point of Contact Assigned');
        this.ref.close();
      }
    } finally {
      this.networkActive$$.next(false);
    }
  }
}
