import { ChangeDetectionStrategy, Component, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import { FusedObservable } from '../../utilities/fusedObservable';
import { sort } from 'remeda';
import { LAMStatus } from '../../global-types';

export interface EapSummary {
  id: number;
  stopId: number;
  potentialFacilities: Facility[];
  chosenFacilityId: string | null;
  createdAt: string;
  bestFacility: Facility | null;
}

export interface Trailer {
  id: number;
  atTheDock: boolean;
  assetStatus: LAMStatus;
  loadedStatus: 'any' | 'loaded_and_empty' | 'loaded' | 'empty';
  probabilityEmpty: number;
  name: string;
}

export interface Facility {
  id: string;
  facilityName: string;
  targetType: string;
  targetCount: number;
  maxCount: number;
  totalTrailerCount: number;
  emptyTrailerCount: number;
  probResults: ProbResults;
  trailers: Trailer[];
}

export interface ProbResults {
  missingDriverFacilityTravelData: boolean;
  missingFacilityToPickupTravel: boolean;
  detourMiles: number;
  maxDetourMiles: number;
  estimatedDurationMinutes?: number;
  driverNotAllowed: boolean;
}

const facilitySorter = (a: Facility, b: Facility) => {
  const aValue = a.targetType === 'empty' ? a.emptyTrailerCount - a.targetCount : a.emptyTrailerCount;
  const bValue = b.targetType === 'empty' ? b.emptyTrailerCount - b.targetCount : b.emptyTrailerCount;
  return bValue - aValue;
};

@Component({
  selector: 'td-why-this-eap',
  templateUrl: './why-this-eap.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WhyThisEapComponent implements OnInit, OnChanges {
  private httpClient = inject(HttpClient);
  private eapSummary$$ = new BehaviorSubject<EapSummary>(null);
  public eapSummary$ = this.eapSummary$$.asObservable();
  public selectedFacilityFC = new FormControl<Facility>(null);
  public facilitySearchFC = new FormControl<string>('');
  public filteredFacilities$ = new FusedObservable(
    this.eapSummary$.pipe(
      map((eap) => eap?.potentialFacilities ?? []),
      map((facilities) => sort(facilities, facilitySorter)),
    ),
    this.facilitySearchFC.valueChanges,
    ['facilityName'],
  ).fused$;

  public get selectedFacility(): Facility {
    return this.selectedFacilityFC.value;
  }

  @Input() public loadId: string;
  @Input() public eapStopFacilityId: string;

  public async ngOnInit() {
    if (this.loadId) {
      this.eapSummary$$.next(await this.getEapSummary(this.loadId));
    }
  }

  public async ngOnChanges(changes: SimpleChanges) {
    if (changes['loadId'] && this.loadId) {
      this.eapSummary$$.next(await this.getEapSummary(this.loadId));
    }
  }

  // since this is shared and the load service is so heavy, I don't want to bring it in, just doing the call locally
  private async getEapSummary(loadId: string): Promise<EapSummary> {
    try {
      const response = await lastValueFrom(
        this.httpClient.get<{ eapSummary: EapSummary }>(
          `${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/why_this_eap`,
        ),
      );
      return response.eapSummary;
    } catch (e) {
      return null;
    }
  }
}
