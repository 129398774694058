import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Customer, CustomersService } from '../../services/customers.service';
import { Observable } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';
import { FusedObservable } from '../../utilities/fusedObservable';

@Component({
  selector: 'td-select-customer',
  templateUrl: './select-customer.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectCustomerComponent {
  private customers$: Observable<Customer[]>;

  public searchControl = new FormControl(null);
  public filteredCustomers$: Observable<Customer[]>;

  public customerControl = new FormControl<Customer>(null, [Validators.required]);

  constructor(private customerService: CustomersService) {
    this.customers$ = this.customerService.customers$;
    this.filteredCustomers$ = new FusedObservable(this.customers$, this.searchControl.valueChanges, ['name']).fused$;
  }
}
