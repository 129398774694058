import { Pipe, PipeTransform } from '@angular/core';
import { UserPayModel } from '../global-types';

const userPayModels: Record<UserPayModel, string> = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  percentage_of_gross: 'Percentage of Gross',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  full_gross: 'Full Gross',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  none: 'None',
};

@Pipe({
  name: 'userPayModel',
})
export class UserPayModelPipe implements PipeTransform {
  public transform(value: UserPayModel | UserPayModel[]): string | string[] {
    if (Array.isArray(value)) {
      return value.map((v) => userPayModels[v]);
    }
    return userPayModels[value] || '—';
  }
}
