<form class="grid grid-cols-2 gap-2 relative" [formGroup]="formGroup" (ngSubmit)="doSave()">
  <mat-form-field>
    <mat-label>Tractor Make</mat-label>
    <input matInput formControlName="tractorMake" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Trailer Make</mat-label>
    <input matInput formControlName="trailerMake" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Tractor Model</mat-label>
    <input matInput formControlName="tractorModel" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Trailer Model</mat-label>
    <input matInput formControlName="trailerModel" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Tractor Number</mat-label>
    <input matInput formControlName="tractorNumber" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Trailer Number</mat-label>
    <input matInput formControlName="trailerNumber" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Tractor Year</mat-label>
    <input matInput formControlName="tractorYear" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Trailer Year</mat-label>
    <input matInput formControlName="trailerYear" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Tractor VIN</mat-label>
    <input matInput formControlName="tractorVIN" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Trailer VIN</mat-label>
    <input matInput formControlName="trailerVIN" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Trailer Type</mat-label>
    <mat-select formControlName="trailerType">
      <mat-option
        *ngFor="let choice of trailerTypes$ | async; trackBy: trailerTypes$ | trackByKey: 'type'"
        [value]="choice.type"
      >
        {{ choice.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Trailer Attributes</mat-label>
    <mat-select formControlName="trailerAttributeIDs" [multiple]="true">
      <mat-option
        *ngFor="let choice of trailerTypeAttributes$ | async; trackBy: trailerTypeAttributes$ | trackByKey: 'id'"
        [value]="choice.id"
      >
        {{ choice.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Additional Equipment</mat-label>
    <mat-select formControlName="additionalEquipmentIds" [multiple]="true">
      <mat-option
        *ngFor="let choice of additionalEquipmentTypes$ | async; trackBy: additionalEquipmentTypes$ | trackByKey: 'id'"
        [value]="choice.id"
      >
        {{ choice.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div class="flex justify-end w-full col-span-2">
    <button mat-flat-button color="primary" type="submit">Save</button>
  </div>
  <div
    class="bg-white opacity-50 absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center"
    *ngIf="networkActive"
  >
    <mat-spinner></mat-spinner>
  </div>
</form>
