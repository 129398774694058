import { Pipe, PipeTransform } from '@angular/core';

const values: Record<string, string> = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  due_to_driver_or_carrier: 'Due to Driver or Carrier',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  due_to_shipper: 'Due to Shipper',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  other: 'Other',
};

@Pipe({
  name: 'cancelledCause',
})
export class CancelledCausePipe implements PipeTransform {
  public transform(value: string): string {
    return values[value] || value;
  }
}
