import { Pipe, PipeTransform } from '@angular/core';

const values: Record<string, string> = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  one_day_before_pickup: 'Pre-Pickup',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  pre_pickup_recurring: 'Pre-Pickup',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  pickup_window_start: 'Pre-Pickup',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  en_route: 'En Route',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  delivery: 'Delivered',
};

@Pipe({
  name: 'brokerCheckCallType',
})
export class BrokerCheckCallTypePipe implements PipeTransform {
  public transform(value: string): string {
    return values[value] || value;
  }
}
