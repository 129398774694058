import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationModalComponent, ConfirmationModalData } from './confirmation-modal.component';
import { Observable } from 'rxjs';
import {
  ConfirmationModalReasonInput,
  ConfirmationModalReasonComponent,
  ConfirmationModalReasonOutput,
} from '../confirmation-modal-reason/confirmation-modal-reason.component';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationModalService {
  constructor(public dialog: MatDialog) {}

  public open(
    content = '',
    title = 'Are you sure?',
    yesLabel = 'Confirm',
    noLabel = 'Cancel',
  ): MatDialogRef<ConfirmationModalComponent, boolean> {
    return this.dialog.open<ConfirmationModalComponent, ConfirmationModalData, boolean>(ConfirmationModalComponent, {
      data: { title, content, yesLabel, noLabel },
      autoFocus: false,
    });
  }

  public openReason$(
    reasonLabel = '',
    title = 'Why?',
    yesLabel = 'Confirm',
    noLabel = 'Cancel',
  ): Observable<ConfirmationModalReasonOutput> {
    return this.dialog
      .open<ConfirmationModalReasonComponent, ConfirmationModalReasonInput, ConfirmationModalReasonOutput>(
        ConfirmationModalReasonComponent,
        {
          data: { title, reasonLabel, yesLabel, noLabel },
          autoFocus: false,
        },
      )
      .afterClosed();
  }
}
