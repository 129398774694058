import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ScrollDetectionService {
  private marginOfErrorInPx = 5;
  private position$$ = new BehaviorSubject<{ distanceScrolled: number; distanceAvailable: number }>({
    distanceScrolled: 0,
    distanceAvailable: 0,
  });
  public position$ = this.position$$.pipe(shareReplay(1));
  public atBottom$ = this.position$.pipe(
    map(
      ({ distanceScrolled, distanceAvailable }) =>
        distanceAvailable !== 0 && distanceAvailable - distanceScrolled < this.marginOfErrorInPx,
    ),
  );

  public updateScrollPosition(distanceScrolled: number, distanceAvailable: number) {
    this.position$$.next({ distanceScrolled, distanceAvailable });
  }
}
