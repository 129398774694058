import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { NetworkableDestroyableComponent } from '../../networkable-destroyable.component';
import { LohiLoadAllowedChargeType } from '../../../../modules/carrier/carrier-loads/carrier-loads.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LohiLoad } from '../../../global-types';
import { LoadBillingService } from '../../../services/load-billing.service';
import { combineLatestWith, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'td-load-billing-request-charge',
  templateUrl: './load-billing-request-charge.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadBillingRequestChargeComponent extends NetworkableDestroyableComponent {
  public tourChargeAddForm = this.fb.group({
    allowedChargeTypeId: new FormControl<number>(null, [Validators.required, Validators.minLength(4)]),
    requestedCents: new FormControl<number>(0, [Validators.required, Validators.min(0)]),
    carrierNotes: new FormControl<string>(null, []),
    chassisNumber: new FormControl<string>(this.data.load.chassisNumber, []),
  });
  public chargeTypes$: Observable<LohiLoadAllowedChargeType[]>;
  public requiresChassis$: Observable<boolean>;

  constructor(
    private fb: FormBuilder,
    private matDialogRef: MatDialogRef<LoadBillingRequestChargeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { load: LohiLoad },
    public loadBillingService: LoadBillingService,
  ) {
    super();

    this.chargeTypes$ = this.loadBillingService.chargeTypes$;
    this.requiresChassis$ = this.tourChargeAddForm.controls.allowedChargeTypeId.valueChanges.pipe(
      combineLatestWith(this.chargeTypes$),
      map(([allowedChargeTypeId, chargeTypes]) => {
        const chargeType = chargeTypes.find((ct) => ct.id === allowedChargeTypeId);
        return chargeType ? chargeType.displayName.includes('Chassis') && this.data.load?.isDrayage : false;
      }),
      tap((requiresChassis) => {
        if (requiresChassis) {
          this.tourChargeAddForm.controls.chassisNumber.setValidators([Validators.required]);
          this.tourChargeAddForm.controls.chassisNumber.setValue(this.data.load.chassisNumber);
        } else {
          this.tourChargeAddForm.controls.chassisNumber.setValidators([]);
          this.tourChargeAddForm.controls.chassisNumber.setValue(null);
        }
        this.tourChargeAddForm.controls.chassisNumber.updateValueAndValidity();
      }),
    );
  }

  public async addCharge() {
    if (!this.tourChargeAddForm.valid || this.networkActive$$.value) {
      return;
    }
    try {
      this.networkActive$$.next(true);

      const val = this.tourChargeAddForm.getRawValue();
      const success = await this.loadBillingService.requestNewCharge(
        this.data.load.id,
        val.allowedChargeTypeId,
        val.requestedCents,
        val.carrierNotes,
        val.chassisNumber,
      );
      if (success) {
        this.matDialogRef.close();
      }
    } finally {
      this.networkActive$$.next(false);
    }
  }
}
