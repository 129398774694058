import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe, TitleCasePipe } from '@angular/common';
import { SideNavComponent } from './side-nav/side-nav.component';
import { MapPipe } from './pipes/map.pipe';
import { TrackByKeyPipe } from './pipes/track-by-key.pipe';
import { StandardDatePipe } from './pipes/standard-date.pipe';
import { StandardTimePipe } from './pipes/standard-time.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { NumberBadgeComponent } from './components/number-badge/number-badge.component';
import { CurrencyInPenniesPipe } from './pipes/currency-in-pennies.pipe';
import { DashIfNothingPipe } from './pipes/dash-if-nothing.pipe';
import { FileUploadTypePipe } from './pipes/upload-file-type.pipe';
import { DismissReasonPipe } from './pipes/dismiss-reason.pipe';
import { IsPhonePipe } from './pipes/is-phone.pipe';
import { PageTitleDirective } from './directives/page-title.directive';
import { HighlightTextDirective } from './directives/highlight-text.directive';
import { RouterModule } from '@angular/router';
import { IsImagePipe } from './pipes/is-image.pipe';
import { CompareWithPipe } from './pipes/compare-with.pipe';
import { CurrencyInputDirective } from './directives/money.directive';
import { NumberInputDirective } from './directives/number-input.directive';
import { RecordCallDirective } from './directives/record-call.directive';
import { TrailerTypePipe } from './pipes/trailer-type.pipe';
import { IsArrayPipe } from './pipes/is-array.pipe';
import { AddressSearchComponent } from './components/address-search/address-search.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LohiLoadStatusPipe } from './pipes/lohi-load-status.pipe';
import { AddMinutesPipe } from './pipes/add-minutes.pipe';
import { NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { AgoPipe } from './pipes/ago.pipe';
import { VersioningComponent } from './components/versioning/versioning.component';
import { MinutesToHoursAndMinutesPipe } from './pipes/minutes-to-hours-and-minutes.pipe';
import { HoursToDaysAndHoursPipe } from './pipes/hours-to-days-and-hours.pipe';
import { JoinWithAndPipe } from './pipes/join-with-and.pipe';
import { CompanyInfoComponent } from './components/company-info/company-info.component';
import { JoinWithArrowRightPipe } from './pipes/join-with-arrow-right.pipe';
import { InHoursPipe } from './pipes/in-hours.pipe';
import { IsBeforePipe } from './pipes/is-before.pipe';
import { StopMapComponent } from './components/stop-map/stop-map.component';
import { SumPipe } from './pipes/sum.pipe';
import { IsAfterPipe } from './pipes/is-after.pipe';
import { MoneyNumberLineComponent } from './components/money-number-line/money-number-line.component';
import { NameToInitialsPipe } from './pipes/name-to-initials.pipe';
import { EditLoadDetailsComponent } from './components/edit-load-details/edit-load-details.component';
import { EditLoadStopComponent } from './components/edit-load-stop/edit-load-stop.component';
import { InTimezonePipe } from './pipes/in-timezone.pipe';
import { LoadListStatusPipe } from './pipes/load-list-status.pipe';
import { CheckCallStatusPipe } from './pipes/check-call-status.pipe';
import { CreateAdminComponent } from './components/create-admin/create-admin.component';
import { CreateDriverComponent } from './components/create-driver/create-driver.component';
import { DatePickerDirective } from './directives/date-picker.directive';
import { HasPermissionDirective } from './directives/has-permission.directive';
import { CarrierContactTypePipe } from './pipes/contact-type.pipe';
import { PhotoGalleryComponent } from './components/photo-gallery/photo-gallery.component';
import { LightboxComponent } from './components/lightbox/lightbox.component';
import { CityPipe } from './pipes/city.pipe';
import { CitiesPipe } from './pipes/cities.pipe';
import { StatesPipe } from './pipes/states.pipe';
import { ReturnHomeFreqPipe } from './pipes/return-home-freq.pipe';
import { MultiStopPipe } from './pipes/multi-stop.pipe';
import { DropTrailerPipe } from './pipes/drop-trailer.pipe';
import { DriverTouchPipe } from './pipes/driver-touch.pipe';
import { AdditionalEquipmentPipe } from './pipes/additional-equipment.pipe';
import { TrailerAttributesPipe } from './pipes/trailer-attributes.pipe';
import { MaterialModule } from './material/material.module';
import { PushPipe } from './pipes/push.pipe';
import { CountdownPipe } from './pipes/countdown.pipe';
import { DifferenceInMinutesPipe } from './pipes/difference-in-minutes.pipe';
import { DayOfWeekPipe } from './pipes/day-of-week.pipe';
import { SortIndicatorComponent } from './components/sort-indicator/sort-indicator.component';
import { CancelledCausePipe } from './pipes/cancelled-cause.pipe';
import { CancelLoadComponent } from './components/cancel-load/cancel-load.component';
import { JoinPhonesWithAndPipe } from './pipes/join-phones-with-and.pipe';
import { DriverCommentsSectionComponent } from './components/driver-comments-section/driver-comments-section.component';
import { DriverCommentComponent } from './components/driver-comments-section/driver-comment/driver-comment.component';
import { InternalDirective } from './directives/internal.directive';
import { InternalOrExternalDispatcherDirective } from './directives/internalOrExternal.directive';
import { MarketHeatTypePipe } from './pipes/market-heat-type.pipe';
import { IsFranchiseDirective } from './directives/is-franchise.directive';
import { CompetitionMetricPipe } from './pipes/competition-metric.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { DriverProfileEditOtherPreferencesComponent } from './components/profile/driver-profile-edit-other-preferences/driver-profile-edit-other-preferences.component';
import { DriverProfileEditEquipmentPreferencesComponent } from './components/profile/driver-profile-edit-equipment-preferences/driver-profile-edit-equipment-preferences.component';
import { DriverProfileEditLoadPreferencesComponent } from './components/profile/driver-profile-edit-load-preferences/driver-profile-edit-load-preferences.component';
import { DriverProfileEditGeneralComponent } from './components/profile/driver-profile-edit-general/driver-profile-edit-general.component';
import { LoadBillingComponent } from './components/load-billing/load-billing.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { PlusMorePipe } from './pipes/plus-more.pipe';
import { NoSafeUrlPipe } from './pipes/no-safe-url.pipe';
import { CalendarTagColorPipe } from './pipes/calendar-tag-color.pipe';
import { KpiDisplayDurationPipe } from './pipes/kpi-display-duration.pipe';
import { FranchiseOrInternalDirective } from './directives/franchise-or-internal.directive';
import { DialpadPipe } from './pipes/dialpad.pipe';
import { BrokerCheckCallTypePipe } from './pipes/broker-check-call-type.pipe';
import { TimezoneNamePipe } from './pipes/timezone-name.pipe';
import { CommentUnavailableStatusPipePipe } from './pipes/comment-unavailable-status-pipe.pipe';
import { RatingEmojiComponent } from './components/rating-emoji/rating-emoji.component';
import { DriverCalendarDayNightComponent } from './components/driver-calendar-day-night/driver-calendar-day-night.component';
import { StandardDateOnlyPipe } from './pipes/standard-date-only.pipe';
import { FeatureFlagDirective } from './directives/feature-flag.directive';
import { DrayageStatusPipe } from './pipes/drayage-status.pipe';
import { StopTypePipe } from './pipes/stop-type.pipe';
import { CreateCustomerComponent } from './components/create-customer/create-customer.component';
import { TextToDateComponent } from './components/text-to-date/text-to-date.component';
import { FeatureFlagPipe } from './pipes/feature-flag.pipe';
import { CoverageStrategyPipe } from './pipes/coverage-strategy.pipe';
import { CarrierTypePipe } from './pipes/carrier-type.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { FileUploadTypesPipe } from './pipes/file-upload-types.pipe';
import { DistinctPipe } from './pipes/distinct.pipe';
import { IsPastPipe } from './pipes/is-past.pipe';
import { IsFuturePipe } from './pipes/is-future.pipe';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { TooltipModule } from 'ng2-tooltip-directive-major-angular-updates';
import { AutodispatchStatusPipe } from './pipes/autodispatch-status.pipe';
import { SideNavCarrierComponent } from './side-nav/side-nav-carrier/side-nav-carrier.component';
import { SideNavInternalComponent } from './side-nav/side-nav-internal/side-nav-internal.component';
import { CompanySwitcherSharedComponent } from './components/company-switcher-shared/company-switcher-shared.component';
import { CommandPalletComponent } from './components/command-pallet/command-pallet.component';
import { CarrierTouchpointComponent } from './components/carrier-touchpoint/carrier-touchpoint.component';
import { FirstIncompletePipe } from './pipes/first-incomplete.pipe';
import { StopsRemainingPipe } from './pipes/stops-remaining.pipe';
import { DoubleConfirmComponent } from './components/double-confirm/double-confirm.component';
import { UserPayModelPipe } from './pipes/user_pay_model.pipe';
import { ScrollIntoViewContainerDirective } from './directives/scroll-into-view-container.directive';
import { ScrollIntoViewAnchorDirective } from './directives/scroll-into-view-anchor.directive';
import { ScrollIntoViewSpacerDirective } from './directives/scroll-into-view-spacer.directive';
import { UserIdMatchDirective } from './directives/user-id-match.directive';
import { NoSafeHtmlPipe } from './pipes/no-safe-html.pipe';
import { LoadingTypePipe } from './pipes/loading-type.pipe';
import { PaymentLoadingTypePipe } from './pipes/payment-loading-type.pipe';
import { OrdinalPipe } from './pipes/ordinal.pipe';
import { StaticMapComponent } from './components/static-map/static-map.component';
import { DisputeStatusPipe } from './pipes/dispute-status.pipe';
import { LoadRateconPaymentStatusComponent } from './components/load-ratecon-payment-status/load-ratecon-payment-status.component';
import { RoundPipe } from './pipes/round.pipe';
import { ConfirmApproveForInvoicingComponent } from './components/load-billing/confirm-approve-for-invoicing/confirm-approve-for-invoicing.component';
import { FusePipe } from './pipes/fuse.pipe';
import { TrailerStatusPipe } from './pipes/trailer-status.pipe';
import { LoadTimelineComponent } from './load-timeline/load-timeline.component';
import { LoadTimelineMapComponent } from './load-timeline/load-timeline-map/load-timeline-map.component';
import { SelectCustomerComponent } from './components/select-customer/select-customer.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { LoadBillingRequestChargeComponent } from './components/load-billing/load-billing-request-charge/load-billing-request-charge.component';
import { ConfirmationModalReasonComponent } from './components/confirmation-modal-reason/confirmation-modal-reason.component';
import { LoadBillingRequestChargeUpdateComponent } from './components/load-billing/load-billing-request-charge-update/load-billing-request-charge-update.component';
import { LoadBillingChargeListComponent } from './components/load-billing/load-billing-charge-list/load-billing-charge-list.component';
import { LoadBillingPendingChargeListComponent } from './components/load-billing/load-billing-pending-charge-list/load-billing-pending-charge-list.component';
import { LoadBillingPromptDeleteUploadComponent } from './components/load-billing/load-billing-prompt-delete-upload/load-billing-prompt-delete-upload.component';
import { GeojsonMapComponent } from './components/geojson-map/geojson-map.component';
import { RFC3339DateOnlyPipe } from './pipes/rfc3339-date-only';
import { MinutesToTimeOfDayPipe } from './pipes/minutes-to-time-of-day.pipe';
import { AddressSearchV2Component } from './components/address-search-v2/address-search-v2.component';
import { ShiplineImageComponent } from './components/shipline-image/shipline-image.component';
import { DarkModeClassDirective } from './directives/dark-mode-class.directive';
import { UpdateWorkPhoneComponent } from './components/update-work-phone/update-work-phone.component';
import { CpgUfgPromptMarkUfgComponent } from './components/cpg-ufg-prompt-mark-ufg/cpg-ufg-prompt-mark-ufg.component';
import { TierTypeGradientPipe } from './pipes/tier-type-gradient.pipe';
import { TierTypeTextColorPipe } from './pipes/tier-type-text-color.pipe';
import { TierTypeIconSourcePipe } from './pipes/tier-type-icon-source.pipe';
import { AscensionLevelIconSourcePipe } from './pipes/ascension-level-icon-source.pipe';
import { FusedSearchElementComponent } from './components/fused-search-element/fused-search-element.component';
import { EnumPipe } from './pipes/enum.pipe';
import { PriceQADecisionPipe } from './pipes/price-qa-decision.pipe';
import { EapSummaryComponent } from './components/eap-summary/eap-summary.component';
import { WhyThisEapComponent } from './components/why-this-eap/why-this-eap.component';
import { PolygonalGeofenceFacilityComponent } from './components/polygonal-geofence-facility/polygonal-geofence-facility.component';

const tooltipOptions = {
  placement: 'top',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'show-delay': 200,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'tooltip-class': 'tooltip-overrides',
};

@NgModule({
  declarations: [
    TrackByKeyPipe,
    SideNavComponent,
    MapPipe,
    StandardDatePipe,
    StandardTimePipe,
    PhonePipe,
    NumberBadgeComponent,
    CurrencyInPenniesPipe,
    DashIfNothingPipe,
    FileUploadTypePipe,
    DismissReasonPipe,
    IsPhonePipe,
    PageTitleDirective,
    HighlightTextDirective,
    IsImagePipe,
    CompareWithPipe,
    CurrencyInputDirective,
    NumberInputDirective,
    RecordCallDirective,
    TrailerTypePipe,
    IsArrayPipe,
    AddressSearchComponent,
    LohiLoadStatusPipe,
    AddMinutesPipe,
    AgoPipe,
    VersioningComponent,
    MinutesToHoursAndMinutesPipe,
    JoinWithAndPipe,
    CompanyInfoComponent,
    JoinWithArrowRightPipe,
    DriverCommentsSectionComponent,
    DriverCommentComponent,
    InHoursPipe,
    IsBeforePipe,
    StopMapComponent,
    SumPipe,
    IsAfterPipe,
    MoneyNumberLineComponent,
    NameToInitialsPipe,
    EditLoadDetailsComponent,
    EditLoadStopComponent,
    InTimezonePipe,
    LoadListStatusPipe,
    CheckCallStatusPipe,
    CreateAdminComponent,
    CreateDriverComponent,
    DatePickerDirective,
    HasPermissionDirective,
    CarrierContactTypePipe,
    PhotoGalleryComponent,
    LightboxComponent,
    CityPipe,
    CitiesPipe,
    StatesPipe,
    ReturnHomeFreqPipe,
    MultiStopPipe,
    DropTrailerPipe,
    DriverTouchPipe,
    AdditionalEquipmentPipe,
    TrailerAttributesPipe,
    HoursToDaysAndHoursPipe,
    PushPipe,
    CountdownPipe,
    DifferenceInMinutesPipe,
    DayOfWeekPipe,
    SortIndicatorComponent,
    CancelledCausePipe,
    CancelLoadComponent,
    JoinPhonesWithAndPipe,
    InternalDirective,
    InternalOrExternalDispatcherDirective,
    MarketHeatTypePipe,
    IsFranchiseDirective,
    CompetitionMetricPipe,
    SortPipe,
    DriverProfileEditOtherPreferencesComponent,
    DriverProfileEditEquipmentPreferencesComponent,
    DriverProfileEditLoadPreferencesComponent,
    DriverProfileEditGeneralComponent,
    LoadBillingComponent,
    PlusMorePipe,
    NoSafeUrlPipe,
    CalendarTagColorPipe,
    KpiDisplayDurationPipe,
    FranchiseOrInternalDirective,
    DialpadPipe,
    BrokerCheckCallTypePipe,
    TimezoneNamePipe,
    CommentUnavailableStatusPipePipe,
    RatingEmojiComponent,
    DriverCalendarDayNightComponent,
    StandardDateOnlyPipe,
    FeatureFlagDirective,
    DrayageStatusPipe,
    StopTypePipe,
    CreateCustomerComponent,
    TextToDateComponent,
    FeatureFlagPipe,
    CoverageStrategyPipe,
    CarrierTypePipe,
    FilterPipe,
    FileUploadTypesPipe,
    DistinctPipe,
    IsPastPipe,
    IsFuturePipe,
    ConfirmationModalComponent,
    AutodispatchStatusPipe,
    SideNavCarrierComponent,
    SideNavInternalComponent,
    CompanySwitcherSharedComponent,
    CommandPalletComponent,
    CarrierTouchpointComponent,
    FirstIncompletePipe,
    StopsRemainingPipe,
    DoubleConfirmComponent,
    UserPayModelPipe,
    ScrollIntoViewContainerDirective,
    ScrollIntoViewAnchorDirective,
    ScrollIntoViewSpacerDirective,
    UserIdMatchDirective,
    NoSafeHtmlPipe,
    LoadingTypePipe,
    PaymentLoadingTypePipe,
    OrdinalPipe,
    StaticMapComponent,
    DisputeStatusPipe,
    LoadRateconPaymentStatusComponent,
    RoundPipe,
    ConfirmApproveForInvoicingComponent,
    FusePipe,
    TrailerStatusPipe,
    LoadTimelineComponent,
    LoadTimelineMapComponent,
    SelectCustomerComponent,
    LoadBillingRequestChargeComponent,
    ConfirmationModalReasonComponent,
    LoadBillingRequestChargeUpdateComponent,
    LoadBillingChargeListComponent,
    LoadBillingPendingChargeListComponent,
    LoadBillingPromptDeleteUploadComponent,
    GeojsonMapComponent,
    RFC3339DateOnlyPipe,
    MinutesToTimeOfDayPipe,
    AddressSearchV2Component,
    ShiplineImageComponent,
    DarkModeClassDirective,
    UpdateWorkPhoneComponent,
    CpgUfgPromptMarkUfgComponent,
    TierTypeGradientPipe,
    TierTypeTextColorPipe,
    TierTypeIconSourcePipe,
    AscensionLevelIconSourcePipe,
    FusedSearchElementComponent,
    EnumPipe,
    PriceQADecisionPipe,
    EapSummaryComponent,
    WhyThisEapComponent,
    PolygonalGeofenceFacilityComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    TooltipModule.forRoot(tooltipOptions),
    RouterModule,
    ReactiveFormsModule,
    NgxMatNativeDateModule,
    NgxFileDropModule,
    ScrollingModule,
  ],
  exports: [
    TrackByKeyPipe,
    SideNavComponent,
    MapPipe,
    StandardDatePipe,
    StandardTimePipe,
    PhonePipe,
    NumberBadgeComponent,
    CurrencyInPenniesPipe,
    DashIfNothingPipe,
    FileUploadTypePipe,
    DismissReasonPipe,
    IsPhonePipe,
    PageTitleDirective,
    HighlightTextDirective,
    IsImagePipe,
    CompareWithPipe,
    CurrencyInputDirective,
    NumberInputDirective,
    RecordCallDirective,
    TrailerTypePipe,
    IsArrayPipe,
    AddressSearchComponent,
    LohiLoadStatusPipe,
    AddMinutesPipe,
    NgxMatNativeDateModule,
    AgoPipe,
    VersioningComponent,
    MinutesToHoursAndMinutesPipe,
    JoinWithAndPipe,
    CompanyInfoComponent,
    JoinWithArrowRightPipe,
    InHoursPipe,
    IsBeforePipe,
    StopMapComponent,
    SumPipe,
    IsAfterPipe,
    MoneyNumberLineComponent,
    NameToInitialsPipe,
    EditLoadDetailsComponent,
    EditLoadStopComponent,
    InTimezonePipe,
    LoadListStatusPipe,
    CheckCallStatusPipe,
    CreateAdminComponent,
    CreateDriverComponent,
    DatePickerDirective,
    HasPermissionDirective,
    CarrierContactTypePipe,
    PhotoGalleryComponent,
    LightboxComponent,
    CityPipe,
    CitiesPipe,
    StatesPipe,
    ReturnHomeFreqPipe,
    MultiStopPipe,
    DropTrailerPipe,
    DriverTouchPipe,
    AdditionalEquipmentPipe,
    TrailerAttributesPipe,
    HoursToDaysAndHoursPipe,
    PushPipe,
    CountdownPipe,
    DifferenceInMinutesPipe,
    DayOfWeekPipe,
    SortIndicatorComponent,
    CancelledCausePipe,
    JoinPhonesWithAndPipe,
    DriverCommentsSectionComponent,
    MarketHeatTypePipe,
    IsFranchiseDirective,
    InternalDirective,
    InternalOrExternalDispatcherDirective,
    CompetitionMetricPipe,
    SortPipe,
    DriverProfileEditOtherPreferencesComponent,
    DriverProfileEditEquipmentPreferencesComponent,
    DriverProfileEditLoadPreferencesComponent,
    DriverProfileEditGeneralComponent,
    LoadBillingComponent,
    DriverCommentComponent,
    PlusMorePipe,
    NoSafeUrlPipe,
    KpiDisplayDurationPipe,
    CalendarTagColorPipe,
    MaterialModule,
    DialpadPipe,
    BrokerCheckCallTypePipe,
    FranchiseOrInternalDirective,
    TimezoneNamePipe,
    CommentUnavailableStatusPipePipe,
    RatingEmojiComponent,
    DriverCalendarDayNightComponent,
    StandardDateOnlyPipe,
    FeatureFlagDirective,
    DrayageStatusPipe,
    StopTypePipe,
    CreateCustomerComponent,
    TextToDateComponent,
    FeatureFlagPipe,
    CoverageStrategyPipe,
    CarrierTypePipe,
    TooltipModule,
    AutodispatchStatusPipe,
    CommandPalletComponent,
    FirstIncompletePipe,
    StopsRemainingPipe,
    DoubleConfirmComponent,
    UserPayModelPipe,
    ScrollIntoViewContainerDirective,
    ScrollIntoViewAnchorDirective,
    ScrollIntoViewSpacerDirective,
    UserIdMatchDirective,
    IsFuturePipe,
    IsPastPipe,
    LoadingTypePipe,
    PaymentLoadingTypePipe,
    OrdinalPipe,
    StaticMapComponent,
    DisputeStatusPipe,
    LoadRateconPaymentStatusComponent,
    RoundPipe,
    FusePipe,
    TrailerStatusPipe,
    LoadTimelineComponent,
    LoadTimelineMapComponent,
    SelectCustomerComponent,
    ScrollingModule,
    GeojsonMapComponent,
    RFC3339DateOnlyPipe,
    MinutesToTimeOfDayPipe,
    AddressSearchV2Component,
    ShiplineImageComponent,
    DarkModeClassDirective,
    NoSafeHtmlPipe,
    CpgUfgPromptMarkUfgComponent,
    TierTypeGradientPipe,
    TierTypeTextColorPipe,
    TierTypeIconSourcePipe,
    AscensionLevelIconSourcePipe,
    FusedSearchElementComponent,
    EnumPipe,
    PriceQADecisionPipe,
    EapSummaryComponent,
    WhyThisEapComponent,
    PolygonalGeofenceFacilityComponent,
  ],
  providers: [
    CurrencyInPenniesPipe,
    StandardDatePipe,
    MinutesToHoursAndMinutesPipe,
    TrailerTypePipe,
    NoSafeUrlPipe,
    AgoPipe,
    LoadListStatusPipe,
    DrayageStatusPipe,
    DecimalPipe,
    InTimezonePipe,
    LohiLoadStatusPipe,
    TrailerStatusPipe,
    TitleCasePipe,
  ],
})
export class SharedModule {}
