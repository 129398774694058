import { Injectable } from '@angular/core';
import { ChannelService, ChatClientService, ThemeService } from 'stream-chat-angular';
import { AuthService, hasDispatcherPermissions } from './auth.service';
import { distinctUntilChanged, filter, shareReplay, startWith, take, takeWhile, tap } from 'rxjs/operators';
import { BehaviorSubject, debounceTime, from, lastValueFrom, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { NGXLogger } from 'ngx-logger';
import { HttpClient } from '@angular/common/http';
import { Channel } from 'stream-chat';

@Injectable({
  providedIn: 'root',
})
export class StreamService {
  private channel: Channel<any>;

  private unreadCount$$ = new BehaviorSubject<number>(0);
  public unreadCount$: Observable<number> = this.unreadCount$$.pipe(shareReplay(1));

  private streamReady$$ = new BehaviorSubject<boolean>(false);
  public streamReady$: Observable<boolean> = this.streamReady$$.pipe(shareReplay(1));

  private channelInit$$ = new BehaviorSubject<{
    name?: string;
    id?: string;
  }>({
    id: undefined,
    name: undefined,
  });

  constructor(
    private chatService: ChatClientService,
    private channelService: ChannelService,
    private authService: AuthService,
    private logger: NGXLogger,
    private http: HttpClient,
    private themeService: ThemeService,
  ) {
    this.setupStream();
    this.listenToTheme();
  }

  private async setupStream() {
    const userInfo = await lastValueFrom(
      this.authService.userInfo$.pipe(
        filter((v) => !!v),
        take(1),
      ),
    );
    await this.chatService.init(
      environment.streamChatAPIKey,
      {
        id: userInfo.id,
        username: userInfo.driverName,
        name: userInfo.driverName,
      },
      this.getStreamToken,
    );
    this.streamReady$$.next(true);
    this.setupUnreadCount();
    this.listenForChannelInit();
  }

  public async setupChannel(id: string, name?: string) {
    try {
      await this.getUser();
      const activeChannel = await lastValueFrom(this.channelService.activeChannel$.pipe(take(1)));
      if (activeChannel && activeChannel.id === id) {
        return;
      }
      this.channelService.reset();
      this.channelService.deselectActiveChannel();
      this.channel = this.chatService.chatClient.channel('messaging', id, {
        name: name || id,
      });
      await this.channel.create();
      this.channelInit$$.next({ id });
      this.channel.watch();
      const userInfo = await lastValueFrom(
        this.authService.userInfo$.pipe(
          filter((v) => !!v),
          take(1),
        ),
      );
      await this.channel.addMembers([userInfo.id]);
      if (name) {
        await this.channel.update({
          name,
        });
      }
    } catch (e) {
      this.logger.error(e);
    }
  }

  public getUnreadsForChannel$ = (id: string): Observable<number> => from(this.getUnreadsForChannel(id));

  public getUnreadsForChannel = async (id: string) => {
    await this.getUser();
    const channels = await this.chatService.chatClient.queryChannels({
      id: { $eq: id },
    });
    if (channels.length > 0) {
      return channels[0].countUnread();
    }
    return 0;
  };

  public getUnreadsForChannels$ = (ids: string[]): Observable<Record<string, number>> =>
    from(this.getUnreadsForChannels(ids));

  public getUnreadsForChannels = async (ids: string[]): Promise<Record<string, number>> => {
    try {
      await this.getUser();
      const channels = await this.chatService.chatClient.queryChannels(
        {
          id: { $in: ids },
        },
        {
          unread_count: -1,
        },
        { limit: 30 },
      );
      while (true) {
        const moreChannels = await this.chatService.chatClient.queryChannels(
          {
            id: { $in: ids },
          },
          {
            unread_count: -1,
          },
          {
            offset: channels.length,
            limit: 30,
          },
        );
        if (moreChannels.length === 0) {
          break;
        }
        channels.push(...moreChannels);
      }
      const unreads: Record<string, number> = {};
      channels.forEach((channel) => {
        unreads[channel.id] = channel.countUnread();
      });
      return unreads;
    } catch (e) {
      return {};
    }
  };

  private async getUser() {
    return await lastValueFrom(
      this.chatService.user$.pipe(
        filter((v) => !!v),
        take(1),
      ),
    );
  }

  private async getLohiUser() {
    return await lastValueFrom(
      this.authService.userInfo$.pipe(
        filter((v) => !!v),
        take(1),
      ),
    );
  }

  public getStreamToken = async (): Promise<string> => {
    try {
      const token = await lastValueFrom(
        this.http.get<{ token: string }>(`${environment.api}/v2/max_payloop/get_stream`),
      );
      return token.token;
    } catch (e) {
      return '';
    }
  };

  private listenToTheme() {
    this.themeService.theme$.next(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (event) => {
      this.themeService.theme$.next(event.matches ? 'dark' : 'light');
    });
  }

  public initChannels(query?: string) {
    this.channelInit$$.next({
      name: query,
    });
  }

  private listenForChannelInit() {
    this.channelInit$$
      .pipe(
        distinctUntilChanged((previous, current) => {
          return previous.id === current.id && previous.name === current.name;
        }),
        debounceTime(500),
      )
      .subscribe({
        next: (query) => {
          this.loadStreamChannels(query.name, query.id);
        },
      });
  }

  private async loadStreamChannels(query?: string, id?: string) {
    const userInfo = await this.getUser();
    const lohiUserInfo = await this.getLohiUser();
    await this.channelService.reset();
    if (id) {
      this.channelService.init({
        type: 'messaging',
        id,
      });
    } else {
      if (query) {
        this.channelService.init({
          type: 'messaging',
          members: { $in: [userInfo.id] },
          name: {
            $autocomplete: `5F Dispatch - ${query}`,
          },
        });
      } else {
        this.channelService.init({
          type: 'messaging',
          members: { $in: [userInfo.id] },
          name: {
            $autocomplete: '5F Dispatch',
          },
        });
      }
    }
  }

  private async markLLAsRead() {
    const userInfo = await lastValueFrom(
      this.authService.userInfo$.pipe(
        filter((v) => !!v),
        take(1),
      ),
    );
    const channels = await this.chatService.chatClient.queryChannels(
      {
        type: 'messaging',
        members: { $in: [userInfo.id] },
        name: {
          $autocomplete: 'LLL',
        },
      },
      {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        unread_count: -1,
      },
    );
    if (channels.length === 0) {
      return;
    }
    await this.chatService.chatClient.markChannelsRead({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      user_id: userInfo.id,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      read_by_channel: channels.reduce((previousValue, currentValue) => {
        previousValue[currentValue.cid] = '';
        return previousValue;
      }, {} as Record<string, string>),
    });
  }

  private setupUnreadCount() {
    this.chatService.chatClient.on((event) => {
      if (event.total_unread_count !== undefined) {
        this.unreadCount$$.next(event.total_unread_count);
      }
    });
    this.unreadCount$.pipe(take(1)).subscribe({
      next: () => {
        this.markLLAsRead();
      },
    });
  }
}
