<div matDialogTitle class="text-lg">✨ EAP Insights</div>
<div *ngIf="eapSummary$ | async as summary" matDialogContent>
  <div *ngIf="summary.chosenFacilityId !== eapStopFacilityId" class="p-3 rounded bg-red-350 text-black">
    This is not the facility we recommended for this EAP. Below is what the AI recommends.
  </div>
  <div *ngIf="summary.bestFacility as bestFac; else noRecommendedFacility">
    <div>Best Facility</div>
    <div class="font-semibold">
      🌟️ {{ bestFac.facilityName }} 🌟 <span class="capitalize">(🎯 {{ bestFac.targetType }})</span>
    </div>
    <div class="font-semibold mb-2 text-lg">
      Total Trailers {{ bestFac.totalTrailerCount | number }} ({{ bestFac.emptyTrailerCount | number }} Empty)
    </div>
    <div class="flex gap-3 mb-2">
      <div
        *ngFor="let trailer of bestFac.trailers; trackBy: bestFac.trailers | trackByKey: 'id'"
        class="p-3 border rounded"
      >
        <div>
          <a class="font-semibold text-blue-500" routerLink="/drop_trailer/trailer/{{ trailer.id }}" target="_blank">{{
            trailer.name
          }}</a>
          <div><span class="font-semibold">Status:</span> {{ trailer.assetStatus | trailerStatus }}</div>
          <div><span class="font-semibold">Loaded Status:</span> {{ trailer.loadedStatus }}</div>
          <div><span class="font-semibold">Empty Probability:</span> {{ trailer.probabilityEmpty | percent }}</div>
        </div>
      </div>
    </div>
    <div *ngIf="bestFac.probResults as probResults" class="flex flex-col gap-2 my-3">
      <div class="font-semibold">Driver Insights</div>
      <div *ngIf="probResults.detourMiles !== null">
        Detour Miles: <span class="font-semibold">{{ probResults.detourMiles | number }} mile(s)</span>
      </div>
      <div *ngIf="probResults.estimatedDurationMinutes !== null">
        ETA To Pickup: <span class="font-semibold">{{ probResults.estimatedDurationMinutes | number }}</span> minutes
      </div>
    </div>
  </div>
  <ng-template #noRecommendedFacility>
    <div>
      There are no recommended facilities for an EAP, this usually means all empties are spoken for and if you claim one
      of them you will <span class="font-semibold text-red-400">ruin autodispatch</span>
    </div>
  </ng-template>
  <mat-form-field class="w-full">
    <mat-label>View Other Facility Stats</mat-label>
    <mat-select [formControl]="selectedFacilityFC">
      <mat-option>
        <ngx-mat-select-search
          placeholderLabel="Search"
          noEntriesFoundLabel="No match found"
          [formControl]="facilitySearchFC"
        ></ngx-mat-select-search>
      </mat-option>
      <mat-option
        *ngFor="let fac of filteredFacilities$ | async; trackBy: filteredFacilities$ | trackByKey: 'id'"
        [value]="fac"
      >
        {{ fac.facilityName }}&nbsp;<span class="capitalize">(🎯 {{ fac.targetType }})</span> - Total Trailers
        {{ fac.totalTrailerCount | number }}
        <ng-container *ngIf="fac.targetType === 'empty'; else onlyEmpty">
          (<span [class.text-red-500]="fac.emptyTrailerCount <= fac.targetCount"
            >{{ fac.emptyTrailerCount | number }} Empty
          </span>
          / {{ fac.targetCount | number }} Target)
        </ng-container>
        <ng-template #onlyEmpty> ({{ fac.emptyTrailerCount | number }} Empty)</ng-template>
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div *ngIf="selectedFacility as selectedFac" class="mt-3">
    <div>Your Selection</div>
    <div class="font-semibold">
      {{ selectedFac.facilityName }} (<span class="capitalize">🎯 {{ selectedFac.targetType }})</span>
    </div>
    <div class="font-semibold mb-2 text-lg">
      Total Trailers {{ selectedFacility.totalTrailerCount | number }} ({{
        selectedFacility.emptyTrailerCount | number
      }}
      Empty)
    </div>
    <div class="flex gap-3 mb-2">
      <div
        *ngFor="let trailer of selectedFacility.trailers; trackBy: selectedFacility.trailers | trackByKey: 'id'"
        class="p-3 border rounded"
      >
        <div>
          <a class="font-semibold text-blue-500" routerLink="/drop_trailer/trailer/{{ trailer.id }}" target="_blank">{{
            trailer.name
          }}</a>
          <div><span class="font-semibold">Status:</span> {{ trailer.assetStatus | trailerStatus }}</div>
          <div><span class="font-semibold">Loaded Status:</span> {{ trailer.loadedStatus }}</div>
          <div><span class="font-semibold">Empty Probability:</span> {{ trailer.probabilityEmpty | percent }}</div>
        </div>
      </div>
    </div>
    <div *ngIf="selectedFac.probResults as probResults" class="flex flex-col gap-2 my-3">
      <div class="font-semibold">Driver Insights</div>
      <div *ngIf="probResults.detourMiles !== null">
        Detour Miles: <span class="font-semibold">{{ probResults.detourMiles | number }} mile(s)</span>
      </div>
      <div *ngIf="probResults.estimatedDurationMinutes !== null">
        ETA To Pickup: <span class="font-semibold">{{ probResults.estimatedDurationMinutes | number }}</span> minutes
      </div>
    </div>
  </div>
</div>
<div matDialogActions>
  <div></div>
  <button mat-flat-button color="primary" matDialogClose>Close</button>
</div>
