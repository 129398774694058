import { Pipe, PipeTransform } from '@angular/core';
import { SupportedLoadingType } from '../global-types';

const mappedSupportedTypes: Record<SupportedLoadingType, string> = {
  [SupportedLoadingType.live]: 'Live',
  [SupportedLoadingType.drop]: 'Drop',
  [SupportedLoadingType.liveDrop]: 'Live & Drop',
};

@Pipe({
  name: 'paymentLoadingType',
})
export class PaymentLoadingTypePipe implements PipeTransform {
  public transform(value: SupportedLoadingType): string {
    return mappedSupportedTypes[value] || value;
  }
}
