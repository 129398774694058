import { Pipe, PipeTransform } from '@angular/core';
import { TimezoneResponse, TimezoneService } from '../services/timezone.service';
import { LongLat, XYPoint } from '../global-types';
import { catchError, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Pipe({
  name: 'timezoneName',
})
export class TimezoneNamePipe implements PipeTransform {
  constructor(private tzService: TimezoneService) {}

  public transform(value: LongLat | XYPoint, abbreviation = true): Observable<string> {
    if (!value) {
      return of(null);
    }
    return this.tzService
      .queryTimezoneForLocation$(
        (value as XYPoint).y || (value as LongLat).latitude,
        (value as XYPoint).x || (value as LongLat).longitude,
      )
      .pipe(
        map((v) => (abbreviation ? v.abbreviation : v.name)),
        catchError(() => of(null)),
      );
  }
}
