import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { catchError, Observable, switchMap, throwError } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { SnackBarService } from './snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService {
  constructor(private authService: AuthService, private afAuth: AngularFireAuth, private snackbar: SnackBarService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.isLoggedIn$.pipe(
      filter((isLoggedIn) => !!isLoggedIn),
      switchMap(() => this.afAuth.idToken),
      take(1),
      switchMap((token) => {
        const copy = req.clone({
          setHeaders: {
            /* eslint-disable @typescript-eslint/naming-convention */
            Authorization: `Bearer ${token}`,
            'web-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
            'web-timezone-offset-minutes': `${new Date().getTimezoneOffset()}`,
            'x-api-web-app': 'dispatcher',
            /* eslint-enable @typescript-eslint/naming-convention */
          },
        });
        return next.handle(copy).pipe(
          catchError((err) => {
            if (localStorage.getItem('HIDE_ALL_ERRORS') !== 'true') {
              this.logError(err, true);
            }
            return throwError(err);
          }),
        );
      }),
    );
  }

  private logError(error: any, showSnackbar: boolean = true) {
    if (error instanceof HttpErrorResponse && showSnackbar) {
      if (error.error?.isClientError) {
        this.snackbar.showError(error.error.messageToUser);
      } else {
        this.snackbar.showError('Unexpected Error Occurred Processing Request');
      }
    }
  }
}
