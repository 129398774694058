import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, Subject, switchMap, tap } from 'rxjs';
import { map, shareReplay, throttleTime } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { once } from 'remeda';
import { GanttView, PotentialDriver, SpotLoadDriverInvite, UFGLoadListItem } from './cpg-ufg.types';
import { environment } from '../../../../environments/environment';
import { SimpleFinn } from '../../../shared/utilities/simpleFinn';
import { LohiLoad } from '../../../shared/global-types';

@Injectable({
  providedIn: 'root',
})
export class CpgUfgService {
  private isCpgUfg$$ = new BehaviorSubject<boolean>(false);
  public isCpgUfg$ = this.isCpgUfg$$.asObservable().pipe(shareReplay(1));

  private loads: SimpleFinn<UFGLoadListItem[]> = new SimpleFinn([], () => this.getLoadsForCarrier());
  public loadsLoading$ = this.loads.networkActive$;
  public get loads$() {
    return this.loads.get$();
  }
  public openLoads$ = this.loads$.pipe(
    map((loads) => loads.filter((load) => load.loadStatusInfo.ufgStatus === 'unassigned')),
    shareReplay(1),
  );
  public claimedLoads$ = this.loads$.pipe(
    map((loads) => loads.filter((load) => load.loadStatusInfo.ufgStatus !== 'unassigned')),
    shareReplay(1),
  );
  private ganttView = new SimpleFinn<GanttView>(null, () => this.getGanttView());

  public get ganttView$() {
    return this.ganttView.get$();
  }

  constructor(private httpClient: HttpClient) {
    this.loadIsCpgUfg();
  }

  // this won't change after the first time it's called, wrapping in once, so you really have to think about why you are unwrapping it
  private loadIsCpgUfg = once(() => {
    this.httpClient
      .get<{
        inPool: boolean;
      }>(`${environment.api}/v2/vpf/company_admin/ufgv3/in_dispatch_pool`)
      .subscribe((res) => {
        this.isCpgUfg$$.next(res.inPool || false);
      });
  });

  private getOpenLoads$(): Observable<UFGLoadListItem[]> {
    return this.httpClient
      .get<{ loads: UFGLoadListItem[] }>(`${environment.api}/v2/vpf/company_admin/ufgv3/ufg_loads_for_carrier`)
      .pipe(map((res) => res.loads || []));
  }

  private getClaimedLoads$(): Observable<UFGLoadListItem[]> {
    return this.httpClient
      .get<{ loads: UFGLoadListItem[] }>(`${environment.api}/v2/vpf/company_admin/ufgv3/assigned_ufg_loads`)
      .pipe(map((res) => res.loads || []));
  }

  private getLoadsForCarrier(): Promise<UFGLoadListItem[]> {
    return firstValueFrom(
      combineLatest([this.getOpenLoads$(), this.getClaimedLoads$()]).pipe(
        map(([openLoads, claimedLoads]) => [...openLoads, ...claimedLoads]),
      ),
    );
  }

  public async getPotentialDriversForLoad(loadId: string): Promise<PotentialDriver[]> {
    return await firstValueFrom(
      this.httpClient
        .get<{ drivers: PotentialDriver[] }>(`${environment.api}/v2/vpf/company_admin/ufgv3/${loadId}/drivers`)
        .pipe(map((res) => res.drivers || [])),
    );
  }

  public async assignDriverToLoad(loadId: string, driverId: string): Promise<boolean> {
    try {
      await firstValueFrom(
        this.httpClient.post<void>(`${environment.api}/v2/vpf/company_admin/ufgv3/${loadId}/assign_driver`, {
          driverId,
        }),
      );
      this.loads.get$();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async unassignDriverFromLoad(loadId: string, notes: string): Promise<boolean> {
    try {
      await firstValueFrom(
        this.httpClient.post<void>(`${environment.api}/v2/vpf/company_admin/ufgv3/${loadId}/unassign_driver`, {
          notes,
        }),
      );
      this.loads.get$();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async markNotInterested(loadId: string, notes: string): Promise<boolean> {
    try {
      await firstValueFrom(
        this.httpClient.post<void>(`${environment.api}/v2/vpf/company_admin/ufgv3/${loadId}/indicate_not_interested`, {
          notes,
        }),
      );
      this.loads.get$();
      return true;
    } catch (e) {
      return false;
    }
  }

  public createMinimalDriverForSpotLoad$(invite: SpotLoadDriverInvite): Observable<string> {
    return this.httpClient
      .post<{
        newUserId: string;
      }>(`${environment.api}/v2/vpf/company_admin/ufgv3/create_minimal_driver_for_spot_load`, invite)
      .pipe(map((res) => res.newUserId));
  }

  private getGanttView(): Promise<GanttView> {
    return firstValueFrom(
      this.httpClient
        .get<{ ganttView: GanttView }>(`${environment.api}/v2/vpf/company_admin/ufgv3/drivers_gantt_chart_data`)
        .pipe(map((res) => res.ganttView)),
    );
  }

  public refreshLoadList() {
    this.loads.get$();
  }

  public async getLoadDetails(loadId: string) {
    return await firstValueFrom(
      this.httpClient
        .get<{ loadDetails: LohiLoad }>(`${environment.api}/v2/vpf/company_admin/ufgv3/${loadId}/details`)
        .pipe(map((res) => res.loadDetails)),
    );
  }
}
