import { Pipe, PipeTransform } from '@angular/core';

export const joinWithArrowRight = (arr: any[]) => {
  if (arr.length === 1) {
    return arr[0];
  }

  return arr.join(' → ');
};

@Pipe({
  name: 'joinWithArrowRight',
})
export class JoinWithArrowRightPipe implements PipeTransform {
  public transform(value: any[]): string {
    if (!Array.isArray(value)) {
      return value;
    }

    if (!value.length) {
      return null;
    }

    return joinWithArrowRight(value);
  }
}
