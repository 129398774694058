import { AbstractControl, UntypedFormControl } from '@angular/forms';

export const validateTrimmed = (control: UntypedFormControl | AbstractControl) => {
  const isWhitespace = (control.value || '')?.trim().length === 0;
  const isNotWhitespace = !isWhitespace;
  return isNotWhitespace ? null : { whitespace: true };
};

export const valueTrimmed = (value: string | null): string | null => {
  if (!value) {
    return null;
  }
  const trim = value?.trim();
  if (trim.length === 0) {
    return null;
  }
  return trim;
};
