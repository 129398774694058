import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe, formatDate } from '@angular/common';
import { isDate, isToday, isTomorrow } from 'date-fns';
import { getUserShortTimezone } from '../utilities/dateTime';

@Pipe({
  name: 'standardDate',
})
export class StandardDatePipe extends DatePipe implements PipeTransform {
  // @ts-ignore
  public transform(value: any, format?: string, timezone?: string, locale?: string): string | null {
    if (!value) {
      return '—';
    }
    if (format) {
      return super.transform(value, format, timezone, locale);
    }
    // This is a nullable time from the server
    if (value.constructor === Object) {
      if (!value.valid || !value.time) {
        return '—';
      }
      return super.transform(value.time, standardDateFormat(value.time));
    }

    return super.transform(value, standardDateFormat(value));
  }
}

export const standardDateFormat = (value: any): string => {
  const timezone = getUserShortTimezone() || '';

  const asDate = new Date(value);
  let formattingText = `h:mmaaaaa'm' '${timezone}' M/dd/yyyy`;
  if (isToday(asDate)) {
    formattingText = `h:mmaaaaa'm' '${timezone}' 'Today'`;
  } else if (isTomorrow(asDate)) {
    formattingText = `h:mmaaaaa'm' '${timezone}' 'Tomorrow'`;
  }
  return formattingText;
};

export function formatDateStandard(value: any): string {
  if (!isDate(value)) {
    value = new Date(value);
  }
  const formatStr = standardDateFormat(value);
  return formatDate(value, formatStr, 'en-US');
}
