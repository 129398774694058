import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SimpleFinn } from '../../../shared/utilities/simpleFinn';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject, EMPTY, firstValueFrom, lastValueFrom, Observable, of, switchMap, tap } from 'rxjs';
import { CARRIER_ID } from './carrier-list-consts';
import { RouterStateService } from '../../../shared/services/router-state.service';
import { catchError, map, shareReplay } from 'rxjs/operators';
import {
  BasicUser,
  CarrierContactType,
  CarrierTouchpointType,
  CarrierType,
  LohiUploadedFile,
  LongLat,
  TrailerType,
} from '../../../shared/global-types';
import { SpecialRequirement } from '../../../shared/services/constants.service';
import { RouterFinn } from '../../../shared/utilities/routerFinn';

export interface CarrierListResponse {
  carriersInfoItems: CarriersInfoItem[];
  carriers: CarrierListItem[];
}

export interface CarriersInfoItem {
  carrierType: CarrierType;
  activeCarriers: number;
  activeDrivers: number;
  completedLoads: number;
  olderCompletedLoads: number;
  olderActiveCarrierCount: number;
}

export interface CarrierAdmin {
  email: string;
  id: string;
  name: string;
  phoneNumber: string;
}

export interface CarrierListItem {
  id: string;
  name: string;
  dotNumber?: string;
  mcNumber?: string;
  createdAt: string | null;
  totalCompleted?: number;
  totalCompletedLastThirtyDays?: number;
  lastDeliveryAt?: string;
  contactPhone: string[];
  carrierRepresentativeId: string;
  carrierRepresentativeName: string;
  isActive: boolean | null;
  ownerName: string;
  types: CarrierType[];
  isCompany: boolean | null;
  phoneAdmins: CarrierAdmin[];
  emailAdmins: CarrierAdmin[];
  lastTouchpointType: CarrierTouchpointType;
  lastTouchpointLoadID: string;
  lastTouchpointNotes: string;
  lastTouchpointCreatedAt: string;
  lastTouchpointCreatedBy: string;
  isCPGCarrier: boolean;
  isTourCarrier: boolean;
  isDomesticCarrier: boolean;
  isImportExportCarrier: boolean;
  carrierPointOfContactID: string;
  carrierPointOfContactName: string;
  carrierPointOfContactPhone: string;
  carrierPointOfContactEmail: string;
  truckCount: number;
  truckCountUpdatedAt: string;
  intermodalChassisCount: number;
  intermodalChassisCountUpdatedAt: string;
  ownsYard: boolean;
  ownsLift: boolean;
  ownsWarehouse: boolean;
  profileData: ProfileData;
}

export interface ProfileData {
  assetBased: boolean;
  assetTypes: string[];
  dispatchers: Dispatcher[];
  driverCount: number;
  willDoInterstateLoads: boolean;
  smsLoadRecommendationsEnabled: boolean;
  emailLoadRecommendationsEnabled: boolean;
}

export interface Dispatcher {
  name: string;
  type: string;
  email: string;
  phoneNumber: string;
}

export interface CarrierLoadboardLink {
  url: string;
}

export interface CarrierDetail {
  id: string;
  name: string;
  dotNumber?: string;
  mcNumber?: string;
  scac?: string;
  totalCompleted?: number;
  lastDeliveryAt?: string;
  onboarded: boolean;
  contactPhone: string[];
  driverCount: number;
  isAssetBased: boolean | null;
  trailerTypes: TrailerType[];
  willDoInterstateLoads: boolean | null;
  smsLoadRecommendationsEnabled: boolean | null;
  emailLoadRecommendationsEnabled: boolean | null;
  carrierRepresentativeId: string;
  carrierRepresentativeName: string;
  isBannedFromLoadBooking: boolean | null;
  bannedFromLoadBookingBy: string | null;
  bannedFromLoadBookingReason: string | null;
  using5FOTRDispatch: boolean | null;
  oaHasNextDayPay: boolean | null;
  drivers: {
    id: string;
    name: string;
    tier: string;
  }[];
  createdAt: string;
  createdBy: string;
}

export interface CarrierContact {
  id: number;
  name: string;
  email: string;
  phone: string;
  contactType: CarrierContactType;
}

export interface CarrierContacts {
  carrierContacts: CarrierContact[];
}

export interface CarrierCompletedLoad {
  id: string;
  stopCount: number;
  stopAddresses: string[];
  shipperName: string;
  rateCents: number;
  completedAt: string;
}

export interface CarrierCompletedLoads {
  carrierCompletedLoads: CarrierCompletedLoad[];
}

export interface LaneOfInterest {
  id: string;
  originCity: string;
  originState: string;
  originZip3?: string;
  originLngLat: LongLat;
  destinationCity: string;
  destinationState: string;
  destinationZip3?: string;
  destinationLngLat: LongLat;
  desiredRateCents: number | null;
  trailerTypes: TrailerType[];
  confirmedCarrier: boolean;
}

export interface CarrierLanesOfInterest {
  carrierLanesOfInterest: LaneOfInterest[];
}

export interface DispatcherUsers {
  dispatcherUsers: BasicUser[];
}

export interface CarrierFilesResponse {
  files: LohiUploadedFile[];
}

export interface KpiScorecard {
  loadsInTimespan: number;
  loadsAllTime: number;
  loadsInPastThirtyDays: number;
  revenueCentsInTimespan: number;
  revenueCentsAllTime: number;
  revenueCentsInPastThirtyDays: number;
  timespanBegin: string;
  timespanEnd: string;
  onTimeDeliveryStats: GeneralKPI;
  trackAndTraceStats: GeneralKPI;
  checkCallResponsivenessStats: CheckCallResponsivenessKPI;
  loadCloseoutStats: GeneralKPI;
}

export interface GeneralKPI {
  loadsPassing: string[];
  loadsFailing: string[];
  drivers: DriverKPI[];
}

export interface DriverKPI {
  driverId: string;
  driverName: string;
  loadsPassing: string[];
  loadsFailing: string[];
  driverKPI: number;
}

export interface CheckCallResponsivenessKPI {
  totalAnswered: number;
  totalCalls: number;
  loadsPassing: string[];
  loadsFailing: string[];
  drivers: CheckCallResponsivenessDriverKPI[];
}

export interface CheckCallResponsivenessDriverKPI {
  totalAnswered: number;
  totalCalls: number;
  driverId: string;
  driverName: string;
  loadsPassing: string[];
  loadsFailing: string[];
  driverKPI: number;
}

export interface CarrierAssets {
  truckCount: number;
  intermodalChassisCount: number;
  ownsYard: boolean;
  ownsLift: boolean;
  ownsWarehouse: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CarrierListService {
  private carriersResponse: SimpleFinn<CarrierListResponse>;
  private carriersLoading$$ = new BehaviorSubject<boolean>(false);
  public carriersLoading$ = this.carriersLoading$$.pipe(shareReplay(1));

  public get carriers$(): Observable<CarrierListItem[]> {
    return this.carriersResponse.get$().pipe(map((response: CarrierListResponse) => response.carriers));
  }

  public get carriersInfos$(): Observable<CarriersInfoItem[]> {
    return this.carriersResponse.get$().pipe(map((response: CarrierListResponse) => response.carriersInfoItems));
  }

  private assignableDispatcherUsers: SimpleFinn<BasicUser[]>;

  public get assignableDispatcherUsers$(): Observable<BasicUser[]> {
    return this.assignableDispatcherUsers.get$();
  }

  private carrierDetail$$ = new BehaviorSubject<CarrierDetail>(null);
  public carrierDetail$: Observable<CarrierDetail> = this.carrierDetail$$.pipe(shareReplay(1));

  private carrierContacts$$ = new BehaviorSubject<CarrierContact[]>(null);
  public carrierContacts$: Observable<CarrierContact[]> = this.carrierContacts$$.pipe(shareReplay(1));

  private carrierCompletedLoads$$ = new BehaviorSubject<CarrierCompletedLoad[]>(null);
  public carrierCompletedLoads$: Observable<CarrierCompletedLoad[]> = this.carrierCompletedLoads$$.pipe(shareReplay(1));

  private carrierLanesOfInterest$$ = new BehaviorSubject<LaneOfInterest[]>([]);
  public carrierLanesOfInterest$: Observable<LaneOfInterest[]> = this.carrierLanesOfInterest$$.pipe(shareReplay(1));

  private carrierFiles$$ = new BehaviorSubject<LohiUploadedFile[]>(null);
  public carrierFiles$: Observable<LohiUploadedFile[]> = this.carrierFiles$$.pipe(shareReplay(1));

  private carrierProfile$$ = new BehaviorSubject<{ profile: any; updatedAt: string }>(null);
  public carrierProfile$: Observable<{ profile: any; updatedAt: string }> = this.carrierProfile$$.pipe(shareReplay(1));

  private carrierScorecard$$ = new BehaviorSubject<KpiScorecard>(null);
  public carrierScorecard$: Observable<KpiScorecard> = this.carrierScorecard$$.pipe(shareReplay(1));

  private specialRequirements: RouterFinn<number[]>;
  public get specialRequirements$(): Observable<number[]> {
    return this.specialRequirements.get$();
  }

  private capacity: RouterFinn<number>;
  public get capacity$(): Observable<number> {
    return this.capacity.get$();
  }

  constructor(private http: HttpClient, private routerState: RouterStateService) {
    this.carriersResponse = new SimpleFinn<CarrierListResponse>(
      { carriers: [], carriersInfoItems: [] },
      this.loadCarriersResponse,
    );
    this.assignableDispatcherUsers = new SimpleFinn([], this.getDispatcherUsers);
    this.listenForCarrier();
    this.specialRequirements = new RouterFinn<number[]>(
      [],
      this.routerState.listenForParamChange$(CARRIER_ID),
      this.loadSpecialRequirements,
    );
    this.capacity = new RouterFinn<number>(null, this.routerState.listenForParamChange$(CARRIER_ID), this.loadCapacity);
  }

  private loadCarriersResponse = async (): Promise<CarrierListResponse> => {
    try {
      this.carriersLoading$$.next(true);
      const carriersResponse = await lastValueFrom(
        this.http.get<CarrierListResponse>(`${environment.api}/v2/max_payloop/dispatcher/carrier_list`),
      );
      carriersResponse.carriers = carriersResponse.carriers.sort((a, b) => a.name.localeCompare(b.name)) || [];

      return carriersResponse;
    } catch (e) {
      return { carriers: [], carriersInfoItems: [] };
    } finally {
      this.carriersLoading$$.next(false);
    }
  };

  private listenForCarrier() {
    this.routerState
      .listenForParamChange$(CARRIER_ID)
      .pipe(
        tap((value) => {
          if (!value) {
            this.carrierDetail$$.next(null);
            this.carrierContacts$$.next(null);
            this.carrierScorecard$$.next(null);
          } else if (value !== this.carrierDetail$$.value?.id) {
            this.carrierDetail$$.next(null);
            this.carrierContacts$$.next(null);
            this.carrierScorecard$$.next(null);
          }
        }),
        switchMap((carrierId) => {
          if (!carrierId) {
            return of(null as CarrierDetail);
          }
          return this.loadCarrierDetail(carrierId);
        }),
      )
      .subscribe((value) => {
        if (!value) {
          this.carrierDetail$$.next(null);
          return;
        }
        this.carrierDetail$$.next(value);
        if (value.id) {
          this.refreshCarrierContacts(value.id);
          this.refreshCarrierCompletedLoads(value.id);
          this.refreshCarrierLanesOfInterest(value.id);
          this.refreshCarrierFiles(value.id);
          this.refreshCarrierScorecard(value.id);
        }

        if (value.dotNumber) {
          this.refreshCarrierProfile(value.dotNumber);
        }
      });
  }

  private loadCarrierDetail(carrierId: string) {
    return this.http
      .get<CarrierDetail>(`${environment.api}/v2/max_payloop/dispatcher/carrier_detail/${carrierId}`)
      .pipe(catchError(() => EMPTY));
  }

  public async getCarrierLoadboardLink(carrierId: string, carrierProfileId: string) {
    try {
      return await lastValueFrom(
        this.http.get<CarrierLoadboardLink>(`${environment.api}/v2/max_payloop/dispatcher/loadboard_sign_in_link`, {
          params: {
            carrierId,
            carrierProfileId,
          },
        }),
      );
    } catch (e) {
      return null;
    }
  }

  private getDispatcherUsers = (): Promise<BasicUser[]> => {
    return lastValueFrom(
      this.http
        .get<DispatcherUsers>(`${environment.api}/v2/max_payloop/dispatcher/assignable_dispatchers`)
        .pipe(map((v) => v?.dispatcherUsers || [])),
    );
  };

  public async reAssignCarrierRepresentative(carrierId: string, dispatcherId: string): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.put(`${environment.api}/v2/max_payloop/dispatcher/carrier_detail/${carrierId}/set_representative`, {
          dispatcherId: dispatcherId,
        }),
      );
      await this.refreshCarrier();
      this.carriersResponse.get$(); // refresh carriers list
      return true;
    } catch (error) {
      return false;
    }
  }

  public async reAssignCarrierPointOfContact(
    carrierId: string,
    pointOfContactId: string,
    pointOfContactName: string,
    pointOfContactPhone: string,
    pointOfContactEmail: string,
  ): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.put(`${environment.api}/v2/max_payloop/dispatcher/carrier_detail/${carrierId}/set_point_of_contact`, {
          pointOfContactId,
          pointOfContactName,
          pointOfContactPhone,
          pointOfContactEmail,
        }),
      );
      await this.refreshCarrier();
      this.carriersResponse.get$(); // refresh carriers list
      return true;
    } catch (error) {
      return false;
    }
  }

  public async updateCarrierAssets(carrierId: string, assets: CarrierAssets): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.put(`${environment.api}/v2/max_payloop/dispatcher/carrier_detail/${carrierId}/assets`, assets),
      );
      await this.refreshCarrier();
      this.carriersResponse.get$(); // refresh carriers list
      return true;
    } catch (e) {
      return false;
    }
  }

  public async refreshCarrier() {
    try {
      const carrierId = this.carrierDetail$$.value?.id;
      if (!carrierId) {
        return;
      }
      const carrier = await lastValueFrom(this.loadCarrierDetail(carrierId));
      this.carrierDetail$$.next(carrier);
      if (carrier.id) {
        this.refreshCarrierFiles(carrier.id);
        this.refreshCarrierContacts(carrier.id);
        this.refreshCarrierCompletedLoads(carrier.id);
        this.refreshCarrierLanesOfInterest(carrier.id);
        this.specialRequirements.refresh();
        this.capacity.refresh();
      }
    } catch (e) {}
  }

  private async refreshCarrierContacts(carrierId: string) {
    const carrierContacts = await lastValueFrom(this.getCarrierContacts(carrierId));
    this.carrierContacts$$.next(carrierContacts?.carrierContacts);
  }

  private getCarrierContacts = (carrierId: string): Observable<CarrierContacts> => {
    return this.http.get<CarrierContacts>(
      `${environment.api}/v2/max_payloop/dispatcher/carrier_detail/${carrierId}/contacts`,
    );
  };

  private async refreshCarrierCompletedLoads(carrierId: string) {
    const carrierCompletedLoads = await lastValueFrom(this.getCarrierCompletedLoads(carrierId));
    this.carrierCompletedLoads$$.next(carrierCompletedLoads?.carrierCompletedLoads);
  }

  private getCarrierCompletedLoads = (carrierId: string): Observable<CarrierCompletedLoads> => {
    return this.http.get<CarrierCompletedLoads>(
      `${environment.api}/v2/max_payloop/dispatcher/carrier_detail/${carrierId}/completed_loads`,
    );
  };

  public async refreshCarrierLanesOfInterest(carrierId: string) {
    const lanesOfInterest = await lastValueFrom(this.getCarrierLanesOfInterest(carrierId));
    this.carrierLanesOfInterest$$.next(lanesOfInterest?.carrierLanesOfInterest || []);
  }

  private getCarrierLanesOfInterest = (carrierId: string): Observable<CarrierLanesOfInterest> => {
    return this.http.get<CarrierLanesOfInterest>(
      `${environment.api}/v2/max_payloop/dispatcher/carrier_detail/${carrierId}/lanes_of_interest`,
    );
  };

  public async updateCarrierLanesOfInterest(lanesOfInterest: LaneOfInterest[]): Promise<boolean> {
    try {
      const carrierDetails = await firstValueFrom(this.carrierDetail$$);
      await lastValueFrom(
        this.http.put(
          `${environment.api}/v2/max_payloop/dispatcher/carrier_detail/${carrierDetails.id}/lanes_of_interest`,
          {
            lanesOfInterest,
          },
        ),
      );
      await this.refreshCarrierLanesOfInterest(carrierDetails.id);
      return true;
    } catch (e) {
      return false;
    }
  }

  private async refreshCarrierFiles(carrierId: string) {
    const carrierFiles = await lastValueFrom(this.getCarrierFiles(carrierId));
    this.carrierFiles$$.next(carrierFiles);
  }

  private getCarrierFiles = (carrierId: string): Observable<LohiUploadedFile[]> => {
    return this.http
      .get<CarrierFilesResponse>(`${environment.api}/v2/max_payloop/dispatcher/carrier_detail/${carrierId}/files`)
      .pipe(map((v) => v?.files));
  };

  public async banCarrier(carrierId: string, reason: any): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.put(`${environment.api}/v2/max_payloop/dispatcher/carrier_detail/${carrierId}/ban_load_booking`, {
          reason,
        }),
      );
      await this.refreshCarrier();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async unBanCarrier(carrierId: string): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.put(
          `${environment.api}/v2/max_payloop/dispatcher/carrier_detail/${carrierId}/unban_load_booking`,
          {},
        ),
      );
      await this.refreshCarrier();
      return true;
    } catch (e) {
      return false;
    }
  }

  //probably going to add category at a later date
  public async uploadFiles(carrierId: string, files: File[]) {
    try {
      for (const file of files) {
        const formData = new FormData();
        formData.set('file', file);
        await lastValueFrom(
          this.http.post(
            `${environment.api}/v2/max_payloop/dispatcher/carrier_detail/${carrierId}/upload_file`,
            formData,
          ),
        );
      }
      this.refreshCarrier();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async removeFile(carrierId: string, fileID: number) {
    try {
      await lastValueFrom(
        this.http.delete(`${environment.api}/v2/max_payloop/dispatcher/carrier_detail/${carrierId}/remove_file`, {
          body: {
            fileID,
          },
        }),
      );
      this.refreshCarrier();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async updateCarrierProfile(dotNumber: string, mcNumber: string, name: string, value: any): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.put(`${environment.api}/v2/max_payloop/dispatcher/carrier_profile`, {
          dotNumber,
          mcNumber,
          name,
          value,
        }),
      );
      await this.refreshCarrier();
      return true;
    } catch (error) {
      return false;
    }
  }

  private async refreshCarrierProfile(dotNumber: string) {
    const profile = await this.loadCarrierProfile(dotNumber);
    this.carrierProfile$$.next(profile);
  }

  private async loadCarrierProfile(dotNumber: string) {
    try {
      return await lastValueFrom(
        this.http.get<{ profile: any; updatedAt: string }>(
          `${environment.api}/v2/max_payloop/dispatcher/carrier_profile`,
          {
            params: {
              dotNumber,
            },
          },
        ),
      );
    } catch (error) {
      return null;
    }
  }

  private async refreshCarrierScorecard(carrierId: string) {
    const carrierScorecard = await lastValueFrom(this.getCarrierScorecard(carrierId));
    this.carrierScorecard$$.next(carrierScorecard);
  }

  private getCarrierScorecard = (carrierId: string): Observable<KpiScorecard> => {
    return this.http.get<KpiScorecard>(
      `${environment.api}/v2/max_payloop/dispatcher/carrier_detail/${carrierId}/otr_scorecard`,
    );
  };
  private loadSpecialRequirements = (carrierID: string) => {
    return lastValueFrom(
      this.http
        .get<{ specialRequirements: number[] }>(
          `${environment.api}/v2/max_payloop/dispatcher/special_requirements/${carrierID}`,
        )
        .pipe(map((response) => response?.specialRequirements ?? [])),
    );
  };

  public async updateSpecialRequirements(carrier: string, value: any) {
    try {
      await lastValueFrom(
        this.http.put(`${environment.api}/v2/max_payloop/dispatcher/special_requirements/${carrier}`, value),
      );
      await this.refreshCarrier();
      return true;
    } catch (error) {
      return false;
    }
  }

  private loadCapacity = (carrierID: string) => {
    return lastValueFrom(
      this.http
        .get<{ capacity: number }>(`${environment.api}/v2/max_payloop/dispatcher/carrier_capacity/${carrierID}`)
        .pipe(map((response) => response?.capacity)),
    );
  };

  public async updateCapacity(carrier: string, value: any) {
    try {
      await lastValueFrom(
        this.http.put(`${environment.api}/v2/max_payloop/dispatcher/carrier_capacity/${carrier}`, value),
      );
      await this.refreshCarrier();
      return true;
    } catch (error) {
      return false;
    }
  }
}
