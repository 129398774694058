import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { validateTrimmed } from '../../utilities/validateTrimmed';

export interface ConfirmationModalReasonInput {
  reasonLabel: string;
  title: string;
  yesLabel: string;
  noLabel: string;
}

export interface ConfirmationModalReasonOutput {
  confirmed: boolean;
  reason: string;
}

@Component({
  selector: 'td-confirmation-modal-reason',
  templateUrl: './confirmation-modal-reason.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationModalReasonComponent {
  public reasonControl = new FormControl<string>(null, [Validators.required, validateTrimmed]);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: ConfirmationModalReasonInput,
  ) {}
}
