import { AfterViewInit, ChangeDetectorRef, Directive, HostBinding, Input, OnDestroy } from '@angular/core';
import { ScrollIntoViewContainerDirective } from './scroll-into-view-container.directive';
import { ScrollIntoViewAnchorDirective } from './scroll-into-view-anchor.directive';
import { combineLatest, Subject, takeUntil } from 'rxjs';

@Directive({
  selector: '[tdScrollIntoViewSpacer]',
})
export class ScrollIntoViewSpacerDirective implements OnDestroy, AfterViewInit {
  private destroy$$ = new Subject();
  @HostBinding('style.height.px') public height: number = 0;
  // Needs to be the last one in the list of anchors
  @Input() public tdScrollIntoViewSpacer: ScrollIntoViewAnchorDirective;

  constructor(private container: ScrollIntoViewContainerDirective, private cd: ChangeDetectorRef) {}

  public ngOnDestroy() {
    this.destroy$$.next(null);
    this.destroy$$.unsubscribe();
  }

  public ngAfterViewInit() {
    combineLatest([this.container.height$, this.tdScrollIntoViewSpacer.height$])
      .pipe(takeUntil(this.destroy$$))
      .subscribe(([containerHeight, anchorHeight]) => {
        this.height = containerHeight - anchorHeight;
        this.cd.detectChanges();
      });
  }
}
