import { ChangeDetectionStrategy, Component, HostBinding, Inject, Input, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GalleryImage } from '../photo-gallery/photo-gallery.component';

@Component({
  selector: 'td-lightbox',
  templateUrl: './lightbox.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LightboxComponent {
  @HostBinding('class.cursor-move') public drag = true;
  @Input() public image: GalleryImage;
  public rotate = 0;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) data: { image: GalleryImage }) {
    if (data) {
      this.image = data.image;
    }
  }

  public rotateClockWise() {
    if (this.rotate === 3) {
      this.rotate = 0;
    } else {
      this.rotate = this.rotate + 1;
    }
  }

  public rotateCounterClockWise() {
    if (this.rotate === 0) {
      this.rotate = 3;
    } else {
      this.rotate = this.rotate - 1;
    }
  }
}
