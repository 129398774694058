import { Injectable } from '@angular/core';
import { SimpleFinn } from '../../../shared/utilities/simpleFinn';
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  lastValueFrom,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { RouterStateService } from '../../../shared/services/router-state.service';
import { CPG_SHIFT_ID_FOR_MAP, DISPATCH_POOL_ID, DISPATCH_POOL_LOAD_ASSIGNMENT_DATE } from './dispatch-pool';
import {
  AvailableDriver,
  CoveredLoadStopTimeType,
  FacilityPriorityTargetType,
  FacilityPriorityType,
  Hitch,
  HitchForecast,
  LoadingType,
  LohiLoadStatus,
  LongLat,
  NullableXYPoint,
  StopType,
  XYPoint,
} from '../../../shared/global-types';
import { environment } from '../../../../environments/environment';
import { eachWeekOfInterval, formatISO, isValid, startOfToday } from 'date-fns';
import { sort } from 'remeda';
import { RouterFinn } from '../../../shared/utilities/routerFinn';
import { take } from 'rxjs/operators';
import { sortBy } from '../../../shared/utilities/genericObjectSort';
import { shareReplayComponentConfig } from '../../../shared/constants';
import { DateTime } from 'luxon';
import { ArrayResponse, TierType } from '../dashboard/interfaces';
import { pMemoizeDecorator } from 'p-memoize';
import ExpiryMap from 'expiry-map';

export type DispatchPoolWarningLevels = 'none' | 'low' | 'high';

const shiftForceEndedToolTip = new ExpiryMap<string, any>(1000 * 30);

export interface SlimDispatchPool {
  id: number;
  name: string;
  description: string;
  driverCount: number;
  loadCount: number;
  unreadAlertCountForUser: number;
  dabEnabled: boolean;
  patiencePayEnabled: boolean;
  autodispatchEnabled: boolean;
}

export interface SlimDispatchPoolMetrics {
  timePeriod1Days: number;
  timePeriod2Days: number;
  otpPercentage1: number;
  otpPercentage2: number;
  otpPercentageAbsDiff: number;
  otpPercentagePercentDiff: number;
  revenuePerShift1: number;
  revenuePerShift2: number;
  revenuePerShiftAbsDiff: number;
  revenuePerShiftPercentDiff: number;
  turnRate1: number;
  turnRate2: number;
  turnRateAbsDiff: number;
  turnRatePercentDiff: number;
  daysToPay1: number;
  daysToPay2: number;
  daysToPayAbsDiff: number;
  daysToPayPercentDiff: number;
  percentLoadsAutodispatched1: number;
  percentLoadsAutodispatched2: number;
  percentLoadsAutodispatchedAbsDiff: number;
  percentLoadsAutodispatchedPercentDiff: number;
}

export interface DispatchPoolWithConfig extends DispatchPool {
  configs: PoolConfig[];
}

export interface PoolConfig {
  poolID: number;
  customerID: string;
  acceptAllTenders: boolean;
  isDedicated: boolean;
  isAutoReadyCollectionsEnabled: boolean;
  blockManualEapsAgainstRecommendations: boolean;
}

export interface DispatchPool {
  id: number;
  name: string;
  description: string;
  poolManagers: PoolManager[];
  notificationsEnabled: boolean;
  upcomingDays: DispatchPoolLoadAssignment[];
  facilitiesInPool: FacilityInPool[];
  loadsInPool: LoadInPool[];
  cancelableLoads: CancelableLoad[];
  loadTypes: LoadTypes;
  plannedShiftCount: number;
  settings: {
    autoReady: boolean;
    autoDispatch: boolean;
  };
  allowContractOverrides: boolean;
  canShowAllLoadsInPoolInVST: boolean;
  driverDemandLockoutEnabled: boolean;
  enableManualLoadReassignment: boolean;
  enableManualShiftDemandUpdate: boolean;
  enableManualDriverQueueUpdate: boolean;
  enableManualLoadDriverUnassign: boolean;
}

export type LoadTypes = 'none' | 'live' | 'drop' | 'live_drop';

export interface PoolPatch {
  id: number;
  name: string;
  description: string;
  loadTypes: LoadTypes;
  canShowAllLoadsInPoolInVST: boolean;
  plannedShiftCount: number;
  managerIds: string[];
}

export interface PoolConfigPatches {
  poolID: number;
  patches: PoolConfigPatch[];
}

export interface PoolConfigPatch {
  poolID: number;
  customerID: string;
  isAutoReadyCollectionsEnabled: boolean;
  isDedicated: boolean;
  acceptAllTenders: boolean;
}

export interface FacilityInPool {
  id: string;
  name: string;
  address: string | null;
  lngLat: LongLat;
  loadsPickingUpFromFacility: string[];
  customerName: string;
  predictedPickupMinutes: number;
  predictedDropoffMinutes: number;
}

export interface LoadInPool {
  id: string;
}

export interface DriverInPool {
  id: string;
  name: string;
  carrierId: string;
  carrierName: string;
  lngLat: LongLat | null; // null if we dont want to show the driver on the map
  currentLoadId: string | null;
  currentHitch: Hitch | null;
  futureHitch: Hitch | null;
  currentHitchForecast: HitchForecast | null;
  isDedicated: boolean;
  isActive: boolean;
  preferred: boolean;
  homebaseLngLat: NullableXYPoint;
  homebaseAddress: string | null;
  homebaseCity: string | null;
  homebaseState: string | null;
  homebaseZip: string | null;
  tier: string;
  contractId: string;
  contractName: string;
  overrideDabCents: number | null;
  sortingHitchStartDate: string;
  sortingHitchStartTime: number;
  loadsCompletedInPast30Days: number;
  lastLoadCompleted: string;
}

export interface DispatchPoolLoadAssignment {
  date: string;
  totalLoads: number;
  loadsAtRisk: number;
  totalDrivers: number;
  driversAtRisk: number;
  uncoveredLoads: UnCoveredLoad[];
  coveredLoads: CoveredLoad[];
  driversWithoutLoad: DriverWithoutLoad[];
  autodispatchSchedule: AutodispatchSchedule;
  additionalDriverDemand: AdditionalDriverDemand;
}

export interface AdditionalDriverDemand {
  totalCount: number;
  demandSlots: AdditionalDriverDemandSlot[];
}

export interface AdditionalDriverDemandSlot {
  startTime: string;
  endTime: string;
  count: number;
  manuallySet: boolean;
}

export interface UnCoveredLoad {
  id: string;
  status: 'at_risk' | 'coverable';
  loadPriority: string;
  coveredBy: string;
  referenceNumber: string;
  warnings: string[];
  warningLevel: DispatchPoolWarningLevels;
  dropoffDeadlineTime: string;
  dropoffTimezone: string;
  loadStatus: string;
  pickupDeadlineTime: string;
  pickupTimezone: string;
  hasOpenRatecon: boolean;
}

export interface AutodispatchSchedule {
  shifts: AutodispatchShift[];
  optedOutShifts: AutodispatchOptedOutShift[];
  atRiskLoads: AutodispatchLoad[];
  rescheduleRecommendations: RescheduleRecommendations[];
  swapLoadRecommendations: SwapLoadRecommendations[];
  timezone: string;
  hasAppointmentRecommendations: boolean;
  readyLoads: ReadyLoadListItem[];
  notReadyLoads: ReadyLoadListItem[];
  nonAutodispatchableLoads: NonAudispatchableLoad[];
}

export interface LoadBase {
  id: string;
  customerId: string;
  customerName: string;
  customerLoadId: string;
  lamTrailer: ReadyLoadListItemTrailer;
  stops: LoadListItemStop[];
  driverId?: string;
  assignedDriverName?: string;
  etaInquired: boolean;
  queuedForCancellation: boolean;
  priority?: {
    isPriority: string;
    priorityType: string;
    timedEndsAt: string;
  };
  schedulingFailureReason: string | null;
  isCostcoLoad: boolean;
  trailerType: string;
}

export interface ReadyLoadListItem extends LoadBase {
  hasDropTrailerStops: boolean;
  readyNote: string | null;
  readyCustomerNote: string | null;
  readyNoteAddedByName: string | null;
  readySetAt: string | null;
}

export interface LoadListItemStop {
  stopId: number;
  facilityId: number;
  facilityName: string;
  lngLat: XYPoint;
  arrivalWindowStartTime: string;
  arrivalWindowEndTime: string;
  stopType: StopType;
  loadingType: LoadingType;
}

export interface NonAudispatchableLoad extends LoadBase {
  visibleToBrokerage: boolean;
  isSpotLoad: boolean;
  status: LohiLoadStatus;
}

export interface ReadyLoadListItemTrailer {
  id: number;
  name: string;
  externalId: string;
}

export interface AutodispatchShift extends AutodispatchShiftBase {
  loads: AutodispatchScheduledLoad[];
}

export interface AutodispatchShiftBase {
  driverId: string;
  driverName: string;
  shiftId: number;
  startTime: string;
  endTime: string;
  homebaseLngLat: NullableXYPoint;
  homebaseAddress: string | null;
  homebaseCity: string | null;
  homebaseState: string | null;
  homebaseZip: string | null;
  confirmationRequest?: AutodispatchConfirmationRequest;
  trailerId: number | null;
  trailerName: string | null;
  trailerExternalId: string | null;
  forceEnded: boolean;
  endShiftLog: string | null;
  isStandby: boolean;
  isUpForGrabs: boolean;
  updateShiftEndTime: string;
  hasDriverStarted: boolean;
}

export interface AutodispatchOptedOutShift {
  driverId: string;
  driverName: string;
  shiftId: number;
  startTime: string;
  endTime: string;
  reasonForArchiving: string;
  canUnarchive: boolean;
  tooltipHint: string;
}

export interface AutodispatchConfirmationRequest {
  id: number;
  warningLevel: string;
  deadlineTime: string;
  shiftDate: {
    year: number;
    month: number;
    day: number;
  };
}

export interface AutodispatchLoad {
  loadId: string;
  customerLoadId: string;
  stops: AutodispatchStop[];
  warnings: string[];
  warningLevel: DispatchPoolWarningLevels;
  startTime: string;
  completeTime: string;
  etaInquired: string;
  acceptedAt: string;
  trailerType: string;
}

export interface AutodispatchStop {
  facilityId: string;
  facilityName: string;
  facilityCustomerReferenceNumber: string;
  type: StopType;
  loadingType: LoadingType;
  arrivalWindowStartTime: string;
  arrivalWindowEndTime: string;
  customerOriginalArrivalEndTime?: string;
  needsAppointment: boolean;
}

export interface RescheduleRecommendations {
  recommendationId: number;
  loadId: string;
  originalAppointments: string[];
  recommendedAppointments: string[];
  effect: string;
}

export interface SwapLoadRecommendations {
  recommendationId: number;
  loadId: string;
  originalDriverId: string;
  originalDriverName: string;
  newDriverId: string;
  newDriverName: string;
}

export interface LoadPriorityDetail {
  isPriority: string;
  priorityType: string;
  timedEndsAt: string;
}

export interface AutodispatchScheduledLoad extends AutodispatchLoad {
  stops: AutodispatchScheduledStop[];
  autodispatchLocked: boolean;
  assignedToDriver: boolean;
  penaltyFreeAssignment: boolean;
  trailerId: number | null;
  trailerName: string | null;
  trailerExternalIdentifier: string | null;
  inProgress: boolean;
  status: LohiLoadStatus;
  priorityDetail?: LoadPriorityDetail;
  driverName: string;
  isCostcoLoad: boolean;
}

export interface AutodispatchScheduledStop extends AutodispatchStop {
  driverStartTime: string;
  eta: string;
  trailerTask: 'swap' | 'pickup' | 'drop' | 'completed' | null;
}

export interface CoveredLoad {
  id: string;
  status: 'driver_allocated' | 'confirmed_by_driver';
  loadPickedUpAt: string;
  loadClaimedAt: string;
  loadScheduledAt: string;
  driverId: string;
  driverName: string;
  driverPast7dEarnings: number;
  referenceNumber: string;
  loadStatus: LohiLoadStatus;
  pickupTime: CoveredLoadStopTime;
  dropoffTime: CoveredLoadStopTime;
  warnings: string[];
  warningLevel: DispatchPoolWarningLevels;
  dropoffDeadlineTime: string;
  pickupDeadlineTime: string;
  pickupETA: CoveredLoadETA;
  dropoffETA: CoveredLoadETA;
  pickupTimezone: string;
  dropoffTimezone: string;
  pickupFacilityName: string;
  dropoffFacilityName: string;
  pickupType: StopType;
  dropoffType: StopType;
  pickupLoadingType: LoadingType;
  dropoffLoadingType: LoadingType;
  trailerName: string;
  trailerExternalId: string;
  pickupWindowStart: string;
  dropoffWindowStart: string;
}

export interface CoveredLoadStopTime {
  time: string;
  type: CoveredLoadStopTimeType;
  description: string;
}

export interface CoveredLoadETA {
  time: string;
  isActual: boolean;
  late: boolean;
}

export interface DriverWithoutLoad {
  driverId: string;
  driverName: string;
  driverPast7dEarnings: number;
}

export interface CancelableLoad {
  shipmentId: string;
  loadId: string;
  count: number;
  createdAt: string;
  updatedAt: string;
}

export interface PoolManager {
  id: string;
  name: string;
}

export interface AvailableFacility {
  id: string;
  name: string;
  customerName: string;
}

export type DispatchPoolLogLevel = 'info' | 'warn' | 'alarm';

export interface DispatchPoolActivityEntry {
  logId: number;
  level: DispatchPoolLogLevel;
  log: string;
  action: string;
  affectedLoadId: string | null;
  affectedCustomerId: string | null;
  affectedCustomerName: string | null;
  actorName: string | null;
  createdAt: string;
  affectedUserId: string | null;
  affectedUserName: string | null;
  alarmUnread: boolean;
}

export interface PossiblePoolLoads {
  loadId: string;
  pickupName: string;
  pickupExtremaStart: string;
  pickupExtremaEnd: string;
  dropoffName: string;
  dropoffExtremaStart: string;
  dropoffExtremaEnd: string;
}

export interface Waypoint {
  lngLat: LongLat;
  clientCreatedAt: string;
  accuracy: number;
  speed: number;
  createdAt: string;
}

export interface ShiftLoadStop {
  sequence: number;
  type: string;
  arrivedAt: string;
  exitedAt: string;
  facilityId: string;
  name: string;
  lngLat: LongLat;
  completedAt: string;
  manuallyCompleted: boolean;
  estimatedArrivalTime: string;
  estimatedCompletedTime: string;
  geofenceRadiusMeters: number;
  boundingPolygon: XYPoint[];
}

export interface ShiftLoad {
  id: string;
  status: string;
  assignedAt: string;
  startedAt: string;
  completedAt: string;
  stops: ShiftLoadStop[];
}

export interface ShiftInfo {
  id: number;
  startDate: string;
  startedAt: string;
  endedAt: string;
  driverPhone: string;
  driverName: string;
  timezone: string;
}

export interface SuspectStop {
  facilityId: string;
  siteRadius: number;
  suggestedRadius: number;
}

export interface UnassignedLoads {
  loadId: string;
  shiftId: string;
  driverId: string;
  startedAt: string | null;
  unassignedAt: string;
  id: number;
}

export interface MappableShift {
  waypoints: Waypoint[];
  loads: ShiftLoad[];
  shiftInfo: ShiftInfo;
  suspectStops: SuspectStop[];
  unassignedLoads: UnassignedLoads[];
}

export interface ShiftEvent {
  shiftId: number;
  eventType: 'shift_about_to_start' | 'not_making_progress' | 'driver_shift_update' | 'internal_shift_update';
  eventAt: string;
  eventTitle: string;
  eventDescription: string;
}

export interface DetentionAlertCustomerSetting {
  customerID: string;
  customerName: string;
  isDetentionAlertActive: boolean;
}

export interface DetentionAlertSetting extends DetentionAlertCustomerSetting {
  contactName: string | null;
  emailAddress: string | null;
  phoneNumber: string | null;
}

export interface HitchDABStatus {
  hitchId: number;
  startDate: string;
  endDate: string;
  driverName: string;
  fullyEvaluated: boolean;
  currentlyMeetsExpectation: boolean;
  partiallyMeetsExpectation: boolean;
  dabCents?: number;
  inQueueForPayment: boolean;
  attachedLoad: any;
  paid: boolean;
  isEligibleForDAB: boolean;
  poolTimezone: string;
  shifts: DABShift[];
}

export interface DABUnassignLoad {
  id: number;
  loadId: string;
  unassignedAt: string;
  unassignedBy: string;
  reason: string;
}

export interface DABShift {
  shiftId: number;
  startsAt: string;
  endsAt: string;
  checkedInAt: string;
  meetsExpectation?: boolean;
  expectationReason?: string;
  overrideAt?: string;
  overrideByName?: string;
  overrideReason?: string;
  inProgressEvaluation: boolean;
  declinedLoads?: DABDeclinedLoad[];
  missedAppointments?: DABMissedAppointment[];
  waypointGaps?: DABWaypointGap[];
  manualRemovals: DABShiftManualRemoval[];
  isStandby: boolean;
  lessThan10Hours: boolean;
  unassignedLoads: DABUnassignLoad[];
  shiftBreakDown: ShiftBreakDownEvent[];
  didDriverDeclineLoads: boolean;
  didDriverStartOnTime: boolean;
  didDriverWorkShortShift: boolean;
  description: string;
}

export interface ShiftBreakDownEvent {
  timeAt?: string;
  displayText?: string;
  secondaryText?: string;
  takeAwayDab?: boolean;
}

export interface DABShiftManualRemoval {
  removedAt: string;
  removedBy: string;
  removedReason: string;
}

export interface DABDeclinedLoad {
  loadId: string;
  declinedAt: string;
  driverNotes: string;
  timedOut: boolean;
}

export interface DABMissedAppointment {
  loadId: string;
  stopSequence: number;
  dueBy: string;
  arrivedAt: any;
}

export interface DABWaypointGap {
  start: string;
  end: string;
}

export interface DriverQueue {
  loadQueue: QueueLoad[];
  availableLoads: QueueLoad[];
}

export interface QueueLoad {
  loadId: string;
  customerId: string;
  customerName: string;
  customerLoadId: string | null;
  firstStopFacilityName: string;
  lastStopFacilityName: string;
}

export interface OTPEmailContact {
  id: number;
  emailName: string;
  emailAddress: string;
}

export interface DABReason {
  id: number;
  name: string;
  displayName: string;
}

export interface ShiftDemand {
  hourOfDay: number;
  count: number;
  dayOfWeek: number;
}

export interface KPIOverTime {
  day: string;
  value: number;
}

export interface PoolKPI {
  past30dTurnRate: KPIOverTime[];
  optimalTurnRate: KPIOverTime[];
  past30dOtp: KPIOverTime[];
  past30dLineHaul: KPIOverTime[];
  past30dResidualTurnRate: KPIOverTime[];
  past30dActualShiftCount: KPIOverTime[];
  past30dOptimalShiftCount: KPIOverTime[];
  shiftTimeBreakdown: ShiftTimeBreakdown;
  autodispatchedCount: KPIOverTime[];
  manualDispatchedCount: KPIOverTime[];
  autoDispatchPercent: KPIOverTime[];
}

export interface ShiftTimeBreakdown {
  avgDriverShiftHours: number;
  avgTimeToStartFirstLoad: number;
  avgTimeOnLoad: number;
  avgTimeBetweenLoads: number;
  avgTimeToEndOfShift: number;
}

export interface PoolKPIWithPoolName extends PoolKPI {
  id: number;
  name: string;
}

export interface ColtDataItem {
  id: number;
  facilityId: string;
  facilityName: string;
  loadId: string;
  referenceNumber: string;
  assetName: string;
  coltStatus: string;
  processStatus: string;
  location: string;
  isReady: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface VSTColtMismatchItem {
  assetId: number;
  assetName: string;
  facilityId: string;
  facilityName: string;
  facilityReferenceNumber: string;
  createdAt: string;
}

export interface ActivityAlert {
  id: number;
  userId: string;
  userPhone: string;
  poolId: string;
  levels: DispatchPoolLogLevel[];
}

export interface SlimContract {
  id: string;
  name: string;
}

export interface ArchivedHitchConflict {
  hitchId: number;
  startDate: string;
  lastDate: string;
  poolName: string;
}

export interface ArchivedHitchForRestoration {
  hitchId: number;
  startDate: string;
  lastDate: string;
  driverName: string;
  archivedAt: string;
  notes: string;
  conflictingHitches: ArchivedHitchConflict[];
}

export interface FacilityListEntry {
  id: string;
  name: string;
  customerName: string;
  customerReferenceNumber: string;
  trailerTargetCount: number;
  trailerMaxCount: number;
  trailerTargetType: string;
  trailers: any[];
  loadedCount: number;
  emptyCount: number;
  reqLoadsNeeded: number;
  neededTenderCount: number;
  notAssignedLoadCount: number;
  pendingLoadCount: number;
  inProgressLoadCount: number;
  criticalStatus: {
    isCritical: boolean;
    hoursToCritical: number;
    warningLevel: 'low' | 'high';
    pessimisticHoursToCritical: number | null;
    depletionTrailersPerDay: number | null;
  } | null;
  priority?: {
    priorityType: FacilityPriorityType;
    targetType: FacilityPriorityTargetType;
    timedEndsAt: string;
  };
  receivingYardCheckEmails: boolean;
}

export interface AllLoadItem {
  loadId: string;
  referenceNumber: string;
  isReady: boolean;
  readyNote: string;
  readyCustomerNote: string;
  readyAt: string;
  readyBy: string;
  customerName: string;
  status: LohiLoadStatus;
  pickupStartsAt: string;
  pickupEndsAt: string;
  pickupExtremaStartsAt: string;
  pickupExtremaEndsAt: string;
  pickupFacilityId: string;
  pickupFacilityName: string;
  pickupFacilityRef: string;
  pickupEta: string;
  pickupArrivedAt: string;
  pickupCompletedAt: string;
  pickupLoadingType: LoadingType;
  pickupAppointmentNumber: string;
  pickupEdiExtremaStartsAt: string;
  pickupEdiExtremaEndsAt: string;
  dropoffStartsAt: string;
  dropoffEndsAt: string;
  dropoffExtremaStartsAt: string;
  dropoffExtremaEndsAt: string;
  dropoffFacilityId: string;
  dropoffFacilityName: string;
  dropoffFacilityRef: string;
  dropoffEta: string;
  dropoffArrivedAt: string;
  dropoffCompletedAt: string;
  dropoffLoadingType: LoadingType;
  dropoffAppointmentNumber: string;
  dropoffEdiExtremaStartsAt: string;
  dropoffEdiExtremaEndsAt: string;
  pastDue: boolean;
  hasEAPStop: boolean;
  eapFacilityId: string;
  eapFacilityName: string;
  hasTrailerAssigned: boolean;
  assignedTrailer: string;
  assignedTrailerInMaintenance: boolean;
  companyId: string;
  companyName: string;
  driverId: string;
  driverName: string;
  poolTimezone: string;
  poolName: string;
  priorityDetail?: LoadPriorityDetail;
  queuedForCancellation: boolean;
  wasAutodispatched: boolean | null;
  brokeredLoad: boolean;
  notes: string;
  appointmentRequired: boolean;
  readyInColtAt: string;
  isAvailableOnSpot: boolean;
  spotStatus: string;
  spotLastUpdatedAt: string;
  trailerType: string;
}

export interface FleetPerformanceByDayTopDeduction {
  name: string;
  totalPoints: number;
}

export interface FleetPerformanceByDay {
  dayStart: string;
  totalLoads: number;
  totalPoints: number;
  averagePoints: number;
  topDeductions: FleetPerformanceByDayTopDeduction[];
}

export interface DispatchPoolAverageDriverTimeMetrics {
  loadedMiles: number;
  totalMiles: number;
  loadCount: number;
  revenueCents: number;
  contributionMargin: number;
}

export interface DispatchPoolAverageDriver {
  tierLevel: TierType;
  tierPoints: number;
  nextTierPoints: number;
  changeInPointsPerWeek: number;
  worksDaysInHitch: number;
  daysInHitch: number;
  worksHoursPerDay: number;
  daysUntilTierChange: number;
  shiftDetail: DispatchPoolAverageDriverTimeMetrics;
  hitchDetail: DispatchPoolAverageDriverTimeMetrics;
}

export interface PoolChallengeDriver {
  driverName: string;
  totalPointsLost: number;
}

export interface PoolChallengeCarrier {
  carrierName: string;
  totalPointsLost: number;
  drivers: PoolChallengeDriver[];
}

export interface PoolChallenge {
  name: string;
  totalPointsLost: number;
  carriers: PoolChallengeCarrier[];
}

export interface DashboardCarrierScorecardDriver {
  id: string;
  name: string;
  hasCurrentHitch: boolean;
  currentHitchStartDate: string;
  currentHitchStartDateDisplay: string;
  currentHitchEndDate: string;
  currentHitchEndDateDisplay: string;
  contributionMarginPerDay: number;
  hasFutureHitch: boolean;
  futureHitchStartDate: string;
  futureHitchStartDateDisplay: string;
  futureHitchEndDate: string;
  futureHitchEndDateDisplay: string;
  otp: number;
  tierName: TierType;
  tierPoints: number;
  topIssues: string[];
}

export interface DashboardCarrierScorecard {
  id: string;
  name: string;
  mcNumber: string;
  dotNumber: string;
  activeDriverCount: number;
  averageTier: TierType;
  averagePoints: number;
  contributionMarginPerDay: number;
  otp: number;
  topIssues: string[];
  drivers: DashboardCarrierScorecardDriver[];
  tierCounts: {
    blackCount: number;
    goldCount: number;
    silverCount: number;
    probationCount: number;
  };
}

export interface PoolShiftInSlot {
  driverId: string;
  driverName: string;
  driverPhone: string;
  companyName: string;
  shiftStartAt: string;
  shiftEndAt: string;
}

export interface PoolShiftInSlot {
  driverId: string;
  driverName: string;
  driverPhone: string;
  companyName: string;
  shiftStartAt: string;
  shiftEndAt: string;
  confirmationDeadline: string;
}

export interface GoodHitchCandidate {
  id: string;
  name: string;
  companyName: string;
  phone: string;
}

export interface PoolShiftDemandSlot {
  startTime: string;
  endTime: string;
  confirmedShiftCount: number;
  unconfirmedShiftCount: number;
  additionalDriversNeeded: number;
  confirmedShifts: PoolShiftInSlot[];
  unconfirmedShifts: PoolShiftInSlot[];
  hitchCandidates: GoodHitchCandidate[];
}

export interface PoolFacilityWithRating {
  facilityId: string;
  facilityName: string;
  facilityExternalId: string;
  latestRating: {
    facilityId: string;
    createdByUserId: string;
    createdByUserName: string;
    reviewerName: string | null;
    note: string | null;
    rating: number;
  };
}

export interface DashboardKPICardData {
  avgRating: number;
  avgRatingPrev: number;
  tierSum: {
    blackCount: number;
    goldCount: number;
    silverCount: number;
    probationCount: number;
  };
  otp: number;
  otpPrev: number;
  brokeredPercent: number;
  brokeredPercentPrev: number;
  brokeredCount: number;
  brokeredCountPrev: number;
}

export interface LoadException {
  isDriverBobtailing: boolean;
}

export interface DPDriverCount {
  activeDriverCount: number;
  noHitchBookedCount: number;
  inactiveDriversCount: number;
}

export interface LogisticsCoordinator {
  id: number;
  emailAddress: string;
  emailName: string;
  customerName: string;
}

export interface DriverPause {
  id: number;
  driverId: string;
  driverName: string;
  poolId: number;
  poolName: string;
  createdAt: string;
  endsAt: string;
  reason: string;
  createdByName: string;
  archivedAt: string | null;
  archivedByName: string | null;
  graphStartTime: string;
  graphEndTime: string;
  activeRightNow: boolean;
}

export interface CustomerNameAndID {
  id: string;
  name: string;
}

export interface DABlessNetRevenue {
  poolName: string;
  dateForGrouping: string;
  sampleCount: number;
  avgHoursOnShift: number;
  avgLoadsCompleted: number;
  avgEarningsWithoutADab: number;
  avgEstTotalDrivenMiles: number;
  avgShiftExpenses: number;
  avgNetRevenue: number;
}

export interface LoadStats {
  statsType: string;
  poolName: string;
  avgDriveToEap: number;
  avgDriveToPickup: number;
  avgDriveToDropoff: number;
  avgDriveToEad: number;
}

@Injectable({
  providedIn: 'root',
})
export class DispatchPoolService {
  public readonly dispatchPoolID$ = this.routerState.listenForParamChange$(DISPATCH_POOL_ID).pipe(shareReplay(1));
  public readonly dispatchPoolDate$ = this.routerState.listenForParamChange$(DISPATCH_POOL_LOAD_ASSIGNMENT_DATE).pipe(
    map((v) => {
      const start = startOfToday();
      const today = formatISO(start, { representation: 'date' });
      const date = new Date(v);
      if (!isValid(date)) {
        return today;
      }
      return v;
    }),
    shareReplay(1),
  );

  private readonly dispatchPools: SimpleFinn<SlimDispatchPool[]>;

  public get dispatchPools$(): Observable<SlimDispatchPool[]> {
    return this.dispatchPools.get$().pipe(map((v) => sort(v, (a, b) => a.name.localeCompare(b.name))));
  }

  private readonly dispatchPoolMetrics: SimpleFinn<Record<number, SlimDispatchPoolMetrics>> = new SimpleFinn({}, () =>
    this.loadPoolsMetrics(),
  );

  public get dispatchPoolMetrics$(): Observable<Record<number, SlimDispatchPoolMetrics>> {
    return this.dispatchPoolMetrics.get$();
  }

  private readonly dispatchPool: RouterFinn<DispatchPoolWithConfig>;

  public get dispatchPool$(): Observable<DispatchPoolWithConfig> {
    return this.dispatchPool.get$();
  }

  public get dispatchPoolLoading$(): Observable<boolean> {
    return this.dispatchPool.networkActive$;
  }

  private readonly dispatchPoolFacilities: RouterFinn<FacilityListEntry[]>;

  public get dispatchPoolFacilities$(): Observable<FacilityListEntry[]> {
    return this.dispatchPoolFacilities.get$();
  }

  public get dispatchPoolFacilitiesLoading$(): Observable<boolean> {
    return this.dispatchPoolFacilities.networkActive$;
  }

  private readonly dispatchPoolLoadAssignment: RouterFinn<DispatchPoolLoadAssignment, [DispatchPool, string]>;

  public get dispatchPoolLoadAssignment$(): Observable<DispatchPoolLoadAssignment> {
    return this.dispatchPoolLoadAssignment.get$();
  }

  private readonly availableDispatchPoolManagers: RouterFinn<PoolManager[]>;

  public get availableDispatchPoolManagers$(): Observable<PoolManager[]> {
    return this.availableDispatchPoolManagers.get$();
  }

  private readonly availableDispatchPoolFacilities: RouterFinn<AvailableFacility[]>;

  public get availableDispatchPoolFacilities$(): Observable<AvailableFacility[]> {
    return this.availableDispatchPoolFacilities.get$();
  }

  private readonly dispatchPoolActivityFeed: RouterFinn<DispatchPoolActivityEntry[]>;

  public get dispatchPoolActivityFeed$(): Observable<DispatchPoolActivityEntry[]> {
    return this.dispatchPoolActivityFeed.get$();
  }

  private readonly dispatchPoolActivityFeedAlerts: RouterFinn<ActivityAlert[]>;

  public get dispatchPoolActivityFeedAlerts$(): Observable<ActivityAlert[]> {
    return this.dispatchPoolActivityFeedAlerts.get$();
  }

  private possiblePoolLoads: RouterFinn<PossiblePoolLoads[]>;

  public get possiblePoolLoads$(): Observable<PossiblePoolLoads[]> {
    return this.possiblePoolLoads.get$();
  }

  private poolShiftMapDays: RouterFinn<string[]>;

  public get poolShiftMapDays$(): Observable<string[]> {
    return this.poolShiftMapDays.get$();
  }

  private shiftMap: RouterFinn<MappableShift>;

  public get shiftMap$(): Observable<MappableShift> {
    return this.shiftMap.get$();
  }

  private shiftEvents: RouterFinn<ShiftEvent[]>;

  public get shiftEvents$(): Observable<ShiftEvent[]> {
    return this.shiftEvents.get$();
  }

  private readonly customerDetentionSettings: SimpleFinn<DetentionAlertCustomerSetting[]>;

  public get customerDetentionSettings$(): Observable<DetentionAlertCustomerSetting[]> {
    return this.customerDetentionSettings.get$().pipe(map((v) => sort(v, sortBy('customerName'))));
  }

  private readonly poolDetentionSettings: RouterFinn<DetentionAlertSetting[]>;

  public get poolDetentionSettings$(): Observable<DetentionAlertSetting[]> {
    return this.poolDetentionSettings.get$().pipe(map((v) => sort(v, sortBy('customerName'))));
  }

  private readonly otpEmailContacts: RouterFinn<OTPEmailContact[]>;

  public get otpEmailContacts$(): Observable<OTPEmailContact[]> {
    return this.otpEmailContacts.get$().pipe(map((v) => sort(v, sortBy('emailName'))));
  }

  private readonly reasons: SimpleFinn<DABReason[]>;

  public get reasons$(): Observable<DABReason[]> {
    return this.reasons.get$().pipe(map((v) => sort(v, (a, b) => a.displayName.localeCompare(b.displayName))));
  }

  private week$$ = new BehaviorSubject<string>(
    DateTime.now().weekday < 5
      ? DateTime.now().startOf('week').minus({ days: 1 }).startOf('week').toISODate()
      : DateTime.now().startOf('week').toISODate(),
  );
  public week$: Observable<string> = this.week$$.pipe(shareReplay(1));

  public set week(week: string) {
    this.week$$.next(week);
  }

  public readonly weeks = eachWeekOfInterval(
    {
      start: new Date(2022, 6),
      end: new Date(),
    },
    {
      weekStartsOn: 1,
    },
  )
    .map((value) =>
      DateTime.fromJSDate(value)
        .setZone('UTC', {
          keepLocalTime: true,
        })
        .toISODate(),
    )
    .reverse();

  private readonly shiftDemand: RouterFinn<ShiftDemand[]>;

  public get shiftDemand$(): Observable<ShiftDemand[]> {
    return this.shiftDemand.get$();
  }

  private readonly kpiMetrics: RouterFinn<PoolKPI>;

  public get kpiMetrics$(): Observable<PoolKPI> {
    return this.kpiMetrics.get$();
  }

  public get kpiMetricsLoading$(): Observable<boolean> {
    return this.kpiMetrics.networkActive$;
  }

  private readonly allKpiMetrics: SimpleFinn<PoolKPIWithPoolName[]>;

  public get allKpiMetrics$(): Observable<PoolKPIWithPoolName[]> {
    return this.allKpiMetrics.get$();
  }

  public get allKpiMetricsLoading$(): Observable<boolean> {
    return this.allKpiMetrics.networkActive$;
  }

  private readonly coltData: RouterFinn<ColtDataItem[]>;

  public get coltData$(): Observable<ColtDataItem[]> {
    return this.coltData.get$();
  }

  private readonly vstColtMismatchData: RouterFinn<VSTColtMismatchItem[]>;

  public get vstColtMismatchData$(): Observable<VSTColtMismatchItem[]> {
    return this.vstColtMismatchData.get$();
  }

  private readonly contracts: SimpleFinn<SlimContract[]>;

  public get contracts$(): Observable<SlimContract[]> {
    return this.contracts.get$().pipe(map((v) => sort(v, (a, b) => a.name.localeCompare(b.name))));
  }

  private readonly allLoadsInPool: RouterFinn<AllLoadItem[]>;

  public get allLoadsInPool$(): Observable<AllLoadItem[]> {
    return this.allLoadsInPool.get$();
  }

  private readonly allLoads: SimpleFinn<AllLoadItem[]>;

  public get allLoads$(): Observable<AllLoadItem[]> {
    return this.allLoads.get$();
  }

  private readonly averagePoolDriver: RouterFinn<DispatchPoolAverageDriver>;

  public get averagePoolDriver$(): Observable<DispatchPoolAverageDriver> {
    return this.averagePoolDriver.get$();
  }

  private duration$$ = new BehaviorSubject(7);

  private readonly poolChallenges: RouterFinn<PoolChallenge[], [string, number]>;

  public get poolChallenges$(): Observable<PoolChallenge[]> {
    return this.poolChallenges.get$();
  }

  private readonly fleetPerformance: RouterFinn<FleetPerformanceByDay[], [string, number]>;

  public get fleetPerformance$(): Observable<FleetPerformanceByDay[]> {
    return this.fleetPerformance.get$();
  }

  private readonly carrierScorecards: RouterFinn<DashboardCarrierScorecard[], [string, number]>;

  public get carrierScorecards$(): Observable<DashboardCarrierScorecard[]> {
    return this.carrierScorecards.get$();
  }

  private shiftDemandSlots: RouterFinn<PoolShiftDemandSlot[]>;

  public get shiftDemandSlots$(): Observable<PoolShiftDemandSlot[]> {
    return this.shiftDemandSlots.get$();
  }

  private facilitiesWithRating: RouterFinn<PoolFacilityWithRating[]>;

  public get facilitiesWithRating$(): Observable<PoolFacilityWithRating[]> {
    return this.facilitiesWithRating.get$();
  }

  private kpiCardData: RouterFinn<DashboardKPICardData>;

  public get kpiCardData$(): Observable<DashboardKPICardData> {
    return this.kpiCardData.get$();
  }

  private driverCount: RouterFinn<DPDriverCount>;

  public get driverCount$(): Observable<DPDriverCount> {
    return this.driverCount.get$();
  }

  private logisticsCoordinators: RouterFinn<LogisticsCoordinator[]>;

  public get logisticsCoordinators$(): Observable<LogisticsCoordinator[]> {
    return this.logisticsCoordinators.get$();
  }

  private drivers: RouterFinn<DriverInPool[]>;

  public get drivers$(): Observable<DriverInPool[]> {
    return this.drivers.get$();
  }

  private readonly loadExceptions = new RouterFinn<Record<string, LoadException>>({}, this.dispatchPoolID$, (poolId) =>
    this.getLoadExceptionsForPool(+poolId),
  );

  public get loadExceptions$(): Observable<Record<string, LoadException>> {
    return this.loadExceptions.get$();
  }

  private readonly dablessNetRevenue = new RouterFinn<DABlessNetRevenue[]>(
    [],
    this.dispatchPoolID$,
    (poolId) => this.loadDablessNetRevenue(poolId),
    false,
  );

  public get dablessNetRevenue$(): Observable<DABlessNetRevenue[]> {
    return this.dablessNetRevenue.get$();
  }

  private readonly loadStats = new RouterFinn<LoadStats[]>(
    [],
    this.dispatchPoolID$,
    (poolId) => this.loadLoadStats(poolId),
    false,
  );

  public loadStatsLoading$ = this.loadStats.networkActive$;

  public get loadStats$(): Observable<LoadStats[]> {
    return this.loadStats.get$();
  }

  private readonly driverPauses = new RouterFinn<DriverPause[], [string, string]>(
    [],
    combineLatest([this.dispatchPoolID$, this.dispatchPoolDate$]),
    ([poolId, date]) => this.getDriverPausesOn(+poolId, date),
  );

  public get driverPauses$(): Observable<DriverPause[]> {
    return this.driverPauses.get$();
  }

  private readonly autoReadyCustomers: SimpleFinn<CustomerNameAndID[]>;

  public get autoReadyCustomers$(): Observable<CustomerNameAndID[]> {
    return this.autoReadyCustomers.get$();
  }

  constructor(private http: HttpClient, private routerState: RouterStateService) {
    this.autoReadyCustomers = new SimpleFinn<CustomerNameAndID[]>([], this.getAutoReadyCustomers);
    this.dispatchPools = new SimpleFinn<SlimDispatchPool[]>([], this.loadPools);
    this.reasons = new SimpleFinn<DABReason[]>([], this.loadReasons);
    this.dispatchPool = new RouterFinn<DispatchPoolWithConfig>(null, this.dispatchPoolID$, this.getDispatchPool);
    this.dispatchPoolFacilities = new RouterFinn<FacilityListEntry[]>(
      [],
      this.dispatchPoolID$,
      this.getDispatchPoolFacilities,
      false,
    );
    this.kpiMetrics = new RouterFinn<PoolKPI>(null, this.dispatchPoolID$, this.loadPoolKPIMetrics, false);
    this.allKpiMetrics = new SimpleFinn<PoolKPIWithPoolName[]>([], this.loadAllKpiMetrics);
    this.dispatchPoolLoadAssignment = new RouterFinn<DispatchPoolLoadAssignment, [DispatchPool, string]>(
      null,
      combineLatest([this.dispatchPool$, this.dispatchPoolDate$]),
      this.getDispatchPoolLoadAssignment,
    );
    this.availableDispatchPoolManagers = new RouterFinn<PoolManager[]>(
      [],
      this.dispatchPoolID$,
      this.getAvailableDispatchPoolManagers,
      false,
    );
    this.availableDispatchPoolFacilities = new RouterFinn<AvailableFacility[]>(
      [],
      this.dispatchPoolID$,
      this.getAvailableDispatchPoolFacilities,
      false,
    );
    this.dispatchPoolActivityFeed = new RouterFinn<DispatchPoolActivityEntry[]>(
      [],
      this.dispatchPoolID$,
      this.getDispatchPoolActivityFeed,
    );
    this.possiblePoolLoads = new RouterFinn<PossiblePoolLoads[]>(
      [],
      this.dispatchPoolID$,
      this.getPossiblePoolLoads,
      false,
    );
    this.poolShiftMapDays = new RouterFinn<string[]>([], this.dispatchPoolID$, this.getPoolShiftDays, false);
    this.poolDetentionSettings = new RouterFinn<DetentionAlertSetting[]>(
      [],
      this.dispatchPoolID$,
      this.loadDetentionSettingsForPool,
      false,
    );

    this.customerDetentionSettings = new SimpleFinn<DetentionAlertCustomerSetting[]>(
      [],
      this.loadCustomerDetentionSettings,
    );
    this.shiftMap = new RouterFinn<MappableShift>(
      null,
      this.routerState.listenForQueryChange$(CPG_SHIFT_ID_FOR_MAP),
      this.getMappableShift,
    );
    this.shiftEvents = new RouterFinn<ShiftEvent[]>(
      null,
      this.routerState.listenForQueryChange$(CPG_SHIFT_ID_FOR_MAP),
      this.getShiftEvents,
    );
    this.otpEmailContacts = new RouterFinn<OTPEmailContact[]>(
      [],
      this.dispatchPoolID$,
      this.getOTPEmailContacts,
      false,
    );

    this.shiftDemand = new RouterFinn<ShiftDemand[]>([], this.dispatchPoolID$, this.getShiftDemand, false);
    this.coltData = new RouterFinn<ColtDataItem[]>([], this.dispatchPoolID$, this.getColtData, false);
    this.vstColtMismatchData = new RouterFinn<VSTColtMismatchItem[]>(
      [],
      this.dispatchPoolID$,
      this.getVstColtMismatchData,
      false,
    );
    this.dispatchPoolActivityFeedAlerts = new RouterFinn<ActivityAlert[]>(
      [],
      this.dispatchPoolID$,
      this.getActivityFeedAlerts,
      false,
    );
    this.averagePoolDriver = new RouterFinn<DispatchPoolAverageDriver>(
      null,
      this.dispatchPoolID$,
      this.getAveragePoolDriver,
      false,
    );
    const poolIDWithDuration$ = combineLatest([this.dispatchPoolID$, this.duration$$]).pipe(shareReplay(1));
    this.poolChallenges = new RouterFinn<PoolChallenge[], [string, number]>(
      [],
      poolIDWithDuration$,
      this.getPoolChallenges,
      false,
    );
    this.fleetPerformance = new RouterFinn<FleetPerformanceByDay[], [string, number]>(
      [],
      poolIDWithDuration$,
      this.getFleetPerformance,
      false,
    );
    this.carrierScorecards = new RouterFinn<DashboardCarrierScorecard[], [string, number]>(
      [],
      poolIDWithDuration$,
      this.getCarrierScorecards,
      false,
    );
    this.shiftDemandSlots = new RouterFinn<PoolShiftDemandSlot[]>(
      [],
      this.dispatchPoolID$,
      this.getDispatchPoolDemandSlots,
      false,
    );
    this.facilitiesWithRating = new RouterFinn<PoolFacilityWithRating[]>(
      [],
      this.dispatchPoolID$,
      this.getDispatchPoolFacilityRatings,
      false,
    );
    this.allLoadsInPool = new RouterFinn<AllLoadItem[]>([], this.dispatchPoolID$, this.getAllPoolLoads, false);
    this.allLoads = new SimpleFinn<AllLoadItem[]>([], this.getAllLoads);
    this.kpiCardData = new RouterFinn<DashboardKPICardData>(null, this.dispatchPoolID$, this.getKPICardData, false);
    this.driverCount = new RouterFinn<DPDriverCount>(null, this.dispatchPoolID$, this.getDriverCountData, false);
    this.logisticsCoordinators = new RouterFinn<LogisticsCoordinator[]>(
      [],
      this.dispatchPoolID$,
      this.getLogisticsCoordinators,
      false,
    );
    this.drivers = new RouterFinn<DriverInPool[]>([], this.dispatchPoolID$, this.getDrivers);
    this.contracts = new SimpleFinn<SlimContract[]>([], this.loadContracts);
  }

  private getAutoReadyCustomers = async (): Promise<CustomerNameAndID[]> => {
    try {
      return (
        (
          await lastValueFrom(
            this.http.get<ArrayResponse<CustomerNameAndID>>(
              `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/auto_ready/customers`,
            ),
          )
        ).data ?? []
      );
    } catch (e) {
      return null;
    }
  };

  private loadPools = async () => {
    try {
      const pools = await lastValueFrom(
        this.http.get<{ pools: SlimDispatchPool[] }>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools`,
        ),
      );
      return pools?.pools || [];
    } catch (e) {
      return [];
    }
  };

  private loadPoolsMetrics = async () => {
    try {
      const response = await lastValueFrom(
        this.http.get<{ metrics: Record<number, SlimDispatchPoolMetrics> }>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/metrics`,
        ),
      );
      return response?.metrics || {};
    } catch (e) {
      return [];
    }
  };

  public getDispatchPool = (id: number | string): Promise<DispatchPoolWithConfig> =>
    lastValueFrom(
      this.http.get<DispatchPoolWithConfig>(
        `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${id}`,
      ),
    );

  public getDispatchPoolLoadAssignment = async ([pool, dateStr]: [
    DispatchPool,
    string,
  ]): Promise<DispatchPoolLoadAssignment> => {
    const date = DateTime.fromFormat(dateStr, 'yyyy-MM-dd').setZone(
      pool.upcomingDays[0].autodispatchSchedule?.timezone,
    );
    const poolDay = (pool.upcomingDays || []).find((day) => {
      const upcomingDate = DateTime.fromISO(day.date).setZone(pool.upcomingDays[0].autodispatchSchedule?.timezone);
      return date.hasSame(upcomingDate, 'day');
    });
    if (poolDay) {
      return poolDay;
    } else {
      return lastValueFrom(
        this.http.get<DispatchPoolLoadAssignment>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${pool.id}/past_schedule_day/${dateStr}`,
        ),
      );
    }
  };

  public async updatePool(pool: PoolPatch): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.patch(`${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${pool.id}`, pool),
      );
      await this.refreshDispatchPool();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async updatePoolConfig(configs: PoolConfigPatches): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.patch(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${configs.poolID}/config`,
          configs,
        ),
      );
      return true;
    } catch (e) {
      return false;
    }
  }

  public async refreshDispatchPool() {
    this.dispatchPool.refresh();
    this.drivers.refresh();
    this.dispatchPoolActivityFeed.refresh();
    this.refreshPoolExtras();
  }

  private async refreshPoolExtras() {
    this.availableDispatchPoolManagers.refresh();
    this.availableDispatchPoolFacilities.refresh();
  }

  private getAvailableDispatchPoolManagers = (id: number | string): Promise<PoolManager[]> =>
    lastValueFrom(
      this.http
        .post<{ managers: PoolManager[] }>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${id}/available_managers`,
          {},
        )
        .pipe(
          map((v) => v?.managers || []),
          map(sort((a, b) => a.name.localeCompare(b.name))),
        ),
    );

  public getAvailableDispatchPoolDrivers$ = (searchTerm: string): Observable<AvailableDriver[]> => {
    return this.dispatchPoolID$.pipe(
      take(1),
      switchMap((id) => {
        if (!id) {
          return of([]);
        }
        return this.http
          .get<{ drivers: AvailableDriver[] }>(
            `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${id}/available_drivers`,
            {
              params: {
                searchTerm,
              },
            },
          )
          .pipe(
            map((v) => v?.drivers || []),
            map(sort((a, b) => a.name.localeCompare(b.name))),
          );
      }),
    );
  };

  public async addDriver(id: number, driverId: string, overrideContractId: string, overrideDABCents: number) {
    try {
      await lastValueFrom(
        this.http.post<void>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${id}/add_driver`,
          {
            driverId,
            overrideContractId,
            overrideDABCents,
          },
        ),
      );
      this.refreshDispatchPool();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async removeDriver(id: number, driverId: string) {
    try {
      await lastValueFrom(
        this.http.post<void>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${id}/remove_driver`,
          {
            driverId,
          },
        ),
      );
      this.refreshDispatchPool();
      return true;
    } catch (e) {
      return false;
    }
  }

  private getAvailableDispatchPoolFacilities = (id: number | string): Promise<AvailableFacility[]> =>
    lastValueFrom(
      this.http
        .get<{ facilities: AvailableFacility[] }>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${id}/available_facilities`,
        )
        .pipe(
          map((v) => v?.facilities || []),
          map(sort((a, b) => a.name.localeCompare(b.name))),
        ),
    );

  public async addFacility(id: number, facilityId: string) {
    try {
      await lastValueFrom(
        this.http.post<void>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${id}/add_facility`,
          {
            facilityId,
          },
        ),
      );
      this.refreshDispatchPool();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async removeFacility(id: number, facilityId: string) {
    try {
      await lastValueFrom(
        this.http.post<void>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${id}/remove_facility`,
          {
            facilityId,
          },
        ),
      );
      this.refreshDispatchPool();
      this.dispatchPoolFacilities.get$();
      return true;
    } catch (e) {
      return false;
    }
  }

  private getDispatchPoolActivityFeed = (id: number | string): Promise<DispatchPoolActivityEntry[]> =>
    lastValueFrom(
      this.http
        .get<{ activity: DispatchPoolActivityEntry[] }>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${id}/activity`,
        )
        .pipe(map((v) => v?.activity || [])),
    );

  public async reassignDriver(poolId: number, loadId: string, newDriverId: string, reason: string) {
    try {
      await lastValueFrom(
        this.http.post<DispatchPool>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolId}/reassign_load`,
          {
            loadId,
            newDriverId,
            reason,
          },
        ),
      );
      this.refreshDispatchPool();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async acceptSwapLoadRequests(id: number, swapLoadRequests: SwapLoadRecommendations[]) {
    try {
      const acceptSwapLoadRequests = swapLoadRequests.map(({ loadId, originalDriverId, newDriverId }) => ({
        loadId,
        originalDriverId,
        newDriverId,
      }));
      await lastValueFrom(
        this.http.post<void>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${id}/accept_swap_load_recommendations`,
          {
            acceptSwapLoadRequests,
          },
        ),
      );
      this.refreshDispatchPool();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async toggleDriverPreferred(poolId: number, driver: DriverInPool) {
    if (driver.preferred) {
      return this.removeDriverFromPreferredDrivers(poolId, driver.id);
    } else {
      return this.addDriverToPreferredDrivers(poolId, driver.id);
    }
  }

  public async toggleDedicatedDriver(driver: DriverInPool) {
    if (driver.isDedicated) {
      return this.disableDedicatedDriver(driver.id);
    } else {
      return this.enableDedicatedDriver(driver.id);
    }
  }

  public async markLoadIdsAsUpForGrabs(loadIds: string[]) {
    return this.markVirtualShiftLoadsAsUpForGrabs(loadIds);
  }

  public async addDriverToPreferredDrivers(poolId: number, driverId: string) {
    try {
      await lastValueFrom(
        this.http.post<void>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolId}/preferred_driver/${driverId}`,
          null,
        ),
      );
      this.refreshDispatchPool();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async removeDriverFromPreferredDrivers(poolId: number, driverId: string) {
    try {
      await lastValueFrom(
        this.http.delete<void>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolId}/preferred_driver/${driverId}`,
        ),
      );
      this.refreshDispatchPool();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async enableDedicatedDriver(driverId: string) {
    try {
      await lastValueFrom(
        this.http.post<void>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/dedicated_driver/${driverId}`,
          null,
        ),
      );
      this.refreshDispatchPool();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async disableDedicatedDriver(driverId: string) {
    try {
      await lastValueFrom(
        this.http.delete<void>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/dedicated_driver/${driverId}`,
        ),
      );
      this.refreshDispatchPool();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async markVirtualShiftLoadsAsUpForGrabs(loadIds: string[]) {
    try {
      await lastValueFrom(
        this.http.post<void>(`${environment.api}/v2/vpf/internal/up_for_grabs/offer_loads_advancement`, {
          loadIds: loadIds,
        }),
      );
      this.refreshDispatchPool();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async updateShift(poolId: number, shiftId: number, startTime: string, endTime: string) {
    try {
      await lastValueFrom(
        this.http.post<DispatchPool>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolId}/update_driver_shift`,
          {
            shiftId,
            startTime,
            endTime,
          },
        ),
      );
      this.refreshDispatchPool();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async endShift(poolId: number, shiftId: number) {
    try {
      await lastValueFrom(
        this.http.post<DispatchPool>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolId}/end_driver_shift`,
          {
            shiftId,
          },
        ),
      );
      this.refreshDispatchPool();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async updateShiftArchive(shiftId: number) {
    try {
      await lastValueFrom(this.http.post<void>(`${environment.api}/v2/vpf/internal/shift/${shiftId}/unarchive`, {}));
      this.refreshDispatchPool();
      return true;
    } catch (e) {
      return false;
    }
  }

  @pMemoizeDecorator({ cache: shiftForceEndedToolTip })
  public async getShiftForceEndedTooltip(shiftId: number): Promise<string> {
    try {
      const response = await lastValueFrom(
        this.http.get<{
          log: string;
        }>(`${environment.api}/v2/vpf/internal/shift/${shiftId}/force_end_log`),
      );
      return response.log;
    } catch (e) {
      return null;
    }
  }

  public async updateShiftStandby(poolId: number, shiftId: number, standby: boolean) {
    try {
      await lastValueFrom(
        this.http.post<DispatchPool>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolId}/update_shift_standby_status`,
          {
            shiftId,
            standby,
          },
        ),
      );
      this.refreshDispatchPool();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async markAlarmsAsRead(activityLogIds: number[]) {
    try {
      await lastValueFrom(
        this.http.post<DispatchPool>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/read_logs`,
          {
            activityLogIds,
          },
        ),
      );
      this.refreshDispatchPool();
      return true;
    } catch (e) {
      return false;
    }
  }

  private getPossiblePoolLoads = (poolId: number | string): Promise<PossiblePoolLoads[]> =>
    lastValueFrom(
      this.http
        .get<{ possibleLoads: PossiblePoolLoads[] }>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolId}/possible_pool_loads`,
        )
        .pipe(map((v) => v?.possibleLoads || [])),
    );

  public setPoolLoads$(
    loadIds: string[],
  ): Observable<{ successes: string[]; failures: { loadId: string; reason: string }[] }> {
    return this.dispatchPoolID$.pipe(
      take(1),
      switchMap((poolId) => {
        if (poolId === null) {
          return of({
            successes: [],
            failures: [
              {
                loadId: '',
                reason: 'No pool selected', // this is a bit hacky but I don't care
              },
            ],
          });
        }
        return this.http.post<{ successes: string[]; failures: { loadId: string; reason: string }[] }>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolId}/set_pool_on_loads`,
          {
            loadIds,
          },
        );
      }),
      tap(() => {
        this.possiblePoolLoads.refresh();
      }),
    );
  }

  private getPoolShiftDays = (poolId: number | string): Promise<string[]> =>
    lastValueFrom(
      this.http
        .get<{ days: string[] }>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolId}/mappable_days`,
        )
        .pipe(map((v) => v?.days || [])),
    );

  public getShiftsForPoolDay$(dayString: string): Observable<{ id: number; name: string }[]> {
    return this.dispatchPoolID$.pipe(
      take(1),
      switchMap((poolId) => {
        if (poolId === null) {
          return of([]);
        }
        return this.http
          .get<{ shifts: { id: number; name: string }[] }>(
            `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolId}/mappable_shifts_for_day`,
            {
              params: { day: dayString },
            },
          )
          .pipe(map((v) => v?.shifts || []));
      }),
    );
  }

  private getMappableShift = (shiftId: number | string): Promise<MappableShift> =>
    lastValueFrom(
      this.http
        .get<{ shiftMap: MappableShift }>(`${environment.api}/v2/vpf/internal/shift_map`, {
          params: { shiftId: `${shiftId}` },
        })
        .pipe(map((v) => v.shiftMap)),
    );

  private getShiftEvents = (shiftId: number | string): Promise<ShiftEvent[]> =>
    lastValueFrom(
      this.http
        .get<{ events: ShiftEvent[] }>(`${environment.api}/v2/vpf/internal/shift_events`, {
          params: { shiftId: `${shiftId}` },
        })
        .pipe(map((v) => v.events)),
    );

  private loadCustomerDetentionSettings = async () =>
    lastValueFrom(
      this.http
        .get<{ detentionAlertSettings: DetentionAlertCustomerSetting[] }>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/detention_alert_customers`,
        )
        .pipe(map((v) => v?.detentionAlertSettings || [])),
    );

  private loadDetentionSettingsForPool = async (poolID) =>
    lastValueFrom(
      this.http
        .get<{ detentionAlertSettings: DetentionAlertSetting[] }>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolID}/detention_alert_setting`,
        )
        .pipe(map((v) => v?.detentionAlertSettings || [])),
    );

  public async toggleCustomerDetention(settings: DetentionAlertCustomerSetting) {
    try {
      await lastValueFrom(
        this.http.post<void>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/create_detention_alert_new_customer`,
          settings,
        ),
      );
      this.customerDetentionSettings.get$();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async saveDetentionSettings(poolID: string | number, value: DetentionAlertSetting) {
    try {
      await lastValueFrom(
        this.http.put(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolID}/detention_alert_setting`,
          value,
        ),
      );
      this.poolDetentionSettings.refresh();
      return true;
    } catch (e) {
      return false;
    }
  }

  public getHitchDabStatuses$(startDate: Date, endDate: Date): Observable<{ hitchStatuses: HitchDABStatus[] }> {
    return this.dispatchPoolID$.pipe(
      take(1),
      switchMap((poolId) => {
        if (poolId === null || startDate === null || endDate === null) {
          return of({ hitchStatuses: [] });
        }
        return this.http.get<{ hitchStatuses: HitchDABStatus[] }>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolId}/hitch_dab_status`,
          {
            params: {
              startDate: startDate.toISOString().substring(0, 10),
              endDate: endDate.toISOString().substring(0, 10),
            },
          },
        );
      }),
    );
  }

  // don't want to preload this or anything, so just gonna call it from the component
  public getArchivedHitchesForPool$(): Observable<{ archivedHitches: ArchivedHitchForRestoration[] }> {
    return this.dispatchPoolID$.pipe(
      take(1),
      switchMap((poolId) => {
        if (poolId === null) {
          return of({ archivedHitches: [] });
        }
        return this.http.get<{ archivedHitches: ArchivedHitchForRestoration[] }>(
          `${environment.api}/v2/vpf/internal/hitches/archived_hitches`,
          {
            params: {
              poolId: `${poolId}`,
            },
          },
        );
      }),
    );
  }

  public async restoreArchivedHitch(archivedHitchId: number): Promise<string> {
    try {
      const res = await lastValueFrom(
        this.http.post<{
          message: string;
        }>(`${environment.api}/v2/vpf/internal/hitches/restore_archived_hitch`, { archivedHitchId }),
      );
      return res.message;
    } catch (e) {
      return null;
    }
  }

  public overrideShiftEvaluation$(shiftId: number, reason: string): Observable<boolean> {
    return this.http
      .post<{ success: boolean }>(`${environment.api}/v2/vpf/internal/override_shift_evaluation`, {
        shiftId,
        reason,
      })
      .pipe(map((v) => v.success));
  }

  public async cancelHitch(hitchID: number | string) {
    try {
      await lastValueFrom(this.http.delete(`${environment.api}/v2/vpf/internal/hitches/${hitchID}`));
      this.refreshDispatchPool();
      return true;
    } catch (e) {
      return false;
    }
  }

  public updatePoolSettings$(poolId: number | string, param: { autoReady: boolean; autoDispatch: boolean }) {
    return this.http
      .post<void>(
        `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolId}/update_settings`,
        param,
      )
      .pipe(
        tap(() => {
          this.refreshDispatchPool();
        }),
      );
  }

  public async updateLoadReadyStatus(
    poolId: number | string,
    loadId: string,
    readyForDispatch: boolean,
    note?: string,
    notifyCustomer?: boolean,
    customerNote?: string,
    expiresAt?: string,
    preventAutoOverride?: boolean,
    trailerId?: number,
    trailerAction?: string,
    treatAsDecline?: boolean,
  ) {
    try {
      await firstValueFrom(
        this.http.post<void>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolId}/${loadId}/ready_for_dispatch`,
          {
            readyForDispatch,
            note,
            notifyCustomer,
            customerNote,
            expiresAt,
            preventAutoOverride,
            trailerId,
            trailerAction,
            treatAsDecline,
          },
        ),
      );
      this.refreshDispatchPool();
      return true;
    } catch (e) {
      return false;
    }
  }

  public getDriverLoadQueue$(poolId: string | number, driverId: string): Observable<DriverQueue> {
    return this.http
      .get<{
        queue: DriverQueue;
      }>(
        `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolId}/driver/${driverId}/load_queue`,
      )
      .pipe(
        map((v) => v?.queue),
        shareReplay(shareReplayComponentConfig),
      );
  }

  public async updateDriverLoadQueue(poolId: number, driverId: string, loadIds: string[]) {
    try {
      await lastValueFrom(
        this.http.post<void>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolId}/driver/${driverId}/load_queue`,
          {
            loadIds,
          },
        ),
      );
      this.refreshDispatchPool();
      return true;
    } catch (e) {
      return false;
    }
  }

  private getOTPEmailContacts = async (poolId: number | string) => {
    return lastValueFrom(
      this.http
        .get<{ emails: OTPEmailContact[] }>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolId}/otp_pardon_contacts`,
        )
        .pipe(map((v) => v?.emails || [])),
    );
  };
  private loadReasons = async () => {
    return lastValueFrom(
      this.http
        .get<{ reasons: DABReason[] }>(`${environment.api}/v2/vpf/internal/dab_management/removal_reasons`)
        .pipe(map((v) => v?.reasons || [])),
    );
  };

  public async removeDAB(value: any) {
    try {
      await lastValueFrom(this.http.post<void>(`${environment.api}/v2/vpf/internal/dab_management/remove_dab`, value));
      this.refreshDispatchPool();
      return true;
    } catch (e) {
      return false;
    }
  }

  private loadDablessNetRevenue = async (poolID: string) => {
    if (!poolID) {
      return null;
    }
    const response = await lastValueFrom(
      this.http.get<{
        dablessNetRevenue: DABlessNetRevenue[];
      }>(`${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolID}/kpi/dabless_net_revenue`),
    );
    return response?.dablessNetRevenue || [];
  };

  private loadLoadStats = async (poolID: string) => {
    if (!poolID) {
      return null;
    }
    const response = await lastValueFrom(
      this.http.get<{
        loadStats: LoadStats[];
      }>(`${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolID}/kpi/load_stats`),
    );
    return response?.loadStats || [];
  };

  private loadPoolKPIMetrics = async (poolID) => {
    if (!poolID) {
      return null;
    }
    return lastValueFrom(
      this.http.get<PoolKPI>(
        `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolID}/kpi/metrics`,
      ),
    );
  };

  private getShiftDemand = async (poolID) => {
    if (!poolID) {
      return [];
    }
    return lastValueFrom(
      this.http
        .get<{ shiftDemand: ShiftDemand[] }>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolID}/shift_demand`,
        )
        .pipe(map((v) => v?.shiftDemand ?? [])),
    );
  };

  public async updateShiftDemand(poolID: string | number, demand: any): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.put(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolID}/shift_demand`,
          demand,
        ),
      );
      this.shiftDemand.refresh();
      return true;
    } catch (e) {
      return false;
    }
  }

  public setDriverDemandLockout = async (poolID, enabled) => {
    if (!poolID) {
      return null;
    }

    try {
      await firstValueFrom(
        this.http.post(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolID}/set_driver_demand_lockout`,
          {
            enabled,
          },
        ),
      );
      return true;
    } catch (e) {
      return false;
    }
  };

  private loadAllKpiMetrics = () => {
    return lastValueFrom(
      this.http
        .get<{
          metrics: PoolKPIWithPoolName[];
        }>(`${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/all_metrics`)
        .pipe(map((v) => v?.metrics?.sort((a, b) => a.name.localeCompare(b.name)) ?? [])),
    );
  };

  private getColtData = async (poolID) => {
    return lastValueFrom(
      this.http
        .get<{ coltData: ColtDataItem[] }>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolID}/colt_data`,
        )
        .pipe(map((v) => v?.coltData ?? [])),
    );
  };

  private getVstColtMismatchData = async (poolID) => {
    return lastValueFrom(
      this.http
        .get<{ vstColtMismatchData: VSTColtMismatchItem[] }>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolID}/vst_colt_mismatch`,
        )
        .pipe(map((v) => v?.vstColtMismatchData ?? [])),
    );
  };

  public refreshAllPoolKPIs() {
    this.allKpiMetrics.get$();
  }

  private getAllPoolLoads = async (poolID) => {
    return lastValueFrom(
      this.http
        .get<{ loads: AllLoadItem[] }>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolID}/all_loads`,
        )
        .pipe(map((v) => v?.loads ?? [])),
    );
  };

  private getAllLoads = async () => {
    return lastValueFrom(
      this.http
        .get<{ loads: AllLoadItem[] }>(`${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/all_loads`)
        .pipe(map((v) => v?.loads ?? [])),
    );
  };

  private getActivityFeedAlerts = async (poolID) => {
    return lastValueFrom(
      this.http
        .get<{ alerts: ActivityAlert[] }>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolID}/activity/alerts`,
        )
        .pipe(map((v) => v?.alerts ?? [])),
    );
  };

  public async createActivityFeedAlert(poolID, levels) {
    try {
      await firstValueFrom(
        this.http.post(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolID}/activity/alerts`,
          { levels },
        ),
      );
      this.dispatchPoolActivityFeedAlerts.refresh();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async deleteActivityFeedAlert(poolID, alertID) {
    try {
      await firstValueFrom(
        this.http.delete(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolID}/activity/alerts/${alertID}`,
        ),
      );
      this.dispatchPoolActivityFeedAlerts.refresh();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async overrideDriverCountOnDemandSlot(slot: AdditionalDriverDemandSlot, additionalDriversNeeded: number) {
    try {
      const poolId = await firstValueFrom(this.dispatchPoolID$);
      if (!poolId) {
        return false;
      }
      await lastValueFrom(
        this.http.post<void>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolId}/set_driver_demand_override`,
          {
            startTime: slot.startTime,
            endTime: slot.endTime,
            additionalDriversNeeded,
          },
        ),
      );
      this.refreshDispatchPool();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async removeDriverCountOverrideOnDemandSlot(slot: AdditionalDriverDemandSlot) {
    try {
      const poolId = await firstValueFrom(this.dispatchPoolID$);
      if (!poolId) {
        return false;
      }
      await lastValueFrom(
        this.http.post<void>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolId}/remove_driver_demand_override`,
          {
            startTime: slot.startTime,
          },
        ),
      );
      this.refreshDispatchPool();
      return true;
    } catch (e) {
      return false;
    }
  }

  private loadContracts = () => {
    return lastValueFrom(
      this.http
        .get<{ contracts: SlimContract[] }>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/contracts`,
        )
        .pipe(map((v) => v?.contracts ?? [])),
    );
  };

  private getDispatchPoolFacilities = async (poolID) => {
    return lastValueFrom(
      this.http
        .get<{ facilities: FacilityListEntry[] }>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolID}/facilities`,
        )
        .pipe(
          map((v) => v?.facilities ?? []),
          map(
            sort((a, b) => {
              if (a.criticalStatus?.warningLevel === b.criticalStatus?.warningLevel) {
                return a.criticalStatus?.hoursToCritical - b.criticalStatus?.hoursToCritical;
              }
              if (!a.criticalStatus) {
                return 1;
              }
              if (!b.criticalStatus) {
                return -1;
              }
              if (a.criticalStatus?.warningLevel === 'high') {
                return -1;
              }
              if (b.criticalStatus?.warningLevel === 'high') {
                return 1;
              }
              if (a.criticalStatus?.warningLevel === 'low') {
                return -1;
              }
              if (b.criticalStatus?.warningLevel === 'low') {
                return 1;
              }
              return a.name.localeCompare(b.name);
            }),
          ),
        ),
    );
  };

  public async markFacilityAsPriority(
    facilityID: string,
    priorityType: FacilityPriorityType,
    targetType: FacilityPriorityTargetType,
    timedEndsAt: Date | null,
  ) {
    try {
      await lastValueFrom(
        this.http.post<void>(`${environment.api}/v2/vpf/internal/facility_priority/${facilityID}`, {
          priorityType,
          timedEndsAt,
          targetType,
        }),
      );
      this.dispatchPoolFacilities.refresh();
      this.refreshDispatchPool();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async removeFacilityPriority(facilityID: string) {
    try {
      await lastValueFrom(this.http.delete(`${environment.api}/v2/vpf/internal/facility_priority/${facilityID}`));
      this.dispatchPoolFacilities.refresh();
      this.refreshDispatchPool();
      return true;
    } catch (e) {
      return false;
    }
  }

  private getAveragePoolDriver = async (poolID): Promise<DispatchPoolAverageDriver> => {
    return await lastValueFrom(
      this.http.get<DispatchPoolAverageDriver>(
        `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolID}/area_manager_dashboard/average_driver`,
      ),
    );
  };

  public getPoolChallenges = async ([poolID, duration]): Promise<PoolChallenge[]> => {
    return await lastValueFrom(
      this.http
        .get<{ data: PoolChallenge[] }>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolID}/area_manager_dashboard/biggest_challenges`,
          {
            params: {
              periodInDays: duration,
            },
          },
        )
        .pipe(map((v) => v?.data ?? [])),
    );
  };

  private getFleetPerformance = async ([poolID, duration]): Promise<FleetPerformanceByDay[]> => {
    return await lastValueFrom(
      this.http
        .get<{ data: FleetPerformanceByDay[] }>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolID}/area_manager_dashboard/fleet_performance`,
          {
            params: {
              periodInDays: duration,
            },
          },
        )
        .pipe(map((v) => v?.data ?? [])),
    );
  };

  public getCarrierScorecards = async ([poolID, duration]): Promise<DashboardCarrierScorecard[]> => {
    return await lastValueFrom(
      this.http
        .get<{ data: DashboardCarrierScorecard[] }>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolID}/area_manager_dashboard/load_performance`,
          {
            params: {
              periodInDays: duration,
            },
          },
        )
        .pipe(map((v) => v?.data ?? [])),
    );
  };

  public getDispatchPoolFacilityRatings = async (poolID): Promise<PoolFacilityWithRating[]> => {
    return await lastValueFrom(
      this.http
        .get<{ facilities: PoolFacilityWithRating[] }>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolID}/area_manager_dashboard/customer_satisfaction`,
        )
        .pipe(map((v) => v?.facilities ?? [])),
    );
  };

  public updateDashboardDuration(value: number) {
    this.duration$$.next(value);
    this.poolChallenges.refresh();
    this.fleetPerformance.refresh();
    this.carrierScorecards.refresh();
  }

  private getDispatchPoolDemandSlots = async (poolID) => {
    return await lastValueFrom(
      this.http
        .get<{ poolShiftDemandSlots: PoolShiftDemandSlot[] }>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolID}/area_manager_dashboard/shift_demand`,
          {
            params: {
              periodInHours: 36,
            },
          },
        )
        .pipe(map((v) => v?.poolShiftDemandSlots ?? [])),
    );
  };

  private async getLoadExceptionsForPool(poolId: number): Promise<Record<string, LoadException>> {
    if (!poolId) {
      return {};
    }
    try {
      const response = await lastValueFrom(
        this.http.get<{ loadExceptions: Record<string, LoadException> }>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolId}/load_exceptions`,
        ),
      );
      return response?.loadExceptions || {};
    } catch (e) {
      return {};
    }
  }

  public async submitFacilityReview(facilityID: string, review: any) {
    try {
      await lastValueFrom(
        this.http.post(
          `${environment.api}/v2/vpf/internal_or_external_dispather/facilities/${facilityID}/rating`,
          review,
        ),
      );
      this.facilitiesWithRating.refresh();
      return true;
    } catch (e) {
      return false;
    }
  }

  private getKPICardData = async (poolID) => {
    return await lastValueFrom(
      this.http.get<DashboardKPICardData>(
        `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolID}/area_manager_dashboard/kpi_cards`,
      ),
    );
  };

  private getDriverCountData = async (poolID) => {
    return await lastValueFrom(
      this.http.get<DPDriverCount>(
        `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolID}/area_manager_dashboard/driver_counts`,
      ),
    );
  };

  private getLogisticsCoordinators = async (poolID) => {
    return await lastValueFrom(
      this.http
        .get<{ coordinators: LogisticsCoordinator[] }>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolID}/lcs`,
        )
        .pipe(map((v) => v?.coordinators ?? [])),
    );
  };

  public async removeContact(poolID: string, id: number) {
    try {
      await lastValueFrom(
        this.http.delete<void>(`${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolID}/lcs`, {
          body: {
            contactId: id,
          },
        }),
      );
      this.logisticsCoordinators.get$();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async removeAllContacts(poolID: string) {
    try {
      await lastValueFrom(
        this.http.delete<void>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolID}/lcs/delete_all`,
        ),
      );
      this.logisticsCoordinators.get$();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async addContact(poolID: string, contact: any) {
    try {
      await lastValueFrom(
        this.http.post<void>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolID}/lcs`,
          contact,
        ),
      );
      this.logisticsCoordinators.get$();
      return true;
    } catch (e) {
      return false;
    }
  }

  private getDrivers = async (poolID) => {
    return await lastValueFrom(
      this.http
        .get<{ drivers: DriverInPool[] }>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolID}/drivers`,
        )
        .pipe(map((v) => v?.drivers ?? [])),
    );
  };

  private async getDriverPausesOn(poolId: number, ymdDate: string): Promise<DriverPause[]> {
    if (!poolId || !ymdDate) {
      return [];
    }
    try {
      const response = await lastValueFrom(
        this.http.get<{ pauses: DriverPause[] }>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolId}/driver_load_promotion_pauses`,
          {
            params: { ymdDate },
          },
        ),
      );
      return response.pauses || [];
    } catch (e) {
      return [];
    }
  }

  public async removeDriverPause(pauseId: number): Promise<string> {
    try {
      const response = await lastValueFrom(
        this.http.post<{
          message: string;
        }>(`${environment.api}/v2/vpf/internal/remove_driver_load_promotion_pause`, { pauseId }),
      );
      this.driverPauses.refresh();
      return response.message;
    } catch (e) {
      return null;
    }
  }

  public async moveHitchDateForward(hitchId: number): Promise<string> {
    const response = await lastValueFrom(
      this.http.post<{ message: string }>(`${environment.api}/v2/vpf/internal/move_hitch_forward_by_one_day`, {
        hitchId,
      }),
    );
    return response.message;
  }

  public async addADayToAHitch(hitchId: number): Promise<string> {
    const response = await lastValueFrom(
      this.http.post<{ message: string }>(`${environment.api}/v2/vpf/internal/extend_hitch_for_driver`, {
        hitchId,
      }),
    );
    return response.message;
  }

  public async bookAOneDayHitch(
    date: Date,
    driverId: string,
    firstShiftStartMinute: number,
    poolId: number,
  ): Promise<string> {
    // these are in local time per api
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const response = await lastValueFrom(
      this.http.post<{ message: string }>(`${environment.api}/v2/vpf/internal/book_single_shift_for_driver`, {
        startDate: {
          year,
          month,
          day,
        },
        driverId,
        firstShiftStartMinute,
        poolId,
      }),
    );
    return response.message;
  }

  public async moveHitchDateBack(hitchId: number) {
    const response = await firstValueFrom(
      this.http.post<{ message: string }>(`${environment.api}/v2/vpf/internal/move_hitch_back_by_one_day`, {
        hitchId,
      }),
    );
    return response.message;
  }
}
