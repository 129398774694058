<div mat-dialog-title>{{ data.title }}</div>
<div mat-dialog-content>
  {{ data.content }}
</div>
<div mat-dialog-actions class="flex justify-between gap-2">
  <button mat-stroked-button [matDialogClose]="false">{{ data.noLabel }}</button>
  <button mat-flat-button color="primary" [matDialogClose]="true">
    {{ data.yesLabel }}
  </button>
</div>
