import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, lastValueFrom, map, Observable, of, switchMap, tap } from 'rxjs';
import { shareReplay, startWith } from 'rxjs/operators';
import { RouterStateService } from '../../../shared/services/router-state.service';
import { CUSTOMER_ID } from './shipper-load-consts';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SimpleFinn } from '../../../shared/utilities/simpleFinn';
import { Accessorial, CustomChargeType, LohiLoad, PricingReport, SlimProduct } from '../../../shared/global-types';
import { ExportLoad } from './shipper-load.service.types';

export interface CustomerInformation {
  id: string;
  name: string;
  type: string;
  billingContactName: string;
  billingContactPhone: string;
  billingContactEmail: string;
  billingContactBillingInstructions: string;
  notifyOfDetentionMinutes: number;
  detentionCents: number;
  detentionStartsAfterMinutes: number;
  customerResources: CustomerResource[];
  brokerage: string;
}

export interface CustomerResource {
  id: string;
  customerId: string;
  resourceURL: string;
  resourceTitle: string;
  uploadedBy: string;
  createdAt: string;
}

export interface OutstandingInventoryParams {
  customer: string;
  date: string;
  loadType: string;
}

export interface OutstandingInventory {
  dates: string[];
  rows: InventoryRow[];
}

export interface InventoryRow {
  time: string;
  loadCounts: number[];
  totalBooked: number;
}

export interface CustomerSatisfactionParams {
  startDate: string;
  endDate: string;
  customer: string;
}

export interface CustomerSatisfaction {
  rows: CustomerSatisfactionRow[];
}

export interface CustomerSatisfactionRow {
  id: string;
  name: string;
  upcomingVolume: number;
  loadsInTimespan: number;
  margin: number;
  onTimeRate: number;
  rolledLoads: number;
  uniqueCarriers: number;
}

export interface MatchbackReport {
  activeCount: number;
  eligible: {
    count: number;
    facilities: string;
  };
  notCoveredCount: number;
  assignedNotYetPickedUpCount: number;
  assignedInProgressCount: number;
  timespanReport: MatchbackTimespanReport;
}

export interface MatchbackTimespanReport {
  timespanDays: number;
  assigned: number;
  carrierTotalCents: number;
  costSavingsCents: number;
  averageWaitlistLength: number;
  percentOfLoadsWithWaitlistAboveOne: number;
  awardedByBookNowButton: number;
  awardedToWaitlistedCarrier: number;
  locationTrackedInApp: number;
}

@Injectable({
  providedIn: 'root',
})
export class ShipperLoadService {
  private customerInformation$$ = new BehaviorSubject<CustomerInformation>(null);
  public customerInformation$: Observable<CustomerInformation> = this.customerInformation$$.pipe(shareReplay(1));

  private outstandingInventoryParams$$ = new BehaviorSubject<OutstandingInventoryParams>({
    customer: null,
    date: null,
    loadType: null,
  });
  private outstandingInventory$$ = new BehaviorSubject<OutstandingInventory>(null);
  public outstandingInventory$: Observable<OutstandingInventory> = this.outstandingInventory$$.pipe(shareReplay(1));

  private customerSatisfactionParams$$ = new BehaviorSubject<CustomerSatisfactionParams>({
    startDate: null,
    endDate: null,
    customer: null,
  });
  private customerSatisfaction$$ = new BehaviorSubject<CustomerSatisfaction>(null);
  public customerSatisfaction$: Observable<CustomerSatisfaction> = this.customerSatisfaction$$.pipe(shareReplay(1));

  private matchbackReport: SimpleFinn<MatchbackReport>;

  public get matchbackReport$(): Observable<MatchbackReport> {
    return this.matchbackReport.get$();
  }

  private accessorials: SimpleFinn<Accessorial[]>;

  public get accessorials$(): Observable<Accessorial[]> {
    return this.accessorials.get$().pipe(map((value) => value.sort((a, b) => a.name.localeCompare(b.name))));
  }

  private customCharges: SimpleFinn<CustomChargeType[]>;

  public get customCharges$(): Observable<CustomChargeType[]> {
    return this.customCharges.get$().pipe(map((value) => value.sort((a, b) => a.name.localeCompare(b.name))));
  }

  constructor(private http: HttpClient, private routerState: RouterStateService) {
    this.listenForCustomer();
    this.listenForOutstandingInventory();
    this.listenForCustomerSatisfaction();
    this.matchbackReport = new SimpleFinn(null, this.loadMatchbackReport);
    this.accessorials = new SimpleFinn<Accessorial[]>([], this.loadAccessorials);
    this.customCharges = new SimpleFinn<CustomChargeType[]>([], this.loadCustomChargeTypes);
  }

  private listenForCustomer() {
    this.routerState
      .listenForParamChange$(CUSTOMER_ID)
      .pipe(
        tap((value) => {
          if (!value) {
            this.customerInformation$$.next(null);
          } else if (value !== this.customerInformation$$.value?.id) {
            this.customerInformation$$.next(null);
          }
        }),
        switchMap((customerID: string) => {
          if (!customerID) {
            return of(null as CustomerInformation);
          }
          return this.loadCustomerInformation(customerID);
        }),
      )
      .subscribe((value) => {
        if (!value) {
          this.customerInformation$$.next(null);
          return;
        }
        this.customerInformation$$.next(value);
      });
  }

  private async refreshCustomer(customerId: string) {
    const customer = await lastValueFrom(this.loadCustomerInformation(customerId));
    this.customerInformation$$.next(customer);
  }

  private loadCustomerInformation(customerId: string) {
    return this.http.get<CustomerInformation>(`${environment.api}/v2/max_payloop/dispatcher/shipper_loads/customer`, {
      params: {
        customerId,
      },
    });
  }

  public async uploadURL(customerId: string, resourceURL: string, resourceTitle: string): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.post(`${environment.api}/v2/max_payloop/dispatcher/shipper_loads/customer/resource`, {
          customerId,
          resourceURL,
          resourceTitle,
        }),
      );
      this.refreshCustomer(customerId);
      return true;
    } catch (e) {
      return false;
    }
  }

  private listenForOutstandingInventory() {
    this.outstandingInventoryParams$$
      .pipe(
        startWith(this.outstandingInventoryParams$$.value),
        tap((params) => {
          if (
            !params ||
            params.customer !== this.outstandingInventoryParams$$.value.customer ||
            params.date !== this.outstandingInventoryParams$$.value.date ||
            params.loadType !== this.outstandingInventoryParams$$.value.loadType
          ) {
            this.outstandingInventory$$.next(null);
          }
        }),
        switchMap((params) => {
          if (!params) {
            return of(null as OutstandingInventory);
          }
          return this.loadOutstandingInventory(params?.customer, params?.date, params?.loadType);
        }),
      )
      .subscribe((value) => {
        if (!value) {
          this.outstandingInventory$$.next(null);
          return;
        }
        this.outstandingInventory$$.next(value);
      });
  }

  public updateOutstandingInventoryParams(params: OutstandingInventoryParams) {
    this.outstandingInventoryParams$$.next(params);
  }

  private loadOutstandingInventory(customer: string, date: string, loadType: string) {
    let params = new HttpParams();
    if (date) {
      params = params.set('date', date);
    }
    if (customer) {
      params = params.set('customer', customer);
    }
    if (loadType) {
      params = params.set('loadType', loadType);
    }
    return this.http.get<OutstandingInventory>(
      `${environment.api}/v1/external/broker_portal/internal_or_external_dispatcher/management/outstanding_inventory`,
      { params },
    );
  }

  private listenForCustomerSatisfaction() {
    this.customerSatisfactionParams$$
      .pipe(
        startWith(this.customerSatisfactionParams$$.value),
        tap((params) => {
          if (
            !params ||
            params.customer !== this.customerSatisfactionParams$$.value.customer ||
            params.startDate !== this.customerSatisfactionParams$$.value.startDate ||
            params.endDate !== this.customerSatisfactionParams$$.value.endDate
          ) {
            this.customerSatisfaction$$.next(null);
          }
        }),
        switchMap((params) => {
          if (!params) {
            return of(null as CustomerSatisfaction);
          }
          return this.loadCustomerSatisfaction(params?.startDate, params?.endDate, params?.customer);
        }),
      )
      .subscribe((value) => {
        if (!value) {
          this.customerSatisfaction$$.next(null);
          return;
        }
        this.customerSatisfaction$$.next(value);
      });
  }

  public updateCustomerSatisfactionParams(params: CustomerSatisfactionParams) {
    this.customerSatisfactionParams$$.next(params);
  }

  private loadCustomerSatisfaction(startDate: string, endDate: string, customer: string) {
    let params = new HttpParams();
    if (startDate) {
      params = params.set('startDate', startDate);
    }
    if (endDate) {
      params = params.set('endDate', endDate);
    }
    if (customer) {
      params = params.set('customer', customer);
    }
    return this.http.get<CustomerSatisfaction>(
      `${environment.api}/v1/external/broker_portal/internal_or_external_dispatcher/management/customer_satisfaction`,
      { params },
    );
  }

  private loadMatchbackReport = async () => {
    try {
      return await lastValueFrom(
        this.http.get<MatchbackReport>(
          `${environment.api}/v1/external/broker_portal/internal/management/matchback_reporting`,
        ),
      );
    } catch (e) {
      return null;
    }
  };

  private loadAccessorials = (): Promise<Accessorial[]> => {
    return lastValueFrom(
      this.http
        .get<{ accessorials: Accessorial[] }>(`${environment.api}/v1/external/broker_portal/accessorials`)
        .pipe(map((response) => response.accessorials)),
    );
  };

  private loadCustomChargeTypes = (): Promise<CustomChargeType[]> => {
    return lastValueFrom(
      this.http
        .get<{ chargeTypes: CustomChargeType[] }>(`${environment.api}/v1/external/broker_portal/custom_charge_types`)
        .pipe(map((response) => response.chargeTypes)),
    );
  };

  public async bookLoad(formValue: any) {
    return lastValueFrom(
      this.http.post<LohiLoad>(`${environment.api}/v1/external/broker_portal/loads/book`, formValue),
    );
  }

  public async createExportLoad(singleLoad: ExportLoad) {
    return lastValueFrom(
      this.http.post<{ loadIds: string[] }>(`${environment.api}/v1/external/broker_portal/loads/create_export_loads`, {
        loads: [singleLoad],
      }),
    );
  }

  public async bookDrayageLoad(formValue: any) {
    return lastValueFrom(
      this.http.post<{ loadId: string; refNumber: string; failureReason: string }>(
        `${environment.api}/v1/external/broker_portal/loads/upload_drayage`,
        formValue,
        {
          params: { loadType: formValue.load.loadType },
        },
      ),
    );
  }

  public getCustomerProducts$(customerID: string) {
    return this.http
      .get<{ products: SlimProduct[] }>(
        `${environment.api}/v2/max_payloop/dispatcher/loopi/customer_products/${customerID}`,
      )
      .pipe(
        catchError(() => of(null)),
        map((v) => v?.products ?? []),
      );
  }
}
