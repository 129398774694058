import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
import { isToday, isTomorrow } from 'date-fns';

@Pipe({
  name: 'inTimezone',
})
export class InTimezonePipe implements PipeTransform {
  public transform(
    value: string | Date,
    timezone: string,
    format = 'M/d/yyyy h:mma ZZZZ',
    standardDate?: boolean,
  ): string {
    return formatInTimezone(value, timezone, format, standardDate);
  }
}

export const formatInTimezone = (
  value: string | Date,
  timezone: string,
  format = 'M/d/yyyy h:mma ZZZZ',
  standardDate: boolean,
) => {
  if (!value) {
    return null;
  }
  let inTz: DateTime;
  if (typeof value === 'string' || value instanceof String) {
    inTz = DateTime.fromISO(value as string, {
      zone: timezone,
    });
  } else {
    inTz = DateTime.fromJSDate(value, {
      zone: timezone,
    });
  }
  if (standardDate && format.includes('M/d/yyyy')) {
    if (inTz.hasSame(DateTime.local({ zone: timezone }), 'day')) {
      format = format.replace('M/d/yyyy', "'Today'");
    } else if (inTz.hasSame(DateTime.local({ zone: timezone }).plus({ day: 1 }), 'day')) {
      format = format.replace('M/d/yyyy', "'Tomorrow'");
    }
  }
  return inTz.toFormat(format, {
    locale: 'en-us',
  });
};
