import { ChangeDetectionStrategy, Component, Inject, inject } from '@angular/core';
import {
  DispatchPoolUfgV3Service,
  SpotLoadPrecheck,
} from '../../../modules/internal/dispatch-pool/dispatch-pool-ufg-v3.service';
import { NetworkableDestroyableComponent } from '../networkable-destroyable.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { shareReplayComponentConfig } from '../../constants';
import { FormControl } from '@angular/forms';
import { SnackBarService } from '../../services/snackbar.service';

@Component({
  selector: 'td-cpg-ufg-prompt-mark-ufg',
  templateUrl: './cpg-ufg-prompt-mark-ufg.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CpgUfgPromptMarkUfgComponent extends NetworkableDestroyableComponent {
  public loadId: string;
  private ufgLoadStatus$$ = new BehaviorSubject<SpotLoadPrecheck>(null);
  public ufgLoadStatus$ = this.ufgLoadStatus$$.asObservable().pipe(shareReplay(shareReplayComponentConfig));
  private ufgService = inject(DispatchPoolUfgV3Service);
  private snackbar = inject(SnackBarService);
  private matDialogRef: MatDialogRef<CpgUfgPromptMarkUfgComponent> = inject(MatDialogRef);
  public notesFC = new FormControl<string>(null);

  constructor(@Inject(MAT_DIALOG_DATA) data: { loadId: string }) {
    super();
    this.loadId = data.loadId;
    this.getUfgLoadStatus(this.loadId);
  }

  private async getUfgLoadStatus(loadId: string) {
    this.networkActive$$.next(true);
    try {
      const status = await this.ufgService.getSpotLoadPrecheck(loadId);
      this.ufgLoadStatus$$.next(status);
    } finally {
      this.networkActive$$.next(false);
    }
  }

  public async markLoadAsSpotLoad() {
    if (this.isActive) {
      return;
    }
    try {
      this.isActive = true;
      const success = await this.ufgService.markLoadAsSpotLoad(this.loadId, this.notesFC.value);
      if (success) {
        this.snackbar.showMessage('Load marked as spot load');
        this.matDialogRef.close();
      }
    } finally {
      this.isActive = false;
    }
  }
}
