import { ChangeDetectionStrategy, Component, Input, TemplateRef, ViewChild } from '@angular/core';
import {
  LohiLoadAllowedChargeType,
  TourChargeUpload,
  TourLoadCharge,
} from '../../../../modules/carrier/carrier-loads/carrier-loads.service';
import { LoadBillingRequestChargeUpdateComponent } from '../load-billing-request-charge-update/load-billing-request-charge-update.component';
import { LohiLoad, ProductLoad } from '../../../global-types';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { isFileUploadTypeValid } from '../../../utilities/isFileUploadTypeValid';
import { LightboxComponent } from '../../lightbox/lightbox.component';
import { shareReplayComponentConfig } from '../../../constants';
import { NetworkableDestroyableComponent } from '../../networkable-destroyable.component';
import { LoadBillingService } from '../../../services/load-billing.service';
import { SnackBarService } from '../../../services/snackbar.service';
import { sort } from 'remeda';

@Component({
  selector: 'td-load-billing-charge-list',
  templateUrl: './load-billing-charge-list.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadBillingChargeListComponent extends NetworkableDestroyableComponent {
  // TODO: remove this once we no longer need charges are requests feature flag
  @ViewChild('addTourChargeModal') private addTourChargeModal: TemplateRef<any>;
  @ViewChild('editTourChargeModal') private editTourChargeModal: TemplateRef<any>;
  @ViewChild('confirmDeleteLinehaulCharge') private confirmDeleteLinehaulChargeModal: TemplateRef<any>;
  public tourChargeEditForm: UntypedFormGroup;
  private confirmDeleteCharge$$ = new BehaviorSubject<boolean>(false);
  public confirmDeleteCharge$: Observable<boolean> = this.confirmDeleteCharge$$.pipe(
    shareReplay(shareReplayComponentConfig),
  );
  // TODO remove through here

  private dialogRef: MatDialogRef<any>;
  // why do we need both?
  @Input() public load: LohiLoad = null;
  @Input() public productLoad: ProductLoad = null;
  private _charges: TourLoadCharge[] = [];
  @Input()
  public set charges(charges: TourLoadCharge[]) {
    this._charges = sort(charges, (a, b) => {
      if (a.chargeType === b.chargeType) {
        return a.id - b.id;
      }
      if (a.chargeType === 'custom') {
        return 1;
      }
      if (b.chargeType === 'custom') {
        return -1;
      }
      return a.id - b.id;
    }).filter((item) => item.totalCents != 0 || item.requiresUpload);
  }

  public get charges() {
    return this._charges;
  }

  @Input() public canEditCharges: boolean = false;
  private networkActiveCharge$$ = new BehaviorSubject<Record<number, boolean>>({});
  public networkActiveCharge$: Observable<Record<number, boolean>> = this.networkActiveCharge$$.pipe(
    shareReplay(shareReplayComponentConfig),
  );

  private imageRef: MatDialogRef<LightboxComponent>;
  public chargeTypes$: Observable<LohiLoadAllowedChargeType[]>;

  constructor(private lbs: LoadBillingService, private matDialog: MatDialog, private snackbar: SnackBarService) {
    super();
    this.chargeTypes$ = this.lbs.chargeTypes$;
  }

  public async startRequestUpdateCustomCharge(charge: TourLoadCharge) {
    if (charge.chargeType !== 'custom') {
      return;
    }

    this.dialogRef = this.matDialog.open(LoadBillingRequestChargeUpdateComponent, {
      panelClass: ['w-full', 'lg:w-1/3'],
      data: {
        load: this.load,
        charge,
      },
    });
  }

  public startEditCustomCharge(charge: TourLoadCharge) {
    if (charge.chargeType !== 'custom') {
      return;
    }

    const ct: LohiLoadAllowedChargeType = {
      id: charge.chargeTypeNameId,
      displayName: charge.name,
      requiresUpload: charge.requiresUpload,
    };
    this.tourChargeEditForm = new UntypedFormGroup({
      id: new UntypedFormControl(charge.id),
      name: new UntypedFormControl(ct, [Validators.required, Validators.minLength(4)]),
      price: new UntypedFormControl(charge.totalCents, [Validators.required, Validators.minLength(4)]),
    });
    this.dialogRef = this.matDialog.open(this.editTourChargeModal, {
      panelClass: ['w-full', 'lg:w-1/3'],
      autoFocus: false,
    });
  }

  public async startDeleteLinehaulCharge() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    this.dialogRef = this.matDialog.open(this.confirmDeleteLinehaulChargeModal, {
      panelClass: ['w-full', 'lg:w-1/3'],
    });
  }

  public async editTourCharge() {
    if (!this.tourChargeEditForm || this.networkActive$$.value) {
      return;
    }
    this.networkActive$$.next(true);

    const val: { name: LohiLoadAllowedChargeType; price: number; id: number } = this.tourChargeEditForm.value;
    const success = await this.lbs.editTourCharge(this.load.id, val.id, val.name.id, val.name.displayName, val.price);
    if (success) {
      this.dialogRef.close();
    }
    this.networkActive$$.next(false);
  }

  public async deleteCustomCharge(id: number) {
    if (this.networkActive$$.value) {
      return;
    }
    this.networkActive$$.next(true);
    const success = await this.lbs.deleteTourCharge(this.load.id, id);
    if (success) {
      this.confirmDeleteCharge$$.next(false);
      this.dialogRef?.close();
    }
    this.networkActive$$.next(false);
  }

  public async deleteLinehaulCharge() {
    if (this.networkActive$$.value) {
      return;
    }
    this.networkActive$$.next(true);
    const success = await this.lbs.deleteTourLinehaulCharge(this.load.id);
    if (success) {
      this.confirmDeleteCharge$$.next(false);
      this.dialogRef?.close();
    }
    this.networkActive$$.next(false);
  }

  public async onTourChargeEditFileChange(charge: TourLoadCharge, event) {
    if (this.networkActiveCharge$$.value[charge.id]) {
      return;
    }
    if (isFileUploadTypeValid(event.target.files)) {
      const [file]: File[] = event.target.files;
      if (file) {
        this.networkActiveChargeSet(charge.id, true);
        await this.lbs.uploadTourChargeFile(charge, file);
        event.target.value = '';
        this.networkActiveChargeSet(charge.id, false);
      } else {
        this.snackbar.showError('No File Specified');
        event.target.value = '';
      }
    } else {
      this.snackbar.showError('No file selected or invalid file type selected in upload');
    }
  }

  public async deleteCustomChargeFile(charge: TourLoadCharge, fileID: number) {
    if (this.networkActiveCharge$$.value[charge.id]) {
      return;
    }
    this.networkActiveChargeSet(charge.id, true);
    const success = await this.lbs.deleteTourChargeFile(this.load.id, charge.id, fileID);
    if (success) {
      this.confirmDeleteCharge$$.next(false);
      this.dialogRef?.close();
    }
    this.networkActiveChargeSet(charge.id, false);
  }

  public updateConfirmDeleteCharge(b: boolean) {
    this.confirmDeleteCharge$$.next(b);
  }

  private networkActiveChargeSet(chargeID: number, b: boolean) {
    const val = {
      ...this.networkActiveCharge$$.value,
    };
    val[chargeID] = b;
    this.networkActiveCharge$$.next(val);
  }
}
