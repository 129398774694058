import { Component, ChangeDetectionStrategy, HostBinding, ChangeDetectorRef } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';

@Component({
  selector: 'td-versioning',
  templateUrl: './versioning.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VersioningComponent {
  @HostBinding('class') public classes = 'version-warning';
  @HostBinding('class.wrong-version') public isWrongVersion = false;

  constructor(private swUpdate: SwUpdate, private cd: ChangeDetectorRef) {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.checkForUpdate().then();
    }

    interval(6 * 1000).subscribe(() => {
      if (this.swUpdate.isEnabled) {
        this.swUpdate.checkForUpdate().then();
      }
    });

    this.swUpdate.versionUpdates.pipe().subscribe((event) => {
      if (event.type === 'VERSION_READY') {
        this.isWrongVersion = true;
        this.cd.markForCheck();
      }
    });
  }

  public reload() {
    location.reload();
  }
}
