<div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="flex flex-row justify-between">
    <div>
      {{ image.name }}
    </div>
    <button mat-dialog-close mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content>
  <div style="height: calc(90vh - 150px)">
    <img
      [src]="image.src"
      class="object-contain w-full h-full transform"
      [ngClass]="{
        'rotate-0': rotate === 0,
        'rotate-90': rotate === 1,
        'rotate-180': rotate === 2,
        '-rotate-90': rotate === 3
      }"
    />
  </div>
</mat-dialog-content>
<div mat-dialog-actions class="flex flex-row justify-center items-center">
  <button mat-icon-button class="bg-blue-500 text-white" (click)="rotateCounterClockWise()">
    <mat-icon>rotate_left</mat-icon>
  </button>
  <button mat-icon-button class="bg-blue-500 text-white" (click)="rotateClockWise()">
    <mat-icon>rotate_right</mat-icon>
  </button>
  <a [href]="image.src" target="_blank" class="bg-blue-500 text-white" mat-icon-button>
    <mat-icon>arrow_downward</mat-icon>
  </a>
</div>
