import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hoursToDaysAndHoursPipe',
})
export class HoursToDaysAndHoursPipe implements PipeTransform {
  public transform(value: number, ...args: any[]): string {
    const days = Math.floor(value / 24);
    const hours = Math.round(value - days * 24);

    let ret = '';

    if (days > 0) {
      ret += `${days}d `;
    }

    ret += `${hours}h`;

    return ret;
  }
}
