import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';
import { ConstantsService, PreferenceListItem } from 'src/app/shared/services/constants.service';
import { DriverLoadPreferences } from 'src/app/shared/global-types';

@Component({
  selector: 'td-driver-profile-edit-load-preferences',
  templateUrl: './driver-profile-edit-load-preferences.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverProfileEditLoadPreferencesComponent {
  public multiStopChoices$: Observable<PreferenceListItem[]>;
  public dropTrailerChoices$: Observable<PreferenceListItem[]>;
  public driverTouchChoices$: Observable<PreferenceListItem[]>;
  private isSetup = false;

  @Input() public networkActive = false;

  @Input()
  public set prefs(prefs: DriverLoadPreferences) {
    if (!this.isSetup) {
      this.setupForm(prefs);
      this.isSetup = true;
    }
  }

  @Output() public save = new EventEmitter<DriverLoadPreferences>();
  public formGroup: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, private constantsService: ConstantsService) {
    const driverPreferenceSpecialLoadChoices$ = this.constantsService.driverPreferenceSpecialLoadChoices$;
    this.multiStopChoices$ = driverPreferenceSpecialLoadChoices$.pipe(
      map((choices) => choices?.multiStopChoices || []),
    );
    this.dropTrailerChoices$ = driverPreferenceSpecialLoadChoices$.pipe(
      map((choices) => choices?.dropTrailerChoices || []),
    );
    this.driverTouchChoices$ = driverPreferenceSpecialLoadChoices$.pipe(
      map((choices) => choices?.dropTrailerChoices || []),
    );
    this.formGroup = this.fb.group({
      commoditiesExcludedIds: [null],
      hasHazmatEndorsement: [null],
      hasTankerEndorsement: [null],
      multistopChoice: [null],
      dropTrailerChoice: [null],
      driverTouchChoice: [null],
    });
  }

  private setupForm(prefs: DriverLoadPreferences) {
    this.formGroup.setValue(prefs);
  }

  public doSave() {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }
    const value: DriverLoadPreferences = this.formGroup.getRawValue();
    this.save.next(value);
  }
}
