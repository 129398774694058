import { Component, ChangeDetectionStrategy } from '@angular/core';
import { CustomersService } from '../../services/customers.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { NetworkableDestroyableComponent } from '../networkable-destroyable.component';
import { SnackBarService } from '../../services/snackbar.service';

@Component({
  selector: 'td-create-customer',
  templateUrl: './create-customer.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateCustomerComponent extends NetworkableDestroyableComponent {
  public form: UntypedFormGroup;

  constructor(
    private customerService: CustomersService,
    private fb: UntypedFormBuilder,
    private ref: MatDialogRef<CreateCustomerComponent>,
    private snackbar: SnackBarService,
  ) {
    super();
    this.form = this.fb.group({
      name: [null, [Validators.required]],
      type: ['shipper', [Validators.required]],
      brokerage: ['2', [Validators.required]],
      billingContactName: [null, []],
      billingContactPhone: [null, []],
      billingContactEmail: [null, []],
      billingContactBillingInstructions: [null, []],
      notifyOfDetentionMinutes: [30, [Validators.required]],
      detentionCents: [0, [Validators.required]],
      detentionStartsAfterMinutes: [0, [Validators.required]],
      canPriceBelowCostToCover: [false, []],
    });
  }

  public async createCustomer() {
    if (this.form.invalid && this.networkActive$$.value) {
      return;
    }
    try {
      this.networkActive$$.next(true);
      const val = this.form.value;
      const succ = await this.customerService.createCustomer(val);
      if (succ) {
        this.snackbar.showMessage('Customer Created');
        this.ref?.close({
          id: succ,
        });
      }
    } finally {
      this.networkActive$$.next(false);
    }
  }
}
