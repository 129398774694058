import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'push',
})
export class PushPipe implements PipeTransform {
  public transform<T>(value: T[], otherArray: T[]): T[] {
    if (!value || !otherArray) {
      return value || otherArray;
    }
    const val = value.slice();
    val.push(...otherArray);
    return val;
  }
}
