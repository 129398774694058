import { FileUploadType } from '../global-types';

const uploadTypes: Record<FileUploadType, string> = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  rate_con: 'Rate Con',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  bol_image: 'BOL Image',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  other_image: 'Other',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ticket_image: 'Ticket Image',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  in_gate: 'In-gate',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  out_gate: 'Out-gate',
};

export const toReadableFileUploadType = (value: string): string => {
  const fileType = uploadTypes[value];

  if (fileType !== undefined) {
    return fileType;
  }

  return value;
};
