import { Injectable } from '@angular/core';
import { ReloadableSubject } from '../../../shared/utilities/reloadableSubject';
import { BehaviorSubject, catchError, filter, Observable, of, switchMap, tap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { TourBillingDetails } from '../carrier-loads/carrier-loads.service';
import { AuthService } from '../../../shared/services/auth.service';

export interface OTRCompletedLoadCounts {
  actionNeededCount: number;
  awaiting5FQAReviewCount: number;
  awaiting5FBillingReviewCount: number;
  awaiting5FReviewCount: number;
  approvedForPaymentCount: number;
  invoicedCount: string;
  settledCount: string;
}

export interface OTRSettledLoadResponse {
  previousPageStartAt: number;
  nextPageStartAt: number;
  total: number;
  loads: OTRCompletedSettledListItem[];
}

export interface OTRSettledLoadsLoading {
  previousPageStartAt: number;
  nextPageStartAt: number;
  total: number;
  loads: OTRSettledLoadListItemLoading[];
}

export interface OTRCompletedLoadListItem {
  id: string;
  referenceNumber: string;
  driverName: string;
  customer: string;
  originName: string;
  originTime: string;
  destinationName: string;
  destinationTime: string;
  totalCents: number;
  approvedAt: string;
  approvedByName: string;
  linehaulMiles: number;
}

export interface OTRSettledLoadListItemLoading {
  load: OTRCompletedSettledListItem;
  loading: boolean;
}

export interface OTRCompletedActionNeededLoadListItem extends OTRCompletedLoadListItem {
  disapprovalReason: string;
  hasChargesWithMissingUploads: boolean;
  missingDocumentNames: string[];
  readyToInvoiceAt: string;
  rejectedCharges: {
    chargeName: string;
    rejectedReason: string;
  }[];
}

export interface OTRCompletedActionNeededLoadListItemLoading {
  load: OTRCompletedActionNeededLoadListItem;
  loading: boolean;
}

export interface OTRCompletedSettledListItem extends OTRCompletedLoadListItem {
  settlementDate: string;
  settlementId: string | null;
  totalSettledCents: number | null;
  wasManual: boolean;
}

export interface PaginationAndSearch {
  startAt: number;
  count: number;
  searchFor: string | null;
}

const pageCount = 25;

@Injectable({
  providedIn: 'root',
})
export class CarrierCompletedLoadsService {
  private companyId$: Observable<string | null> = this.authService.userInfo$.pipe(
    map((userInfo) => userInfo?.companyId ?? null),
    filter((companyId) => !!companyId),
    distinctUntilChanged(),
  );
  private settledPaginationAndSearch$$ = new BehaviorSubject<PaginationAndSearch>({
    startAt: 0,
    count: pageCount,
    searchFor: null,
  });

  public settledPaginationAndSearch$ = this.settledPaginationAndSearch$$.asObservable();

  private invoicedPaginationAndSearch$$ = new BehaviorSubject<PaginationAndSearch>({
    startAt: 0,
    count: pageCount,
    searchFor: null,
  });

  public invoicedPaginationAndSearch$ = this.invoicedPaginationAndSearch$$.asObservable();

  private completedSummary$$ = new ReloadableSubject<string, OTRCompletedLoadCounts>(
    {
      actionNeededCount: 0,
      awaiting5FReviewCount: 0,
      approvedForPaymentCount: 0,
      awaiting5FQAReviewCount: 0,
      awaiting5FBillingReviewCount: 0,
      invoicedCount: '0',
      settledCount: '0',
    },
    () => this.getCompletedSummary$(),
    this.companyId$,
  );
  public completedSummary$: Observable<OTRCompletedLoadCounts> = this.completedSummary$$.asObservable();
  public completedSummaryNetworkActive$ = this.completedSummary$$.networkActive$;

  private actionNeededCount$$ = new ReloadableSubject<string, number>(
    0,
    () => this.getActionNeededCount$(),
    this.companyId$,
  );
  public actionNeededCount$: Observable<number> = this.actionNeededCount$$.asObservable();

  private actionNeededLoads$$ = new ReloadableSubject<string, OTRCompletedActionNeededLoadListItemLoading[]>(
    [],
    () => this.getActionNeededLoads$(),
    this.companyId$,
  );
  public actionNeededLoads$: Observable<OTRCompletedActionNeededLoadListItemLoading[]> =
    this.actionNeededLoads$$.asObservable();
  public actionNeededLoadsNetworkActive$ = this.actionNeededLoads$$.networkActive$;

  private awaiting5FQALoads$$ = new ReloadableSubject<string, OTRCompletedLoadListItem[]>(
    [],
    () => this.getAwaiting5FQALoads$(),
    this.companyId$,
  );

  public awaiting5FQALoads$: Observable<OTRCompletedLoadListItem[]> = this.awaiting5FQALoads$$.asObservable();
  public awaiting5FQALoadsNetworkActive$ = this.awaiting5FQALoads$$.networkActive$;

  private awaiting5FBillingLoads$$ = new ReloadableSubject<string, OTRCompletedLoadListItem[]>(
    [],
    () => this.getAwaiting5FBillingLoads$(),
    this.companyId$,
  );

  public awaiting5FBillingLoads$: Observable<OTRCompletedLoadListItem[]> = this.awaiting5FBillingLoads$$.asObservable();
  public awaiting5FBillingLoadsNetworkActive$ = this.awaiting5FBillingLoads$$.networkActive$;

  private approvedLoads$$ = new ReloadableSubject<string, OTRCompletedLoadListItem[]>(
    [],
    () => this.getApprovedLoads$(),
    this.companyId$,
  );
  public approvedLoads$: Observable<OTRCompletedLoadListItem[]> = this.approvedLoads$$.asObservable();
  public approvedLoadsNetworkActive$ = this.approvedLoads$$.networkActive$;

  private settledLoads$$ = new ReloadableSubject<PaginationAndSearch, OTRSettledLoadsLoading>(
    {
      previousPageStartAt: null,
      nextPageStartAt: null,
      total: 0,
      loads: [...Array(pageCount)].map(() => ({ load: null, loading: true })),
    },
    (pg: PaginationAndSearch) => this.getSettledLoads$(pg),
    this.settledPaginationAndSearch$,
  );

  public settledLoads$: Observable<OTRSettledLoadsLoading> = this.settledLoads$$.asObservable();
  public settledLoadsNetworkActive$ = this.settledLoads$$.networkActive$;

  private invoicedLoads$$ = new ReloadableSubject<PaginationAndSearch, OTRSettledLoadsLoading>(
    {
      previousPageStartAt: null,
      nextPageStartAt: null,
      total: 0,
      loads: [...Array(pageCount)].map(() => ({ load: null, loading: true })),
    },
    (pg: PaginationAndSearch) => this.getInvoicedLoads$(pg),
    this.invoicedPaginationAndSearch$,
  );

  public invoicedLoads$: Observable<OTRSettledLoadsLoading> = this.invoicedLoads$$.asObservable();
  public invoicedLoadsNetworkActive$ = this.invoicedLoads$$.networkActive$;

  constructor(private httpClient: HttpClient, private authService: AuthService) {}

  public refreshActionNeeded() {
    this.actionNeededLoads$$.refresh(true);
    this.completedSummary$$.refresh(true);
  }

  public setSettledSearchFor(searchFor: string) {
    this.settledPaginationAndSearch$$.next({
      ...this.settledPaginationAndSearch$$.value,
      searchFor,
    });
  }

  public settledPreviousPage() {
    if (this.settledLoads$$.value?.previousPageStartAt !== null) {
      this.settledPaginationAndSearch$$.next({
        ...this.settledPaginationAndSearch$$.value,
        startAt: this.settledLoads$$.value.previousPageStartAt,
      });
    }
  }

  public settledNextPage() {
    if (this.settledLoads$$.value?.nextPageStartAt !== null) {
      this.settledPaginationAndSearch$$.next({
        ...this.settledPaginationAndSearch$$.value,
        startAt: this.settledLoads$$.value.nextPageStartAt,
      });
    }
  }

  public setInvoicedSearchFor(searchFor: string) {
    this.invoicedPaginationAndSearch$$.next({
      ...this.invoicedPaginationAndSearch$$.value,
      searchFor,
    });
  }

  public invoicedPreviousPage() {
    if (this.invoicedLoads$$.value?.previousPageStartAt !== null) {
      this.invoicedPaginationAndSearch$$.next({
        ...this.invoicedPaginationAndSearch$$.value,
        startAt: this.invoicedLoads$$.value.previousPageStartAt,
      });
    }
  }

  public invoicedNextPage() {
    if (this.invoicedLoads$$.value?.nextPageStartAt !== null) {
      this.invoicedPaginationAndSearch$$.next({
        ...this.invoicedPaginationAndSearch$$.value,
        startAt: this.invoicedLoads$$.value.nextPageStartAt,
      });
    }
  }

  private getCompletedSummary$(): Observable<OTRCompletedLoadCounts> {
    return this.httpClient
      .get<{ counts: OTRCompletedLoadCounts }>(`${environment.api}/v2/load/otr_list_v2/completed/summary_info`)
      .pipe(
        map((res) => res.counts),
        tap((counts) => {
          if (counts) {
            this.actionNeededCount$$.next(counts.actionNeededCount || 0);
          }
        }),
      );
  }

  private getActionNeededCount$(): Observable<number> {
    return this.httpClient
      .get<{ actionNeededCount: number }>(`${environment.api}/v2/load/otr_list_v2/completed/summary_info/action_needed`)
      .pipe(map((res) => res.actionNeededCount));
  }

  private getActionNeededLoads$(): Observable<OTRCompletedActionNeededLoadListItemLoading[]> {
    return this.httpClient
      .get<{ loads: OTRCompletedActionNeededLoadListItem[] }>(
        `${environment.api}/v2/load/otr_list_v2/completed/action_needed`,
      )
      .pipe(map((res) => res.loads.map((load) => ({ load, loading: false }))));
  }

  private getAwaiting5FQALoads$(): Observable<OTRCompletedLoadListItem[]> {
    return this.httpClient
      .get<{ loads: OTRCompletedLoadListItem[] }>(
        `${environment.api}/v2/load/otr_list_v2/completed/awaiting_5f_qa_approval`,
      )
      .pipe(map((res) => res.loads));
  }

  private getAwaiting5FBillingLoads$(): Observable<OTRCompletedLoadListItem[]> {
    return this.httpClient
      .get<{ loads: OTRCompletedLoadListItem[] }>(
        `${environment.api}/v2/load/otr_list_v2/completed/awaiting_5f_billing_approval`,
      )
      .pipe(map((res) => res.loads));
  }

  private getApprovedLoads$(): Observable<OTRCompletedLoadListItem[]> {
    return this.httpClient
      .get<{ loads: OTRCompletedLoadListItem[] }>(`${environment.api}/v2/load/otr_list_v2/completed/approved`)
      .pipe(map((res) => res.loads));
  }

  private getSettledLoads$(pg: PaginationAndSearch): Observable<OTRSettledLoadsLoading> {
    const params = {
      startAt: pg.startAt,
      count: pg.count,
    };
    if (pg.searchFor?.length > 0) {
      params['searchFor'] = pg.searchFor;
    }
    return this.httpClient
      .get<OTRSettledLoadResponse>(`${environment.api}/v2/load/otr_list_v2/completed/settled`, {
        params,
      })
      .pipe(
        map((res) => {
          return {
            previousPageStartAt: res.previousPageStartAt,
            nextPageStartAt: res.nextPageStartAt,
            total: res.total,
            loads: res.loads.map((load) => {
              return {
                load,
                loading: false,
              };
            }),
          };
        }),
      );
  }

  private getInvoicedLoads$(pg: PaginationAndSearch): Observable<OTRSettledLoadsLoading> {
    const params = {
      startAt: pg.startAt,
      count: pg.count,
    };
    if (pg.searchFor?.length > 0) {
      params['searchFor'] = pg.searchFor;
    }
    return this.httpClient
      .get<OTRSettledLoadResponse>(`${environment.api}/v2/load/otr_list_v2/completed/invoiced_on_behalf_of_carrier`, {
        params,
      })
      .pipe(
        map((res) => {
          return {
            previousPageStartAt: res.previousPageStartAt,
            nextPageStartAt: res.nextPageStartAt,
            total: res.total,
            loads: res.loads.map((load) => {
              return {
                load,
                loading: false,
              };
            }),
          };
        }),
      );
  }

  private refreshActionNeededLoad$(loadID: string): Observable<OTRCompletedActionNeededLoadListItem> {
    return this.httpClient
      .get<{ load: OTRCompletedActionNeededLoadListItem }>(
        `${environment.api}/v2/load/otr_list_v2/completed/action_needed/${loadID}`,
      )
      .pipe(
        map((res) => res.load),
        tap((load) => {
          const loads = [...this.actionNeededLoads$$.value];
          const idx = loads.findIndex((l) => l.load?.id === load.id);
          if (idx !== -1) {
            loads[idx] = { load, loading: false };
            this.actionNeededLoads$$.next(loads);
          }
        }),
      );
  }

  private markActionNeededNetworkActive(loadID: string) {
    const loads = [...this.actionNeededLoads$$.value];
    const idx = loads.findIndex((l) => l.load?.id === loadID);
    if (idx !== -1) {
      loads[idx] = {
        ...loads[idx],
        loading: true,
      };
      this.actionNeededLoads$$.next(loads);
    }
  }

  private markActionNeededNetworkInactive(loadID: string) {
    const loads = [...this.actionNeededLoads$$.value];
    const idx = loads.findIndex((l) => l.load?.id === loadID);
    if (idx !== -1) {
      loads[idx] = {
        ...loads[idx],
        loading: false,
      };
      this.actionNeededLoads$$.next(loads);
    }
  }

  public approveLoad$(loadID: string): Observable<OTRCompletedActionNeededLoadListItem> {
    this.markActionNeededNetworkActive(loadID);
    return this.httpClient
      .post<{ details: TourBillingDetails }>(`${environment.api}/v2/load/${loadID}/tour_billing/admin_approve`, {})
      .pipe(
        catchError(() => {
          this.markActionNeededNetworkInactive(loadID);
          return of(null);
        }),
        map((resp) => resp?.details),
        switchMap((details) => {
          if (details) {
            return this.refreshActionNeededLoad$(details.id);
          }
          return of(null);
        }),
      );
  }

  public unapproveLoad$(loadID: string): Observable<OTRCompletedActionNeededLoadListItem> {
    this.markActionNeededNetworkActive(loadID);
    return this.httpClient
      .post<{ details: TourBillingDetails }>(`${environment.api}/v2/load/${loadID}/tour_billing/admin_unapprove`, {})
      .pipe(
        map((resp) => resp.details),
        switchMap((details) => {
          return this.refreshActionNeededLoad$(details.id);
        }),
      );
  }
}
