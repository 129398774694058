import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserManagementService {
  constructor(private http: HttpClient) {}

  public async createNewAdmin(newAdmin: any) {
    try {
      const url = await lastValueFrom(
        this.http.post<{ userID: string }>(
          `${environment.api}/v2/max_payloop/dispatcher/company_search/create_company_admin`,
          newAdmin,
        ),
      );
      return url.userID;
    } catch (e) {
      return false;
    }
  }

  public async createNewDriver(newDriver: any) {
    try {
      const url = await lastValueFrom(
        this.http.post<{ userID: string }>(
          `${environment.api}/v2/max_payloop/dispatcher/company_search/create_company_driver`,
          newDriver,
        ),
      );
      return url.userID;
    } catch (e) {
      return false;
    }
  }
}
