<div *ngIf="loadRateconPaymentStatus" class="mx-auto max-w-screen-lg">
  <div class="card-with-shadow-white-no-radius p-2 print:shadow-none">
    <div class="font-semibold text-xl">Payment Processing Status</div>
    <div class="p-2 gap-1 my-1 mx-1">
      <mat-progress-bar
        mode="determinate"
        value="{{
          loadRateconPaymentStatus.isPaymentSettled
            ? 100
            : loadRateconPaymentStatus.isPaymentApproved
            ? 65
            : loadRateconPaymentStatus.isInvoiceApproved
            ? 30
            : 10
        }}"
      >
      </mat-progress-bar>
    </div>
    <div class="grid grid-cols-3 pt-1 text-center">
      <div class="grid grid-rows-3">
        <div>
          <mat-icon *ngIf="loadRateconPaymentStatus.isInvoiceApproved" class="text-green-500">check_circle</mat-icon>
          <mat-icon *ngIf="!loadRateconPaymentStatus.isInvoiceApproved" color="warn">cancel</mat-icon>
        </div>
        <div class="font-semibold">
          <ng-container *ngIf="loadRateconPaymentStatus.isInvoiceApproved">Carrier Approved</ng-container>
          <ng-container *ngIf="!loadRateconPaymentStatus.isInvoiceApproved">Carrier Not Approved Yet</ng-container>
        </div>
        <div *ngIf="loadRateconPaymentStatus.isInvoiceApproved">
          {{ loadRateconPaymentStatus.invoiceApprovedByCarriersAt | standardDate }}
        </div>
      </div>
      <div class="grid grid-rows-3">
        <div>
          <mat-icon *ngIf="loadRateconPaymentStatus.isPaymentApproved" class="text-green-500">check_circle</mat-icon>
          <mat-icon *ngIf="!loadRateconPaymentStatus.isPaymentApproved" color="warn">cancel</mat-icon>
        </div>
        <div class="font-semibold">
          <ng-container *ngIf="loadRateconPaymentStatus.isPaymentApproved"
            >{{ brokerage === 'lohi' ? 'LoHi' : (brokerage | titlecase) }} Approved
          </ng-container>
          <ng-container *ngIf="!loadRateconPaymentStatus.isPaymentApproved"
            >{{ brokerage === 'lohi' ? 'LoHi' : (brokerage | titlecase) }}
            Not Approved Yet
          </ng-container>
        </div>
        <div *ngIf="loadRateconPaymentStatus.isPaymentApproved">
          {{ loadRateconPaymentStatus.paymentApprovedBy5FAt | standardDate }}
        </div>
      </div>
      <div class="grid grid-rows-3">
        <div>
          <mat-icon *ngIf="loadRateconPaymentStatus.isPaymentSettled" class="text-green-500">check_circle</mat-icon>
          <mat-icon *ngIf="!loadRateconPaymentStatus.isPaymentSettled" color="warn">cancel</mat-icon>
        </div>
        <div class="font-semibold">
          <ng-container *ngIf="loadRateconPaymentStatus.isPaymentSettled">Funds Disbursed</ng-container>
          <ng-container *ngIf="!loadRateconPaymentStatus.isPaymentSettled">Funds Not Disbursed Yet</ng-container>
        </div>
        <div *ngIf="loadRateconPaymentStatus.isPaymentSettled">
          {{ loadRateconPaymentStatus.paymentSettledAt | standardDate }}
        </div>
      </div>
    </div>
    <ng-container *tdFeatureFlag="'showLoadDisapprovalHistory'">
      <!--    Once we have approved this, there is no real reason to show the history, just want to make factoring companies afraiad-->
      <ng-container *ngIf="!loadRateconPaymentStatus.paymentApprovedBy5FAt">
        <div *ngIf="loadRateconPaymentStatus.rejectionEvents?.length">
          <div class="font-semibold text-lg">
            <span class="text-red-500">Warning</span> This load has previously been pushed pushed back for the following
            reasons
          </div>
          <div class="flex flex-col pt-1 text-center">
            <div
              class="flex flex-row"
              *ngFor="
                let event of loadRateconPaymentStatus.rejectionEvents;
                trackBy: loadRateconPaymentStatus.rejectionEvents | trackByKey: 'eventTime'
              "
            >
              <div class="mr-3">
                {{ event.eventTime | standardDate }}
              </div>
              <div class="font-semibold">
                {{ event.carrierNotes }}
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
