<div mat-dialog-title>{{ data.title }}</div>
<div mat-dialog-content>
  <mat-form-field class="w-full">
    <mat-label>{{ data.reasonLabel }}</mat-label>
    <textarea matInput [formControl]="reasonControl" autocomplete="off"></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions class="flex justify-between gap-2">
  <button mat-stroked-button [matDialogClose]="{ confirmed: false, reason: '' }">
    {{ data.noLabel }}
  </button>
  <button
    mat-flat-button
    color="primary"
    [matDialogClose]="{ confirmed: true, reason: reasonControl.value }"
    [disabled]="reasonControl.invalid"
  >
    {{ data.yesLabel }}
  </button>
</div>
