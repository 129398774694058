import { Injectable } from '@angular/core';
import { SimpleFinn } from '../utilities/simpleFinn';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';

export interface WorkPhone {
  phoneNumber: string | null;
  phoneNumberExtension: string | null;
}

@Injectable({
  providedIn: 'root',
})
export class WorkPhoneService {
  private workPhone: SimpleFinn<WorkPhone>;

  public get workPhone$() {
    return this.workPhone.get$();
  }
  constructor(private http: HttpClient) {
    this.workPhone = new SimpleFinn<WorkPhone>(null, this.loadWorkPhone);
  }

  private loadWorkPhone = async () => {
    return await lastValueFrom(this.http.get<WorkPhone>(`${environment.api}/v2/max_payloop/dispatcher/work_phone`));
  };

  public async updateWorkPhone$(workPhone: WorkPhone) {
    try {
      await lastValueFrom(
        this.http.put<WorkPhone>(`${environment.api}/v2/max_payloop/dispatcher/work_phone`, workPhone),
      );
      this.workPhone.get$();
      return true;
    } catch (e) {
      return false;
    }
  }
}
