<div mat-dialog-title>
  <div class="flex justify-between">
    <div>Assign Point of Contact</div>
    <mat-spinner class="ml-1" [diameter]="24" *ngIf="networkActive$ | async"></mat-spinner>
  </div>
</div>
<div mat-dialog-content class="flex flex-col gap-y-2 pt-2">
  <mat-form-field class="w-full">
    <mat-label>Select Contact</mat-label>
    <mat-select *ngIf="contacts$ | async as contacts" [formControl]="contactControl">
      <mat-select-trigger>
        {{ contactControl.value?.name }}
      </mat-select-trigger>
      <mat-option>
        <ngx-mat-select-search
          placeholderLabel="Search"
          noEntriesFoundLabel="No match found"
          [formControl]="searchControl"
        ></ngx-mat-select-search>
      </mat-option>
      <mat-option [value]="null"> Unassign Contact</mat-option>
      <mat-option
        style="height: unset; min-height: 50px; line-height: 24px; font-size: 14px"
        *ngFor="let contact of contacts; trackBy: contacts | trackByKey: 'id'"
        [value]="contact"
      >
        <div class="my-1">
          <div>{{ contact.name | dashIfNothing }}</div>
          <div class="text-sm text-gray-600">Phone: {{ contact.phone | phone }}</div>
          <div class="text-sm text-gray-600">Email: {{ contact.email | dashIfNothing }}</div>
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <div class="flex flex-row justify-between gap-x-3">
    <div>
      <button mat-stroked-button mat-dialog-close="">Nevermind</button>
    </div>
    <div *ngIf="contactControl.value === null; else assign" class="flex flex-row">
      <button
        mat-flat-button
        color="warn"
        (click)="reassignCarrierPointOfContact()"
        [disabled]="networkActive$ | async"
      >
        Unassign Contact
      </button>
    </div>
    <ng-template #assign>
      <button
        mat-flat-button
        color="accent"
        (click)="reassignCarrierPointOfContact()"
        [disabled]="networkActive$ | async"
      >
        Assign Contact
      </button>
    </ng-template>
  </div>
</div>
