import { HostListener, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Params, ResolveEnd, Router, RoutesRecognized } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

export interface RouterState {
  params: Params;
  queryParams: Params;
  url: string;
}

@Injectable({
  providedIn: 'root',
})
export class RouterStateService {
  private backUrl$$ = new BehaviorSubject<string[]>(null);
  private backUrlParams$$ = new BehaviorSubject<Params>(null);
  private routerState$$ = new BehaviorSubject<RouterState>(null);

  public get routerState(): RouterState {
    return this.routerState$$.value;
  }

  public get routerState$(): Observable<RouterState> {
    return this.routerState$$.pipe(filter((params) => !!params));
  }

  public get backUrl$(): Observable<string[]> {
    return this.backUrl$$;
  }

  public get backUrlParams$(): Observable<Params> {
    return this.backUrlParams$$;
  }

  public listenForParamChange$(paramName: string): Observable<string> {
    return this.routerState$.pipe(
      map((state) => state.params[paramName]),
      distinctUntilChanged(),
    );
  }

  public listenForQueryChange$(queryParamName: string): Observable<string> {
    return this.routerState$.pipe(
      map((state) => state.queryParams[queryParamName]),
      distinctUntilChanged(),
    );
  }

  constructor(private router: Router) {
    this.router.events
      .pipe(
        filter((event) => {
          return event instanceof NavigationEnd || event instanceof ResolveEnd;
        }),
      )
      .subscribe((value: NavigationEnd | ResolveEnd) => {
        const snapshot = (value as ResolveEnd).state || this.router.routerState.snapshot;
        let { url } = snapshot;
        url = url.split('?')[0];
        const { queryParams } = snapshot.root;

        if (this.routerState$$.value) {
          const withoutParams = this.routerState$$.value.url.split('?')[0];
          if (withoutParams !== url) {
            const oldUrl = ['/', ...withoutParams.split('/').filter((segment) => segment !== '')];
            this.backUrl$$.next(oldUrl);
            this.backUrlParams$$.next(this.routerState$$.value.queryParams);
          }
        }

        let state: ActivatedRouteSnapshot = snapshot.root;
        let params = {};
        while (state.firstChild) {
          params = { ...params, ...state.params };
          state = state.firstChild;
        }
        params = { ...params, ...state.params };
        const data = state.data;
        const routerState = {
          params,
          queryParams,
          url,
          data,
        };
        this.routerState$$.next(routerState);
      });
  }
}
