import { Pipe, PipeTransform } from '@angular/core';
import { CompetitionMetric } from '../global-types';

const statuses: Record<CompetitionMetric, string> = {
  totalDriversManaged: 'Total Drivers Managed',
  totalRevenueCents: 'Total Revenue',
  revenuePerDayCents: 'Revenue per Day',
  averageRevenueCentsPerDriver: 'Average Revenue per Driver',
  averageRatePerMileCents: 'Average Rate per Mile',
  averageChatResponseTime: 'Average Chat Response Time',
  assetUtilization: 'Asset Utilization',
  totalLoadsBooked: 'Total Loads Booked',
  averageRecommendationRanking: 'Average Recommendation Ranking',
};

@Pipe({
  name: 'competitionMetric',
})
export class CompetitionMetricPipe implements PipeTransform {
  public transform(value: CompetitionMetric): string {
    return statuses[value];
  }
}
