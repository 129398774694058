import { Pipe, PipeTransform } from '@angular/core';
import { sortBy } from '../utilities/genericObjectSort';

@Pipe({
  name: 'sort',
})
export class SortPipe implements PipeTransform {
  public transform<T>(value: T[], sortKey: keyof T, sortAsc: boolean = true): T[] {
    if (!sortKey) {
      return value;
    }

    const sort = sortBy(sortKey as string);
    const copy = value.slice().sort(sort);
    return !sortAsc ? copy.reverse() : copy;
  }
}
