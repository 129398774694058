import { ChangeDetectorRef, Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject, takeUntil } from 'rxjs';
import { AuthService, LoHiUserInfo } from '../services/auth.service';
import { Permission } from '../services/auth.service';

@Directive({
  selector: '[tdHasPermission]',
})
export class HasPermissionDirective implements OnDestroy {
  private hasView = false;
  private destroy$$ = new Subject<void>();
  public users$: Observable<LoHiUserInfo>;
  private userPermissions$$ = new BehaviorSubject<Permission[]>([]);
  private notUserPermissions$$ = new BehaviorSubject<Permission[]>([]);
  private elseTemplateRef$$ = new BehaviorSubject<TemplateRef<any>>(null);
  private onlyCheckFranchise$$ = new BehaviorSubject<boolean>(false);

  @Input()
  public set tdHasPermission(userPermissions: Permission | Permission[]) {
    if (!Array.isArray(userPermissions)) {
      this.userPermissions$$.next([userPermissions]);
    } else {
      this.userPermissions$$.next(userPermissions);
    }
  }

  @Input()
  public set tdHasPermissionNot(userPermissions: Permission | Permission[]) {
    if (!Array.isArray(userPermissions)) {
      this.notUserPermissions$$.next([userPermissions]);
    } else {
      this.notUserPermissions$$.next(userPermissions);
    }
  }

  @Input()
  public set tdHasPermissionElse(templateRef: TemplateRef<any>) {
    this.elseTemplateRef$$.next(templateRef);
  }

  @Input()
  public set tdHasPermissionOnlyCheckForFranchise(onlyCheckFranchise: boolean) {
    this.onlyCheckFranchise$$.next(onlyCheckFranchise);
  }

  constructor(
    private authService: AuthService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private cd: ChangeDetectorRef,
  ) {
    this.users$ = this.authService.userInfo$;
    this.listenForViewUpdates();
  }

  public ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.unsubscribe();
  }

  private listenForViewUpdates() {
    combineLatest([
      this.authService.userInfo$,
      this.userPermissions$$,
      this.notUserPermissions$$,
      this.elseTemplateRef$$,
      this.onlyCheckFranchise$$,
    ])
      .pipe(takeUntil(this.destroy$$))
      .subscribe(([userProfile, accountTypes, notAccountTypes, elseRef, onlyCheckFranchise]) => {
        if (
          userProfile &&
          accountTypes.length &&
          ((accountTypes.some((v) => userProfile.permissions.includes(v)) &&
            !(notAccountTypes.length && notAccountTypes.some((v) => userProfile.permissions.includes(v)))) ||
            (onlyCheckFranchise && !userProfile.isOtrFranchisee))
        ) {
          this.renderView();
        } else {
          if (elseRef) {
            this.renderElseView(elseRef);
          } else {
            this.clearView();
          }
        }
      });
  }

  private renderView() {
    this.clearView();
    if (!this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
      this.cd.markForCheck();
    }
  }

  private clearView() {
    if (this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
      this.cd.markForCheck();
    }
  }

  private renderElseView(ref: TemplateRef<any>) {
    this.clearView();
    if (!this.hasView) {
      this.viewContainer.createEmbeddedView(ref);
      this.hasView = true;
      this.cd.markForCheck();
    }
  }
}
