import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MappableComponent } from '../mappable/mappable.component';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { GeoJSONSource, LngLatBounds } from 'mapbox-gl';
import { Feature, FeatureCollection, Polygon } from '@turf/helpers/dist/js/lib/geojson';
import { XYPoint } from '../../global-types';
import { centroid } from '@turf/centroid';

@Component({
  selector: 'td-polygonal-geofence-facility',
  templateUrl: './polygonal-geofence-facility.component.html',
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PolygonalGeofenceFacilityComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: PolygonalGeofenceFacilityComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PolygonalGeofenceFacilityComponent extends MappableComponent implements ControlValueAccessor, Validator {
  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};
  private disabled = true;
  private _polygon: { x: number; y: number }[] = [];
  @Input()
  public set polygon(p: XYPoint[]) {
    // this is only valid if we are not using this in form mode
    this._polygon = p;
    this.recalculateMap();
  }

  private draw: MapboxDraw;

  constructor() {
    super();
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.recalculateMap();
  }

  public writeValue(obj: any): void {
    this.polygon = obj;
  }

  public override onStyleLoad() {
    super.onStyleLoad();
    this.recalculateMap();
  }

  public registerOnValidatorChange(fn: () => void): void {
    // do nothing
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value as XYPoint[];
    if (!value) {
      return null;
    }
    if (value.length < 4) {
      return {
        polygon: 'Polygon must have at least 3 points',
      };
    }
    if (value[0].x !== value[value.length - 1].x || value[0].y !== value[value.length - 1].y) {
      return {
        polygon: 'Polygon must be closed',
      };
    }
    return null;
  }

  private recalculateMap() {
    if (!this.map) {
      return;
    }
    if (this.disabled) {
      this.makeUneditable();
    } else {
      this.makeEditable();
    }
    this.updateBounds();
  }

  private updateBounds() {
    if (this._polygon.length === 0) {
      return;
    }
    const center = centroid({
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Polygon',
        coordinates: [this._polygon.map(({ x, y }) => [x, y])],
      },
    });
    this.map.setCenter(center.geometry.coordinates as any);
    const bounds = new LngLatBounds();
    this._polygon.forEach(({ x, y }) => {
      bounds.extend([x, y]);
    });
    this.map.fitBounds(bounds, {
      padding: 100,
    });
  }

  private makeEditable() {
    const layer = this.map.getLayer('draw-readonly');
    if (layer) {
      this.map.removeLayer('draw-readonly');
    }

    if (!this.draw) {
      this.draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: true,
          trash: true,
        },
      });
      this.map.addControl(this.draw);
    }

    this.draw.deleteAll();

    this.draw.set({
      type: 'FeatureCollection',
      features: [
        {
          id: 'draw',
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Polygon',
            coordinates: [this._polygon.map(({ x, y }) => [x, y])],
          },
        },
      ],
    });

    this.draw.changeMode('simple_select', { featureIds: ['draw'] });

    this.map.on('draw.create', (e) => {
      this.handlePolygonChange();
    });

    this.map.on('draw.update', (e) => {
      this.onTouched();
      this.handlePolygonChange();
    });

    this.map.on('draw.delete', () => {
      this.onTouched();
      this.handlePolygonChange();
    });
  }

  private handlePolygonChange() {
    const data = this.draw.getAll() as FeatureCollection<Polygon>;
    const coordinates = data.features[0].geometry.coordinates[0];
    this._polygon = coordinates.map(([x, y]) => ({ x, y }));
    this.onChange(this._polygon);
  }

  private makeUneditable() {
    if (this.draw) {
      this.map.removeControl(this.draw);
    }

    const data: Feature = {
      type: 'Feature',
      properties: {
        name: 'draw-readonly',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [this._polygon.map(({ x, y }) => [x, y])],
      },
    };

    const source = this.map.getSource('draw-readonly') as GeoJSONSource;
    if (source) {
      source.setData(data as any);
    } else {
      this.map.addSource('draw-readonly', {
        type: 'geojson',
        data: data as any,
      });
      this.map.addLayer({
        id: 'draw-readonly',
        type: 'fill',
        source: 'draw-readonly',
        paint: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'fill-color': '#0080ff', // blue color fill
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'fill-opacity': 0.5,
        },
      });
    }
  }
}
