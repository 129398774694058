import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpBackend, HttpClient } from '@angular/common/http';

export interface HereSearchResult {
  title: string;
  id: string;
  resultType: string;
  localityType: string;
  address: Address;
}

/*export interface Address {
  label: string;
  countryCode: string;
  countryName: string;
  stateCode: string;
  state: string;
  county: string;
  city: string;
  postalCode: string;
}*/

export interface HereLookupResult {
  title: string;
  id: string;
  resultType: string;
  localityType: string;
  address: Address;
  position: Position;
  mapView: MapView;
}

export interface Address {
  label: string;
  countryCode: string;
  countryName: string;
  stateCode: string;
  state: string;
  county: string;
  city: string;
  postalCode: string;
  addressCity: string;
  building: string;
  countyCode: string;
  district: string;
  houseNumber: string;
  street: string;
  subblock: string;
  subdistrict: string;
}

export interface MapView {
  west: number;
  south: number;
  east: number;
  north: number;
}

export interface Position {
  lat: number;
  lng: number;
}

@Injectable({
  providedIn: 'root',
})
export class HereService {
  private httpClient: HttpClient;
  constructor(private http: HttpBackend) {
    this.httpClient = new HttpClient(this.http);
  }

  public searchForAddresses$(search: string): Observable<HereSearchResult[]> {
    return this.httpClient
      .get<{ items: HereSearchResult[] }>(`${environment.api}/v2/external/shipper_load_bid/autosuggest?q=${search}`)
      .pipe(map((v) => v?.items || []));
  }

  public lookup$(id: string): Observable<HereLookupResult> {
    return this.httpClient
      .get<{ result: HereLookupResult }>(`${environment.api}/v2/external/shipper_load_bid/lookup?hereID=${id}`)
      .pipe(map((v) => v?.result));
  }
}
