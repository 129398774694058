import { Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

export interface TimezoneResponse {
  name: string;
  abbreviation: string;
}

@Injectable({
  providedIn: 'root',
})
export class TimezoneService {
  private readonly tmpCache: Record<number, Record<number, TimezoneResponse>> = {};

  constructor(private http: HttpClient) {}

  public queryTimezoneForLocation$(latitude: number, longitude: number): Observable<TimezoneResponse> {
    const lngCache = this.tmpCache[longitude];
    if (lngCache) {
      const tz = lngCache[latitude];
      if (tz) {
        return of(tz);
      }
    }
    return this.http
      .get<TimezoneResponse>(`${environment.api}/v2/max_payloop/timezone`, {
        params: {
          latitude,
          longitude,
        },
      })
      .pipe(
        tap((v) => {
          const lngCache = this.tmpCache[longitude] || {};
          lngCache[latitude] = v;
          this.tmpCache[longitude] = lngCache;
        }),
      );
  }
}
