import { Pipe, PipeTransform } from '@angular/core';
import { CarrierType } from '../global-types';

const types: Record<CarrierType, string> = {
  all: 'Totals',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  CPG: 'CPG',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Tours: 'Tours',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Domestic: 'Domestic',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'Import/Export': 'Import/Export',
};

@Pipe({
  name: 'carrierType',
})
export class CarrierTypePipe implements PipeTransform {
  public transform(value: CarrierType): string {
    return types[value] || value;
  }
}
