import {
  add,
  addMinutes,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  getDate,
  getMonth,
  getYear,
  isDate,
  isValid,
  sub,
} from 'date-fns';

const differenceInFn = {
  second: differenceInSeconds,
  minute: differenceInMinutes,
  hour: differenceInHours,
  day: differenceInDays,
  month: differenceInMonths,
};
type PossibleParts = keyof typeof differenceInFn;

interface PreciseDiff {
  seconds: number;
  minutes: number;
  hours: number;
  days: number;
  months: number;
}

const dateOrder: (keyof PreciseDiff)[] = ['months', 'days', 'hours', 'minutes'];

const substitutes: Partial<Record<keyof PreciseDiff, string>> = {
  months: 'mo',
  minutes: 'm',
  hours: 'h',
  days: 'd',
};

export const addMinutesIfNotNull = (value: Date | number | string, minutes: number): Date => {
  if (!value && value !== 0) {
    return null;
  }
  if (!isDate(value)) {
    value = new Date(value);
  }
  if (!isValid(value)) {
    return value as Date;
  }
  return add(value as Date, { minutes });
};

export const preciseDifference = (
  leftDate: Date,
  rightDate: Date,
  parts: PossibleParts[] = ['month', 'day', 'hour', 'minute'],
): PreciseDiff | null => {
  const response: PreciseDiff = {
    seconds: 0,
    minutes: 0,
    hours: 0,
    days: 0,
    months: 0,
  };

  parts.forEach((part, i) => {
    const fn = differenceInFn[part];
    if (fn) {
      const differenceInPart = fn(leftDate, rightDate);
      if (differenceInPart) {
        response[`${part}s`] = differenceInPart;
        const subtractionDuration = { [`${part}s`]: differenceInPart };
        // We are just going to subtract from "now" until we get down to the date
        leftDate = sub(leftDate, subtractionDuration);
      }
    }
  });
  return response;
};

export const preciseDiffToHuman = (
  leftDate: Date,
  rightDate: Date,
  maxParts: number,
  suffix: string,
  useShort: boolean,
): string => {
  let response = '—';
  if (isValid(leftDate) && isValid(rightDate)) {
    const preciseDiff = preciseDifference(leftDate, rightDate);
    if (preciseDiff) {
      const result = [];
      let dateOrderIndex = 0;
      while (dateOrderIndex < dateOrder.length && result.length < maxParts) {
        const key = dateOrder[dateOrderIndex];
        if (preciseDiff[key] > 0) {
          const keyName = substitutes[key] && useShort ? substitutes[key] : key;
          if (useShort) {
            result.push(`${preciseDiff[key]}${removePluralIfNeeded(preciseDiff[key], keyName)}`);
          } else {
            result.push(`${preciseDiff[key]} ${removePluralIfNeeded(preciseDiff[key], keyName)}`);
          }
        }
        dateOrderIndex++;
      }
      response = 'just now';
      if (result.length) {
        response = `${result.join(' ')} ${suffix}`;
      }
    }
  }
  return response;
};

export const getUserShortTimezone = (): string | undefined => {
  return /.*\s(.+)/.exec(new Date().toLocaleDateString(navigator.language, { timeZoneName: 'short' }))?.[1];
};

export const removePluralIfNeeded = (count: number, pluralString: string) => {
  if (count !== 1 || !pluralString.endsWith('s')) {
    return pluralString;
  }
  return pluralString.substring(0, pluralString.length - 1);
};

export const getDateOrNullFromString = (start: string | Date): Date | null => {
  if (!start) {
    return null;
  }
  if (typeof start === 'string') {
    start = new Date(start);
  }
  return start;
};

export const stringOrDateToDate = (date: string | Date) => {
  if (typeof date === 'string') {
    return new Date(date);
  }
  return date;
};
export interface ServerDate {
  year: number;
  month: number;
  day: number;
}
