import { Pipe, PipeTransform } from '@angular/core';
import { LoadAutodispatchStatus } from '../../modules/internal/shipper-load/awarded-loads/awarded-loads.service';

const adStatuses: Record<LoadAutodispatchStatus, string> = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  available: 'Available for AD',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  not_available: 'Not Available for AD',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  missing_info: 'AD Missing Info',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  soft_assigned: 'AD Soft Assigned',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  covered: 'AD Soft Assigned',
};

@Pipe({
  name: 'autodispatchStatus',
})
export class AutodispatchStatusPipe implements PipeTransform {
  public transform(value: LoadAutodispatchStatus): string {
    return adStatuses[value];
  }
}
