import { ChangeDetectionStrategy, Component } from '@angular/core';
import { StreamService } from '../../services/stream.service';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SideNavComponent } from '../side-nav.component';

@Component({
  selector: 'td-side-nav-internal',
  templateUrl: './side-nav-internal.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideNavInternalComponent {
  public unreadCount$: Observable<number>;

  constructor(private parent: SideNavComponent, private streamService: StreamService) {
    this.unreadCount$ = this.streamService.unreadCount$;
  }
  public openLoadSearch() {
    this.parent.openCommandPallet();
  }
}
