import { Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatestWith, fromEvent, Observable, Subject, takeUntil } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Directive({
  selector: '[tdDarkModeClass]',
})
export class DarkModeClassDirective implements OnDestroy {
  private destroy$$ = new Subject<void>();

  private isDarkMode$ = fromEvent(window.matchMedia('(prefers-color-scheme: dark)'), 'change').pipe(
    map((v: MediaQueryListEvent) => v.matches),
    startWith(window.matchMedia('(prefers-color-scheme: dark)').matches),
  );
  private classes$$ = new BehaviorSubject<string | string[]>('');
  private lightClasses$$ = new BehaviorSubject<string | string[]>('');

  @Input()
  public set tdDarkModeClassLight(lightClasses: string | string[]) {
    this.lightClasses$$.next(lightClasses);
  }

  @Input()
  public set tdDarkModeClass(cssClass: string | string[]) {
    this.classes$$.next(cssClass);
  }

  constructor(private elRef: ElementRef<HTMLElement>) {
    this.isDarkMode$.pipe(takeUntil(this.destroy$$), combineLatestWith(this.classes$$, this.lightClasses$$)).subscribe({
      next: ([isDark, classes, lightClasses]) => {
        const classesToRemove = isDark ? lightClasses : classes;
        const classesToAdd = isDark ? classes : lightClasses;
        const classesToRemoveArray = Array.isArray(classesToRemove) ? classesToRemove : [classesToRemove];
        const classesToAddArray = Array.isArray(classesToAdd) ? classesToAdd : [classesToAdd];
        if (classesToRemove?.length) {
          this.elRef.nativeElement.classList.remove(...classesToRemoveArray);
        }
        if (classesToAdd?.length) {
          this.elRef.nativeElement.classList.add(...classesToAddArray);
        }
      },
    });
  }

  public ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.unsubscribe();
  }
}
