<div mat-dialog-content>
  <div class="flex flex-col gap-y-2 p-2 py-3 overflow-y-auto md:max-h-[400px]">
    <mat-form-field class="flex-1">
      <mat-icon matPrefix>search</mat-icon>
      <mat-label>Start typing to search for a driver or load</mat-label>
      <input #searchInput matInput autocomplete="off" [formControl]="searchBox" />
      <mat-progress-spinner *ngIf="active$ | async" matSuffix mode="indeterminate" diameter="20"></mat-progress-spinner>
    </mat-form-field>
    <ng-container
      *ngIf="{
        drivers: filteredDrivers$ | async,
        loads: maxLoadSearchResults$ | async,
        trailers: maxTrailersSearchResults$ | async,
        commands: filteredCommands$ | async,
        history: filteredHistory$ | async,
        all: allResults$ | async
      } as searchResults"
    >
      <div
        *ngIf="
          searchResults.drivers?.length + searchResults.loads?.results?.length + searchResults.commands?.length > 10
        "
      >
        <div class="text-xs font-medium text-gray-600">Jump To</div>
        <div class="flex flex-row gap-2">
          <div
            class="hover:text-blue-500 cursor-pointer"
            *ngIf="searchResults.loads?.results?.length"
            (click)="scrollToLoads()"
          >
            Loads
          </div>
          <div
            class="hover:text-blue-500 cursor-pointer"
            *ngIf="searchResults.trailers?.results?.length"
            (click)="scrollToTrailers()"
          >
            Trailers
          </div>
          <div
            class="hover:text-blue-500 cursor-pointer"
            *ngIf="searchResults.drivers?.length"
            (click)="scrollToDrivers()"
          >
            Drivers
          </div>
          <div
            class="hover:text-blue-500 cursor-pointer"
            *ngIf="searchResults.commands?.length"
            (click)="scrollToCommands()"
          >
            Commands
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-y-1" #loadsLink *ngIf="searchResults.loads?.results?.length">
        <div class="font-semibold text-xs">Loads</div>
        <a
          [routerLink]="['/loads', load.internalId]"
          (click)="ref.close(load.internalId)"
          *ngFor="
            let load of searchResults.loads?.results;
            trackBy: searchResults.loads?.results | trackByKey: 'internalId'
          "
          class="flex flex-row cursor-pointer justify-between p-2 hover:dark:bg-zinc-700 hover:bg-gray-200 color-transition px-2 py-1 result"
        >
          <div class="max-w-2/3">
            <div class="font-semibold">{{ load.internalId }} / {{ load.referenceNumber }}</div>
            <div *ngIf="load.shipperId">
              <div class="text-xs text-gray-600">Short ID</div>
              {{ load.shipperId }}
            </div>
            <div
              class="bg-blue-500 text-sm text-white font-medium max-w-min px-1 rounded-sm"
              *ngIf="(firstResult$ | async) === load"
            >
              Enter
            </div>
          </div>
          <div class="text-right">
            <div>
              {{ load.status | lohiLoadStatus }}
            </div>
            <div *ngIf="load.carrierName">
              <div class="text-xs text-gray-600">Carrier</div>
              {{ load.carrierName }}
            </div>
          </div>
        </a>
        <div>
          <button color="accent" mat-button *ngIf="searchResults.loads?.showMore" (click)="showMoreLoads()">
            Show More
          </button>
        </div>
      </div>
      <div class="flex flex-col gap-y-1" #trailersLink *ngIf="searchResults.trailers?.results?.length">
        <div class="font-semibold text-xs">Trailers</div>
        <a
          [routerLink]="['/drop_trailer/trailer', trailer.id]"
          (click)="ref.close(trailer.id)"
          *ngFor="
            let trailer of searchResults.trailers?.results;
            trackBy: searchResults.trailers?.results | trackByKey: 'id'
          "
          class="flex flex-row cursor-pointer justify-between p-2 hover:dark:bg-zinc-700 hover:bg-gray-200 color-transition px-2 py-1 result"
        >
          <div class="max-w-2/3">
            <div class="font-semibold">{{ trailer.name }}</div>
            <div *ngIf="trailer.externalIdentifier" class="text-gray-600">
              {{ trailer.externalIdentifier }}
            </div>
            <div
              class="bg-blue-500 text-sm text-white font-medium max-w-min px-1 rounded-sm mt-1"
              *ngIf="(firstResult$ | async) === trailer"
            >
              Enter
            </div>
          </div>
        </a>
        <div>
          <button color="accent" mat-button *ngIf="searchResults.trailers?.showMore" (click)="showMoreTrailers()">
            Show More
          </button>
        </div>
      </div>
      <div class="flex flex-col gap-y-1" #driversLink *ngIf="searchResults.drivers?.length">
        <div class="font-semibold text-xs">Drivers</div>
        <a
          [routerLink]="['/', 'dashboard', 'driver', driver.userId]"
          (click)="ref.close(driver.userId)"
          *ngFor="let driver of searchResults.drivers; trackBy: searchResults.drivers | trackByKey: 'userId'"
          class="flex flex-row cursor-pointer justify-between p-2 hover:dark:bg-zinc-700 hover:bg-gray-200 color-transition px-2 py-1"
        >
          <div class="max-w-2/3">
            <div class="font-semibold">{{ driver.userName }}</div>
            <div *ngIf="driver.userPhone">
              <div class="text-xs text-gray-600">Phone</div>
              {{ driver.userPhone | phone }}
            </div>
            <div *ngIf="driver.userEmail">
              <div class="text-xs text-gray-600">Email</div>
              {{ driver.userEmail }}
            </div>
            <div
              class="bg-blue-500 text-sm text-white font-medium max-w-min px-1 rounded-sm"
              *ngIf="(firstResult$ | async) === driver"
            >
              Enter
            </div>
          </div>
          <div class="text-right">
            <div>
              {{ driver.carrierName }}
            </div>
            <div *ngIf="driver.carrierDOTNumber">
              <div class="text-xs text-gray-600">DOT Number</div>
              {{ driver.carrierDOTNumber }}
            </div>
            <div *ngIf="driver.carrierMcNumber">
              <div class="text-xs text-gray-600">MC Number</div>
              {{ driver.carrierMcNumber }}
            </div>
          </div>
        </a>
      </div>
      <div class="flex flex-col gap-y-1" #commandsLink *ngIf="searchResults.commands?.length">
        <div class="font-semibold text-xs">Commands</div>
        <button
          (click)="ref.close(); command.activate()"
          *ngFor="let command of searchResults.commands; trackBy: searchResults.commands | trackByKey: 'displayName'"
          class="flex flex-row justify-between items-center hover:dark:bg-zinc-700 hover:bg-gray-200 color-transition px-2 py-1 result"
        >
          <div>
            {{ command.displayName }}
            <div
              class="bg-blue-500 text-sm text-white font-medium max-w-min px-1 rounded-sm"
              *ngIf="(firstResult$ | async) === command"
            >
              Enter
            </div>
          </div>
          <button mat-stroked-button color="primary" *ngIf="command.shortcut">
            {{ command.shortcut }}
          </button>
        </button>
      </div>
      <div class="flex flex-col gap-y-1" #recentLink *ngIf="searchResults.history?.length">
        <div class="font-semibold text-xs">Recent</div>
        <a
          [routerLink]="history.url"
          (click)="ref.close(history)"
          *ngFor="let history of searchResults.history; trackBy: searchResults.history | trackByKey: 'id'"
          class="flex flex-row gap-2 items-center hover:dark:bg-zinc-700 hover:bg-gray-200 color-transition px-2 py-1 result"
        >
          <ng-container [ngSwitch]="history.type">
            <mat-icon *ngSwitchCase="'load'">local_shipping</mat-icon>
            <mat-icon *ngSwitchCase="'pool'">workspaces</mat-icon>
            <mat-icon *ngSwitchCase="'driver'">person</mat-icon>
          </ng-container>
          <div>
            {{ history.name }}
            <div class="text-gray-600 text-xs">
              {{ history.type === 'load' ? 'Load' : history.type === 'pool' ? 'Dispatch Pool' : 'Driver' }}
            </div>
            <div
              class="bg-blue-500 text-sm text-white font-medium max-w-min px-1 rounded-sm"
              *ngIf="(firstResult$ | async) === history"
            >
              Enter
            </div>
          </div>
        </a>
      </div>
    </ng-container>
  </div>
</div>
