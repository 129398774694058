import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'td-number-badge',
  templateUrl: './number-badge.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberBadgeComponent {
  @Input() public count = 0;
  @Input() public bgClass = 'bg-accent-500';
  @Input() public showIfZero = false;
  @Input() public showMargin = true;

  constructor() {}
}
