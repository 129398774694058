<form class="grid grid-cols-2 gap-x-2 relative" [formGroup]="formGroup" (ngSubmit)="doSave()">
  <mat-form-field class="py-1">
    <mat-label>How frequently do you want to be home?</mat-label>
    <mat-select formControlName="frequencyHomeChoiceID">
      <mat-option
        *ngFor="let choice of homeChoices$ | async; trackBy: homeChoices$ | trackByKey: 'id'"
        [value]="choice.id"
        >{{ choice.label }}</mat-option
      >
    </mat-select>
  </mat-form-field>
  <mat-form-field class="py-1">
    <mat-label>Brokers To Avoid</mat-label>
    <input formControlName="brokersExcluded" matInput />
  </mat-form-field>
  <mat-form-field class="py-1">
    <mat-label>Terminal/Home Location</mat-label>
    <input formControlName="homeAddress" matInput />
    <mat-hint>Please ask the driver to set this in the app</mat-hint>
  </mat-form-field>
  <mat-form-field class="py-1">
    <mat-label>Shippers To Avoid</mat-label>
    <input formControlName="shippersExcluded" matInput />
  </mat-form-field>
  <div class="py-1 flex flex-col">
    <mat-label>Are you a team driver?</mat-label>
    <mat-slide-toggle formControlName="teamDriver"></mat-slide-toggle>
  </div>
  <mat-form-field class="py-1">
    <mat-label>States To Avoid</mat-label>
    <mat-select formControlName="statesExcludedIds" [multiple]="true">
      <mat-option>
        <ngx-mat-select-search
          placeholderLabel="Search"
          noEntriesFoundLabel="No match found"
          [formControl]="stateSearch"
        ></ngx-mat-select-search>
      </mat-option>
      <mat-option
        *ngFor="let state of filteredStates$ | async; trackBy: states$ | trackByKey: 'id'"
        [value]="state.id"
        >{{ state.abbreviation }}</mat-option
      >
    </mat-select>
  </mat-form-field>
  <mat-form-field class="py-1">
    <mat-label>Cities To Avoid</mat-label>
    <mat-select formControlName="citiesExcludedIds" [multiple]="true">
      <mat-option>
        <ngx-mat-select-search
          placeholderLabel="Search"
          noEntriesFoundLabel="No match found"
          [formControl]="citySearch"
        ></ngx-mat-select-search>
      </mat-option>
      <mat-option *ngFor="let city of filteredCities$ | async; trackBy: cities$ | trackByKey: 'id'" [value]="city.id">{{
        city.displayName
      }}</mat-option>
    </mat-select>
  </mat-form-field>
  <div class="flex justify-end w-full col-span-2">
    <button mat-flat-button color="primary" type="submit">Save</button>
  </div>
  <div
    class="bg-white opacity-50 absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center"
    *ngIf="networkActive"
  >
    <mat-spinner></mat-spinner>
  </div>
</form>
