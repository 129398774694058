import { Pipe, PipeTransform } from '@angular/core';
import { LohiLoadStatus } from '../global-types';

const statuses: Record<LohiLoadStatus, string> = {
  completed: 'Completed',
  cancelled: 'Cancelled',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  in_progress: 'In Progress',
  pending: 'Scheduled',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  not_assigned: 'Unassigned',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  at_yard: 'At Yard',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  asset_available: 'Asset Available',
  claimed: 'Claimed',
  unclaimed: 'Unclaimed',
};

@Pipe({
  name: 'lohiLoadStatus',
})
export class LohiLoadStatusPipe implements PipeTransform {
  public transform(value: LohiLoadStatus): string {
    return statuses[value];
  }
}
