import { Pipe, PipeTransform } from '@angular/core';

const values: Record<string, string> = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  not_sent: '-',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  waiting_for_response: 'Waiting For Response',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  on_track: 'On Track',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  delayed: 'Delayed',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  delayed_resolved: 'Resolved',
};

@Pipe({
  name: 'checkCallStatus',
})
export class CheckCallStatusPipe implements PipeTransform {
  public transform(value: string): string {
    return values[value] || value;
  }
}
