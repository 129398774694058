import { Pipe, PipeTransform } from '@angular/core';
import { TimelineCommentUnavailableStatus } from '../global-types';

const commentStatuses: Record<TimelineCommentUnavailableStatus, string> = {
  driver: 'Driver Issue',
  equipment: 'Equipment Issue',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  on_other_load: 'On Other Load',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '10_hour_reset': '10 Hour Reset',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '34_hour_reset': '34 Hour Reset',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  doesnt_want_to_work: "Doesn't Want To Work",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  home_time: 'Home Time',
  unresponsive: 'Unresponsive',
};

@Pipe({
  name: 'commentUnavailableStatusPipe',
})
export class CommentUnavailableStatusPipePipe implements PipeTransform {
  public transform(value: TimelineCommentUnavailableStatus | string): string {
    return commentStatuses[value as TimelineCommentUnavailableStatus] || value;
  }
}
