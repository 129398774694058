import { CurrencyPipe } from '@angular/common';
import { Directive, ElementRef, forwardRef, Input, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';

const onlyNumbers = /\D/g;

@Directive({
  providers: [
    {
      multi: true,
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CurrencyInputDirective),
    },
    CurrencyPipe,
  ],
  selector: '[tdCurrencyInput]',
})
export class CurrencyInputDirective implements ControlValueAccessor {
  @Input() public allowNegatives = false;

  private type: string;
  public onChange: (arg0: any) => void;
  public onTouched: (arg0: any) => void;
  public value: number;

  constructor(private renderer: Renderer2, private elementRef: ElementRef, private currencyPipe: CurrencyPipe) {
    this.type = (elementRef.nativeElement as HTMLInputElement).type;
    if (this.type !== 'text') {
      console.warn('currency pipe without text input');
    }
    fromEvent(this.elementRef.nativeElement, 'input')
      .pipe(
        tap((event: Event) => {
          event.preventDefault();
          event.stopImmediatePropagation();
          event.stopPropagation();
        }),
        map((v) => (v?.target as any)?.value),
        startWith(''),
      )
      .subscribe((value: string) => {
        const isNegative = this.allowNegatives && value.charAt(0) === '-';
        const multiplyBy = isNegative ? -1 : 1;
        const numberString = value.replace(onlyNumbers, '');
        let number = parseInt(numberString, 10);
        number = number === 0 ? 0 : multiplyBy * number || null;

        if (number !== null) {
          this.writeValue(number);
        } else {
          //don't overwrite leading negative signs
          this.renderer.setProperty(this.elementRef.nativeElement, 'value', isNegative ? '-' : null);
          if (this.onChange) {
            this.onChange(null);
          }
        }
      });
  }

  public writeValue(input: number): void {
    if (input === null) {
      this.renderer.setProperty(this.elementRef.nativeElement, 'value', null);
      if (this.onChange) {
        this.onChange(input);
      }
      return;
    }
    const inDollars = input / 100;
    if (this.type === 'text') {
      const formattedField = this.currencyPipe.transform(inDollars);
      this.renderer.setProperty(this.elementRef.nativeElement, 'value', formattedField);
    } else {
      this.renderer.setProperty(this.elementRef.nativeElement, 'value', inDollars);
    }
    if (this.onChange) {
      this.onChange(input);
    }
  }
  public registerOnChange(fn: (arg0: any) => void): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: (arg0: any) => void): void {
    this.onTouched = fn;
  }
  public setDisabledState?(isDisabled: boolean): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
  }
}
