<div mat-dialog-title>
  <div class="flex justify-between">
    <div>Assign Representative</div>
    <mat-spinner class="ml-1" [diameter]="24" *ngIf="networkActive$ | async"></mat-spinner>
  </div>
</div>
<div mat-dialog-content class="flex flex-col gap-y-2 pt-2">
  <mat-form-field class="w-full">
    <mat-label>Select Owner</mat-label>
    <mat-select *ngIf="dispatchers$ | async as dispatchers" [formControl]="dispatcherControl">
      <mat-option>
        <ngx-mat-select-search
          placeholderLabel="Search"
          noEntriesFoundLabel="No match found"
          [formControl]="searchFc"
        ></ngx-mat-select-search>
      </mat-option>
      <mat-option [value]="'unassign'"> Unassign Representative</mat-option>
      <mat-option
        style="height: unset; min-height: 50px; line-height: 24px; font-size: 14px"
        *ngFor="let dispatcher of dispatchers; trackBy: dispatchers | trackByKey: 'userId'"
        [value]="dispatcher.userId"
      >
        <div>
          <div>
            {{ dispatcher.userName }}
          </div>
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <div class="flex flex-row justify-between gap-x-3">
    <div>
      <button mat-stroked-button mat-dialog-close="">Nevermind</button>
    </div>
    <div *ngIf="dispatcherControl.value === 'unassign'; else assign" class="flex flex-row">
      <button
        mat-flat-button
        color="warn"
        (click)="reassignCarrierRepresentative()"
        [disabled]="networkActive$ | async"
      >
        Unassign Representative
      </button>
    </div>
    <ng-template #assign>
      <button
        mat-flat-button
        color="accent"
        (click)="reassignCarrierRepresentative()"
        [disabled]="networkActive$ | async"
      >
        Assign to Representative
      </button>
    </ng-template>
  </div>
</div>
