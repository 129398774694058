<div mat-dialog-title>Editing Stop #{{ _stop.sequence }} of {{ _stop.loadId }}</div>
<div mat-dialog-content>
  <div
    [formGroup]="editStopForm"
    class="grid grid-cols-1 md:grid-cols-2 md:gap-x-2 dark:even:bg-zinc-800 even:bg-gray-200 last:border-0 gap-y-1"
  >
    <mat-form-field>
      <mat-label>Type</mat-label>
      <mat-select formControlName="type">
        <mat-option value="pickup">Pickup</mat-option>
        <mat-option value="dropoff">Dropoff</mat-option>
        <mat-option value="empty_asset_pickup" [disabled]="(canAddEap$ | async) === false"
          >Empty Asset Pickup
        </mat-option>
        <mat-option value="empty_asset_dropoff">Empty Asset Dropoff</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Loading Type</mat-label>
      <mat-select formControlName="loadingType">
        <mat-option value="live">Live</mat-option>
        <mat-option value="drop">Drop</mat-option>
      </mat-select>
    </mat-form-field>
    <div
      class="flex items-center justify-center"
      *ngIf="editStopForm.value.type === 'dropoff' && editStopForm.value.loadingType === 'drop'"
    >
      <mat-slide-toggle formControlName="attachEmptyTrailerPickup"
        >Require Driver to Pickup Empty Trailer
      </mat-slide-toggle>
    </div>
    <div *ngIf="editStopForm.value.attachEmptyTrailerPickup">
      <mat-form-field class="w-full">
        <mat-label>Trailer</mat-label>
        <mat-select formControlName="emptyTrailerPickupID">
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="Search"
              noEntriesFoundLabel="Nothing found"
              [formControl]="trailerSearch"
            ></ngx-mat-select-search>
          </mat-option>
          <ng-container *ngIf="trailers$ | async as trailers">
            <mat-optgroup label="Current Trailer" *ngIf="_stop.emptyTrailerPickupId">
              <mat-option [value]="_stop.emptyTrailerPickupId">
                {{ _stop.emptyTrailerPickupName }}
              </mat-option>
            </mat-optgroup>
            <mat-optgroup
              *ngIf="
                trailers.availableTrailersOnSite | fuse: (trailerSearch.valueChanges | async):['name'] as aTrailers
              "
              label="Available Trailers at first stop"
            >
              <mat-option
                [disabled]="trailer.assignedToOtherLoad || !!trailer.reservingUser?.id"
                [value]="trailer.id"
                *ngFor="let trailer of aTrailers; trackBy: aTrailers | trackByKey: 'id'"
                style="height: unset; min-height: 42px; line-height: 24px; font-size: 14px"
              >
                <div>
                  {{ trailer.name }}
                </div>
              </mat-option>
            </mat-optgroup>
            <mat-optgroup
              [disabled]="true"
              *ngIf="
                trailers.unavailableTrailersOnSite | fuse: (trailerSearch.valueChanges | async):['name'] as bTrailers
              "
              label="Unavailable Trailers at first stop"
            >
              <mat-option
                [disabled]="trailer.assignedToOtherLoad || !!trailer.reservingUser?.id"
                [value]="trailer.id"
                *ngFor="let trailer of bTrailers; trackBy: bTrailers | trackByKey: 'id'"
                style="height: unset; min-height: 42px; line-height: 24px; font-size: 14px"
              >
                <div>
                  {{ trailer.name }}
                </div>
                <div>
                  {{ trailer.reservingUser?.name || 'Assigned to other load' }}
                </div>
              </mat-option>
            </mat-optgroup>
            <mat-optgroup
              *ngIf="trailers.otherTrailers | fuse: (trailerSearch.valueChanges | async):['name'] as cTrailers"
              label="Other Trailers"
            >
              <mat-option
                [disabled]="trailer.assignedToOtherLoad || !!trailer.reservingUser?.id"
                [value]="trailer.id"
                *ngFor="let trailer of cTrailers; trackBy: cTrailers | trackByKey: 'id'"
                style="height: unset; min-height: 42px; line-height: 24px; font-size: 14px"
              >
                <div>
                  {{ trailer.name }}
                </div>
              </mat-option>
            </mat-optgroup>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="my-1 mb-2 col-span-2 flex flex-col">
      <mat-form-field>
        <mat-label>Facility</mat-label>
        <mat-select formControlName="facilityId">
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="Search"
              noEntriesFoundLabel="Facility not found"
              [formControl]="facilitySearch"
            ></ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="let facility of filteredFacilities$ | async; trackBy: filteredFacilities$ | trackByKey: 'id'"
            [value]="facility.id"
            >{{ facility.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <ng-template #loading>
        <div class="flex flex-col mb-2">
          <div class="flex">
            <div class="flex-grow">Loading EAP insights...</div>
            <mat-spinner class="ml-1" [diameter]="24"></mat-spinner>
          </div>
        </div>
      </ng-template>
      <td-eap-summary
        *ngIf="editStopForm.controls.type.value === 'empty_asset_pickup'"
        [canAddEap]="canAddEap$ | async"
        [eapSummary]="eapSummary$ | async"
        [selectedFacilityId]="editStopForm.controls.facilityId.value"
      >
      </td-eap-summary>
      <div
        class="col-span-2 flex flex-col ml-1 text-gray-500 dark:text-gray-300"
        *ngIf="selectedFacility$ | async as facility"
      >
        <div>{{ facility.name }}</div>
        <div *ngIf="facility.addressLine1 as line1">{{ line1 }}</div>
        <div *ngIf="facility.addressLine2 as line2">{{ line2 }}</div>
        <div class="flex">
          <div *ngIf="facility.addressCity as city">{{ city }},&nbsp;</div>
          <div *ngIf="facility.addressState as state">{{ state }},&nbsp;</div>
          <div *ngIf="facility.addressZipCode as zip">{{ zip }}</div>
        </div>
      </div>
    </div>

    <div class="col-span-2 text-gray-500 dark:text-gray-300">OTP Window</div>
    <div class="mb-1">
      <mat-form-field class="w-full">
        <mat-label
          >Earliest Possible Arrival Time <span *ngIf="timezone">({{ timezone }})</span></mat-label
        >
        <input
          matInput
          [ngxMatDatetimePicker]="startExtremaPicker"
          [formControl]="editStopForm.controls.arrivalWindowExtremaStartsAt"
          autocomplete="off"
          tdDatePicker
        />
        <mat-datepicker-toggle matSuffix [for]="$any(startExtremaPicker)"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker
          [enableMeridian]="true"
          [stepMinute]="15"
          #startExtremaPicker
        ></ngx-mat-datetime-picker>
        <mat-hint class="flex flex-row w-full">
          <div
            class="text-blue-500 cursor-pointer ml-auto"
            (click)="editStopForm.controls.arrivalWindowExtremaStartsAt.reset()"
          >
            Clear
          </div>
        </mat-hint>
        <mat-error *ngIf="editStopForm.controls.arrivalWindowExtremaStartsAt.hasError('arrivalWindowExtremaEndsAt')">
          <div class="flex flex-row w-full">
            <div>This must be before the latest possible arrival time</div>
            <div
              class="text-blue-500 cursor-pointer ml-auto mr-1"
              (click)="editStopForm.controls.arrivalWindowExtremaStartsAt.reset()"
            >
              Clear
            </div>
          </div>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="mb-1">
      <mat-form-field class="w-full">
        <mat-label>
          Latest Possible Arrival Time <span *ngIf="timezone">({{ timezone }})</span>
        </mat-label>
        <input
          matInput
          [ngxMatDatetimePicker]="endExtremaPicker"
          [formControl]="editStopForm.controls.arrivalWindowExtremaEndsAt"
          autocomplete="off"
          tdDatePicker
        />
        <mat-datepicker-toggle matSuffix [for]="$any(endExtremaPicker)"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker [enableMeridian]="true" [stepMinute]="15" #endExtremaPicker></ngx-mat-datetime-picker>
        <mat-hint class="flex flex-row w-full">
          <div
            class="text-blue-500 cursor-pointer ml-auto"
            (click)="editStopForm.controls.arrivalWindowExtremaEndsAt.reset()"
          >
            Clear
          </div>
        </mat-hint>
        <mat-error *ngIf="editStopForm.controls.arrivalWindowExtremaEndsAt.hasError('arrivalWindowExtremaStartsAt')">
          <div class="flex flex-row w-full">
            <div>This must be after the earliest possible arrival time (OTP start).</div>
            <div
              class="text-blue-500 cursor-pointer ml-auto mr-1"
              (click)="editStopForm.controls.arrivalWindowExtremaEndsAt.reset()"
            >
              Clear
            </div>
          </div>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-span-2 text-gray-500 dark:text-gray-300">Appointment Window</div>
    <div class="mb-5">
      <mat-form-field class="w-full">
        <mat-label
          >Arrival Window Starts At <span *ngIf="timezone">({{ timezone }})</span></mat-label
        >
        <input
          matInput
          [ngxMatDatetimePicker]="picker"
          [formControl]="editStopForm.controls.arrivalWindowStartsAt"
          autocomplete="off"
          tdDatePicker
        />
        <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker [enableMeridian]="true" [stepMinute]="15" #picker></ngx-mat-datetime-picker>
        <mat-hint class="flex flex-row w-full">
          <div *ngIf="siteTime?.arrivalWindowStartsAt$ | async as inSiteTime">
            <div class="text-green-500 text-base font-semibold my-[4px]">Site time: {{ inSiteTime }}</div>
            <div>*This site time is the time that will be shown to the driver</div>
          </div>
          <div
            class="text-blue-500 cursor-pointer ml-auto"
            (click)="editStopForm.controls.arrivalWindowStartsAt.reset()"
          >
            Clear
          </div>
        </mat-hint>
        <mat-error *ngIf="editStopForm.controls.arrivalWindowStartsAt.hasError('arrivalWindowEndsAt')">
          <div class="flex flex-row w-full">
            <div>This must be before the arrival window end</div>
            <div
              class="text-blue-500 cursor-pointer ml-auto mr-1"
              (click)="editStopForm.controls.arrivalWindowStartsAt.reset()"
            >
              Clear
            </div>
          </div>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="mb-5">
      <mat-form-field class="w-full">
        <mat-label
          >Arrival Window Ends At <span *ngIf="timezone">({{ timezone }})</span></mat-label
        >
        <input
          matInput
          [ngxMatDatetimePicker]="endPicker"
          [formControl]="editStopForm.controls.arrivalWindowEndsAt"
          autocomplete="off"
          tdDatePicker
        />
        <mat-datepicker-toggle matSuffix [for]="$any(endPicker)"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker [enableMeridian]="true" [stepMinute]="15" #endPicker></ngx-mat-datetime-picker>
        <mat-hint class="flex flex-row w-full">
          <div *ngIf="siteTime?.arrivalWindowEndsAt$ | async as inSiteTime">
            <div class="text-green-500 text-base font-semibold my-[4px]">Site time: {{ inSiteTime }}</div>
            <div>*This time will never be shown to the driver</div>
          </div>
          <div class="text-blue-500 cursor-pointer ml-auto" (click)="editStopForm.controls.arrivalWindowEndsAt.reset()">
            Clear
          </div>
        </mat-hint>
        <mat-error *ngIf="editStopForm.controls.arrivalWindowEndsAt.hasError('arrivalWindowStartsAt')">
          <div class="flex flex-row w-full">
            <div>This must be after the arrival window start</div>
            <div
              class="text-blue-500 cursor-pointer ml-auto mr-1"
              (click)="editStopForm.controls.arrivalWindowEndsAt.reset()"
            >
              Clear
            </div>
          </div>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="mb-3">
      <mat-form-field class="w-full">
        <mat-label
          >Original Window Starts At <span *ngIf="timezone">({{ timezone }})</span></mat-label
        >
        <input
          matInput
          [ngxMatDatetimePicker]="originalPicker"
          formControlName="originalArrivalWindowStartsAt"
          autocomplete="off"
          tdDatePicker
        />
        <mat-datepicker-toggle matSuffix [for]="$any(originalPicker)"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker [enableMeridian]="true" [stepMinute]="15" #originalPicker></ngx-mat-datetime-picker>
        <mat-hint>
          *Edit this if the customer has changed when they want the load delivered, it will NOT be shown to the driver
        </mat-hint>
      </mat-form-field>
    </div>
    <div class="mb-2">
      <mat-form-field class="w-full">
        <mat-label
          >Expected At <span *ngIf="timezone">({{ timezone }})</span></mat-label
        >
        <input
          matInput
          [ngxMatDatetimePicker]="expectedPicker"
          formControlName="expectedAt"
          autocomplete="off"
          tdDatePicker
        />
        <mat-datepicker-toggle matSuffix [for]="$any(expectedPicker)"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker [enableMeridian]="true" [stepMinute]="15" #expectedPicker></ngx-mat-datetime-picker>
        <mat-hint class="flex flex-row w-full">
          <div *ngIf="siteTime?.expectedAt$ | async as inSiteTime">
            <div class="text-green-500 text-base font-semibold my-[4px]">Site time: {{ inSiteTime }}</div>
          </div>
          <div class="text-blue-500 cursor-pointer ml-auto" (click)="editStopForm.controls.expectedAt.reset()">
            Clear
          </div>
        </mat-hint>
      </mat-form-field>
    </div>
    <mat-form-field>
      <mat-label>Appointment Tracking Number</mat-label>
      <input matInput formControlName="appointmentTrackingNumber" autocomplete="off" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Reference Number</mat-label>
      <input matInput formControlName="referenceNumber" autocomplete="off" />
    </mat-form-field>
    <ng-container *ngIf="shouldSelectProduct$ | async; else textCommodity">
      <mat-form-field>
        <mat-label>Payload/Commodity</mat-label>
        <mat-select formControlName="productId">
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="Search"
              noEntriesFoundLabel="No match found"
              [formControl]="productSearch"
            ></ngx-mat-select-search>
          </mat-option>
          <mat-option
            [value]="product.id"
            *ngFor="let product of filteredProducts$ | async; trackBy: filteredProducts$ | trackByKey: 'id'"
          >
            {{ product.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <ng-template #textCommodity>
      <mat-form-field>
        <mat-label>Payload/Commodity</mat-label>
        <input formControlName="commodity" matInput autocomplete="off" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Unit</mat-label>
        <mat-select formControlName="unit" [compareWith]="units$ | compareWith: 'id'">
          <mat-option [value]="unit.id" *ngFor="let unit of units$ | async; trackBy: units$ | trackByKey: 'id'">
            {{ unit.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-template>
    <mat-form-field>
      <mat-label>Quantity</mat-label>
      <input matInput tdNumberInput formControlName="quantity" autocomplete="off" required />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Detention Free Time</mat-label>
      <input matInput tdNumberInput required formControlName="detentionFreeMinutes" autocomplete="off" />
      <span matSuffix>minutes</span>
    </mat-form-field>
    <mat-form-field class="lg:col-span-2">
      <mat-label>Details</mat-label>
      <textarea matInput formControlName="details" autocomplete="off"></textarea>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <div>
    <td-double-confirm
      buttonType="flat"
      buttonColor="warn"
      confirmText="Are you sure you want to remove this stop?"
      (confirm)="removeStop()"
    >
      <button color="warn" mat-button>Remove Stop From Load</button>
    </td-double-confirm>
  </div>
  <div>
    <button
      mat-raised-button
      type="button"
      (click)="updateStop()"
      color="primary"
      [disabled]="
        (networkActive$ | async) ||
        (editStopForm.controls.type.value === 'empty_asset_pickup' && (canAddEapAtFacility$ | async) === false)
      "
    >
      Update Stop
    </button>
    <mat-spinner class="ml-1" [diameter]="24" *ngIf="networkActive$ | async"></mat-spinner>
  </div>
</div>
