<div *ngIf="comments$ | async as comments" class="flex flex-col w-full">
  <div *ngIf="comments.driverComments?.length > 0 && !onlyInput">
    <ng-container *ngFor="let comment of comments.driverComments; trackBy: comments.driverComments | trackByKey: 'id'">
      <td-driver-comment [comment]="comment"></td-driver-comment>
    </ng-container>
  </div>
  <form
    [formGroup]="commentForm"
    (ngSubmit)="submitComment()"
    [ngClass]="onlyInput ? ['flex', 'flex-row', 'gap-2', 'items-center'] : []"
  >
    <mat-form-field class="w-full">
      <mat-label>Add a note</mat-label>
      <textarea matInput rows="1" formControlName="comment"></textarea>
    </mat-form-field>
    <div>
      <button type="submit" mat-raised-button color="accent">Add Note</button>
    </div>
  </form>
</div>
