import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, interval, lastValueFrom, Observable, Subject, switchMap } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, throttleTime } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import * as ld from 'launchdarkly-js-client-sdk';
import { AuthService, LoHiUserInfo } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  private overrides$$ = new BehaviorSubject<Record<string, boolean>>({});
  private flags$$ = new BehaviorSubject<Record<string, boolean>>(null);
  private ldFlags$$ = new BehaviorSubject<Record<string, boolean>>(null);
  private flagPoll$$ = new Subject<null>();
  private ldClient: ld.LDClient;

  public flags$ = combineLatest([this.flags$$, this.overrides$$, this.ldFlags$$]).pipe(
    filter(([flags]) => !!flags),
    map(([flags, overrides, ldFlags]) => Object.assign({}, flags, ldFlags, overrides)),
    shareReplay(1),
  );

  constructor(private httpClient: HttpClient, private authService: AuthService) {
    this.loadFlags();
    this.pollFlags();
    this.setupLaunchDarkly();
  }

  public isFlagActive$(flag: string): Observable<boolean> {
    return this.flags$.pipe(
      map((flags) => {
        return processFlag(flags, flag);
      }),
    );
  }

  private pollFlags() {
    this.flagPoll$$.pipe(throttleTime(30 * 1000)).subscribe(() => {
      this.loadFlags();
    });
    interval(5 * 60 * 1000).subscribe(() => {
      this.flagPoll$$.next(null);
    });
  }

  private async loadFlags() {
    try {
      const flags = await lastValueFrom(
        this.httpClient.get<{ name: string; active: boolean }[]>(`${environment.api}/v1/feature_flags`),
      );
      if (flags) {
        const flagMap: Record<string, boolean> = (flags || []).reduce((acc, flag) => {
          acc[flag.name] = flag.active;
          return acc;
        }, {});
        this.flags$$.next(flagMap);
      } else {
        this.flags$$.next({});
      }
    } catch (e) {
      console.warn('Failed to load feature flags', e);
    }
  }

  private setupLaunchDarkly() {
    this.authService.userInfo$
      .pipe(
        filter((user) => !!user),
        distinctUntilChanged((a, b) => a.id === b.id),
        switchMap(async (user: LoHiUserInfo) => {
          if (!user) {
            await this.ldClient.close();
            this.ldFlags$$.next({});
            this.ldClient = null;
          }
          return this.initializeLaunchDarkly(user);
        }),
      )
      .subscribe(() => {});
  }

  private async initializeLaunchDarkly(user: LoHiUserInfo): Promise<void> {
    if (this.ldClient) {
      await this.ldClient.close();
      this.ldFlags$$.next({});
      this.ldClient = null;
    }
    const context: ld.LDContext = {
      kind: 'user',
      key: `user-key-${user.id}`,
      name: user.driverName,
      groups: [user.companyId],
    };
    this.ldClient = ld.initialize(environment.launchDarklyClientSideId, context);
    await this.ldClient.waitUntilReady();
    this.setLDFlags(this.ldClient.allFlags());
    this.ldClient.on('error', (err) => {
      console.error('LaunchDarkly error', err);
    });
    this.ldClient.on('change', (flags) => {
      this.setLDFlags(flags);
    });
  }

  private setLDFlags(flags: Record<string, any>) {
    const flagsRecord: Record<string, boolean> = Object.keys(flags).reduce((acc, key) => {
      const value = flags[key];
      if (typeof value === 'boolean') {
        acc[key] = value;
      }
      return acc;
    }, {});
    this.ldFlags$$.next(flagsRecord);
  }
}

export const processFlag = (flags: Record<string, boolean>, flag: string): boolean => {
  let targetState = true;
  if (flag.startsWith('!')) {
    flag = flag.substr(1);
    targetState = false;
  }
  if (Reflect.has(flags, flag)) {
    return flags[flag] === targetState;
  }
  return false === targetState;
};
