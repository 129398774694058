import { Pipe, PipeTransform } from '@angular/core';
import { CoverageStrategy } from '../global-types';

export const coverageStrategies: Record<CoverageStrategy, string> = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  street_turn: 'Street Turn',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  source_load: 'Source Load',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  transit_load: 'Transit Load',
};

@Pipe({
  name: 'coverageStrategy',
})
export class CoverageStrategyPipe implements PipeTransform {
  public transform(value: CoverageStrategy | CoverageStrategy[]): string | string[] {
    if (Array.isArray(value)) {
      return value.map((v) => coverageStrategies[v]);
    }
    return coverageStrategies[value];
  }
}
