<mat-form-field class="w-full" (click)="$event.stopPropagation()">
  <mat-label>Switch Company</mat-label>
  <mat-select #companySelect (opened)="searchInput.focus()" [formControl]="companyPicker" class="w-full">
    <mat-select-trigger>
      <ng-container *ngIf="(companies$ | async)?.[0] as company">
        {{ company.name }} ({{ company.id }})
        <ng-container *ngIf="company.billingSubsidiary === 'lohi'">[OA]</ng-container>
        <ng-container *ngIf="company.billingSubsidiary === '5F'">[OO]</ng-container>
        <ng-container *ngIf="company.billingSubsidiary === 'none'">[Classic]</ng-container>
      </ng-container>
    </mat-select-trigger>
    <mat-form-field class="w-full p-1">
      <input
        type="text"
        matInput
        [formControl]="companySearch"
        autocomplete="off"
        #searchInput
        (keydown)="onKeyDown($event)"
      />
    </mat-form-field>
    <mat-option
      *ngFor="let company of companies$ | async; let i = index; trackBy: companies$ | trackByKey: 'id'"
      [value]="company.id"
      ><span
        class="font-mono text-white dark:text-slate-900 bg-slate-900 dark:bg-gray-200 rounded-sm mr-1"
        *ngIf="i >= 1 && i <= 9"
        >CTRL+{{ i }}</span
      >
      {{ company.name }} ({{ company.id }})
      <span class="font-bold"
        >[<ng-container *ngIf="company.billingSubsidiary === 'lohi'">OA</ng-container>
        <ng-container *ngIf="company.billingSubsidiary === '5F'">OO</ng-container>
        <ng-container *ngIf="company.billingSubsidiary === 'none'">Classic</ng-container>]</span
      ></mat-option
    >
  </mat-select>
</mat-form-field>
