export const isFileUploadTypeValid = (files: FileList): boolean => {
  if (!files || !files.length) {
    return false;
  }

  for (let i = 0; i < files.length; i++) {
    const f = files.item(i);
    if (!f.type.startsWith('image/') && !acceptableTypes.includes(f.type)) {
      return false;
    }
  }

  return true;
};

const acceptableTypes = [
  'text/csv',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.text',
  'application/pdf',
  'application/vnd.ms-powerpointapplication/vnd.ms-powerpoint',
  'application/rtf',
  'text/plain',
  'text/xml',
  'text/markdown',
  'text/tab-separated-values',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];
