<div mat-dialog-title>Mark Load As Spot Load?</div>
<ng-container *ngIf="ufgLoadStatus$ | async as ufgLoadStatusSummary">
  <div mat-dialog-content>
    <div class="border border-red-400 rounded-l p-3" *ngIf="ufgLoadStatusSummary.warnings?.length">
      <div
        *ngFor="let warning of ufgLoadStatusSummary.warnings; trackBy: ufgLoadStatusSummary.warnings | trackByKey: null"
        class="text-lg"
      >
        {{ warning }}
      </div>
    </div>
    <div *ngIf="!ufgLoadStatusSummary.canBeMarkedUFG" class="text-red-500 text-xl mt-3">
      This load cannot be marked as a spot load.
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-button mat-dialog-close="">Nevermind</button>
    <button
      mat-flat-button
      color="primary"
      [disabled]="!ufgLoadStatusSummary.canBeMarkedUFG"
      (click)="markLoadAsSpotLoad()"
    >
      Mark as Spot Load
    </button>
  </div>
</ng-container>
