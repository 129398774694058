import { Pipe, PipeTransform } from '@angular/core';
import { KPIDuration as KPIDurationEnum } from '../../modules/carrier/carrier-kpis/carrier-kpis-v2.service';
import { KPIDuration as KPIDurationUnion } from '../global-types';

/* eslint-disable @typescript-eslint/naming-convention -- silly setting */
const unionMap: Record<KPIDurationUnion, string> = {
  '1_weeks': 'Week',
  '2_weeks': '2 Weeks',
  '1_months': 'Month',
  '1_quarters': 'Quarter',
  last_7_days: '7 Days',
  last_14_days: '14 Days',
  last_30_days: '30 Days',
};

const enumMap: Record<KPIDurationEnum, string> = {
  7: '7d',
  14: '14d',
  30: '4 Weeks',
  90: '90d',
};

const enumToUnionMap: Record<KPIDurationEnum, KPIDurationUnion> = {
  7: 'last_7_days',
  14: 'last_14_days',
  30: 'last_30_days',
  90: '1_quarters',
};
/* eslint-enable @typescript-eslint/naming-convention */

@Pipe({
  name: 'kpiDisplayDuration',
})
export class KpiDisplayDurationPipe implements PipeTransform {
  public transform(value: KPIDurationUnion | KPIDurationEnum): string {
    return typeof value === 'string' ? unionToReadable(value) : enumToReadable(value);
  }
}

export const unionToReadable = (duration: KPIDurationUnion): string => {
  return unionMap[duration] || duration;
};

export const enumToReadable = (duration: KPIDurationEnum): string => {
  return enumMap[duration] || 'Unknown Duration';
};
