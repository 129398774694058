import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LoggerModule, NGXLogger, NgxLoggerLevel } from 'ngx-logger';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AuthInterceptorService } from './shared/services/auth-interceptor.service';
import { LogRocketErrorHandler } from './shared/utilities/error-handler';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { StreamAutocompleteTextareaModule, StreamChatModule } from 'stream-chat-angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';

const firebaseConfig = {
  projectId: 'lohi-f5606',
  appId: '1:471883051371:web:fe51d013d0c96804f69c2e',
  databaseURL: 'https://lohi-f5606.firebaseio.com',
  storageBucket: 'lohi-f5606.appspot.com',
  locationId: 'us-central',
  apiKey: 'AIzaSyC-c8kIxYnYOWHEnTBQiblF1bzFvyBAQZw',
  authDomain: 'lohi-f5606.firebaseapp.com',
  messagingSenderId: '471883051371',
  measurementId: 'G-E35GJMJZN0',
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AppRoutingModule,
    HttpClientModule,
    StreamAutocompleteTextareaModule,
    StreamChatModule,
    TranslateModule.forRoot(),
    LoggerModule.forRoot({
      level: environment.production ? NgxLoggerLevel.ERROR : NgxLoggerLevel.DEBUG,
      colorScheme: ['blue', 'teal', 'gray', 'gray', 'red', 'red', 'red'],
      enableSourceMaps: !environment.production,
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !window.location.href.includes('localhost'),
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    { provide: ErrorHandler, useClass: LogRocketErrorHandler, deps: [NGXLogger] },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
