<div
  class="p-2 text-lg align-middle"
  [ngClass]="{
    'bg-[#F38876]': rating === 'poor',
    'text-[#952A18]': rating === 'poor',
    'bg-[#D0E1FF]': rating === 'good',
    'text-[#556683]': rating === 'good',
    'bg-[#A1D8C4]': rating === 'great',
    'text-[#3B715E]': rating === 'great',
    'text-gray-600': rating === 'not_ranked'
  }"
>
  <ng-container [ngSwitch]="rating">
    <ng-container *ngSwitchCase="'poor'"> 🙁 </ng-container>
    <ng-container *ngSwitchCase="'good'"> 🙂 </ng-container>
    <ng-container *ngSwitchCase="'great'"> 🥳 </ng-container>
    <ng-container *ngSwitchDefault> - </ng-container>
  </ng-container>
  <ng-content></ng-content>
</div>
