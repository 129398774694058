import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { catchError, lastValueFrom, Observable, of } from 'rxjs';
import { SimpleFinn } from '../utilities/simpleFinn';

@Injectable({
  providedIn: 'root',
})
export class InternalOrExternalDispatcherService {
  private isInternalOrExternalDispatcherShared: SimpleFinn<boolean>;

  public get isInternalOrExternalDispatcherShared$(): Observable<boolean> {
    return this.isInternalOrExternalDispatcherShared.get$();
  }

  constructor(private httpClient: HttpClient) {
    this.isInternalOrExternalDispatcherShared = new SimpleFinn<boolean>(false, this.loadIsInternalOrExternalDispatcher);
  }

  // this should not be used in components, only for guards and services
  // use isInternalShared instead
  public get isInternalOrExternalDispatcher$(): Observable<boolean> {
    return this.getIsInternalOrExternalDispatcher$();
  }

  private loadIsInternalOrExternalDispatcher = async (): Promise<boolean> =>
    await lastValueFrom(this.getIsInternalOrExternalDispatcher$());

  private getIsInternalOrExternalDispatcher$ = (): Observable<boolean> =>
    this.httpClient
      .get<{ internalOrExternalDispatcher: boolean }>(
        `${environment.api}/v1/internal_or_external_dispatcher/is_internal_or_external_dispatcher`,
      )
      .pipe(
        map((response) => {
          return response.internalOrExternalDispatcher;
        }),
        catchError(() => {
          // dont need to log the error here, interceptor should handle it
          return of(false);
        }),
      );
}
