import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  public transform<T>(items: T[], filter: any, filterOnProperty?: keyof T): T[] {
    if (!items) {
      return items;
    }

    if (!filterOnProperty) {
      return items.filter((item) => item === filter);
    }

    return items.filter((item) => item[filterOnProperty] === filter);
  }
}
