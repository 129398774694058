import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SimpleFinn } from '../../../shared/utilities/simpleFinn';
import { BehaviorSubject, combineLatest, lastValueFrom, Observable, of, switchMap, tap } from 'rxjs';
import { RouterStateService } from '../../../shared/services/router-state.service';
import { DRIVER_ID, DRIVER_LOAD_ID_2 } from './dashboard-consts';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import {
  BasicUser,
  DriverLocationResponse,
  DriverScheduleTimelineComment,
  DriverTractorTrailerPhotoUploads,
  DriverTractorTrailerPreferences,
  FutureTour,
  LohiLoadStatus,
  LongLat,
  QuickScoreCard,
  ReloadLoadOrLohiLoad,
  TagColor,
  TourNextData,
  TrailerType,
} from '../../../shared/global-types';
import { LoadBillingService } from '../../../shared/services/load-billing.service';
import { InternalService } from '../../../shared/services/internal.service';
import { DriverHitchTierDetail } from './interfaces';

export interface Driver {
  userId: string;
  userName: string;
  userPhone: string;
  userEmail: string;
  carrierId: string;
  carrierName: string;
  carrierMcNumber?: string;
  carrierDOTNumber?: string;
  carrierAuthority?: string;
  tourId?: number;
  hasLoadOffer?: boolean;
  respondedToLoadOffer: boolean;
  needsFirstLoad: boolean;
  respondedToLoadOfferAt: string;
  tourStartsAt: string;
  dispatcherNames: string[];
  isOnTrack: boolean;
  offTrackReason: string;
  sitRepUpdatedAt: string;
}

export interface DriverComment {
  id: number;
  driverId: string;
  commenterUserId: string;
  comment: string;
  createdAt: string;
  commenterName: string;
  commenterRoles: string[];
  isMyComment: boolean;
}

export interface DriverComments {
  driverComments: DriverComment[];
}

export interface DriverDetail extends Driver {
  hosIsFromELD: boolean;
  assignedToMe: boolean;
  onTour: boolean;
  staticTemplate?: number;
  onLoad: boolean;
  currentLaneSequence?: number;
  equipmentType: TrailerType;
  equipmentLength: number;
  equipmentWeight: number;
  equipmentOther: string;
  lastWaypoint?: LongLat;
  lastWaypointCityState?: string;
  lastWaypointUpdateTime?: string;
  preferences: {
    shipperExclude?: string[];
    cityExclude?: string[];
    commodityExclude?: string[];
    stateExclude?: string[];
    minCentsPerMile?: number;
    otherPreferences?: string;
    followTheMoney: boolean;
    costPerMileCents?: number;
    teamDriver?: boolean;
    hasHazmatEndorsement?: boolean;
    hasTankerEndorsement?: boolean;
  };
  carrierDetails?: {
    daysOfAuthority?: number;
  };
  tourStartData?: {
    startTime: string;
    startLngLat: LongLat;
    startCityState: string;
  };
  tourEndData?: {
    endTime: string;
    endLngLat: LongLat;
    endCityState: string;
  };
  tourNextEventData?: TourNextData;
  hosMinutesRemaining: number | null;
  companyAdmins: BasicUser[];
  hosCreatedAt?: string;
  hosWeeklyMinutesRemaining: number | null;
  assignableUsers: BasicUser[];
  assignableSalesUsers: BasicUser[];
  markedAsChurnedSince: string;
  isOnTrack: boolean;
  sitRepResolvedBy: string;
  sitRepResolvedByName: string;
  expectedDeliverTime: string;
  driverTractorTrailerPreferences: DriverTractorTrailerPreferences;
  driverTractorTrailerPhotoUploads: DriverTractorTrailerPhotoUploads;
  ineligibleForPreBooking: boolean;
  assignedDriverManager: BasicUser;
  assignedLoadBooker: BasicUser;
  tagColor: TagColor;
  tagName: string | null;
  unavailableComment: DriverScheduleTimelineComment | null;
  loadOnTimePercentage: number | null;
  futureTour: FutureTour | null;
  cdl: string;
}

export interface DriverTimeline {
  timelineEvents: DriverTimelineEvent[];
}

export interface DriverTimelineEvent {
  time: string;
  msg: string;
  loadId?: string;
  subEvents?: DriverTimelineEvent[];
  commentId?: number;
  commentStart?: Date;
  commentEnd?: Date;
  commentBy?: string;
  pta?: Date;
}

export interface DriverLoadListItem {
  id: string;
  referenceNumber?: string;
  status: LohiLoadStatus;
  completedAt?: string;
  rateCents?: number;
  brokerName?: string;
  stopAddresses: string[];
  stopCityStates: string[];
  companyName: string;
  firstPickupAt: string | null;
  dropoffAt?: string | null;
  linehaulMiles?: number;
  stopPoints: StopLngLats;
  cancelledLoadHistoryId: number;
  cancelledLoadDriverNotes: string;
  paidAt?: string;
  dispatchScores: QuickScoreCard | null;
}

export interface StopLngLats {
  stopLnglats: XYPoint[];
}

/* eslint-disable @typescript-eslint/naming-convention */
export interface LockedLane {
  DestinationCity: string;
  DestinationState: string;
  EstLowerBoundCents: number;
  EstMiles: number;
  EstUpperBoundCents: number;
  LoadID: null | string;
  LoadRateCents: null | number;
  LoadStatus: null | string;
  OriginCity: string;
  OriginState: string;
  PlannedLaneID: number;
  Sequence: number;
  OriginLngLat: XYPoint;
  DestinationLnglat: XYPoint;
}
/* eslint-enable @typescript-eslint/naming-convention */

export interface TourDetails {
  startTime: string;
  endTime: string;
  cents: number;
  originLocation: { longitude: number; latitude: number };
  destinationLocation: { longitude: number; latitude: number };
}

export interface XYPoint {
  x: number;
  y: number;
}

export interface DriverStaticTourLane {
  sequence: number;
  originLngLat: XYPoint;
  destinationLngLat: XYPoint;
  originName: string;
  destinationName: string;
  isCurrentLane: boolean;
  loadId: string;
}

export interface HitchTierDetail {
  currentAscensionTier: string;
  currentAscensionTierDisplay: string;
  ascensionProgramWorkingDays: number;
  ascensionLevels: HitchAscensionLevel[];
  recentEvents: HitchRecentEvent[];
}

export interface HitchDetail {
  hasCurrentHitch: number;
}

export interface HitchAscensionLevel {
  rankName: string;
  rankDisplayName: string;
  workingDaysRequired: number;
  remainingDaysRequired: number | null;
  earnedAt: Date | null;
}

export interface HitchRecentEvent {
  eventTime: Date;
  eventTitle: string;
  eventDescription: string;
  loadId: null;
}

export interface DriverOTPLoadListItem {
  loadId: string;
  completedAt: string;
  wasLate: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private allDrivers: SimpleFinn<Driver[]>;

  public get allDrivers$(): Observable<Driver[]> {
    return this.allDrivers.get$();
  }

  private driverLocations: SimpleFinn<DriverLocationResponse>;

  public get driverLocations$(): Observable<DriverLocationResponse> {
    return this.driverLocations.get$();
  }

  private driverStaticTourLanes$$ = new BehaviorSubject<DriverStaticTourLane[]>(null);
  public driverStaticTourLanes$: Observable<DriverStaticTourLane[]> = this.driverStaticTourLanes$$.pipe(shareReplay(1));

  private driverProfile$$ = new BehaviorSubject<DriverDetail>(null);
  public driverProfile$: Observable<DriverDetail> = this.driverProfile$$.pipe(shareReplay(1));

  private driverTimeline$$ = new BehaviorSubject<DriverTimelineEvent[]>([]);
  public driverTimeline$: Observable<DriverTimelineEvent[]> = this.driverTimeline$$.pipe(shareReplay(1));

  private driverLoads$$ = new BehaviorSubject<DriverLoadListItem[]>([]);
  public driverLoads$: Observable<DriverLoadListItem[]> = this.driverLoads$$.pipe(shareReplay(1));

  private driverLoad$$ = new BehaviorSubject<ReloadLoadOrLohiLoad>(null);
  public driverLoad$: Observable<ReloadLoadOrLohiLoad> = this.driverLoad$$.pipe(shareReplay(1));

  private driverComments$$ = new BehaviorSubject<DriverComments>(null);
  public driverComments$: Observable<DriverComments> = this.driverComments$$.pipe(shareReplay(1));

  private driverHitchTierDetails$$ = new BehaviorSubject<DriverHitchTierDetail>(null);
  public driverHitchTierDetail$ = this.driverHitchTierDetails$$.asObservable();

  private driverTierLoads$$ = new BehaviorSubject<DriverOTPLoadListItem[]>([]);
  public driverTierLoads$: Observable<DriverOTPLoadListItem[]> = this.driverTierLoads$$.pipe(shareReplay(1));

  constructor(
    private http: HttpClient,
    private routerState: RouterStateService,
    private loadBilling: LoadBillingService,
    private internalService: InternalService,
  ) {
    this.allDrivers = new SimpleFinn<Driver[]>([], this.loadAllDrivers);
    this.driverLocations = new SimpleFinn<DriverLocationResponse>(null, this.loadDriverLocations);

    this.listenForDriver();
    this.listenForLoad();
  }

  private loadDriverLocations = async (): Promise<DriverLocationResponse> => {
    try {
      return await lastValueFrom(
        this.http.get<DriverLocationResponse>(`${environment.api}/v2/max_payloop/dispatcher/driver_locations`),
      );
    } catch (e) {
      return null;
    }
  };

  private loadAllDrivers = (): Promise<Driver[]> => {
    return lastValueFrom(
      this.http
        .get<{ drivers: Driver[] }>(`${environment.api}/v2/max_payloop/dispatcher/all_drivers`)
        .pipe(map((v) => v.drivers || [])),
    );
  };

  private listenForDriver() {
    this.routerState
      .listenForParamChange$(DRIVER_ID)
      .pipe(
        tap((value) => {
          if (!value) {
            this.driverProfile$$.next(null);
            this.driverTimeline$$.next([]);
            this.driverLoads$$.next([]);
            this.driverComments$$.next(null);
          } else if (value !== this.driverProfile$$.value?.userId) {
            this.driverProfile$$.next(null);
            this.driverTimeline$$.next([]);
            this.driverLoads$$.next([]);
            this.driverComments$$.next(null);
          }
        }),
        switchMap((driverID) => {
          if (!driverID) {
            return of(null as DriverDetail);
          }
          return this.loadDriverDetail(driverID);
        }),
      )
      .subscribe((value) => {
        if (!value) {
          this.driverProfile$$.next(null);
          return;
        }
        this.driverProfile$$.next(value);
        this.refreshTimeline(value.userId);
        this.refreshLoads(value.userId);
        this.refreshComments(value.userId);
        if (value.staticTemplate) {
          this.refreshStaticTour(value.userId);
        }
        this.refreshHitchDetails(value.userId);
        this.refreshTierLoads(value.userId);
      });
  }

  private loadDriverDetail(driverId: string) {
    return this.http.get<DriverDetail>(`${environment.api}/v2/max_payloop/dispatcher/my_drivers`, {
      params: {
        driverId,
      },
    });
  }

  private loadDriverComments(driverId: string) {
    return this.http.get<DriverComments>(`${environment.api}/v2/max_payloop/dispatcher/my_drivers/comments`, {
      params: {
        driverId,
      },
    });
  }

  private loadDriverTimeline$(driverId: string): Observable<DriverTimeline> {
    return this.http.get<DriverTimeline>(`${environment.api}/v2/max_payloop/dispatcher/my_drivers/timeline`, {
      params: {
        driverId,
      },
    });
  }

  private loadHitchDetails$(driverId: string): Observable<DriverHitchTierDetail> {
    return this.internalService.isInternal$.pipe(
      switchMap((isInternal) => {
        if (isInternal) {
          return this.http.get<DriverHitchTierDetail>(
            `${environment.api}/v2/vpf/internal/tier_management/${driverId}/hitch_tier_detail`,
          );
        }
        return of(null);
      }),
    );
  }

  private async refreshTimeline(userId: string) {
    const timeline = await lastValueFrom(this.loadDriverTimeline$(userId));
    this.driverTimeline$$.next(timeline.timelineEvents || []);
  }

  private async refreshHitchDetails(userId: string) {
    const tierResponse = await lastValueFrom(this.loadHitchDetails$(userId));
    this.driverHitchTierDetails$$.next(tierResponse);
  }

  private loadDriverLoads$(driverId: string): Observable<DriverLoadListItem[]> {
    return this.http
      .get<{ loads: DriverLoadListItem[] }>(`${environment.api}/v2/max_payloop/dispatcher/my_drivers/loads`, {
        params: {
          driverId,
        },
      })
      .pipe(map((v) => v?.loads || []));
  }

  private async refreshLoads(userId: string) {
    const loads = await lastValueFrom(this.loadDriverLoads$(userId));
    this.driverLoads$$.next(loads || []);
  }

  private async refreshComments(userId: string) {
    const comments = await lastValueFrom(this.loadDriverComments(userId));
    this.driverComments$$.next(comments || null);
  }

  private async refreshStaticTour(driverId: string) {
    const staticTour = await lastValueFrom(this.loadStaticTour(driverId));
    this.driverStaticTourLanes$$.next(staticTour?.lanes || []);
  }

  private loadStaticTour(driverId: string) {
    return this.http.get<{ lanes: DriverStaticTourLane[] }>(
      `${environment.api}/v2/max_payloop/dispatcher/my_drivers/static_tours/driver_lanes`,
      {
        params: {
          driverId,
        },
      },
    );
  }

  public async refreshDriver(userId: string) {
    const detail = await lastValueFrom(this.loadDriverDetail(userId));
    this.driverProfile$$.next(detail);
    this.refreshTimeline(userId);
    this.refreshLoads(userId);
    this.refreshComments(userId);
    this.refreshHitchDetails(userId);
    this.refreshTierLoads(userId);
  }

  private loadDriverLoad$(loadId: string): Observable<ReloadLoadOrLohiLoad> {
    return this.http.get<ReloadLoadOrLohiLoad>(`${environment.api}/v2/max_payloop/dispatcher/my_drivers/load`, {
      params: {
        loadId,
      },
    });
  }

  private listenForLoad() {
    this.routerState
      .listenForParamChange$(DRIVER_LOAD_ID_2)
      .pipe(
        tap((loadId) => {
          if (!loadId) {
            this.driverLoad$$.next(null);
          } else if (loadId !== this.driverLoad$$.value?.reload?.id && loadId !== this.driverLoad$$.value?.lohi?.id) {
            this.driverLoad$$.next(null);
          }
        }),
        switchMap((loadId) => {
          if (!loadId) {
            return of(null as ReloadLoadOrLohiLoad);
          }
          return this.loadDriverLoad$(loadId);
        }),
      )
      .subscribe((value) => {
        if (!value) {
          this.driverLoad$$.next(null);
          return;
        }
        this.driverLoad$$.next(value);
        if (value.lohi) {
          this.loadBilling.refreshLoad(value.lohi.id);
        }
      });
  }

  public async refreshLoad() {
    if (!this.driverLoad$$.value?.lohi?.id && !this.driverLoad$$.value?.reload?.id) {
      return;
    }
    try {
      const load = await lastValueFrom(
        this.loadDriverLoad$(this.driverLoad$$.value?.lohi?.id || this.driverLoad$$.value?.reload?.id),
      );
      this.driverLoad$$.next(load);
      if (load.lohi) {
        this.loadBilling.refreshLoad(load.lohi.id);
      }
    } catch (e) {}
  }

  public async resolveSitRep(driverId: string, comment: any): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.post(`${environment.api}/v2/max_payloop/dispatcher/situation_report/resolve_off_track`, {
          driverId,
          comment,
        }),
      );
      await this.refreshDriver(driverId);
      return true;
    } catch (e) {
      return false;
    }
  }

  public async addDriverComment(comment: any, driverId: string): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.post(`${environment.api}/v2/max_payloop/dispatcher/my_drivers/create_comment`, {
          driverId,
          comment,
        }),
      );
      await this.refreshComments(driverId);
      return true;
    } catch (e) {
      return false;
    }
  }

  public async deleteComment(comment: DriverComment): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.delete(`${environment.api}/v2/max_payloop/dispatcher/my_drivers/remove_comment`, {
          body: {
            id: comment.id,
            driverId: comment.driverId,
            commenterId: comment.commenterUserId,
          },
        }),
      );
      await this.refreshComments(comment.driverId);
      return true;
    } catch (e) {
      return false;
    }
  }

  public async resolveDeclinedLoad(loadId: string, comments: any): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.put(`${environment.api}/v2/max_payloop/dispatcher/load_cancellation/load/${loadId}/resolve`, {
          comments,
        }),
      );
      await this.refreshLoad();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async setDriverHOS(
    driverId: string,
    dailyHOSMinutesRemaining: number,
    weeklyHOSMinutesRemaining: number,
  ): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.post(`${environment.api}/v2/max_payloop/hos`, {
          driverId,
          dailyHOSMinutesRemaining,
          weeklyHOSMinutesRemaining,
        }),
      );
      await this.refreshDriver(driverId);
      return true;
    } catch (e) {
      return false;
    }
  }

  private loadDriverTierLoads$(driverId: string): Observable<DriverOTPLoadListItem[]> {
    return this.http
      .get<{ loads: DriverOTPLoadListItem[] }>(
        `${environment.api}/v2/vpf/internal/tier_management/load_compliance/on_time_load_list`,
        {
          params: {
            driverId,
          },
        },
      )
      .pipe(map((v) => v?.loads || []));
  }

  private async refreshTierLoads(userId: string) {
    const loads = await lastValueFrom(this.loadDriverTierLoads$(userId));
    this.driverTierLoads$$.next(loads || []);
  }
}
