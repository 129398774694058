import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { DriverGeneralPreferences } from 'src/app/shared/global-types';
import { City, ConstantsService, PreferenceListItem, State } from 'src/app/shared/services/constants.service';
import { FusedObservable } from '../../../utilities/fusedObservable';

type FormGroupValue = Omit<Record<keyof DriverGeneralPreferences, any>, 'shippersExcluded' | 'brokersExcluded'> & {
  shippersExcluded: string;
  brokersExcluded: string;
};

@Component({
  selector: 'td-driver-profile-edit-general',
  templateUrl: './driver-profile-edit-general.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverProfileEditGeneralComponent {
  public homeChoices$: Observable<PreferenceListItem[]>;
  public stateSearch = new UntypedFormControl(null);
  public states$: Observable<State[]>;
  public filteredStates$: Observable<State[]>;
  public citySearch = new UntypedFormControl(null);
  public cities$: Observable<City[]>;
  public filteredCities$: Observable<City[]>;
  private isSetup = false;

  @Input() public networkActive = false;

  @Input()
  public set prefs(prefs: DriverGeneralPreferences) {
    if (!this.isSetup) {
      this.setupForm(prefs);
      this.isSetup = true;
    }
  }

  @Output() public save = new EventEmitter<DriverGeneralPreferences>();
  public formGroup: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, private constantsService: ConstantsService) {
    this.homeChoices$ = this.constantsService.driverReturnHomeChoices$;
    this.cities$ = this.constantsService.allCities$;
    this.filteredCities$ = new FusedObservable(this.cities$, this.citySearch.valueChanges, {
      distance: 1000,
      keys: ['name', 'displayName'],
      location: 0,
      minMatchCharLength: 1,
      threshold: 0.2,
    }).fused$;
    this.states$ = this.constantsService.allStates$;
    this.filteredStates$ = new FusedObservable(this.states$, this.stateSearch.valueChanges, {
      distance: 1000,
      keys: ['name', 'abbreviation'],
      location: 0,
      minMatchCharLength: 1,
      threshold: 0.2,
    }).fused$;
    this.formGroup = this.fb.group({
      frequencyHomeChoiceID: [null],
      homeAddress: [null],
      shippersExcluded: [null],
      brokersExcluded: [null],
      statesExcludedIds: [null],
      citiesExcludedIds: [null],
      teamDriver: [null],
      costPerMileCents: [null],
      homeLocation: [null],
      doNotUpdateStates: [false],
    });
    this.formGroup.get('homeAddress').disable();
  }

  private setupForm(prefs: DriverGeneralPreferences) {
    this.formGroup.setValue({
      ...prefs,
      doNotUpdateStates: false,
      brokersExcluded: (prefs.brokersExcluded || []).join(', '),
      shippersExcluded: (prefs.shippersExcluded || []).join(', '),
    });
  }

  public doSave() {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }
    const value: FormGroupValue = this.formGroup.getRawValue();
    this.save.next({
      ...value,
      brokersExcluded: (value.brokersExcluded || '').split(',').map((v) => v.trim()),
      shippersExcluded: (value.shippersExcluded || '').split(',').map((v) => v.trim()),
    });
  }
}
