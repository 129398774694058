import { Pipe, PipeTransform } from '@angular/core';
import { TierType } from '../../modules/internal/dashboard/interfaces';

const tierTypeToIconSrc = {
  platinum: 'assets/icons/tiers/platinum.svg',
  black: 'assets/icons/tiers/black.svg',
  gold: 'assets/icons/tiers/gold.svg',
  silver: 'assets/icons/tiers/silver.svg',
  probation: 'assets/icons/tiers/v2_probation.svg',
};

@Pipe({
  name: 'tierTypeIconSource',
})
export class TierTypeIconSourcePipe implements PipeTransform {
  public transform(value: TierType | string): string {
    if (!value) {
      return '';
    }

    return tierTypeToIconSrc[(value as string)?.toLowerCase()];
  }
}
