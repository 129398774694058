import { NotReadyReason } from './global-types';

export const shareReplayComponentConfig = {
  bufferSize: 1,
  refCount: true,
};

export const listOfFacilityRegions = ['No Region Assigned', 'East', 'Delta', 'West'];

export const acceptableFileTypes = [
  'text/csv',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.text',
  'application/pdf',
  'application/vnd.ms-powerpointapplication/vnd.ms-powerpoint',
  'application/rtf',
  'text/plain',
  'text/xml',
  'text/markdown',
  'text/tab-separated-values',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];
// TODO move these reasons to the server. If you are changing any of these, please make sure to update the server as well at typesv2.loads.go
export const notReadyReasons: { name: string; reasons: NotReadyReason[] }[] = [
  {
    name: 'Driver',
    reasons: [
      {
        name: `Mechanical Issue`,
        markNotReady: false,
        willRemoveDAB: true,
      },
      {
        name: `Routing Restriction`,
        markNotReady: false,
        willRemoveDAB: true,
      },
      {
        name: `Non-Responsive or Unwilling`,
        markNotReady: false,
        willRemoveDAB: true,
      },
      {
        name: `Delays on Prior Shipment`,
        markNotReady: false,
      },
      {
        name: `Reassigned to Priority Shipment`,
        markNotReady: false,
      },
      {
        name: `Safety Issue`,
        markNotReady: false,
        willRemoveDAB: true,
      },
      {
        name: `Performance Issue`,
        markNotReady: false,
        willRemoveDAB: true,
      },
    ],
  },
  {
    name: 'Facility',
    reasons: [
      {
        name: `Trailer Not Yet Loaded`,
        markNotReady: true,
        requiresTrailer: true,
      },
      {
        name: `Overpooled`,
        markNotReady: true,
      },
      {
        name: `Unable to Access Trailer`,
        markNotReady: true,
      },
    ],
  },
  {
    name: 'Customer',
    reasons: [
      {
        name: `Canceled`,
        markNotReady: true,
      },
    ],
  },
  {
    name: 'Trailer',
    reasons: [
      {
        name: `Trailer Inoperable`,
        markNotReady: true,
        requiresTrailer: true,
      },
    ],
  },
  {
    name: 'LC Override',
    reasons: [
      {
        name: `LC Override`,
        markNotReady: true,
        expires: true,
        requiresNote: true,
        preventAutoOverride: true,
      },
    ],
  },
];
