import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DriverOtherPreferences } from 'src/app/shared/global-types';

@Component({
  selector: 'td-driver-profile-edit-other-preferences',
  templateUrl: './driver-profile-edit-other-preferences.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverProfileEditOtherPreferencesComponent {
  private isSetup = false;
  @Input() public networkActive = false;

  @Input()
  public set prefs(prefs: DriverOtherPreferences) {
    if (!this.isSetup) {
      this.setupForm(prefs);
      this.isSetup = true;
    }
  }

  @Output() public save = new EventEmitter<DriverOtherPreferences>();
  public formGroup: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {
    this.formGroup = this.fb.group({
      additionalPreference: [null],
    });
  }

  private setupForm(prefs: DriverOtherPreferences) {
    this.formGroup.setValue(prefs);
  }

  public doSave() {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }
    const value: DriverOtherPreferences = this.formGroup.getRawValue();
    this.save.next(value);
  }
}
