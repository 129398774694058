import { Injectable } from '@angular/core';
import { BehaviorSubject, scan, shareReplay, Subject } from 'rxjs';

export interface HistoryEntry {
  id: string | number;
  name: string;
  url: string[];
  type: 'driver' | 'pool' | 'load';
}

@Injectable({
  providedIn: 'root',
})
export class HistoryService {
  private history$$ = new BehaviorSubject<HistoryEntry[]>([]);
  public history$ = this.history$$.pipe(shareReplay(1));

  constructor() {}

  public addHistory(entry: HistoryEntry) {
    const history = this.history$$.value.filter((v) => v.id !== entry.id);
    history.unshift(entry);
    history.slice(5);
    this.history$$.next(history);
  }
}
