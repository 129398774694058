import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { LoadBillingService } from '../../../services/load-billing.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProductLoadUpload } from '../../../global-types';
import { NetworkableDestroyableComponent } from '../../networkable-destroyable.component';
import { SnackBarService } from '../../../services/snackbar.service';

@Component({
  selector: 'td-load-billing-prompt-delete-upload',
  templateUrl: './load-billing-prompt-delete-upload.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadBillingPromptDeleteUploadComponent extends NetworkableDestroyableComponent {
  private readonly loadId: string;
  public upload: ProductLoadUpload;

  constructor(
    private loadBillingService: LoadBillingService,
    private matDialogRef: MatDialogRef<LoadBillingPromptDeleteUploadComponent>,
    private snackbar: SnackBarService,
    @Inject(MAT_DIALOG_DATA) data: { loadId: string; upload: ProductLoadUpload },
  ) {
    super();
    this.loadId = data?.loadId;
    this.upload = data?.upload;
  }

  public async removeUpload() {
    this.networkActive$$.next(true);
    const success = await this.loadBillingService.removeUploadFromLoad(this.loadId, +this.upload.id);
    this.networkActive$$.next(false);
    if (success) {
      this.snackbar.showMessage('Upload removed from load');
      this.matDialogRef?.close();
    }
  }
}
