import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { QuickScoreCardRating } from '../../global-types';

@Component({
  selector: 'td-rating-emoji',
  templateUrl: './rating-emoji.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RatingEmojiComponent {
  @Input() public rating: QuickScoreCardRating;

  constructor() {}
}
