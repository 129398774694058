import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

const suffix = `5F OTR`;
@Injectable({
  providedIn: 'root',
})
export class TitleService {
  constructor(private title: Title) {}

  public setTitle(title: string) {
    if (title && title !== '') {
      this.title.setTitle(`${title} - ${suffix}`);
    } else {
      this.title.setTitle(suffix);
    }
  }
}
