import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Feature, Geometry } from 'geojson';
import { GeoJSONSource, GeoJSONSourceRaw, LngLatBounds, Marker } from 'mapbox-gl';
import { LoadHistoryMap, LoadHistoryMapWaypoint } from '../../global-types';
import { MappableComponent } from '../../components/mappable/mappable.component';
import { BehaviorSubject, takeUntil } from 'rxjs';

// From https://stackoverflow.com/questions/37599561/drawing-a-circle-with-the-radius-in-miles-meters-with-mapbox-gl-js
const metersToPixelsAtMaxZoom = (meters: number, latitude: number) =>
  meters / (78271.484 / 2 ** 20) / Math.cos((latitude * Math.PI) / 180);

const circleColor = 'rgba(255, 0, 0, 0.5)';

const waypointToFeature = (waypoint: LoadHistoryMapWaypoint): GeoJSON.Feature => ({
  type: 'Feature',
  geometry: {
    type: 'Point',
    coordinates: [waypoint.lngLat.longitude, waypoint.lngLat.latitude],
  },
  properties: {
    time: new Date(waypoint.clientCreatedAt).getTime(),
  },
});

type FacilityData = Feature<
  Geometry,
  {
    description: string;
    id: string;
    originalRadius: number;
    latitude: number;
    radius: number;
    color: string;
  }
>;

const getWaypointSources = (
  loadHistoryMap: LoadHistoryMap,
): {
  beforeLoad: GeoJSONSourceRaw;
  duringLoad: GeoJSONSourceRaw;
  afterLoad: GeoJSONSourceRaw;
} => {
  let activeLowerBound = loadHistoryMap.details.startedAt;
  let activeUpperBound = loadHistoryMap.details.completedAt;
  const beforeLoadWaypoints = [];
  const duringLoadWaypoints = [];
  const afterLoadWaypoints = [];
  for (const waypoint of loadHistoryMap.waypoints || []) {
    if (!waypoint) {
      continue;
    }
    if (waypoint.clientCreatedAt < activeLowerBound) {
      beforeLoadWaypoints.push(waypoint);
    } else if (waypoint.clientCreatedAt >= activeLowerBound && waypoint.clientCreatedAt < activeUpperBound) {
      // non-inclusive
      duringLoadWaypoints.push(waypoint);
    } else {
      afterLoadWaypoints.push(waypoint);
    }
  }

  const beforeLoadSource: mapboxgl.GeoJSONSourceRaw = {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: beforeLoadWaypoints.map(waypointToFeature),
    },
  };

  const activeSource: mapboxgl.GeoJSONSourceRaw = {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: duringLoadWaypoints.map(waypointToFeature),
    },
  };

  const afterSource: mapboxgl.GeoJSONSourceRaw = {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: afterLoadWaypoints.map(waypointToFeature),
    },
  };

  return {
    beforeLoad: beforeLoadSource,
    duringLoad: activeSource,
    afterLoad: afterSource,
  };
};

@Component({
  selector: 'td-load-timeline-map',
  templateUrl: './load-timeline-map.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadTimelineMapComponent extends MappableComponent {
  private waypointMarker: Marker;
  private loadData$$ = new BehaviorSubject<LoadHistoryMap>(null);

  @Input()
  public set activeWaypoint(waypoint: LoadHistoryMapWaypoint) {
    if (!this.map) {
      return;
    }
    if (waypoint) {
      if (this.waypointMarker) {
        this.waypointMarker.setLngLat({ lng: waypoint.lngLat.longitude, lat: waypoint.lngLat.latitude });
      } else {
        const element = document.createElement('div');
        element.style.fontSize = '3rem';
        element.innerHTML = '🚚';
        this.waypointMarker = new Marker({ element })
          .setLngLat({ lng: waypoint.lngLat.longitude, lat: waypoint.lngLat.latitude })
          .addTo(this.map);
      }
    } else {
      if (this.waypointMarker) {
        this.waypointMarker.remove();
        this.waypointMarker = null;
      }
    }
  }

  @Input()
  public set mapData(mapData: LoadHistoryMap) {
    this.loadData$$.next(mapData);
  }

  constructor() {
    super();
  }

  public override onStyleLoad() {
    super.onStyleLoad();
    this.map.on('load', () => {
      this.loadData$$.pipe(takeUntil(this.destroy$$)).subscribe({
        next: (loadHistoryMap) => {
          this.setupWaypoints(loadHistoryMap);
          this.setupFacilities(loadHistoryMap);
          this.updateBounds(loadHistoryMap);
        },
      });
    });
  }

  private setupFacilities(loadHistoryMap: LoadHistoryMap) {
    if (!loadHistoryMap) {
      if (this.map.getLayer('facilities')) {
        this.map.removeLayer('facilities');
      }
      if (this.map.getLayer('facilityNames')) {
        this.map.removeLayer('facilityNames');
      }
      if (this.map.getSource('facilities')) {
        this.map.removeSource('facilities');
      }
      return;
    }
    const facilityData: FacilityData[] = [];
    const geoJsonFacilities: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
      type: 'FeatureCollection',
      features: [],
    };
    const alreadyAddedFacilities = new Set<string>();

    loadHistoryMap.stops.forEach((stop) => {
      if (alreadyAddedFacilities.has(stop.facilityId)) {
        return;
      }
      alreadyAddedFacilities.add(stop.facilityId);
      facilityData.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [stop.lngLat.longitude, stop.lngLat.latitude],
        },
        properties: {
          description: stop.name,
          id: stop.facilityId,
          originalRadius: stop.geofenceRadiusMeters,
          latitude: stop.lngLat.latitude,
          radius: metersToPixelsAtMaxZoom(stop.geofenceRadiusMeters, stop.lngLat.latitude),
          color: circleColor,
        },
      });
    });
    geoJsonFacilities.features = facilityData;
    const source = this.map.getSource('facilities') as GeoJSONSource;
    if (source) {
      source.setData(geoJsonFacilities);
    } else {
      this.map.addSource('facilities', {
        type: 'geojson',
        data: geoJsonFacilities,
      });
      this.map.addLayer({
        id: `facilities`,
        type: 'circle',
        source: `facilities`,
        paint: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'circle-radius': ['interpolate', ['exponential', 2], ['zoom'], 0, 0, 20, ['*', ['get', 'radius'], 1]],
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'circle-color': ['get', 'color'],
        },
      });
      this.map.addLayer({
        id: 'facilityNames',
        type: 'symbol',
        source: 'facilities',
        layout: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'text-field': ['get', 'description'],
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'text-variable-anchor': ['left'],
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'text-radial-offset': 0.5,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'text-justify': 'auto',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'text-allow-overlap': true,
        },
      });
    }
  }

  private setupWaypoints(loadHistoryMap: LoadHistoryMap) {
    if (!loadHistoryMap) {
      if (this.map.getLayer('beforeLoadWaypoints')) {
        this.map.removeLayer('beforeLoadWaypoints');
      }
      if (this.map.getSource('beforeLoadWaypoints')) {
        this.map.removeSource('beforeLoadWaypoints');
      }
      if (this.map.getLayer('duringLoadWaypoints')) {
        this.map.removeLayer('duringLoadWaypoints');
      }
      if (this.map.getSource('duringLoadWaypoints')) {
        this.map.removeSource('duringLoadWaypoints');
      }
      if (this.map.getLayer('afterLoadWaypoints')) {
        this.map.removeLayer('afterLoadWaypoints');
      }
      if (this.map.getSource('afterLoadWaypoints')) {
        this.map.removeSource('afterLoadWaypoints');
      }
      return;
    }
    const heatMapColors = [
      'interpolate',
      ['linear'],
      ['heatmap-density'],
      0,
      'rgba(0, 0, 100, 0)',
      0.5,
      'rgba(0, 0, 100, 0.5)',
      1,
      'rgba(0, 0, 100, 0.8)',
    ];
    const waypointSources = getWaypointSources(loadHistoryMap);
    this.setWaypoints('beforeLoadWaypoints', waypointSources.beforeLoad, heatMapColors);
    const activeHeatMapColors = [...heatMapColors];
    activeHeatMapColors[4] = 'rgba(0, 0, 255, 0)';
    activeHeatMapColors[6] = 'rgba(0, 0, 255, 0.5)';
    activeHeatMapColors[8] = 'rgba(0, 0, 255, 0.8)';
    this.setWaypoints('duringLoadWaypoints', waypointSources.duringLoad, activeHeatMapColors);
    this.setWaypoints('afterLoadWaypoints', waypointSources.afterLoad, heatMapColors);
  }

  private setWaypoints(
    sourceName: 'beforeLoadWaypoints' | 'duringLoadWaypoints' | 'afterLoadWaypoints',
    source: GeoJSONSourceRaw,
    heatMapColors: any[],
  ) {
    const existingSource = this.map.getSource(sourceName) as GeoJSONSource;
    if (existingSource) {
      existingSource.setData(source.data as any);
    } else {
      this.map.addSource(sourceName, source);
      this.map.addLayer({
        id: sourceName,
        type: 'heatmap',
        source: sourceName,
        paint: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'heatmap-color': heatMapColors as any,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'heatmap-radius': 3,
        },
      });
    }
  }

  private updateBounds(loadHistoryMap: LoadHistoryMap) {
    this.map.resize();
    const bounds = new LngLatBounds();
    loadHistoryMap.stops.forEach((stop) => {
      bounds.extend([stop.lngLat.longitude, stop.lngLat.latitude]);
    });

    (loadHistoryMap?.waypoints || []).forEach((waypoint) => {
      bounds.extend([waypoint.lngLat.longitude, waypoint.lngLat.latitude]);
    });
    this.map.setMaxZoom(12);
    if (!bounds.isEmpty()) {
      this.map.fitBounds(bounds, { padding: 100 });
      this.map.setMaxZoom(22);
    }
  }
}
