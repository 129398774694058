<div class="overflow-auto h-full flex flex-col mx-3" *tdInternalOrExternalDispatcher="null">
  <div class="font-bold mb-3" *tdInternal="null">Internal</div>
  <div class="grid md:grid-cols-2 gap-3">
    <div>
      <div class="card-with-shadow-white-no-radius p-3 h-full">
        <div class="font-bold leading-loose text-lg">Shipper Services</div>
        <div class="flex flex-col">
          <a
            [routerLink]="['/carrier_list']"
            class="w-full leading-loose hover:text-blue-500 color-transition"
            *tdInternal="null"
            >Carriers</a
          >
          <div class="flex items-center gap-3 w-full" *tdInternal="null">
            <a [routerLink]="['/route_guide', 'v2']" class="leading-loose hover:text-blue-500 color-transition"
              >Route Guide</a
            >
          </div>
          <div class="flex items-center w-full" *tdInternal="null">
            <a [routerLink]="['/route_guide/activity']" class="leading-loose hover:text-blue-500 color-transition"
              >Route Guide Activity</a
            >
            <span class="w-2 text-center">/</span>
            <a
              [routerLink]="['/route_guide/activity/details']"
              class="leading-loose hover:text-blue-500 color-transition"
              >Details</a
            >
          </div>
          <a
            [routerLink]="['/shipper_loads/domestic']"
            class="w-full leading-loose hover:text-blue-500 color-transition"
            *tdInternal="null"
            >Domestic Active Shipments</a
          >
          <div class="flex items-center w-full" *tdInternal="null">
            <a [routerLink]="['/shipper_loads/drayage']" class="leading-loose hover:text-blue-500 color-transition"
              >Import/Export Active Shipments</a
            >
            <span class="w-2 text-center">/</span>
            <a [routerLink]="['/shipper_loads/drayage/v2']" class="leading-loose hover:text-blue-500 color-transition"
              >Import/Export Grid List</a
            >
          </div>
          <a
            [routerLink]="['/accounting/loads/all']"
            class="w-full leading-loose hover:text-blue-500 color-transition"
            *tdInternal="null"
            >Accounting Load List</a
          >
          <!--          <a
            [routerLink]="['/shipper_loads/invoicing']"
            class="w-full leading-loose hover:text-blue-500 color-transition"
            >Invoicing</a
          >-->
          <a [routerLink]="['/shipper_loads/award']" class="w-full leading-loose hover:text-blue-500 color-transition"
            >Create Shipment</a
          >
        </div>
      </div>
    </div>
    <div>
      <div class="card-with-shadow-white-no-radius p-3" *tdInternal="null">
        <div class="font-bold leading-loose text-lg">Tours Dispatch</div>
        <div class="flex flex-col">
          <a [routerLink]="['/dashboard/map']" class="w-full leading-loose hover:text-blue-500 color-transition">Map</a>
        </div>
      </div>
      <div class="card-with-shadow-white-no-radius mt-3 p-3">
        <div class="font-bold leading-loose text-lg">CPG</div>
        <div class="flex flex-col">
          <a [routerLink]="['/dispatch_pools']" class="w-full leading-loose hover:text-blue-500 color-transition"
            >Dispatch Pools</a
          >
          <a
            [routerLink]="['/load_disputes']"
            class="w-full leading-loose hover:text-blue-500 color-transition"
            *tdInternal="null"
            >OTP Load Disputes</a
          >
          <a
            [routerLink]="['/disputed_events']"
            class="w-full leading-loose hover:text-blue-500 color-transition"
            *tdInternal="null"
            >Disputed Events</a
          >
          <a [routerLink]="['/drop_trailer']" class="w-full leading-loose hover:text-blue-500 color-transition"
            >Drop Trailer</a
          >
          <a [routerLink]="['/facility']" class="w-full leading-loose hover:text-blue-500 color-transition"
            >Facility Management</a
          >
          <a
            [routerLink]="['/rates']"
            class="w-full leading-loose hover:text-blue-500 color-transition"
            *tdInternal="null"
          >
            Rates
          </a>
          <a
            [routerLink]="['/dispatch_pools/all_pools_loads']"
            class="w-full leading-loose hover:text-blue-500 color-transition"
            *tdInternal="null"
          >
            All CPG Loads
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-1"></div>
  <div class="flex flex-col">
    <div
      (click)="openLoadSearch()"
      class="w-full leading-loose hover:text-blue-500 color-transition cursor-pointer"
      *tdInternalOrExternalDispatcher="null"
    >
      Find Past Load
    </div>
  </div>
</div>
