import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, switchMap } from 'rxjs';
import { LoadHistoryMap, LoadHistoryMapDetails, LoadHistoryMapStop, LoadHistoryMapWaypoint } from '../global-types';
import {
  add,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  differenceInWeeks,
  isAfter,
  isBefore,
  isFuture,
  min,
  sub,
} from 'date-fns';
import { NetworkableDestroyableComponent } from '../components/networkable-destroyable.component';
import { map, shareReplay } from 'rxjs/operators';
import { shareReplayComponentConfig } from '../constants';
import { uniqBy } from 'remeda';

const staleWaypointSeconds = 5 * 60;

export interface Box {
  name: string;
  startPercent: number;
  heightPercent: number;
  startIsFake?: boolean;
  endIsFake?: boolean;
}

export interface StaleWaypointRegion {
  startPercent: number;
  heightPercent: number;
  startAt: string;
  endAt: string;
}

export interface GeofencePauseRegion {
  startPercent: number;
  heightPercent: number;
  startAt: string;
  endAt: string;
}

export interface LoadHistoryEvent {
  name: string;
  date: string;
}

export interface BoxData<T> extends Box {
  data: T;
}

export interface HourMarker {
  locationPercent: number;
  time: string;
}

export interface ActiveWaypoint {
  locationPercent: number;
  actualTime: Date;
  waypoint: LoadHistoryMapWaypoint;
  isFuture: boolean;
  staleWaypointRegion: StaleWaypointRegion;
}

export interface LoadEvent {
  type: 'exception';
  iconName: string;
  iconClass: string;
  time: string;
  message: string;
}

interface LoadEventsGroup {
  locationPercent: number;
  shiftEvents: LoadEvent[];
  rightPx: number;
  icons: { name: string; className: string }[];
}

export type PercentFn = (date: string | Date) => number;
export type PercentToTimeFn = (percent: number) => Date;

const startDateCalculator = (loadHistoryMap: LoadHistoryMap): Date => {
  if (!loadHistoryMap) {
    return null;
  }
  const oneHourBeforeLoadStart = sub(
    new Date(loadHistoryMap.details.startedAt || loadHistoryMap.details.assignedAt || new Date()),
    { hours: 1 },
  );
  if (!loadHistoryMap.waypoints?.length) {
    return oneHourBeforeLoadStart;
  }
  const firstWaypoint = loadHistoryMap.waypoints[0];
  const firstDate = new Date(firstWaypoint.clientCreatedAt);
  return min([firstDate, oneHourBeforeLoadStart]);
};

const endDateCalculator = (loadHistoryMap: LoadHistoryMap): Date => {
  if (!loadHistoryMap) {
    return null;
  }
  if (loadHistoryMap.details.completedAt) {
    const loadCompleted = new Date(loadHistoryMap.details.completedAt);
    return add(loadCompleted, { hours: 1 });
  }
  return add(new Date(), { hours: 1 });
};

const percentFnFactory = ([startDate, endDate]: [Date, Date]): PercentFn => {
  if (!startDate || !endDate) {
    return null;
  }
  const totalSeconds = differenceInSeconds(endDate, startDate);
  return (dateStr: string) => {
    const date = new Date(dateStr);
    const secondsSinceStart = differenceInSeconds(date, startDate);
    return (secondsSinceStart / totalSeconds) * 100;
  };
};

const percentToTimeFnFactory = ([startDate, endDate]: [Date, Date]): PercentToTimeFn => {
  if (!startDate || !endDate) {
    return null;
  }
  const totalSeconds = differenceInSeconds(endDate, startDate);
  return (percent: number) => {
    const seconds = (percent / 100) * totalSeconds;
    const date = add(startDate, { seconds });
    date.setMilliseconds(0); // Should make caching easier
    return date;
  };
};

const loadBoxCalculator = ([loadHistoryMap, percentFn]: [
  LoadHistoryMap,
  PercentFn,
]): BoxData<LoadHistoryMapDetails> => {
  let startPercent = percentFn(loadHistoryMap.details.startedAt);
  let startIsFake = false;
  if (startPercent < 0) {
    startIsFake = true;
    startPercent = 0;
  }

  let endPercent: number;
  let endIsFake = false;

  const lastStop = loadHistoryMap.stops.at(-1);

  if (loadHistoryMap.details.completedAt) {
    endPercent = percentFn(loadHistoryMap.details.completedAt);
  } else if (lastStop.completedAt) {
    endPercent = percentFn(lastStop.completedAt);
    endIsFake = true;
  } else {
    endPercent = percentFn(add(new Date(), { minutes: 45 }));
    endIsFake = true;
  }
  if (endPercent > 100) {
    endPercent = 100;
    endIsFake = true;
  }
  return {
    name: `Load ${loadHistoryMap.details.id}`,
    startPercent: startPercent,
    heightPercent: endPercent - startPercent,
    startIsFake,
    endIsFake,
    data: loadHistoryMap.details,
  };
};

const eventBoxesCalculator = ([loadHistoryMap, percentFn]: [
  LoadHistoryMap,
  PercentFn,
]): BoxData<LoadHistoryEvent>[] => {
  if (!loadHistoryMap?.details || !percentFn) {
    return null;
  }
  const events: BoxData<LoadHistoryEvent>[] = [];

  //These events may not show up on the timeline, as they are often set far before/after the load is started/completed
  if (loadHistoryMap.details.earliestReceiptDate) {
    events.push({
      name: 'ERD',
      startPercent: percentFn(loadHistoryMap.details.earliestReceiptDate),
      heightPercent: 0,
      startIsFake: false,
      endIsFake: false,
      data: {
        name: 'ERD',
        date: loadHistoryMap.details.earliestReceiptDate,
      },
    });
  }
  if (loadHistoryMap.details.portCutoffDate) {
    events.push({
      name: 'Port Cutoff',
      startPercent: percentFn(loadHistoryMap.details.portCutoffDate),
      heightPercent: 0,
      startIsFake: false,
      endIsFake: false,
      data: {
        name: 'Port Cutoff',
        date: loadHistoryMap.details.portCutoffDate,
      },
    });
  }
  if (loadHistoryMap.details.rampCutoffTime) {
    events.push({
      name: 'Ramp Cutoff',
      startPercent: percentFn(loadHistoryMap.details.rampCutoffTime),
      heightPercent: 0,
      startIsFake: false,
      endIsFake: false,
      data: {
        name: 'Ramp Cutoff',
        date: loadHistoryMap.details.rampCutoffTime,
      },
    });
  }

  return events;
};

const stopBoxesCalculator = ([loadHistoryMap, percentFn]: [
  LoadHistoryMap,
  PercentFn,
]): BoxData<LoadHistoryMapStop>[] => {
  if (!loadHistoryMap?.stops || !percentFn) {
    return null;
  }
  const stops: BoxData<LoadHistoryMapStop>[] = [];
  loadHistoryMap.stops.forEach((stop, i, allStops) => {
    let startTime = stop.arrivedAt;
    let endTime = stop.exitedAt || stop.completedAt;

    let startIsFake = false;
    let endIsFake = false;

    if (!startTime && !endTime && loadHistoryMap.details.status === 'in_progress') {
      return;
    }

    if (!startTime) {
      if (stop.exitedAt || stop.completedAt) {
        let startDate = sub(new Date(stop.exitedAt || stop.completedAt), { hours: 1 });
        if (isBefore(startDate, new Date(loadHistoryMap.details.startedAt))) {
          startDate = new Date(loadHistoryMap.details.startedAt);
        }
        startTime = startDate.toISOString();
      } else {
        const previousStop = i > 0 ? allStops[i - 1] : null;
        startTime = previousStop?.exitedAt || previousStop?.completedAt || loadHistoryMap.details.startedAt;
      }
      startIsFake = true;
    }
    if (!endTime) {
      if (stop.arrivedAt) {
        endTime = add(new Date(stop.arrivedAt), { hours: 1 }).toISOString();
      } else {
        const nextStop = i < allStops.length - 1 ? allStops[i + 1] : null;
        endTime = nextStop?.arrivedAt || nextStop?.completedAt || loadHistoryMap.details.completedAt;
      }
      endIsFake = true;
    }

    const startPercent = percentFn(startTime);
    const endPercent = percentFn(endTime);

    stops.push({
      name: `Stop ${stop.sequence}`,
      startPercent,
      heightPercent: endPercent - startPercent,
      startIsFake,
      endIsFake,
      data: stop,
    });
  });

  // handling carrier yard
  if (loadHistoryMap.carrierYard === null || loadHistoryMap.carrierYard.length < 1) {
    return stops;
  }
  loadHistoryMap.carrierYard.forEach((yard, i, allStops) => {
    let startTime = yard.arrivedAt;
    let endTime = yard.exitedAt;

    let startIsFake = false;
    let endIsFake = false;

    if (!startTime) {
      if (yard.exitedAt) {
        let startDate = sub(new Date(yard.exitedAt), { hours: 1 });
        if (isBefore(startDate, new Date(loadHistoryMap.details.startedAt))) {
          startDate = new Date(loadHistoryMap.details.startedAt);
        }
        startTime = startDate.toISOString();
      } else {
        const previousStop = i > 0 ? allStops[i - 1] : null;
        startTime = previousStop?.exitedAt || loadHistoryMap.details.startedAt;
      }
      startIsFake = true;
    }
    if (!endTime) {
      if (yard.arrivedAt) {
        endTime = add(new Date(yard.arrivedAt), { hours: 1 }).toISOString();
      } else {
        const nextStop = i < allStops.length - 1 ? allStops[i + 1] : null;
        endTime = nextStop?.arrivedAt || loadHistoryMap.details.completedAt;
      }
      endIsFake = true;
    }

    const startPercent = percentFn(startTime);
    const endPercent = percentFn(endTime);

    stops.push({
      name: `Carrier Yard`,
      startPercent,
      heightPercent: endPercent - startPercent,
      startIsFake,
      endIsFake,
      data: {
        sequence: null,
        type: 'at_yard',
        facilityId: null,
        name: null,
        arrivedAt: yard.arrivedAt,
        exitedAt: yard.exitedAt,
        lngLat: null,
        completedAt: null,
        manuallyCompleted: yard.manuallyCompleted,
        geofenceRadiusMeters: null,
        timezone: yard.timezone,
      },
    });
  });
  return stops;
};

const waypointSearch = (
  waypoints: LoadHistoryMapWaypoint[],
  targetTime: string,
  canceller: { cancelled: boolean },
): LoadHistoryMapWaypoint => {
  if (canceller.cancelled) {
    return null;
  }
  if (!waypoints?.length) {
    return null;
  }
  const midpoint = Math.floor(waypoints.length / 2);

  if (waypoints[midpoint].clientCreatedAt === targetTime) {
    return waypoints[midpoint];
  }
  if (waypoints.length === 1) {
    return waypoints[0];
  }

  if (waypoints[midpoint].clientCreatedAt > targetTime) {
    return waypointSearch(waypoints.slice(0, midpoint), targetTime, canceller);
  } else if (waypoints[midpoint].clientCreatedAt < targetTime) {
    return waypointSearch(waypoints.slice(midpoint), targetTime, canceller);
  }
  return null;
};

const mouseOverWaypoint = ([loadHistoryMap, mouseEvent, percentToTimeFn, staleWaypointRegions]: [
  LoadHistoryMap,
  MouseEvent,
  PercentToTimeFn,
  StaleWaypointRegion[],
]): Observable<ActiveWaypoint> => {
  if (!loadHistoryMap || !percentToTimeFn || !mouseEvent) {
    return of(null);
  }
  const canceller = { cancelled: false };
  return new Observable<ActiveWaypoint>((observer) => {
    let target = mouseEvent.target as HTMLElement;
    setTimeout(() => {
      while (target && target.id !== 'mouseMove') {
        if (canceller.cancelled) {
          return observer.next(null);
        }
        if (target.tagName === 'BODY') {
          return observer.next(null);
        }
        target = target.parentElement;
      }
      const rect = target.getBoundingClientRect();
      const y = mouseEvent.clientY - rect.top;
      if (y > 0) {
        const locationPercent = (y / rect.height) * 100;
        const time = percentToTimeFn(locationPercent);
        const waypoint = waypointSearch(loadHistoryMap.waypoints, time.toISOString(), canceller);
        if (!waypoint) {
          return observer.next(null);
        }
        const staleWaypointRegion = staleWaypointRegions.find(
          (region) => new Date(region.startAt) <= time && new Date(region.endAt) >= time,
        );
        return observer.next({
          locationPercent,
          actualTime: time,
          waypoint,
          isFuture: isFuture(time),
          staleWaypointRegion,
        });
      }
      return observer.next(null);
    }, 0);
    return () => {
      canceller.cancelled = true;
    };
  });
};

const markersCalculator = ([percentFn, startDate, endDate]: [PercentFn, Date, Date]): HourMarker[] => {
  if (!percentFn || !startDate || !endDate) {
    return null;
  }
  let duration: Duration = { hours: 1 };
  if (differenceInHours(endDate, startDate) > 24) {
    duration = { hours: 4 };
  }
  if (differenceInDays(endDate, startDate) > 7) {
    duration = { days: 1 };
  }
  if (differenceInWeeks(endDate, startDate) > 4) {
    duration = { weeks: 1 };
  }
  let markerDate = startDate;
  markerDate.setMinutes(0, 0, 0);
  markerDate = add(markerDate, duration);
  const markers: HourMarker[] = [];
  while (!isAfter(markerDate, endDate)) {
    const iso = markerDate.toISOString();
    markers.push({
      locationPercent: percentFn(iso),
      time: iso,
    });
    markerDate = add(markerDate, duration);
  }
  return markers;
};

const eventsCalculator = ([map, percentFn]: [LoadHistoryMap, PercentFn]): LoadEventsGroup[] => {
  const exceptionEvents: LoadEvent[] = (map?.exceptions || []).map((exception) => ({
    type: 'exception',
    time: exception.createdAt,
    iconName: 'error',
    iconClass: 'text-red-500',
    message: `${exception.exceptionType.split('_').join(' ')}${
      exception.driverNotes ? ': ' + exception.driverNotes : ''
    }`,
  }));
  const allEvents = [...exceptionEvents];
  allEvents.sort((a, b) => a.time.localeCompare(b.time));
  let eventsGrouped: LoadEventsGroup[] = [];
  if (allEvents.length) {
    eventsGrouped.push({
      locationPercent: percentFn(allEvents[0].time),
      shiftEvents: [allEvents[0]],
      rightPx: 0,
      icons: [],
    });
    eventsGrouped = allEvents.reduce((acc, e, index) => {
      if (index === 0) {
        return acc;
      }
      const lastGroup = acc[acc.length - 1];
      const lastEvent = lastGroup.shiftEvents[lastGroup.shiftEvents.length - 1];
      const lastEventDate = new Date(lastEvent.time);
      const thisEventDate = new Date(e.time);
      if (differenceInMinutes(thisEventDate, lastEventDate) < 15 && lastGroup.shiftEvents.length < 6) {
        lastGroup.shiftEvents.push(e);
      } else {
        acc.push({
          locationPercent: percentFn(e.time),
          shiftEvents: [e],
          rightPx: 0,
          icons: [],
        });
      }
      return acc;
    }, eventsGrouped);
  }
  eventsGrouped.forEach((group, index) => {
    const px = (4 - (index % 4)) * 40; // 200 px, 32px width icons so can fit 4 with some padding on the left an
    group.rightPx = px;
    group.icons = group.shiftEvents.map((e) => ({ name: e.iconName, className: e.iconClass }));
    group.icons = uniqBy(group.icons, (g) => g.name);
    if (group.icons.length > 4) {
      group.icons.length = 3;
      group.icons.push({
        name: 'read_more',
        className: 'text-black',
      });
    }
  });
  return eventsGrouped;
};

const staleWaypointsCalculator = ([loadHistoryMap, percentToTimeFn, percentFn]: [
  LoadHistoryMap,
  PercentToTimeFn,
  PercentFn,
]): StaleWaypointRegion[] => {
  if (!loadHistoryMap || !percentToTimeFn || !percentFn) {
    return null;
  }

  // time in carrier yard should not be counted as stale waypoints even if there is no waypoint
  let timeRangeInCarrierYardToSkip = [];
  if (loadHistoryMap.carrierYard) {
    loadHistoryMap.carrierYard.forEach((yard) => {
      timeRangeInCarrierYardToSkip.push({
        startTime: yard.arrivedAt,
        endTime: yard.exitedAt,
      });
    });
  }

  const waypoints: { clientCreatedAt: string }[] = (loadHistoryMap.waypoints || []).slice();
  waypoints.unshift({ clientCreatedAt: percentToTimeFn(0).toISOString() });
  let end = percentToTimeFn(100);
  if (isFuture(end)) {
    end = new Date();
  }
  waypoints.push({ clientCreatedAt: end.toISOString() });
  const staleWaypoints: StaleWaypointRegion[] = [];
  for (let i = 1; i < waypoints.length; i++) {
    const waypoint = waypoints[i];
    const previousWaypoint = waypoints[i - 1];
    if (
      differenceInSeconds(new Date(waypoint.clientCreatedAt), new Date(previousWaypoint.clientCreatedAt)) >
      staleWaypointSeconds
    ) {
      const startPercent = percentFn(previousWaypoint.clientCreatedAt);
      const endPercent = percentFn(waypoint.clientCreatedAt);
      staleWaypoints.push({
        startPercent,
        heightPercent: endPercent - startPercent,
        startAt: previousWaypoint.clientCreatedAt,
        endAt: waypoint.clientCreatedAt,
      });
    }
  }

  if (timeRangeInCarrierYardToSkip) {
    staleWaypoints.forEach((staleWaypoint, index, array) => {
      timeRangeInCarrierYardToSkip.forEach((timeRangeInCarrierYardToSkip) => {
        if (
          new Date(staleWaypoint.endAt) > new Date(timeRangeInCarrierYardToSkip.startTime) &&
          new Date(staleWaypoint.startAt) < new Date(timeRangeInCarrierYardToSkip.endTime)
        ) {
          array.splice(index, 1);
        }
      });
    });
  }

  return staleWaypoints;
};

const geofencePauseRegionsCalculator = ([loadHistoryMap, percentFn]: [
  LoadHistoryMap,
  PercentFn,
]): GeofencePauseRegion[] => {
  return (loadHistoryMap?.exceptions ?? [])
    .filter((e) => !!e.geofencePauseEndsAt)
    .map((e) => ({
      startPercent: percentFn(e.createdAt),
      heightPercent: percentFn(e.geofencePauseEndsAt) - percentFn(e.createdAt),
      startAt: e.createdAt,
      endAt: e.geofencePauseEndsAt,
    }));
};

@Component({
  selector: 'td-load-timeline',
  templateUrl: './load-timeline.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadTimelineComponent extends NetworkableDestroyableComponent {
  private loadHistoryMap$$ = new BehaviorSubject<LoadHistoryMap>(null);
  public loadHistoryMap$ = this.loadHistoryMap$$.pipe(shareReplay(shareReplayComponentConfig));
  public loadStarted$ = this.loadHistoryMap$.pipe(
    map((data) => !['not_assigned', 'pending'].includes(data?.details?.status)),
  );
  private mouseMoveEvent$$ = new BehaviorSubject<MouseEvent>(null);
  public pickupTimezone$ = this.loadHistoryMap$.pipe(map((data) => data?.stops?.at(0)?.timezone));
  private startDate$ = this.loadHistoryMap$.pipe(map(startDateCalculator));
  private endDate$ = this.loadHistoryMap$.pipe(map(endDateCalculator));
  private percentFn$ = combineLatest([this.startDate$, this.endDate$]).pipe(map(percentFnFactory));
  private percentToTimeFn$ = combineLatest([this.startDate$, this.endDate$]).pipe(map(percentToTimeFnFactory));
  public loadBox$ = combineLatest([this.loadHistoryMap$, this.percentFn$]).pipe(map(loadBoxCalculator));
  public stopBoxes$ = combineLatest([this.loadHistoryMap$, this.percentFn$]).pipe(map(stopBoxesCalculator));
  public eventBoxes$ = combineLatest([this.loadHistoryMap$, this.percentFn$]).pipe(map(eventBoxesCalculator));
  public hourMarkers$ = combineLatest([this.percentFn$, this.startDate$, this.endDate$]).pipe(map(markersCalculator));
  public staleWaypointRegions$ = combineLatest([this.loadHistoryMap$, this.percentToTimeFn$, this.percentFn$]).pipe(
    map(staleWaypointsCalculator),
  );
  public activeWaypoint$ = combineLatest([
    this.loadHistoryMap$,
    this.mouseMoveEvent$$.asObservable(),
    this.percentToTimeFn$,
    this.staleWaypointRegions$,
  ]).pipe(switchMap(mouseOverWaypoint));
  public geofencePauseRegions$ = combineLatest([this.loadHistoryMap$, this.percentFn$]).pipe(
    map(geofencePauseRegionsCalculator),
  );
  public eventsGrouped$ = combineLatest([this.loadHistoryMap$, this.percentFn$]).pipe(map(eventsCalculator));

  @Input()
  // eslint-disable-next-line rxjs/finnish
  public set loadHistoryMap(input: LoadHistoryMap) {
    this.loadHistoryMap$$.next(input);
  }

  public highlightWaypoint(event: MouseEvent) {
    this.mouseMoveEvent$$.next(event);
  }
}
