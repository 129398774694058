import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const joinPhonesWithAnd = (arr: string[]) => {
  if (arr.length === 1) {
    return parsePhoneNumberFromString(`${arr[0]}`, 'US').formatNational();
  }
  const firsts = arr.slice(0, arr.length - 1);
  let firstsPhones = [];
  for (let i = 0; i < firsts.length; i++) {
    const parsedNumber = parsePhoneNumberFromString(`${firsts[i]}`, 'US');
    firstsPhones.push(parsedNumber.formatNational());
  }

  const last = parsePhoneNumberFromString(`${arr[arr.length - 1]}`, 'US').formatNational();
  return `${firstsPhones.join(', ')} and ${last}`;
};

@Pipe({
  name: 'joinPhonesWithAnd',
})
export class JoinPhonesWithAndPipe implements PipeTransform {
  public transform(value: string[]): string {
    if (!Array.isArray(value)) {
      return value;
    }

    if (!value.length) {
      return null;
    }

    return joinPhonesWithAnd(value);
  }
}
