import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameToInitials',
})
export class NameToInitialsPipe implements PipeTransform {
  public transform(value: string, maxInitials?: number): string {
    if (!value || value.length === 0) {
      return '';
    }

    const names = value.split(' ');

    if (names.length === 1) {
      return names[0].charAt(0);
    }

    let initials = '';
    for (let i = 0; i < names.length; i++) {
      initials = initials + names[i].charAt(0);
      if (initials.length === maxInitials) {
        break;
      }
    }

    return initials;
  }
}
