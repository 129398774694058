import { Pipe, PipeTransform } from '@angular/core';
import { stringOrDateToDate } from '../utilities/dateTime';
import { isAfter, isBefore } from 'date-fns';

@Pipe({
  name: 'isAfter',
})
export class IsAfterPipe implements PipeTransform {
  public transform(value: string | Date, dateToCompare: string | Date = new Date()): boolean {
    if (!value) {
      return false;
    }
    const date = stringOrDateToDate(value);
    return isAfter(date, stringOrDateToDate(dateToCompare));
  }
}
