<div mat-dialog-title>Editing {{ loadID }}</div>
<div mat-dialog-content>
  <form *ngIf="editLoadForm" [formGroup]="editLoadForm" class="grid grid-cols-2 gap-x-2">
    <div class="col-span-2 font-semibold pb-1">Shipment Details</div>
    <mat-form-field>
      <mat-label>Customer Load Number</mat-label>
      <input matInput formControlName="referenceNumber" autocomplete="off" />
    </mat-form-field>
    <mat-form-field *ngIf="equipment$ | async as equipments">
      <mat-label>Equipment</mat-label>
      <mat-select multiple formControlName="possibleTrailerTypesIds">
        <mat-option [value]="equip.value" *ngFor="let equip of equipments; trackBy: equipment$ | trackByKey: 'value'">
          {{ equip.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <ng-container *ngIf="specialHandling === 'export'">
      <mat-form-field>
        <mat-label>Commodity</mat-label>
        <input formControlName="commodity" matInput autocomplete="off" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Unit</mat-label>
        <mat-select formControlName="unitId">
          <mat-option [value]="unit.id" *ngFor="let unit of units$ | async; trackBy: units$ | trackByKey: 'id'">
            {{ unit.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Quantity</mat-label>
        <input formControlName="quantity" tdNumberInput matInput autocomplete="off" />
      </mat-form-field>
    </ng-container>
    <mat-form-field>
      <mat-label>Weight</mat-label>
      <input formControlName="weightPounds" [noDecimals]="true" tdNumberInput matInput autocomplete="off" />
      <span matSuffix>lbs</span>
    </mat-form-field>
    <mat-form-field class="col-span-2">
      <mat-label>Shipping Comments</mat-label>
      <textarea formControlName="shippingComments" matInput autocomplete="off"></textarea>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Temperature</mat-label>
      <input matInput type="number" formControlName="temperature" autocomplete="off" />
      <span matSuffix>&#176;F</span>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Cargo Value</mat-label>
      <input matInput tdCurrencyInput formControlName="cargoValueCents" autocomplete="off" />
    </mat-form-field>
    <ng-container *ngIf="!disableRateAndShipper">
      <mat-form-field>
        <mat-label>Rate</mat-label>
        <input matInput tdCurrencyInput formControlName="rateCents" autocomplete="off" required />
      </mat-form-field>
      <mat-form-field class="md:col-span-2">
        <mat-label>Shipper</mat-label>
        <input matInput formControlName="shipper" autocomplete="off" />
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="isDrayage">
      <div class="col-span-2 font-semibold mt-2 mb-1" *ngIf="specialHandling === 'import'">Import Details</div>
      <div class="col-span-2 font-semibold mt-2 mb-1" *ngIf="specialHandling === 'export'">Export Details</div>
      <mat-form-field>
        <mat-label>Steamship Line</mat-label>
        <mat-select formControlName="shippingLine">
          <mat-option
            [value]="sl.name"
            *ngFor="let sl of shippingLines$ | async; trackBy: shippingLines$ | trackByKey: null"
          >
            {{ sl.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Vessel Number</mat-label>
        <input matInput formControlName="vesselNumber" autocomplete="off" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Booking Number</mat-label>
        <input matInput formControlName="bookingNumber" autocomplete="off" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Container Number</mat-label>
        <input matInput formControlName="containerNumber" autocomplete="off" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Seal Number</mat-label>
        <input matInput formControlName="sealNumber" autocomplete="off" />
      </mat-form-field>
      <ng-container *ngIf="specialHandling === 'export'">
        <ng-container *ngIf="dupedFields">
          <mat-form-field>
            <mat-label>Loaded Steamship Line</mat-label>
            <mat-select formControlName="shippingLine2">
              <mat-option
                [value]="sl.name"
                *ngFor="let sl of shippingLines$ | async; trackBy: shippingLines$ | trackByKey: null"
              >
                {{ sl.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Loaded Booking Number</mat-label>
            <input matInput formControlName="bookingNumber2" autocomplete="off" />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Loaded Container Number</mat-label>
            <input matInput formControlName="containerNumber2" autocomplete="off" />
          </mat-form-field>
        </ng-container>
        <mat-form-field>
          <mat-label>Chassis Number</mat-label>
          <input formControlName="chassisNumber" matInput autocomplete="off" />
        </mat-form-field>
        <div class="col-span-2 font-semibold mt-2">Empty Container Pickup Details</div>
        <mat-form-field>
          <mat-label>Facility</mat-label>
          <mat-select formControlName="containerPickupLocation">
            <mat-option>
              <ngx-mat-select-search
                placeholderLabel="Search"
                noEntriesFoundLabel="No match found"
                [formControl]="emptyContainerFacilitySearch"
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option
              [value]="facility.id"
              *ngFor="
                let facility of filteredEmptyContainerFacilities$ | async;
                trackBy: filteredEmptyContainerFacilities$ | trackByKey: 'id'
              "
            >
              {{ facility.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div></div>
        <mat-form-field>
          <mat-label>Appointment Window Start</mat-label>
          <input
            matInput
            [ngxMatDatetimePicker]="containerAppointmentStartPicker"
            formControlName="containerAppointmentStart"
            autocomplete="off"
            tdDatePicker
          />
          <mat-datepicker-toggle matSuffix [for]="$any(containerAppointmentStartPicker)"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker
            [enableMeridian]="true"
            [stepMinute]="15"
            #containerAppointmentStartPicker
          ></ngx-mat-datetime-picker>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Appointment Window End</mat-label>
          <input
            matInput
            [ngxMatDatetimePicker]="containerAppointmentEndPicker"
            formControlName="containerAppointmentEnd"
            autocomplete="off"
            tdDatePicker
          />
          <mat-datepicker-toggle matSuffix [for]="$any(containerAppointmentEndPicker)"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker
            [enableMeridian]="true"
            [stepMinute]="15"
            #containerAppointmentEndPicker
          ></ngx-mat-datetime-picker>
        </mat-form-field>
        <div class="col-span-2 font-semibold mt-2" *ngIf="specialHandling === 'export'">Loading Details</div>
        <mat-form-field>
          <mat-label>Facility</mat-label>
          <mat-select formControlName="pickupLocation">
            <mat-option>
              <ngx-mat-select-search
                placeholderLabel="Search"
                noEntriesFoundLabel="No match found"
                [formControl]="loadingFacilitySearch"
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option
              [value]="facility.id"
              *ngFor="
                let facility of filteredLoadingFacilities$ | async;
                trackBy: filteredLoadingFacilities$ | trackByKey: 'id'
              "
            >
              {{ facility.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>RTL (Earliest Pickup Date)</mat-label>
          <input
            matInput
            [ngxMatDatetimePicker]="earliestPickupTimePicker"
            formControlName="earliestPickupTime"
            autocomplete="off"
            tdDatePicker
          />
          <mat-datepicker-toggle matSuffix [for]="$any(earliestPickupTimePicker)"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker
            [enableMeridian]="true"
            [stepMinute]="15"
            #earliestPickupTimePicker
          ></ngx-mat-datetime-picker>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Latest Pickup Date</mat-label>
          <input
            matInput
            [ngxMatDatetimePicker]="latestPickupTimePicker"
            formControlName="latestPickupTime"
            autocomplete="off"
            tdDatePicker
          />
          <mat-datepicker-toggle matSuffix [for]="$any(latestPickupTimePicker)"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker
            [enableMeridian]="true"
            [stepMinute]="15"
            #latestPickupTimePicker
          ></ngx-mat-datetime-picker>
        </mat-form-field>
        <div></div>
        <mat-form-field>
          <mat-label>Appointment Window Start</mat-label>
          <input
            matInput
            [ngxMatDatetimePicker]="pickupAppointmentStartPicker"
            formControlName="pickupAppointmentStart"
            autocomplete="off"
            tdDatePicker
          />
          <mat-datepicker-toggle matSuffix [for]="$any(pickupAppointmentStartPicker)"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker
            [enableMeridian]="true"
            [stepMinute]="15"
            #pickupAppointmentStartPicker
          ></ngx-mat-datetime-picker>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Appointment Window End</mat-label>
          <input
            matInput
            [ngxMatDatetimePicker]="pickupAppointmentEndPicker"
            formControlName="pickupAppointmentEnd"
            autocomplete="off"
            tdDatePicker
          />
          <mat-datepicker-toggle matSuffix [for]="$any(pickupAppointmentEndPicker)"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker
            [enableMeridian]="true"
            [stepMinute]="15"
            #pickupAppointmentEndPicker
          ></ngx-mat-datetime-picker>
        </mat-form-field>
        <div class="col-span-2 font-semibold mt-2" *ngIf="specialHandling === 'export'">Port/Rail Yard Details</div>
        <mat-form-field>
          <mat-label>Facility</mat-label>
          <mat-select formControlName="dropoffLocation">
            <mat-option>
              <ngx-mat-select-search
                placeholderLabel="Search"
                noEntriesFoundLabel="No match found"
                [formControl]="portRailFacilitySearch"
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option
              [value]="facility.id"
              *ngFor="
                let facility of filteredPortRailFacilities$ | async;
                trackBy: filteredPortRailFacilities$ | trackByKey: 'id'
              "
            >
              {{ facility.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>ERD (Earliest Receipt Date)</mat-label>
          <input
            matInput
            [ngxMatDatetimePicker]="earliestReceiptTimePicker"
            formControlName="earliestReceiptTime"
            autocomplete="off"
            tdDatePicker
          />
          <mat-datepicker-toggle matSuffix [for]="$any(earliestReceiptTimePicker)"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker
            [enableMeridian]="true"
            [stepMinute]="15"
            #earliestReceiptTimePicker
          ></ngx-mat-datetime-picker>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Document Cutoff Date</mat-label>
          <input
            matInput
            [ngxMatDatetimePicker]="documentationCutoffTimePicker"
            formControlName="documentationCutoffTime"
            autocomplete="off"
            tdDatePicker
          />
          <mat-datepicker-toggle matSuffix [for]="$any(documentationCutoffTimePicker)"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker
            [enableMeridian]="true"
            [stepMinute]="15"
            #documentationCutoffTimePicker
          ></ngx-mat-datetime-picker>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Rail/Port Cutoff Date</mat-label>
          <input
            matInput
            [ngxMatDatetimePicker]="portCutoffTimePicker"
            formControlName="portCutoffTime"
            autocomplete="off"
            tdDatePicker
          />
          <mat-datepicker-toggle matSuffix [for]="$any(portCutoffTimePicker)"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker
            [enableMeridian]="true"
            [stepMinute]="15"
            #portCutoffTimePicker
          ></ngx-mat-datetime-picker>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Ramp Cutoff Date</mat-label>
          <input
            matInput
            [ngxMatDatetimePicker]="rampCutoffTimePicker"
            formControlName="rampCutoffTime"
            autocomplete="off"
            tdDatePicker
          />
          <mat-datepicker-toggle matSuffix [for]="$any(rampCutoffTimePicker)"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker
            [enableMeridian]="true"
            [stepMinute]="15"
            #rampCutoffTimePicker
          ></ngx-mat-datetime-picker>
        </mat-form-field>
        <div></div>
        <mat-form-field>
          <mat-label>Appointment Window Start</mat-label>
          <input
            matInput
            [ngxMatDatetimePicker]="dropoffAppointmentStartPicker"
            formControlName="dropoffAppointmentStart"
            autocomplete="off"
            tdDatePicker
          />
          <mat-datepicker-toggle matSuffix [for]="$any(dropoffAppointmentStartPicker)"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker
            [enableMeridian]="true"
            [stepMinute]="15"
            #dropoffAppointmentStartPicker
          ></ngx-mat-datetime-picker>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Appointment Window End</mat-label>
          <input
            matInput
            [ngxMatDatetimePicker]="dropoffAppointmentEndPicker"
            formControlName="dropoffAppointmentEnd"
            autocomplete="off"
            tdDatePicker
          />
          <mat-datepicker-toggle matSuffix [for]="$any(dropoffAppointmentEndPicker)"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker
            [enableMeridian]="true"
            [stepMinute]="15"
            #dropoffAppointmentEndPicker
          ></ngx-mat-datetime-picker>
        </mat-form-field>
      </ng-container>
      <ng-container *ngIf="specialHandling === 'import'">
        <mat-form-field class="w-full">
          <mat-label>Earliest Pickup Date</mat-label>
          <input
            matInput
            [ngxMatDatetimePicker]="earliestPickupAtPicker"
            formControlName="earliestPickupAt"
            autocomplete="off"
            tdDatePicker
          />
          <mat-datepicker-toggle matSuffix [for]="$any(earliestPickupAtPicker)"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker
            [enableMeridian]="true"
            [stepMinute]="15"
            #earliestPickupAtPicker
          ></ngx-mat-datetime-picker>
        </mat-form-field>
        <mat-form-field class="w-full">
          <mat-label>Last Free Pickup Date</mat-label>
          <input
            matInput
            [ngxMatDatetimePicker]="lastFreePickupAtPicker"
            formControlName="lastFreePickupAt"
            autocomplete="off"
            tdDatePicker
          />
          <mat-datepicker-toggle matSuffix [for]="$any(lastFreePickupAtPicker)"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker
            [enableMeridian]="true"
            [stepMinute]="15"
            #lastFreePickupAtPicker
          ></ngx-mat-datetime-picker>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Rail Billing Confirmation Number</mat-label>
          <input formControlName="railBillingConfirmationNumber" matInput autocomplete="off" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Reservation Confirmation Number</mat-label>
          <input formControlName="reservationConfirmationNumber" matInput autocomplete="off" />
        </mat-form-field>
        <div class="flex flex-col justify-center">
          <mat-checkbox formControlName="useAlmChassis">Use ALM Chassis</mat-checkbox>
        </div>
        <mat-form-field>
          <mat-label>Chassis Number</mat-label>
          <input formControlName="chassisNumber" matInput autocomplete="off" />
        </mat-form-field>
      </ng-container>
    </ng-container>
    <div class="mt-3 text-lg font-semibold col-span-2">Customer Specific Details</div>
    <ng-container *ngIf="customerSpecificDetails$ | async as details">
      <ng-container *ngFor="let item of details; trackBy: details | trackByKey: 'key'">
        <div class="flex items-center mt-2">
          <div class="pr-2">
            <mat-icon class="text-red-500 cursor-pointer" (click)="removeEntryFromCustomerSpecificDetails(item.key)">
              delete
            </mat-icon>
          </div>
          <div class="flex flex-col flex-1">
            <div class="text-gray-600">{{ item.key }}</div>
            <div>{{ item.value }}</div>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <div class="flex col-span-2 w-full items-center py-2 border-b border-gray-200 dark:border-gray-900 mt-2">
      <div class="text-gray-600 flex-1 pr-3">
        <mat-form-field class="w-full">
          <mat-label>New Key</mat-label>
          <input matInput [formControl]="customerSpecificDetailsFG.controls.key" [matAutocomplete]="auto" />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option
              *ngFor="let option of typeAheadOptions$ | async; trackBy: typeAheadOptions$ | trackByKey: null"
              [value]="option"
            >
              {{ option }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="flex-1 pr-1">
        <mat-form-field class="w-full">
          <mat-label>New Value</mat-label>
          <input matInput [formControl]="customerSpecificDetailsFG.controls.value" autocomplete="off" />
        </mat-form-field>
      </div>
      <button class="flex-shrink" mat-mini-fab color="primary" (click)="addEntryToCustomerSpecificDetails()">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </form>
  <div class="mt-2 flex justify-between">
    <div></div>
    <button mat-raised-button type="button" (click)="updateLoad()" color="primary">Update Load</button>
  </div>
</div>
