<div class="flex flex-col rounded bg-gray-200 dark:bg-slate-800 p-1">
  <ng-template #tooltipContent>
    <div *ngIf="companyAdmins?.length">
      <div class="flex flex-col">
        <div class="flex semi-bold my-1">Company Admins</div>
        <div *ngFor="let admin of companyAdmins; trackBy: companyAdmins | trackByKey: 'userId'">
          <div class="flex flex-col my-1">
            <div class="flex">{{ admin.userName }}</div>
            <a href="tel:{{ admin.userPhone }}" class="flex" tdRecordCall [driverId]="admin.userId">{{
              admin.userPhone | phone
            }}</a>
            <a *ngIf="admin.userEmail" href="mailto:{{ admin.userEmail }}">{{ admin.userEmail }}</a>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <div [tooltip]="tooltipContent" contentType="template" [options]="{ placement: 'bottom', hideDelay: 1000 }">
    <div class="flex flex-row">
      <div class="flex flex-col m-1">
        <mat-icon>apartment</mat-icon>
      </div>
      <div class="flex flex-col w-full">
        <a [routerLink]="companyId ? ['/', 'carrier_list', companyId] : []" class="flex text-blue-500"
          >{{ companyName }} →</a
        >
        <div class="flex flex-row">
          <div *ngIf="companyMCNumber" class="flex flex-col mr-4">MC-{{ companyMCNumber }}</div>
          <div *ngIf="companyDOTNumber" class="flex flex-col">DOT-{{ companyDOTNumber }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
