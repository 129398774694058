<ng-container *ngIf="(networkActiveCharge$ | async) || {} as networkActiveCharge">
  <div
    [ngClass]="{ 'last:border-0': hideLastBorder }"
    class="border-b"
    *ngFor="let charge of pendingCharges; trackBy: pendingCharges | trackByKey: 'id'"
  >
    <div
      *ngIf="charge.status === 'denied' && charge.decisionSupportingText"
      class="border border-red-400 rounded-lg p-3 mt-3"
    >
      <div>
        Denied by <span class="font-bold">{{ charge.decisionBy }}</span>
      </div>
      <div class="italic">{{ charge.decisionSupportingText }}</div>
    </div>
    <div class="flex flex-row items-center justify-between py-2 line" [class.line-through]="charge.status === 'denied'">
      <div class="flex items-center">
        <span class="font-semibold">{{ charge.chargeTypeName }}</span>
        <div class="ml-2 text-orange-600 font-semibold" *ngIf="!charge.status">Pending 5F Approval</div>
        <div class="relative flex items-center">
          <td-double-confirm
            buttonType="flat"
            buttonColor="warn"
            confirmText="Confirm"
            (confirm)="deletePendingCharge(charge)"
            *ngIf="canEditCharges === true && charge.status !== 'denied'"
            class="ml-2 absolute"
            style="transform: scale(0.75)"
          >
            <button mat-mini-fab color="warn">
              <mat-icon class="text-white">delete_outline</mat-icon>
            </button>
          </td-double-confirm>
        </div>
      </div>
      <div class="text-right">{{ charge.carrierTotalCents | currencyInPennies }}</div>
    </div>
    <mat-progress-bar mode="indeterminate" *ngIf="networkActiveCharge[charge.id]; else spacer"></mat-progress-bar>
    <ng-template #spacer>
      <div class="h-[4px]"></div>
    </ng-template>
    <div
      class="flex-1 flex flex-col border rounded-lg border-gray-400 dark:border-gray-700 p-3 bg-gray-200 dark:bg-gray-900 m-1 ml-2 lg:mt-1 lg:m-3 lg:ml-6"
      *ngIf="charge.chargeType === 'custom'"
    >
      <div class="flex justify-between">
        <div class="font-semibold text-lg">
          Uploads
          <span
            *ngIf="charge.requiresUpload && !charge.uploads?.length && charge.status !== 'denied'"
            class="flex text-red-500"
            >(Required)</span
          >
        </div>
        <div class="flex items-center px-2" *ngIf="charge.status !== 'denied'">
          <label *ngIf="canEditCharges === true" class="text-teal-500 p-1 w-full" matTooltip="Upload Image for Charge">
            <input class="hidden" (change)="onTourChargeEditFileChange(charge, $event)" type="file" />
            <div class="bg-blue-500 text-white rounded-full w-3 h-3 flex items-center justify-center">
              <mat-icon>add</mat-icon>
            </div>
          </label>
        </div>
      </div>
      <div class="flex flex-row flex-wrap">
        <div
          *ngFor="let upload of charge.uploads; trackBy: charge.uploads | trackByKey: 'id'"
          class="mr-4 relative max-w-1/5 lg:max-w-1/12"
        >
          <td-double-confirm
            buttonType="flat"
            buttonColor="warn"
            confirmText="Confirm"
            (confirm)="deleteCustomChargeFile(charge, upload.id)"
            *ngIf="canEditCharges === true && charge.status !== 'denied'"
            style="position: absolute; transform: scale(0.75); right: -20px"
          >
            <button mat-mini-fab color="warn">
              <mat-icon class="text-white">delete_outline</mat-icon>
            </button>
          </td-double-confirm>
          <div class="whitespace-normal break-words">
            <a [href]="upload.url" target="_blank">
              {{ upload.fileName }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
