import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'ordinal',
})
export class OrdinalPipe extends DecimalPipe implements PipeTransform {
  // @ts-ignore
  public transform(value: number): string {
    if (!value && value !== 0) {
      return '';
    }
    const formatted = super.transform(value, '1.0-0');
    if (value <= 0) {
      return formatted;
    }
    switch (value) {
      case 1:
        return `${formatted}st`;
      case 2:
        return `${formatted}nd`;
      case 3:
        return `${formatted}rd`;
      default:
        return `${formatted}th`;
    }
  }
}
