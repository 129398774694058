import { Pipe, PipeTransform } from '@angular/core';

const values: Record<string, string> = {
  unassigned: 'Unassigned',
  assigned: 'Assigned',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  on_hold: 'On Hold',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  awaiting_signature: 'Awaiting Signature',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  en_route: 'En Route',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  at_stop: 'At Stop',
  completed: 'Completed',
  cancelled: 'Cancelled',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  at_risk: 'At Risk',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  en_route_to_pickup: 'En Route to Pickup',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  on_track: 'On Track',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  at_yard: 'At Yard',
};

@Pipe({
  name: 'loadListStatus',
})
export class LoadListStatusPipe implements PipeTransform {
  public transform(value: string): string {
    return values[value] || value;
  }
}
