import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { WorkPhone, WorkPhoneService } from '../../services/work-phone.service';
import { FormBuilder } from '@angular/forms';
import { NetworkableDestroyableComponent } from '../networkable-destroyable.component';
import { ControlsOf } from '../../global-types';
import { SnackBarService } from '../../services/snackbar.service';

@Component({
  selector: 'td-update-work-phone',
  templateUrl: './update-work-phone.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateWorkPhoneComponent extends NetworkableDestroyableComponent {
  public form = this.fb.group<ControlsOf<WorkPhone>>({
    phoneNumber: this.fb.control<string>(null),
    phoneNumberExtension: this.fb.control<string | null>(null),
  });
  constructor(
    private wps: WorkPhoneService,
    private fb: FormBuilder,
    private ref: MatDialogRef<UpdateWorkPhoneComponent>,
    private snackbar: SnackBarService,
  ) {
    super();
    this.ref.addPanelClass('min-w-1/3');
    this.wps.workPhone$.subscribe((v) => {
      this.form.patchValue(v);
    });
  }

  public async updateWorkPhone() {
    if (this.isActive || this.form.invalid) {
      return;
    }
    try {
      this.isActive = true;
      const success = await this.wps.updateWorkPhone$(this.form.value as WorkPhone);
      if (success) {
        this.ref.close();
        this.snackbar.showMessage('Work Phone Updated');
      }
    } finally {
      this.isActive = false;
    }
  }
}
