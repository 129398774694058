import { ChangeDetectionStrategy, Component, Inject, Input, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { shareReplayComponentConfig } from '../../constants';

export interface GalleryImage {
  name: string;
  src: string;
}

@Component({
  selector: 'td-photo-gallery',
  templateUrl: './photo-gallery.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhotoGalleryComponent {
  @Input() public images: GalleryImage[] = [];
  private selectedImage$$ = new BehaviorSubject<GalleryImage | null>(null);
  public selectedImage$: Observable<GalleryImage | null> = this.selectedImage$$.pipe(
    shareReplay(shareReplayComponentConfig),
  );

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) data: { images: GalleryImage[] }) {
    if (data && data.images) {
      this.images = data.images;
      if (data.images.length) {
        this.selectedImage$$.next(data.images[0]);
      }
    }
  }

  public setSelectedImage(image: GalleryImage) {
    this.selectedImage$$.next(image);
  }
}
