import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'map',
})
export class MapPipe implements PipeTransform {
  public transform<T>(value: T[], property: keyof T): any[] {
    return value.map((item) => item[property]);
  }
}
