import { BehaviorSubject, catchError, combineLatestWith, from, Observable, of, Subject } from 'rxjs';
import { shareReplay, startWith, switchMap, tap, throttleTime } from 'rxjs/operators';

export class RouterFinn<V, K = string> {
  private networkActive$$ = new BehaviorSubject<boolean>(false);
  public networkActive$: Observable<boolean> = this.networkActive$$.pipe(shareReplay(1));
  private bSubject$$: BehaviorSubject<V>;
  private throttle$$ = new Subject<void>();
  private throttle$ = this.throttle$$.pipe(throttleTime(1000));
  private readonly shared$: Observable<V>;

  constructor(
    initValue: V = null,
    sourceValue$: Observable<K>,
    private loadFunc: (sourceValue: K) => Promise<V>,
    private loadOnRouteChange: boolean = true,
  ) {
    this.bSubject$$ = new BehaviorSubject<V>(initValue);
    this.shared$ = this.bSubject$$.pipe(shareReplay(1));
    const v$ = loadOnRouteChange
      ? sourceValue$.pipe(
          startWith(null),
          tap(() => {
            this.bSubject$$.next(initValue);
          }),
          combineLatestWith(this.throttle$.pipe(startWith(null))),
          switchMap(([sourceValue]) => {
            this.networkActive$$.next(true);
            if (!!sourceValue) {
              return from(this.loadFunc(sourceValue)).pipe(catchError(() => of(initValue)));
            } else {
              return of(initValue);
            }
          }),
        )
      : sourceValue$.pipe(
          startWith(null),
          tap(() => {
            this.bSubject$$.next(initValue);
          }),
          switchMap((value) => {
            return this.throttle$.pipe(
              switchMap(() => {
                this.networkActive$$.next(true);
                if (!!value) {
                  return from(this.loadFunc(value)).pipe(catchError(() => of(initValue)));
                } else {
                  return of(initValue);
                }
              }),
            );
          }),
        );

    v$.subscribe({
      next: (value) => {
        this.bSubject$$.next(value);
        this.networkActive$$.next(false);
      },
      error: (err) => {
        this.networkActive$$.next(false);
      },
    });
  }

  public get$(): Observable<V> {
    if (!this.loadOnRouteChange) {
      this.refresh();
    }
    return this.shared$;
  }

  public next(value: V) {
    this.bSubject$$.next(value);
  }

  public refresh() {
    this.throttle$$.next();
  }
}
