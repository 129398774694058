import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
} from '@angular/core';
import { EquipmentType, FacilityListItem, LohiLoad, LohiLoadStop, Unit } from '../../global-types';
import { FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { NetworkActiveComponent } from '../networkActive.component';
import { SnackBarService } from '../../services/snackbar.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ConstantsService } from '../../services/constants.service';
import { EditLoadRequest, LoadService } from '../../../modules/internal/loads/load.service';
import { add } from 'date-fns';
import { FusedObservable } from '../../utilities/fusedObservable';
import { FacilitiesService } from '../../../modules/internal/facilities/facilities.service';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'td-edit-load-details',
  templateUrl: './edit-load-details.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditLoadDetailsComponent extends NetworkActiveComponent {
  public loadID: string;
  public equipment$: Observable<EquipmentType[]>;
  public units$: Observable<Unit[]>;
  public editLoadForm: UntypedFormGroup;
  public disableRateAndShipper = false;
  public isDrayage: boolean;
  public specialHandling: string;
  public shippingLines$ = this.constantsService.steamshipLines$;
  public facilities$: Observable<FacilityListItem[]>;
  public filteredEmptyContainerFacilities$: Observable<FacilityListItem[]>;
  public emptyContainerFacilitySearch = new FormControl<string>(null);
  public filteredLoadingFacilities$: Observable<FacilityListItem[]>;
  public loadingFacilitySearch = new FormControl<string>(null);
  public filteredPortRailFacilities$: Observable<FacilityListItem[]>;
  public portRailFacilitySearch = new FormControl<string>(null);
  public dupedFields: boolean;
  public customerSpecificDetailsFG = new FormGroup({
    key: new FormControl(''),
    value: new FormControl(''),
  });
  public customerSpecificDetails$: Observable<{ key: string; value: any }[]>;
  public typeAheadOptions$ = new FusedObservable(
    of(['coilNumbers']),
    this.customerSpecificDetailsFG.controls.key.valueChanges.pipe(startWith('')),
    [],
  ).fused$;

  @Input()
  public set load(l: LohiLoad) {
    if (!l || this.editLoadForm) {
      return;
    }
    this.loadID = l.id;
    this.isDrayage = l.isDrayage;
    this.specialHandling = l.specialHandling;
    this.dupedFields = l.enableDupeDrayageFields;
    this.setupFacilities(l);
    this.setupForm(l);
  }

  @Output() public wasUpdated = new EventEmitter<boolean>();

  constructor(
    private loadService: LoadService,
    private fb: UntypedFormBuilder,
    private constantsService: ConstantsService,
    private cd: ChangeDetectorRef,
    private snackbar: SnackBarService,
    private facilitiesService: FacilitiesService,
    @Optional() private dRef: MatDialogRef<EditLoadDetailsComponent>,
  ) {
    super();
    this.equipment$ = this.constantsService.equipments$;
    this.units$ = this.constantsService.units$;
  }

  public async updateLoad() {
    if (this.networkActive$$.value) {
      return;
    }

    if (this.editLoadForm.invalid) {
      this.editLoadForm.markAllAsTouched();
      return;
    }

    try {
      this.networkActive$$.next(true);
      const formValue = this.editLoadForm.value as EditLoadRequest;
      const success = await this.loadService.editLoadDetails(this.loadID, formValue);
      if (success) {
        this.snackbar.showMessage('Load updated');
        this.wasUpdated.emit(true);
        this.dRef?.close(true);
      }
    } finally {
      this.networkActive$$.next(false);
    }
  }

  private setupForm(load: LohiLoad) {
    let containerPickup: LohiLoadStop, pickup: LohiLoadStop, dropoff: LohiLoadStop;
    if (load.stops[0]?.type === 'empty_asset_pickup') {
      containerPickup = load.stops[0];
    }
    pickup = load.stops.at(-2);
    dropoff = load.stops.at(-1);

    let containerPickupAppointmentEnd: string, pickupAppointmentEnd: string, dropoffAppointmentEnd: string;
    if (containerPickup?.arrivalWindowMinutes && containerPickup?.arrivalWindowMinutes) {
      containerPickupAppointmentEnd = add(new Date(containerPickup.arrivalWindowStartsAt), {
        minutes: Math.max(containerPickup.arrivalWindowMinutes, 120),
      }).toISOString();
    }
    if (pickup?.arrivalWindowMinutes && pickup?.arrivalWindowMinutes) {
      pickupAppointmentEnd = add(new Date(pickup.arrivalWindowStartsAt), {
        minutes: Math.max(pickup.arrivalWindowMinutes, 120),
      }).toISOString();
    }
    if (dropoff?.arrivalWindowMinutes && dropoff?.arrivalWindowMinutes) {
      dropoffAppointmentEnd = add(new Date(dropoff.arrivalWindowStartsAt), {
        minutes: Math.max(dropoff.arrivalWindowMinutes, 120),
      }).toISOString();
    }

    const formDetails: Record<keyof EditLoadRequest, any> = {
      customerLoadNumber: [load.customerLoadNumber, []],
      shipper: [load.shipper, []],
      brokerEmailId: [load.brokerEmailId, []],
      temperature: [load.tempFahrenheit, []],
      weightPounds: [load.weightPounds, []],
      rateCents: [load.rateCents, [Validators.required]],
      cargoValueCents: [load.cargoValueCents, []],
      shippingComments: [load.shippingComments, []],
      furtherDetails: [null, []],
      brokerNotificationMinutes: [load.brokerNotificationMinutes, [Validators.required, Validators.min(0)]],
      referenceNumber: [load.referenceNumber, []],
      detentionRateCents: [load.detentionRateCents, [Validators.required, Validators.min(0)]],
      possibleTrailerTypesIds: [load.possibleTrailerTypes.map((e) => e.id), [Validators.required]],
      containerNumber: [load.containerNumber, []],
      containerNumber2: [load.containerNumber2, []],
      sealNumber: [load.sealNumber, []],
      vesselNumber: [load.vesselNumber, []],
      bookingNumber: [load.bookingNumber, []],
      bookingNumber2: [load.bookingNumber2, []],
      shippingLine: [load.shippingLine, []],
      shippingLine2: [load.shippingLine2, []],
      railBillingConfirmationNumber: [load.railBillingConfirmationNumber, []],
      reservationConfirmationNumber: [load.reservationConfirmationNumber, []],
      useAlmChassis: [load.useAlmChassis, []],
      chassisNumber: [load.chassisNumber, []],
      earliestPickupAt: [load.earliestPickupAt, []],
      lastFreePickupAt: [load.lastFreePickupAt, []],
      earliestReceiptTime: [load.earliestReceiptTime, []],
      documentationCutoffTime: [load.documentationCutoffTime, []],
      portCutoffTime: [load.portCutoffTime, []],
      rampCutoffTime: [load.rampCutoffTime, []],
      commodity: [load.stops.at(-1)?.commodity, []],
      unitId: [load.stops.at(-1)?.unit?.id, []],
      quantity: [load.stops.at(-1)?.quantity, []],
      containerPickupLocation: [containerPickup?.facilityId, []],
      containerAppointmentStart: [containerPickup?.arrivalWindowStartsAt, []],
      containerAppointmentEnd: [containerPickupAppointmentEnd, []],
      pickupLocation: [pickup?.facilityId, []],
      pickupAppointmentStart: [pickup?.arrivalWindowStartsAt, []],
      pickupAppointmentEnd: [pickupAppointmentEnd, []],
      earliestPickupTime: [load.earliestPickupAt, []],
      latestPickupTime: [load.latestPickupAt, []],
      dropoffLocation: [dropoff?.facilityId, []],
      dropoffAppointmentStart: [dropoff?.arrivalWindowStartsAt, []],
      dropoffAppointmentEnd: [dropoffAppointmentEnd, []],
      isEmptyTrailerMove: [load.isEmptyTrailerMove, []],
      customerSpecificDetails: [load.customerSpecificDetails, []],
    };
    this.editLoadForm = this.fb.group(formDetails);
    this.cd.markForCheck();
    this.listenToDetailChanges();
  }

  private listenToDetailChanges() {
    this.customerSpecificDetails$ = this.editLoadForm.get('customerSpecificDetails').valueChanges.pipe(
      startWith(this.editLoadForm.get('customerSpecificDetails').value),
      map((deets) => {
        const keys = Object.keys(deets);
        if (!keys.length) {
          return [];
        }
        const response: { key: string; value: any }[] = [];
        keys.sort((a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()));
        for (let key of keys) {
          let value = deets[key];
          if (Array.isArray(value)) {
            value = value.join(', ');
          }
          response.push({
            key,
            value: value,
          });
        }
        return response;
      }),
    );
    this.editLoadForm.get('customerSpecificDetails').valueChanges.subscribe((value) => {
      if (value.length > 1) {
        this.editLoadForm.get('possibleTrailerTypesIds').setErrors({ multipleTrailers: true });
      } else {
        this.editLoadForm.get('possibleTrailerTypesIds').setErrors(null);
      }
    });
  }

  public addEntryToCustomerSpecificDetails() {
    const valueToAdd = this.customerSpecificDetailsFG.value;
    const currentValue = {
      ...this.editLoadForm.get('customerSpecificDetails').value,
    };
    const keyExists = Object.keys(currentValue).includes(valueToAdd.key);
    if (keyExists) {
      const value = currentValue[valueToAdd.key];
      if (Array.isArray(value)) {
        value.push(valueToAdd.value);
      } else {
        currentValue[valueToAdd.key] = [value, valueToAdd.value];
      }
    } else {
      currentValue[valueToAdd.key] = valueToAdd.value;
    }
    this.editLoadForm.get('customerSpecificDetails').setValue(currentValue);
    this.customerSpecificDetailsFG.reset();
  }

  public removeEntryFromCustomerSpecificDetails(key: string) {
    const currentValue = {
      ...this.editLoadForm.get('customerSpecificDetails').value,
    };
    delete currentValue[key];
    this.editLoadForm.get('customerSpecificDetails').setValue(currentValue);
  }

  private setupFacilities(load: LohiLoad) {
    this.facilities$ = this.facilitiesService.loadFacilities(load.shipperId);
    this.filteredEmptyContainerFacilities$ = new FusedObservable(
      this.facilities$,
      this.emptyContainerFacilitySearch.valueChanges,
      ['name'],
    ).fused$;
    this.filteredLoadingFacilities$ = new FusedObservable(this.facilities$, this.loadingFacilitySearch.valueChanges, [
      'name',
    ]).fused$;
    this.filteredPortRailFacilities$ = new FusedObservable(this.facilities$, this.portRailFacilitySearch.valueChanges, [
      'name',
    ]).fused$;
  }
}
