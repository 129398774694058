import { Injectable } from '@angular/core';
import { SimpleFinn } from '../utilities/simpleFinn';
import { Observable } from 'rxjs';

export type SidenavType = 'internal' | 'franchise' | 'carrier';

const SIDENAV_LOCAL_STORAGE = 'SIDENAV_LOCAL_STORAGE';

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  private sidenavType: SimpleFinn<SidenavType>;

  public get sidenavType$(): Observable<SidenavType> {
    return this.sidenavType.get$();
  }

  constructor() {
    this.sidenavType = new SimpleFinn<SidenavType>(null, this.loadSidenavType);
  }

  private loadSidenavType = async (): Promise<SidenavType> => {
    return (localStorage.getItem(SIDENAV_LOCAL_STORAGE) || 'carrier') as SidenavType;
  };

  public setSidenavType = async (type: SidenavType) => {
    localStorage.setItem(SIDENAV_LOCAL_STORAGE, type);
    this.sidenavType.next(type);
  };
}
