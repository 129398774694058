import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { map, shareReplay } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { SimpleFinn } from '../../../shared/utilities/simpleFinn';
import {
  CarrierContact,
  CarrierContacts,
  CarrierLanesOfInterest,
  LaneOfInterest,
} from 'src/app/modules/internal/carrier-list/carrier-list.service';
import { CarrierTag, CarrierTouchpoint, CarrierTouchpoints, CompanyCarrierTag } from '../../global-types';

@Injectable({
  providedIn: 'root',
})
export class CarrierTouchpointService {
  private carrierId$$ = new BehaviorSubject<string>(null);

  private carrierLanesOfInterest$$ = new BehaviorSubject<LaneOfInterest[]>([]);
  public carrierLanesOfInterest$: Observable<LaneOfInterest[]> = this.carrierLanesOfInterest$$.pipe(shareReplay(1));

  private carrierTouchpoints$$ = new BehaviorSubject<CarrierTouchpoint[]>([]);
  public carrierTouchpoints$: Observable<CarrierTouchpoint[]> = this.carrierTouchpoints$$.pipe(shareReplay(1));

  private carrierContacts$$ = new BehaviorSubject<CarrierContact[]>([]);
  public carrierContacts$: Observable<CarrierContact[]> = this.carrierContacts$$.pipe(shareReplay(1));

  private carrierTags$$ = new BehaviorSubject<CompanyCarrierTag[]>([]);
  public carrierTags$: Observable<CompanyCarrierTag[]> = this.carrierTags$$.pipe(shareReplay(1));

  private allCarrierTags$$: SimpleFinn<CarrierTag[]>;
  public get allCarrierTags$(): Observable<CarrierTag[]> {
    return this.allCarrierTags$$.get$();
  }

  constructor(private http: HttpClient) {
    this.carrierId$$.subscribe((carrierID) => {
      this.refreshCarrierLanesOfInterest(carrierID);
      this.refreshCarrierTouchpoints(carrierID);
      this.refreshCarrierContacts(carrierID);
      this.refreshCarrierTags(carrierID);
    });
    this.allCarrierTags$$ = new SimpleFinn<CarrierTag[]>([], this.loadAllCarrierTags);
  }

  public setCarrierID(carrierID: string) {
    this.carrierId$$.next(carrierID);
  }

  private async refreshCarrierLanesOfInterest(carrierId: string) {
    const lanesOfInterest = await lastValueFrom(this.getCarrierLanesOfInterest(carrierId));
    this.carrierLanesOfInterest$$.next(lanesOfInterest?.carrierLanesOfInterest || []);
  }

  private getCarrierLanesOfInterest = (carrierId: string): Observable<CarrierLanesOfInterest> => {
    return this.http.get<CarrierLanesOfInterest>(
      `${environment.api}/v2/max_payloop/dispatcher/carrier_detail/${carrierId}/lanes_of_interest`,
    );
  };

  public async updateCarrierLanesOfInterest(carrierId: string, lanesOfInterest: LaneOfInterest[]): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.put(`${environment.api}/v2/max_payloop/dispatcher/carrier_detail/${carrierId}/lanes_of_interest`, {
          lanesOfInterest,
        }),
      );
      await this.refreshCarrierLanesOfInterest(carrierId);
      return true;
    } catch (e) {
      return false;
    }
  }

  private async refreshCarrierTouchpoints(carrierId: string) {
    const touchpoints = await lastValueFrom(this.getCarrierTouchpoints(carrierId));
    this.carrierTouchpoints$$.next(touchpoints?.carrierTouchpoints || []);
  }

  private getCarrierTouchpoints = (carrierId: string): Observable<CarrierTouchpoints> => {
    return this.http.get<CarrierTouchpoints>(
      `${environment.api}/v2/max_payloop/dispatcher/carrier_detail/${carrierId}/touchpoints`,
    );
  };

  public async createCarrierTouchpoint(touchpoint: CarrierTouchpoint): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.post(
          `${environment.api}/v2/max_payloop/dispatcher/carrier_detail/${this.carrierId$$.value}/touchpoints`,
          touchpoint,
        ),
      );
      await this.refreshCarrierTouchpoints(this.carrierId$$.value);
      return true;
    } catch (e) {
      return false;
    }
  }

  public async updateCarrierTouchpoint(touchpoint: CarrierTouchpoint): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.put(
          `${environment.api}/v2/max_payloop/dispatcher/carrier_detail/${this.carrierId$$.value}/touchpoints`,
          touchpoint,
        ),
      );
      await this.refreshCarrierTouchpoints(this.carrierId$$.value);
      return true;
    } catch (e) {
      return false;
    }
  }

  private async refreshCarrierContacts(carrierId: string) {
    const carrierContacts = await lastValueFrom(this.getCarrierContacts(carrierId));
    this.carrierContacts$$.next(carrierContacts?.carrierContacts);
  }

  private getCarrierContacts = (carrierId: string): Observable<CarrierContacts> => {
    return this.http.get<CarrierContacts>(
      `${environment.api}/v2/max_payloop/dispatcher/carrier_detail/${carrierId}/contacts`,
    );
  };

  private async refreshCarrierTags(carrierId: string) {
    const carrierTags = await lastValueFrom(this.getCarrierTags(carrierId));
    this.carrierTags$$.next(carrierTags);
  }

  private getCarrierTags = (carrierId: string): Observable<CompanyCarrierTag[]> => {
    return this.http
      .get<{ tags: CompanyCarrierTag[] }>(
        `${environment.api}/v2/max_payloop/dispatcher/carrier_detail/${carrierId}/tags`,
      )
      .pipe(map((response) => response.tags || []));
  };

  private loadAllCarrierTags = async (): Promise<CarrierTag[]> => {
    try {
      return await lastValueFrom(
        this.http
          .get<{ tags: CarrierTag[] }>(`${environment.api}/v2/max_payloop/dispatcher/carrier_tags`)
          .pipe(map((response) => response.tags || [])),
      );
    } catch (e) {
      return null;
    }
  };

  public async updateCarrierTags(tags: number[]): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.put(`${environment.api}/v2/max_payloop/dispatcher/carrier_detail/${this.carrierId$$.value}/tags`, {
          tags,
        }),
      );
      await this.refreshCarrierTags(this.carrierId$$.value);
      return true;
    } catch (e) {
      return false;
    }
  }
}
