<div class="!-m-[24px] !p-0 w-[80vw]">
  <div *ngIf="!carrierSelected" class="p-2 flex flex-col items-end gap-2">
    <mat-form-field class="w-full">
      <mat-label>Carrier</mat-label>
      <mat-select #carrierSelect [formControl]="carrierControl" (keyup.enter)="onEnterCarrierSelect()">
        <mat-option>
          <ngx-mat-select-search
            placeholderLabel="Search"
            noEntriesFoundLabel="No match found"
            [formControl]="carrierSearch"
          ></ngx-mat-select-search>
        </mat-option>
        <mat-option
          [value]="carrier"
          *ngFor="let carrier of filteredCarriers$ | async; trackBy: filteredCarriers$ | trackByKey: 'id'"
        >
          {{ carrier.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-flat-button [disabled]="!carrierControl?.value?.id" (click)="carrierSelected = true">
      Select Carrier
    </button>
  </div>
  <div *ngIf="carrierSelected" class="h-[80vh] flex flex-row">
    <div class="grow-[2] basis-2 p-3 flex flex-col gap-2" *ngIf="carrierControl.value as carrier">
      <div class="text-2xl font-semibold">{{ carrier.name }}</div>
      <div class="overflow-y-auto overflow-x-hidden flex flex-col gap-3 grow">
        <div class="grid grid-cols-3 gap-2">
          <div class="flex flex-col">
            <div class="text-sm text-gray-600">DOT Number</div>
            {{ carrier.dotNumber | dashIfNothing }}
          </div>
          <div class="flex flex-col">
            <div class="text-sm text-gray-600">MC Number</div>
            {{ carrier.mcNumber | dashIfNothing }}
          </div>
          <div class="flex flex-col">
            <div class="text-sm text-gray-600">Date Started</div>
            {{ carrier.createdAt | standardDateOnly }}
          </div>
          <div class="flex flex-col">
            <div class="text-sm text-gray-600">Loads Completed</div>
            {{ carrier.totalCompleted | number | dashIfNothing }}
          </div>
          <div class="flex flex-col">
            <div class="text-sm text-gray-600">Loads Completed (Past 30d)</div>
            {{ carrier.totalCompletedLastThirtyDays | number | dashIfNothing }}
          </div>
          <div class="flex flex-col">
            <div class="text-sm text-gray-600">Last Completed Load</div>
            {{ carrier.lastDeliveryAt | standardDate | dashIfNothing }}
          </div>
          <div class="flex flex-col">
            <div class="text-sm text-gray-600">Type</div>
            {{ carrier.types | joinWithAnd }}
          </div>
          <div class="flex flex-col">
            <div class="text-sm text-gray-600">Active</div>
            {{ carrier.isActive ? 'Yes' : 'No' }}
          </div>
          <div><!--placeholder--></div>
          <ng-container *ngIf="carrier.id.startsWith('LCO')">
            <div class="flex flex-col">
              <div class="flex flex-row gap-2 text-sm">
                <div class="text-gray-600">Point of Contact</div>
                <a (click)="promptReassignCarrierPointOfContact(carrier)" class="cursor-pointer text-blue-500">
                  Edit
                </a>
              </div>
              <div class="flex flex-col justify-between">
                <div>{{ carrier.carrierPointOfContactName | dashIfNothing }}</div>
                <div>{{ carrier.carrierPointOfContactPhone | phone }}</div>
                <div>{{ carrier.carrierPointOfContactEmail | dashIfNothing }}</div>
              </div>
            </div>
          </ng-container>
          <div class="flex flex-col">
            <div class="flex flex-row gap-2 text-sm">
              <div class="text-gray-600">Carrier Representative</div>
              <a (click)="promptReassignCarrierRepresentative(carrier)" class="cursor-pointer text-blue-500"> Edit </a>
            </div>
            <div>{{ carrier.carrierRepresentativeName | dashIfNothing }}</div>
          </div>
        </div>
        <div class="mt-1">
          <div class="text-xl font-semibold my-1">Ports</div>
          <mat-form-field *ngIf="carrierTags$ | async as carrierTags" class="w-full">
            <mat-label></mat-label>
            <mat-chip-grid #chipList>
              <mat-chip-row
                *ngFor="let tag of carrierTags; trackBy: carrierTags | trackByKey: 'carrierTagId'"
                (removed)="removeTag(tag, carrierTags)"
              >
                {{ tag.carrierTagName }}
                <button matChipRemove>
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip-row>
              <input
                placeholder="New tag..."
                #tagInput
                [formControl]="tagSearchControl"
                [matAutocomplete]="auto"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              />
            </mat-chip-grid>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addTag($event, carrierTags, tagInput)">
              <mat-option
                *ngFor="let tag of filteredCarrierTags$ | async; trackBy: filteredCarrierTags$ | trackByKey: 'id'"
                [value]="tag"
              >
                {{ tag.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="flex flex-col">
          <div class="text-xl font-semibold my-1">Assets</div>
          <div [formGroup]="assetsForm" class="gap-2 grid grid-cols-2 2xl:grid-cols-5 my-1">
            <mat-form-field>
              <mat-label>Truck Count</mat-label>
              <input formControlName="truckCount" tdNumberInput matInput autocomplete="off" />
              <mat-hint *ngIf="carrier.truckCountUpdatedAt">
                Last Updated {{ carrier.truckCountUpdatedAt | standardDate }}
              </mat-hint>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Intermodal Chassis Count</mat-label>
              <input formControlName="intermodalChassisCount" tdNumberInput matInput autocomplete="off" />
              <mat-hint *ngIf="carrier.intermodalChassisCountUpdatedAt">
                Last Updated {{ carrier.intermodalChassisCountUpdatedAt | standardDate }}
              </mat-hint>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Owns Yard?</mat-label>
              <mat-select formControlName="ownsYard">
                <mat-option [value]="true">Yes</mat-option>
                <mat-option [value]="false">No</mat-option>
                <mat-option [value]="null">I Don't Know</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Owns Lift?</mat-label>
              <mat-select formControlName="ownsLift">
                <mat-option [value]="true">Yes</mat-option>
                <mat-option [value]="false">No</mat-option>
                <mat-option [value]="null">I Don't Know</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Owns Warehouse?</mat-label>
              <mat-select formControlName="ownsWarehouse">
                <mat-option [value]="true">Yes</mat-option>
                <mat-option [value]="false">No</mat-option>
                <mat-option [value]="null">I Don't Know</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <button
            class="!mt-2"
            mat-flat-button
            color="primary"
            *ngIf="assetsForm.dirty"
            [disabled]="assetsForm.invalid"
            (click)="updateAssets()"
          >
            Update Assets
          </button>
        </div>
        <div class="flex flex-col mt-1">
          <div class="text-xl font-semibold my-1">Lanes of Interest</div>
          <div [formGroup]="loiForm" class="flex flex-col gap-1 grow">
            <div class="grow flex flex-col gap-2">
              <div *ngIf="!loiForm.controls.lanesOfInterest.controls?.length">No Lanes of Interest</div>
              <div
                class="grid grid-cols-1 lg:grid-cols-2 gap-2 lg:col-span-2"
                *ngFor="
                  let laneGroup of loiForm.controls.lanesOfInterest.controls;
                  let i = index;
                  trackBy: loiForm.controls.lanesOfInterest.controls | trackByKey: null
                "
                [formGroup]="laneGroup"
              >
                <div class="lg:col-span-2 text-lg font-semibold -mb-1 flex flex-row gap-2 justify-between items-center">
                  <div>
                    <ng-container *ngIf="laneGroup.controls.originLngLat.value; else emdash">
                      {{ laneGroup.controls.originCity.value
                      }}<ng-container *ngIf="laneGroup.controls.originCity.value">,</ng-container>
                      {{ laneGroup.controls.originState.value }}
                    </ng-container>
                    <mat-icon inline>arrow_forward</mat-icon>
                    <ng-container *ngIf="laneGroup.controls.destinationLngLat.value; else emdash">
                      {{ laneGroup.controls.destinationCity.value
                      }}<ng-container *ngIf="laneGroup.controls.destinationCity.value">,</ng-container>
                      {{ laneGroup.controls.destinationState.value }}
                    </ng-container>
                    <ng-container *ngIf="laneGroup.dirty">(Unsaved changes)</ng-container>
                    <button mat-icon-button color="warn" (click)="removeLane(i)">
                      <mat-icon>close</mat-icon>
                    </button>
                  </div>
                  <mat-icon
                    tooltip="Carrier confirmed in lane"
                    placement="left"
                    *ngIf="laneGroup.controls.confirmedCarrier.value"
                    >star</mat-icon
                  >
                  <ng-template #emdash>—</ng-template>
                </div>
                <ng-container *ngIf="!laneGroup.controls.id.value">
                  <div>
                    <td-address-search
                      (addressResult)="setOrigin(laneGroup, $event)"
                      label="Origin *"
                      [invalid]="laneGroup.controls.originLngLat.invalid"
                    ></td-address-search>
                    <div class="h-4">
                      <ng-container *ngIf="laneGroup.controls.originLngLat.value">
                        {{ laneGroup.controls.originCity.value
                        }}<ng-container *ngIf="laneGroup.controls.originCity.value">,</ng-container>
                        {{ laneGroup.controls.originState.value }}
                      </ng-container>
                    </div>
                  </div>
                  <div>
                    <td-address-search
                      (addressResult)="setDestination(laneGroup, $event)"
                      label="Destination *"
                      [invalid]="laneGroup.controls.originLngLat.invalid"
                    ></td-address-search>
                    <div class="h-4">
                      <ng-container *ngIf="laneGroup.controls.destinationLngLat.value">
                        {{ laneGroup.controls.destinationCity.value
                        }}<ng-container *ngIf="laneGroup.controls.destinationCity.value">,</ng-container>
                        {{ laneGroup.controls.destinationState.value }}
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
                <mat-form-field>
                  <mat-label>Trailer Types</mat-label>
                  <mat-select formControlName="trailerTypes" [multiple]="true">
                    <mat-option
                      *ngFor="let trailer of equipment$ | async; trackBy: equipment$ | trackByKey: 'value'"
                      [value]="trailer.value"
                    >
                      {{ trailer.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Minimum Rate</mat-label>
                  <input matInput formControlName="desiredRateCents" autocomplete="off" tdCurrencyInput />
                </mat-form-field>
              </div>
            </div>
            <button mat-stroked-button (click)="addLane()">New Lane</button>
            <button
              mat-flat-button
              color="primary"
              *ngIf="loiForm.dirty"
              [disabled]="loiForm.invalid"
              (click)="updateLanes()"
            >
              Save Lanes
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="grow-[1] basis-1 bg-gray-200 dark:bg-gray-900 p-3 flex flex-col gap-2">
      <div class="text-2xl font-semibold">Contact Record</div>
      <ng-container *ngIf="pinnedCarrierTouchpoints$ | async as pinnedCarrierTouchpoints">
        <div
          *ngIf="pinnedCarrierTouchpoints?.length"
          class="card-with-shadow-white-no-radius p-2 min-h-[275px] flex flex-col gap-3"
        >
          <div class="text-xl">{{ pinnedCarrierTouchpoints.length }} Pinned (3 max)</div>
          <div class="flex flex-col gap-3 overflow-auto -mt-2 pt-2">
            <div
              *ngFor="let touchpoint of pinnedCarrierTouchpoints; trackBy: pinnedCarrierTouchpoints | trackByKey: 'id'"
              class="flex flex-row gap-2"
            >
              <div
                class="rounded-full bg-yellow-500 text-white flex justify-center items-center w-[32px] h-[32px] -mt-[5px] shrink-0"
              >
                <mat-icon class="!w-2 !h-2 text-[16px]">{{
                  touchpoint.carrierTouchpointType === 'phone' ? 'call' : 'mail'
                }}</mat-icon>
              </div>
              <div class="grow">
                <div>
                  {{ touchpoint.createdBy | dashIfNothing }}
                  {{ touchpoint.carrierTouchpointType === 'phone' ? 'called' : 'emailed' }}
                  on {{ touchpoint.createdAt | standardDate }}
                </div>
                <div class="group text-white rounded w-full p-2 mt-1 flex flex-row justify-between bg-yellow-500">
                  <div>{{ touchpoint.notes | dashIfNothing }}</div>
                  <div class="hidden group-hover:block w-0 h-0 overflow-visible">
                    <button
                      mat-icon-button
                      (click)="toggleTouchpointPin(touchpoint)"
                      class="!w-[36px] !h-[36px] !ml-[-28px] !mt-[-8px]"
                      matTooltip="Un-pin"
                    >
                      <mat-icon inline class="text-white text-lg">push_pin</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="grow overflow-auto p-1 pl-0 flex flex-col gap-3">
        <ng-container *ngIf="carrierTouchpoints$ | async as carrierTouchpoints">
          <ng-container *ngIf="carrierTouchpoints?.length; else noTouchpoints">
            <div
              *ngFor="let touchpoint of carrierTouchpoints; trackBy: carrierTouchpoints | trackByKey: 'id'"
              class="flex flex-row gap-2"
            >
              <div
                class="rounded-full text-white flex justify-center items-center w-[32px] h-[32px] -mt-[5px] shrink-0"
                [ngClass]="touchpoint.pinned ? 'bg-yellow-500' : 'bg-blue-500'"
              >
                <mat-icon class="!w-2 !h-2 text-[16px]">{{
                  touchpoint.carrierTouchpointType === 'phone' ? 'call' : 'mail'
                }}</mat-icon>
              </div>
              <div class="grow">
                <div>
                  {{ touchpoint.createdBy | dashIfNothing }}
                  {{ touchpoint.carrierTouchpointType === 'phone' ? 'called' : 'emailed' }}
                  on {{ touchpoint.createdAt | standardDate }}
                </div>
                <div
                  class="group text-white rounded w-full p-2 mt-1 flex flex-row justify-between"
                  [ngClass]="touchpoint.pinned ? 'bg-yellow-500' : 'bg-blue-500'"
                >
                  <div>{{ touchpoint.notes | dashIfNothing }}</div>
                  <div class="hidden group-hover:block w-0 h-0 overflow-visible">
                    <button
                      *ngIf="touchpoint.pinned || (pinnedCarrierTouchpoints$ | async)?.length < 3"
                      mat-icon-button
                      (click)="toggleTouchpointPin(touchpoint)"
                      class="!w-[36px] !h-[36px] !ml-[-28px] !mt-[-8px]"
                      [matTooltip]="touchpoint.pinned ? 'Un-pin' : 'Pin'"
                    >
                      <mat-icon inline class="text-white text-lg">push_pin</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #noTouchpoints>
            <div>No touchpoints yet for this carrier</div>
          </ng-template>
        </ng-container>
      </div>
      <form
        class="flex flex-row gap-2 justify-between items-end"
        [formGroup]="touchpointForm"
        (ngSubmit)="saveTouchpoint()"
      >
        <mat-form-field class="w-[40px]">
          <mat-label>Type</mat-label>
          <mat-select formControlName="carrierTouchpointType">
            <mat-select-trigger>
              <mat-icon inline *ngIf="touchpointForm.controls.carrierTouchpointType.value === 'phone'">call</mat-icon>
              <mat-icon inline *ngIf="touchpointForm.controls.carrierTouchpointType.value === 'email'">mail</mat-icon>
            </mat-select-trigger>
            <mat-option [value]="'phone'"><mat-icon inline>call</mat-icon></mat-option>
            <mat-option [value]="'email'"><mat-icon inline>mail</mat-icon></mat-option>
          </mat-select>
        </mat-form-field>
        <div class="grow">
          <div>{{ prompt$ | async }}</div>
          <mat-form-field class="w-full">
            <mat-label>Notes</mat-label>
            <textarea
              matInput
              formControlName="notes"
              autocomplete="off"
              #notesInput="matInput"
              cdkTextareaAutosize
            ></textarea>
          </mat-form-field>
        </div>
        <button mat-mini-fab color="primary" class="flex justify-center items-center !mb-2">
          <mat-icon inline class="text-white !text-[20px] ml-[5px]">send</mat-icon>
        </button>
      </form>
    </div>
  </div>
</div>
