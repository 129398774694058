import { Injectable } from '@angular/core';
import { SimpleFinn } from '../utilities/simpleFinn';
import { catchError, lastValueFrom, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

export interface Customer {
  id: string;
  name: string;
  type: 'shipper' | 'broker' | 'agent';
  brokerage: string;
  billingContactName: string | null;
  billingContactPhone: string | null;
  billingContactEmail: string | null;
  billingContactBillingInstructions: string | null;
  notifyOfDetentionMinutes: number;
  detentionCents: number;
  detentionStartsAfterMinutes: number;
}

@Injectable({
  providedIn: 'root',
})
export class CustomersService {
  private customers: SimpleFinn<Customer[]>;

  public get customers$(): Observable<Customer[]> {
    return this.customers.get$();
  }

  public get customersExcludingAgents$(): Observable<Customer[]> {
    return this.customers.get$().pipe(map((value) => value.filter((value1) => value1.type !== 'agent')));
  }

  constructor(private http: HttpClient) {
    this.customers = new SimpleFinn<Customer[]>([], this.loadCustomers);
  }

  private loadCustomers = (): Promise<Customer[]> => {
    return lastValueFrom(
      this.http
        .get<{ companies: Customer[] }>(`${environment.api}/v1/external/broker_portal/customers`)
        .pipe(map((v) => v.companies || [])),
    );
  };

  public async createCustomer(value: Customer): Promise<string> {
    try {
      const res = await lastValueFrom(
        this.http.post<{ id: string }>(`${environment.api}/v1/external/broker_portal/customers/create_customer`, value),
      );
      this.customers.get$();
      return res.id;
    } catch (e) {
      return null;
    }
  }

  public searchCustomersByEmail$(email: string) {
    if (!email) {
      return of([]);
    }
    return this.http
      .get<{ companies: Customer[] }>(
        `${environment.api}/v1/external/broker_portal/internal/customers/search_by_email`,
        {
          params: {
            email,
          },
        },
      )
      .pipe(
        catchError(() => {
          return of({ companies: [] });
        }),
        map((v) => v?.companies ?? []),
      );
  }
}
