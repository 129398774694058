import { Pipe, PipeTransform } from '@angular/core';
import { LoadingType } from '../global-types';

const mapped: Record<LoadingType, string> = {
  [LoadingType.live]: 'Live',
  [LoadingType.drop]: 'Drop',
};

@Pipe({
  name: 'loadingType',
})
export class LoadingTypePipe implements PipeTransform {
  public transform(value: LoadingType): string {
    return mapped[value] || value;
  }
}
