<div mat-dialog-title>Cancel Load {{ loadID }}</div>
<div mat-dialog-content [formGroup]="form">
  Are you sure you want to cancel this load?
  <mat-form-field class="w-full">
    <mat-label>Select a Cause</mat-label>
    <mat-select *ngIf="cancelledReasons$ | async as reasons" formControlName="cause">
      <mat-option
        style="height: unset; min-height: 50px; line-height: 24px; font-size: 14px"
        *ngFor="let reason of reasons; trackBy: reasons | trackByKey: 'id'"
        [value]="reason.id"
      >
        <div>
          <div>
            {{ reason.displayName }}
          </div>
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="w-full">
    <mat-label>Reason</mat-label>
    <textarea matInput autocomplete="off" formControlName="reason"></textarea>
  </mat-form-field>
  <mat-form-field class="w-full">
    <mat-label>Enter {{ loadID }} to Confirm Cancellation</mat-label>
    <input matInput autocomplete="off" formControlName="loadID" (paste)="stopPaste($event)" />
  </mat-form-field>
</div>
<div mat-dialog-actions class="flex flex-row justify-between gap-2">
  <div>
    <button mat-flat-button color="accent" mat-dialog-close="">Nevermind</button>
  </div>
  <div>
    <ng-container *ngIf="canCancel$ | async; else default">
      <button mat-flat-button color="warn" (click)="cancelLoad()">Confirm Cancellation</button>
    </ng-container>
    <ng-template #default>
      <button mat-flat-button color="warn" (click)="startCancel()">Cancel Load</button>
    </ng-template>
  </div>
</div>
