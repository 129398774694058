<div mat-dialog-title>
  <div class="flex justify-between items-center">
    <div>Update Charge</div>
    <td-double-confirm
      buttonType="flat"
      buttonColor="warn"
      confirmText="Confirmed?"
      (confirm)="confirmedDeleteCharge()"
    >
      <button mat-mini-fab color="warn">
        <mat-icon>delete</mat-icon>
      </button>
    </td-double-confirm>
  </div>
</div>
<div mat-dialog-content>
  <form [formGroup]="tourChargeEditForm" (ngSubmit)="requestTourChargeUpdate()">
    <mat-form-field class="w-full">
      <mat-label>Price</mat-label>
      <input tdCurrencyInput matInput formControlName="requestedCents" autocomplete="off" />
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>Why is this change being requested?</mat-label>
      <input matInput formControlName="carrierNotes" autocomplete="off" />
    </mat-form-field>
    <div *ngIf="networkActive$ | async; else buttons" class="py-1">
      <mat-progress-bar mode="indeterminate" class="w-full"></mat-progress-bar>
    </div>
    <ng-template #buttons>
      <div class="flex justify-between">
        <button mat-button mat-dialog-close type="button">Nevermind</button>
        <button mat-stroked-button color="primary" type="submit" [disabled]="isSamePrice$ | async">
          Update Charge
        </button>
      </div>
    </ng-template>
  </form>
</div>
