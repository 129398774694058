import { Pipe, PipeTransform } from '@angular/core';
import { fuse } from '../utilities/fuse';

@Pipe({
  name: 'fuse',
})
export class FusePipe implements PipeTransform {
  public transform<T>(
    value: T[],
    search: string,
    keys: string[],
    minMatchCharLength = 1,
    threshold: number = 0.2,
  ): T[] {
    if (!search) {
      return value;
    }
    const fused = fuse<T>({
      distance: 1000,
      keys: keys,
      location: 0,
      minMatchCharLength,
      threshold,
    })(value);
    const filtered = fused.fuse.search(search).map((v) => v.item);
    return filtered;
  }
}
