import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { AuthService } from './auth.service';
import { filter, take } from 'rxjs/operators';
import { lastValueFrom, switchMap, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { NGXLogger } from 'ngx-logger';
import LogRocket from 'logrocket';
@Injectable({
  providedIn: 'root',
})
export class MessagingService {
  constructor(
    private afMessaging: AngularFireMessaging,
    private authService: AuthService,
    private http: HttpClient,
    private router: Router,
    private logger: NGXLogger,
  ) {
    this.authService.userInfo$
      .pipe(
        filter((value) => !!value),
        switchMap(() => this.afMessaging.requestToken),
      )
      .subscribe({
        next: (token) => {
          if (!!token) {
            this.sendTokenToServer(token);
          }
        },
      });
    this.afMessaging.messages.subscribe({
      next: (value) => {
        LogRocket.track('notification recieved', {
          value: JSON.stringify(value),
        });
        const options: NotificationOptions = {
          requireInteraction: true,
          icon: '/assets/icons/icon-72x72.png',
        };
        if (value.notification.body) {
          options.body = value.notification.body;
        }
        if (value.notification.image) {
          options.image = value.notification.image;
        }
        if (value.data?.['vibrate']) {
          options.vibrate = parseInt(value.data['vibrate']);
        }
        const noti = new Notification(value.notification.title, options);
        if (value.data?.['url']) {
          noti.addEventListener('click', () => {
            this.router.navigateByUrl(value.data['url']);
          });
        }
      },
    });
    this.checkIfDeniedPermission();
  }

  private sendTokenToServer = async (token: string) => {
    try {
      await lastValueFrom(
        this.http.put(`${environment.api}/v2/max_payloop/session`, {
          token,
        }),
      );
    } catch (e) {}
  };

  private async checkIfDeniedPermission() {
    if (window.location.hostname === 'localhost') {
      return;
    }
    if (!Notification && !LogRocket) {
      return;
    }
    const userInfo = await lastValueFrom(
      this.authService.userInfo$.pipe(
        filter((v) => !!v),
        take(1),
      ),
    );
    if (!userInfo) {
      return;
    }
    const result = await Notification.requestPermission();
    if (result === 'denied') {
      LogRocket.track('denied notification permission', {});
    }
  }
}
