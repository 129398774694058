<div class="flex flex-col w-full" *ngIf="value !== undefined">
  <div class="w-full flex justify-between text-green-500">
    <div class="w-8 text-center">
      <div class="font-semibold">{{ lowerBoundCents | currencyInPennies: 'USD':'symbol':'1.0-0' }}</div>
      <div class="text-sm" *ngIf="lowerBoundRPMCents">{{ lowerBoundRPMCents | currencyInPennies }}/mi</div>
      <ng-container *ngIf="customerPriceCents && !showDollarValueDifferences && showMargin">
        <div class="text-sm text-gray-600">
          {{ customerPriceCents - lowerBoundCents | currencyInPennies: 'USD':'symbol':'1.0-0' }} ({{
            (customerPriceCents - lowerBoundCents) / customerPriceCents | percent
          }})
        </div>
        <div class="text-sm text-gray-600">margin</div>
      </ng-container>
      <ng-container *ngIf="customerPriceCents && showDollarValueDifferences">
        <div class="text-sm text-gray-600">
          {{ lowerBoundCents - customerPriceCents | currencyInPennies: 'USD':'symbol':'1.0-0' }}
        </div>
        <div class="text-sm text-gray-600">{{ lowerBoundCents - customerPriceCents > 0 ? 'above' : 'below' }} post</div>
      </ng-container>
    </div>
    <div class="w-8 text-center">
      <div class="font-semibold">{{ upperBoundCents | currencyInPennies: 'USD':'symbol':'1.0-0' }}</div>
      <div class="text-sm" *ngIf="lowerBoundRPMCents">{{ upperBoundRPMCents | currencyInPennies }}/mi</div>
      <ng-container *ngIf="customerPriceCents && !showDollarValueDifferences && showMargin">
        <div class="text-sm text-gray-600">
          {{ customerPriceCents - upperBoundCents | currencyInPennies: 'USD':'symbol':'1.0-0' }} ({{
            (customerPriceCents - upperBoundCents) / customerPriceCents | percent
          }})
        </div>
        <div class="text-sm text-gray-600">margin</div>
      </ng-container>
      <ng-container *ngIf="customerPriceCents && showDollarValueDifferences">
        <div class="text-sm text-gray-600">
          {{ upperBoundCents - customerPriceCents | currencyInPennies: 'USD':'symbol':'1.0-0' }}
        </div>
        <div class="text-sm text-gray-600">{{ upperBoundCents - customerPriceCents > 0 ? 'above' : 'below' }} post</div>
      </ng-container>
    </div>
  </div>
  <div class="h-10 flex flex-row w-full">
    <div class="flex w-4 border-gray-500 border-r my-1"></div>
    <div class="relative flex flex-col flex-1">
      <div class="border-gray-500 border-b flex-1"></div>
      <div class="flex-1"></div>
      <div
        class="absolute flex flex-col items-center text-green-500"
        style="left: 50%; margin-top: -40px; margin-left: -24px"
        *ngIf="middleBoundTitle && middleBoundCents && middleBoundRPMCents"
      >
        <div class="font-semibold">{{ middleBoundCents | currencyInPennies: 'USD':'symbol':'1.0-0' }}</div>
        <div class="text-sm" *ngIf="middleBoundRPMCents">{{ middleBoundRPMCents | currencyInPennies }}/mi</div>
        <ng-container *ngIf="customerPriceCents && !showDollarValueDifferences && showMargin">
          <div class="text-sm text-gray-600">
            {{ customerPriceCents - middleBoundCents | currencyInPennies: 'USD':'symbol':'1.0-0' }} ({{
              (customerPriceCents - middleBoundCents) / customerPriceCents | percent
            }})
          </div>
          <div class="text-sm text-gray-600">margin</div>
        </ng-container>
        <ng-container *ngIf="customerPriceCents && showDollarValueDifferences">
          <div class="text-sm text-gray-600">
            {{ middleBoundCents - customerPriceCents | currencyInPennies: 'USD':'symbol':'1.0-0' }}
          </div>
          <div class="text-sm text-gray-600">
            {{ middleBoundCents - customerPriceCents > 0 ? 'above' : 'below' }} post
          </div>
        </ng-container>
        <div class="h-8 border-r border-gray-500 my-1"></div>
      </div>
      <div
        *ngIf="!evaluateLoadDesign"
        class="absolute flex flex-col items-center w-6"
        style="top: 24px; margin-left: -24px"
        [style.left]="((value - lowerBoundCents) / (upperBoundCents - lowerBoundCents)) * 100 + '%'"
      >
        <div class="bg-green-500 h-4 w-4 rounded-full"></div>
        <div class="text-center">
          <div class="text-xl font-semibold text-green-500">
            {{ value | currencyInPennies: 'USD':'symbol':'1.0-0' }}
          </div>
          <div class="text-sm text-green-500" *ngIf="valueRPM">{{ valueRPM | currencyInPennies }}/mi</div>
          <ng-container *ngIf="customerPriceCents && !showDollarValueDifferences">
            <div class="text-sm text-gray-600 flex-row">
              {{ customerPriceCents - value | currencyInPennies: 'USD':'symbol':'1.0-0' }} ({{
                (customerPriceCents - value) / customerPriceCents | percent
              }})
            </div>
            <div class="text-sm text-gray-600">margin</div>
          </ng-container>
          <ng-container *ngIf="customerPriceCents && showDollarValueDifferences">
            <div class="text-sm text-gray-600 flex-row">
              {{ value - customerPriceCents | currencyInPennies: 'USD':'symbol':'1.0-0' }}
            </div>
            <div class="text-sm text-gray-600 whitespace-nowrap">
              {{ value - customerPriceCents > 0 ? 'above' : 'below' }} post
            </div>
          </ng-container>
        </div>
      </div>
      <div
        *ngIf="evaluateLoadDesign"
        class="absolute flex flex-col items-center w-6"
        style="margin-top: -37px; margin-left: -24px"
        [style.left]="
          (value - lowerBoundCents) / (upperBoundCents - lowerBoundCents) > 1
            ? '100%'
            : ((value - lowerBoundCents) / (upperBoundCents - lowerBoundCents) | percent)
        "
      >
        <div class="text-center bg-zinc-600 shadow rounded-sm text-white p-[4px]">
          <div class="text-xl font-semibold">
            {{ value | currencyInPennies: 'USD':'symbol':'1.0-0' }}
          </div>
          <div class="text-sm" *ngIf="valueRPM">{{ valueRPM | currencyInPennies }}/mi</div>
          <ng-container *ngIf="customerPriceCents && !showDollarValueDifferences && showMargin">
            <div class="text-sm text-gray-600 flex-row">
              {{ customerPriceCents - value | currencyInPennies: 'USD':'symbol':'1.0-0' }} ({{
                (customerPriceCents - value) / customerPriceCents | percent
              }})
            </div>
            <div class="text-sm text-gray-600">margin</div>
          </ng-container>
          <ng-container *ngIf="customerPriceCents && showDollarValueDifferences">
            <div class="text-sm text-gray-600 flex-row">
              {{ value - customerPriceCents | currencyInPennies: 'USD':'symbol':'1.0-0' }}
            </div>
            <div class="text-sm text-gray-600 whitespace-nowrap">
              {{ value - customerPriceCents > 0 ? 'above' : 'below' }} post
            </div>
          </ng-container>
        </div>
        <div class="bg-green-500 h-4 w-4 rounded-full mt-1"></div>
        <div class="bg-green-500 px-1 text-white font-semibold mt-1 rounded-sm">Target</div>
      </div>
    </div>
    <div class="flex w-4 border-gray-500 border-l my-1"></div>
  </div>
  <div class="w-full flex justify-between">
    <div class="w-8 text-center">{{ lowerBoundTitle }}</div>
    <div *ngIf="middleBoundTitle" class="w-8 text-center ml-1">{{ middleBoundTitle }}</div>
    <div class="w-8 text-center">{{ upperBoundTitle }}</div>
  </div>
</div>
