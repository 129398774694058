import { Pipe, PipeTransform } from '@angular/core';
import { TitleCasePipe } from '@angular/common';

@Pipe({
  name: 'enum',
})
export class EnumPipe implements PipeTransform {
  constructor(private titlecase: TitleCasePipe) {}
  public transform(value: string | null | undefined): string {
    if (!value) {
      return value;
    }
    const lowered = value.replace(/_/g, ' ').toLowerCase();
    return this.titlecase.transform(lowered);
  }
}
