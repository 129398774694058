import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, lastValueFrom, Observable, of, switchMap } from 'rxjs';
import { HereLookupResult, HereSearchResult, HereService } from '../../services/here.service';
import { shareReplay } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { shareReplayComponentConfig } from '../../constants';

@Component({
  selector: 'td-address-search',
  templateUrl: './address-search.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressSearchComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() public disabled = false;
  @Input() public label = 'Search For Address';
  @Input() public showHint = true;
  @Input() public startWith: string;
  @Input() public enableClear = false;
  @Input() public defaultToCurrentLocation = false;
  @Input() public required = false;
  @Input() public invalid = false;

  @Output() private addressResult = new EventEmitter<HereLookupResult>();

  public addressSearch = new UntypedFormControl();
  public addresses$: Observable<HereSearchResult[]>;

  constructor(private hereService: HereService) {
    this.addresses$ = this.addressSearch.valueChanges.pipe(
      debounceTime(300),
      switchMap((v) => {
        if (!v || v?.length < 3) {
          return of([] as HereSearchResult[]);
        }
        return this.hereService.searchForAddresses$(v);
      }),
      shareReplay(shareReplayComponentConfig),
    );
    this.checkDisabled();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.checkDisabled();
    if (this.startWith && !this.disabled && this.addressSearch.untouched) {
      this.addressSearch.setValue(this.startWith);
    }
    if (this.defaultToCurrentLocation && !this.startWith && !this.disabled && this.addressSearch.untouched) {
      this.setToCurrentLocation();
    }
  }

  public ngAfterViewInit(): void {
    this.checkDisabled();
  }

  public ngOnInit(): void {
    this.checkDisabled();
  }

  public async selectAddress(address: MatAutocompleteSelectedEvent) {
    this.addressSearch.setValue(null);
    const lookup = await lastValueFrom(this.hereService.lookup$(address.option.value.id));
    this.addressResult.next(lookup);
    this.addressSearch.setValue(address.option.value.address.label);
  }

  public async clearAddress() {
    this.addressSearch.setValue(null);
    this.addressResult.next(null);
  }

  private checkDisabled() {
    if (this.disabled) {
      this.addressSearch.disable();
    } else {
      this.addressSearch.enable();
    }
  }

  private setToCurrentLocation() {
    this.addressSearch.setValue(null);
    this.addressResult.next(null);
    navigator.geolocation?.getCurrentPosition((position) => {
      this.addressResult.next({
        position: {
          lng: position.coords.longitude,
          lat: position.coords.latitude,
        },
      } as HereLookupResult);
      this.addressSearch.setValue('Current Location');
    });
  }
}
