import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InternalOrExternalDispatcherService } from '../services/internal-or-external-dispatcher.service';

@Injectable({
  providedIn: 'root',
})
export class InternalOrExternalDispatcherGuard {
  constructor(
    private internalOrExternalDispatcherService: InternalOrExternalDispatcherService,
    private router: Router,
  ) {}
  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.internalOrExternalDispatcherService.isInternalOrExternalDispatcher$.pipe(
      map((isInternalOrExternalDispatcher) => {
        if (isInternalOrExternalDispatcher) {
          return true;
        }
        return this.router.parseUrl('/');
      }),
    );
  }

  public canLoad(
    route: Route,
    segments: UrlSegment[],
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.internalOrExternalDispatcherService.isInternalOrExternalDispatcher$.pipe(
      map((isInternalOrExternalDispatcher) => {
        if (isInternalOrExternalDispatcher) {
          return true;
        }
        return this.router.parseUrl('/');
      }),
    );
  }
}
