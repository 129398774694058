<div class="overflow-auto h-full flex flex-col">
  <div class="font-bold mb-1 md:mb-3 mx-3">Carrier Portal</div>
  <div class="flex flex-col flex-1">
    <div class="card-with-shadow-white-no-radius p-1 md:p-3 mx-3">
      <div class="font-bold leading-loose text-lg p-1 md:p-0">Financial</div>
      <div class="flex flex-col">
        <a
          [routerLink]="['/carrier_kpis/cpg']"
          class="w-full leading-loose p-1 md:p-0 hover:text-blue-500 color-transition"
          *tdFeatureFlag="'newCpgDashboard'; else oldDashboard"
          >Dashboard</a
        >
        <ng-template #oldDashboard>
          <a
            [routerLink]="['/carrier_kpis/other', { outlets: { topkpi: 'per_driver_revenue', page: 'improvements' } }]"
            class="w-full leading-loose p-1 md:p-0 hover:text-blue-500 color-transition"
            >Dashboard</a
          >
        </ng-template>
        <a
          [routerLink]="['/carrier_completed_loads']"
          class="w-full leading-loose flex items-center p-1 md:p-0 hover:text-blue-500 color-transition"
          >Completed Loads
          <td-number-badge *ngIf="completedActionNeededCount$ | async as count" [count]="count"></td-number-badge>
        </a>
        <a [routerLink]="['/settlements']" class="w-full leading-loose p-1 md:p-0 hover:text-blue-500 color-transition"
          >Settlements</a
        >
        <a
          *ngIf="isCpg$ | async"
          (click)="openReferralIntercomSurvey()"
          class="w-full leading-loose flex items-center p-1 md:p-0 hover:text-blue-500 color-transition cursor-pointer"
        >
          Refer a Friend – Earn $500 Each
        </a>
        <a
          *ngIf="isCpg$ | async"
          [routerLink]="['/rates']"
          class="w-full leading-loose flex items-center p-1 md:p-0 hover:text-blue-500 color-transition"
        >
          Rates
        </a>
      </div>
    </div>
    <div class="card-with-shadow-white-no-radius mt-2 md:mt-3 p-1 md:p-3 mx-3">
      <div class="font-bold leading-loose text-lg p-1 md:p-0">My Fleet</div>
      <div class="flex flex-col">
        <a
          *ngIf="isCpg$ | async"
          [routerLink]="['/carrier_hitches/dabs']"
          class="w-full leading-loose flex items-center p-1 md:p-0 hover:text-blue-500 color-transition"
        >
          Hitches
        </a>
        <a
          [routerLink]="['/carrier_pending_loads']"
          class="w-full leading-loose flex items-center p-1 md:p-0 hover:text-blue-500 color-transition"
        >
          Pending Loads
          <td-number-badge *ngIf="pendingActionNeededCount$ | async as count" [count]="count"></td-number-badge>
        </a>
        <a
          *ngIf="isCpgUfg$ | async"
          [routerLink]="['/cpg_ufg']"
          class="w-full leading-loose flex items-center p-1 md:p-0 hover:text-blue-500 color-transition"
        >
          Spot Loads
        </a>
        <a
          [routerLink]="['/carrier_loads/active_loads']"
          class="w-full leading-loose p-1 md:p-0 hover:text-blue-500 color-transition"
          >Active Loads</a
        >
        <a
          [routerLink]="['/carrier_assets']"
          class="w-full leading-loose p-1 md:p-0 hover:text-blue-500 color-transition"
          >Assets</a
        >
        <a
          [routerLink]="['/carrier_users/table']"
          class="w-full leading-loose p-1 md:p-0 hover:text-blue-500 color-transition"
          >Users</a
        >
        <a
          [routerLink]="['/carrier_import_export']"
          class="w-full leading-loose p-1 md:p-0 hover:text-blue-500 color-transition"
          >Street Turn Matches</a
        >
        <a [routerLink]="['/street_turns']" class="w-full leading-loose p-1 md:p-0 hover:text-blue-500 color-transition"
          >Street Turns</a
        >
        <a
          *ngIf="isImportExport$ | async"
          [routerLink]="['/carrier_leaderboard']"
          class="w-full leading-loose p-1 md:p-0 hover:text-blue-500 color-transition"
          >Leaderboard</a
        >
      </div>
    </div>
    <div class="flex-1"></div>
    <div class="flex items-center flex-col mt-1 mx-3">
      <a [routerLink]="['/carrier_settings']" class="w-full leading-loose p-1 md:p-0"> Settings </a>
    </div>
  </div>
</div>
