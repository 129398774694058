import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { City, ConstantsService } from '../services/constants.service';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'cities',
})
export class CitiesPipe implements PipeTransform {
  private citiesMap$: Observable<Record<number, City>>;

  constructor(private constantsService: ConstantsService) {
    this.citiesMap$ = this.constantsService.allCitiesMap$;
  }

  public transform(value: number[]): Observable<string[]> {
    if (!Array.isArray(value)) {
      return of(['']);
    }
    return this.citiesMap$.pipe(
      map((cm) => {
        const response: string[] = [];
        value.forEach((v) => {
          response.push(cm[v]?.displayName || null);
        });
        return response.filter((v) => !!v);
      }),
    );
  }
}
