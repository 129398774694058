import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minutesToTimeOfDay',
})
export class MinutesToTimeOfDayPipe implements PipeTransform {
  public transform(value: number): string {
    const hours = Math.floor(value / 60);
    const minutes = value % 60;
    const hoursString = hours.toString().padStart(2, '0');
    const minutesString = minutes.toString().padStart(2, '0');
    return `${hoursString}:${minutesString}`;
  }
}
