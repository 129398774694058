import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { InternalService } from '../services/internal.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InternalGuard {
  constructor(private internalService: InternalService, private router: Router) {}
  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.internalService.isInternal$.pipe(
      map((isInternal) => {
        if (isInternal) {
          return true;
        }
        return this.router.parseUrl('/');
      }),
    );
  }

  public canLoad(
    route: Route,
    segments: UrlSegment[],
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.internalService.isInternal$.pipe(
      map((isInternal) => {
        if (isInternal) {
          return true;
        }
        return this.router.parseUrl('/');
      }),
    );
  }
}
