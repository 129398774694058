import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../../../services/auth.service';
import { SnackBarService } from '../../../services/snackbar.service';
import { DashboardService, DriverComment } from '../../../../modules/internal/dashboard/dashboard.service';

@Component({
  selector: 'td-driver-comment',
  templateUrl: './driver-comment.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverCommentComponent {
  @Input() public comment: DriverComment;

  constructor(
    private authService: AuthService,
    private dashboardService: DashboardService,
    private snackbar: SnackBarService,
  ) {}

  public async deleteComment(comment: DriverComment) {
    const success = await this.dashboardService.deleteComment(comment);

    if (success) {
      this.snackbar.showMessage('Comment Removed');
    } else {
      this.snackbar.showError('Failed to Remove Comment');
    }
  }
}
