import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackBarService } from '../../services/snackbar.service';
import { NetworkActiveComponent } from '../networkActive.component';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { DashboardService } from '../../../modules/internal/dashboard/dashboard.service';
import { shareReplay, take } from 'rxjs/operators';
import { shareReplayComponentConfig } from '../../constants';
import { CancelledReason, LoadService } from '../../../modules/internal/loads/load.service';

@Component({
  selector: 'td-cancel-load',
  templateUrl: './cancel-load.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CancelLoadComponent extends NetworkActiveComponent {
  public form: UntypedFormGroup;
  public cancelledReasons$: Observable<CancelledReason[]>;

  private canCancel$$ = new BehaviorSubject<boolean>(false);
  public canCancel$: Observable<boolean> = this.canCancel$$.pipe(shareReplay(shareReplayComponentConfig));

  constructor(
    @Inject(MAT_DIALOG_DATA) public loadID: string,
    private fb: UntypedFormBuilder,
    private loadService: LoadService,
    private dashboardService: DashboardService,
    private dRef: MatDialogRef<CancelLoadComponent>,
    private snackbar: SnackBarService,
  ) {
    super();
    this.cancelledReasons$ = this.loadService.cancelledReasons$;
    this.form = this.fb.group({
      cause: [null, [Validators.required]],
      reason: [null, [Validators.required]],
      loadID: [null, [Validators.required, Validators.pattern(loadID)]],
    });
  }

  public startCancel() {
    timer(100)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.canCancel$$.next(true);
        },
      });
  }

  public async cancelLoad() {
    if (this.networkActive$$.value || this.form.invalid) {
      return;
    }

    try {
      this.networkActive$$.next(true);

      const value = this.form.value;
      const success = await this.loadService.cancelLoad(this.loadID, value.cause, value.reason);
      if (success) {
        this.dashboardService.refreshLoad();
        this.snackbar.showMessage('Load Cancelled');
        this.dRef.close();
      }
    } finally {
      this.networkActive$$.next(false);
    }
  }

  public stopPaste($event: ClipboardEvent) {
    $event.preventDefault();
  }
}
