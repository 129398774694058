<div mat-dialog-title>New Customer Account</div>
<div mat-dialog-content [formGroup]="form">
  <div class="flex flex-row gap-2 flex-wrap items-center">
    <mat-form-field>
      <mat-label>Customer Name</mat-label>
      <input matInput autocomplete="off" formControlName="name" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Customer Type</mat-label>
      <mat-select formControlName="type">
        <mat-option [value]="'shipper'"> Shipper</mat-option>
        <mat-option [value]="'broker'"> Broker</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Brokerage</mat-label>
      <mat-select formControlName="brokerage">
        <mat-option [value]="'2'"> Lohi (default)</mat-option>
        <mat-option [value]="'17'"> Candlestick</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-checkbox formControlName="canPriceBelowCostToCover" *ngIf="form.get('type').value === 'agent'">
      Allowed to Price Below Cost to Cover
    </mat-checkbox>
  </div>
  <div class="flex flex-row gap-2 flex-wrap">
    <div class="font-semibold w-full">Customer Billing Contact Info</div>
    <mat-form-field class="flex-1">
      <mat-label>Billing Contact Name</mat-label>
      <input matInput autocomplete="off" formControlName="billingContactName" />
    </mat-form-field>
    <mat-form-field class="flex-1">
      <mat-label>Billing Contact Phone</mat-label>
      <ngx-mat-intl-tel-input
        [preferredCountries]="['us']"
        [enablePlaceholder]="true"
        [enableSearch]="true"
        format="national"
        name="phoneNumber"
        formControlName="billingContactPhone"
      ></ngx-mat-intl-tel-input>
    </mat-form-field>
    <mat-form-field class="flex-1">
      <mat-label>Billing Contact Email</mat-label>
      <input matInput autocomplete="off" formControlName="billingContactEmail" />
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>Billing Instructions</mat-label>
      <textarea matInput autocomplete="off" formControlName="billingContactBillingInstructions"></textarea>
    </mat-form-field>
  </div>
  <div class="flex flex-row gap-2 flex-wrap">
    <div class="font-semibold w-full">Customer Detention Info</div>
    <mat-form-field class="flex-1">
      <mat-label>Notify of Detention</mat-label>
      <input matInput autocomplete="off" tdNumberInput [noDecimals]="true" formControlName="notifyOfDetentionMinutes" />
      <span matSuffix>minutes</span>
    </mat-form-field>
    <mat-form-field class="flex-1">
      <mat-label>Detention Rate</mat-label>
      <input matInput autocomplete="off" tdCurrencyInput formControlName="detentionCents" />
      <span matSuffix>/ hour</span>
    </mat-form-field>
    <mat-form-field class="flex-1">
      <mat-label>Detention Starts After</mat-label>
      <input matInput autocomplete="off" tdNumberInput formControlName="detentionStartsAfterMinutes" />
      <span matSuffix>minutes</span>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions class="flex flex-row justify-between">
  <button mat-flat-button (click)="createCustomer()" color="accent" [disabled]="form.invalid">Create Customer</button>
</div>
