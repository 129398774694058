<div mat-dialog-title>Select Customer</div>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label> Customer </mat-label>
    <mat-select [formControl]="customerControl">
      <mat-option>
        <ngx-mat-select-search
          placeholderLabel="Search"
          noEntriesFoundLabel="No match found"
          [formControl]="searchControl"
        ></ngx-mat-select-search>
      </mat-option>
      <mat-option
        [value]="value"
        *ngFor="let value of filteredCustomers$ | async; trackBy: filteredCustomers$ | trackByKey: 'id'"
      >
        {{ value.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <div>
    <button mat-button mat-dialog-close="">Cancel</button>
  </div>
  <div>
    <button
      mat-flat-button
      color="primary"
      [mat-dialog-close]="customerControl.value"
      [disabled]="customerControl.invalid"
    >
      Select
    </button>
  </div>
</div>
