<ng-container *ngIf="clicked$ | async; else notClicked">
  <div class="flex items-center">
    <ng-container [ngSwitch]="buttonType">
      <ng-container *ngSwitchCase="'button'">
        <button [disabled]="disabled$ | async" mat-button [color]="buttonColor" (click)="confirm.emit(true)">
          {{ confirmText }}
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="'raised'">
        <button [disabled]="disabled$ | async" mat-raised-button [color]="buttonColor" (click)="confirm.emit(true)">
          {{ confirmText }}
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="'stroked'">
        <button [disabled]="disabled$ | async" mat-stroked-button [color]="buttonColor" (click)="confirm.emit(true)">
          {{ confirmText }}
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="'flat'">
        <button [disabled]="disabled$ | async" mat-flat-button [color]="buttonColor" (click)="confirm.emit(true)">
          {{ confirmText }}
        </button>
      </ng-container>
    </ng-container>
    <mat-spinner *ngIf="disabled$ | async" [diameter]="24" mode="indeterminate"></mat-spinner>
  </div>
</ng-container>
<ng-template #notClicked>
  <ng-content></ng-content>
</ng-template>
