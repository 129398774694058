import { Component, ViewChildren, QueryList, ElementRef, AfterViewInit, OnDestroy, Inject } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { BehaviorSubject, lastValueFrom, Observable, Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { LngLatLike, Marker } from 'mapbox-gl';
import { LongLat } from '../../global-types';

export const STREETS = 'mapbox://styles/mapbox/streets-v12';
export const SATELLITE = 'mapbox://styles/mapbox/satellite-streets-v12';

@Component({
  template: '',
})
export abstract class MappableComponent implements AfterViewInit, OnDestroy {
  protected map: mapboxgl.Map;

  protected markers: mapboxgl.Marker[] = [];

  @ViewChildren('map') private mapElements: QueryList<ElementRef<HTMLDivElement>>;

  protected networkActive$$ = new BehaviorSubject<boolean>(false);
  public networkActive$: Observable<boolean> = this.networkActive$$;
  protected destroy$$ = new Subject<void>();

  protected constructor(@Inject('style') private style = STREETS) {
    (mapboxgl as any).accessToken =
      'pk.eyJ1Ijoic2F0ZXN0ZGV2IiwiYSI6ImNrYmVraDllZDBtcXQycHFlbzVqYXk0cnMifQ.HzVySDvy0mLDW03ykD2HNw';
  }

  public ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.unsubscribe();
  }

  public ngAfterViewInit(): void {
    this.initMap();
  }

  private async initMap() {
    // Make sure we have a map element
    if (this.mapElements.length === 0) {
      await lastValueFrom(
        this.mapElements.changes.pipe(
          filter((changes: QueryList<any>) => {
            return changes.length > 0;
          }),
          take(1),
        ),
      );
    }

    const options: mapboxgl.MapboxOptions = {
      container: this.mapElements.first.nativeElement,
      style: this.style,
    };

    this.map = new mapboxgl.Map(options);
    this.map.setZoom(3.5);
    this.map.setCenter({ lat: 39.8283, lng: -98.5795 });
    this.map.addControl(new mapboxgl.NavigationControl());
    this.map.addControl(new mapboxgl.FullscreenControl());
    this.map.once('style.load', () => {
      this.onStyleLoad();
    });

    this.map.on('moveend', (ev) => {
      this.onMoveEnd();
    });

    this.map.on('dragend', (ev) => {
      this.onDragEnd();
    });
  }

  public onLoad() {}

  public onStyleLoad() {}

  public onMoveEnd() {}

  public onDragEnd() {}

  protected addMarker(marker: Marker) {
    marker.addTo(this.map);
    this.markers.push(marker);
  }

  protected clearMarkers() {
    while (this.markers.length) {
      const marker = this.markers.shift();
      marker.remove();
    }
  }

  protected fitMarkers(padding = 50) {
    if (this.markers.length === 0) {
      return;
    }
    const bounds = new mapboxgl.LngLatBounds();
    for (const marker of this.markers) {
      bounds.extend(marker.getLngLat());
    }
    this.map.fitBounds(bounds, { padding, maxZoom: 12 });
    this.map.resize();
  }

  protected static getDiv() {
    return document.createElement('div');
  }

  protected fitMarkersAndLngLats(lngLats: LngLatLike[], padding = 50, maxZoom = 12) {
    if (this.markers.length === 0 && lngLats?.length === 0) {
      return;
    }
    const bounds = new mapboxgl.LngLatBounds();
    for (const marker of this?.markers || []) {
      bounds.extend(marker.getLngLat());
    }
    for (const lngLat of lngLats) {
      bounds.extend(lngLat);
    }
    this.map.fitBounds(bounds, { padding, maxZoom });
    this.map.resize();
  }
}
