import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, catchError, debounceTime, Observable, of, shareReplay, Subject, switchMap } from 'rxjs';
import { LohiLoadStatus } from '../global-types';
import { environment } from '../../../environments/environment';
import { shareReplayComponentConfig } from '../constants';

export interface SearchResult {
  status: LohiLoadStatus;
  internalId: string;
  referenceNumber: string;
  shipperId: string;
  carrierName: string;
  isBrokeredLoad: boolean;
  containerNumber: string;
  bookingNumber: string;
}

export interface TrailerSearchResult {
  id: number;
  name: string;
  externalIdentifier: string;
}

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private searchText$$ = new Subject<string>();
  private loading$$ = new BehaviorSubject<boolean>(false);
  public loading$ = this.loading$$.pipe(shareReplay(shareReplayComponentConfig));
  private searchResults$$ = new BehaviorSubject<SearchResult[]>([]);
  public searchResults$ = this.searchResults$$.pipe(shareReplay(shareReplayComponentConfig));
  // trailers
  private trailerLoading$$ = new BehaviorSubject<boolean>(false);
  public trailerLoading$ = this.trailerLoading$$.pipe(shareReplay(shareReplayComponentConfig));
  private trailerSearchResults$$ = new BehaviorSubject<TrailerSearchResult[]>([]);
  public trailerSearchResults$ = this.trailerSearchResults$$.pipe(shareReplay(shareReplayComponentConfig));

  constructor(private http: HttpClient) {
    this.listenToSearchTextChangeLoads();
    this.listenToSearchTextChangeTrailers();
  }

  public search(text: string) {
    this.searchText$$.next(text);
  }

  private listenToSearchTextChangeLoads() {
    this.searchText$$
      .pipe(debounceTime(50))
      .pipe(
        switchMap((searchTextRaw) => {
          this.loading$$.next(true);
          const searchText = searchTextRaw?.trim();
          if (searchText) {
            return this.http
              .get<{ loads: SearchResult[] }>(`${environment.api}/v2/max_payloop/dispatcher/loopi/search`, {
                params: { search: searchText },
              })
              .pipe(catchError(() => of({ loads: new Array<SearchResult>() })));
          } else {
            return of({ loads: new Array<SearchResult>() });
          }
        }),
      )
      .subscribe((results) => {
        this.loading$$.next(false);
        this.searchResults$$.next(results?.loads || []);
      });
  }

  private listenToSearchTextChangeTrailers() {
    this.searchText$$
      .pipe(debounceTime(100))
      .pipe(
        switchMap((searchTextRaw) => {
          this.trailerLoading$$.next(true);
          const searchText = searchTextRaw?.trim();
          if (searchText) {
            return this.http
              .get<{ trailers: TrailerSearchResult[] }>(
                `${environment.api}/v2/vpf/internal_or_external_dispatcher/drop_trailer_dashboard/trailer_search`,
                {
                  params: { search: searchText },
                },
              )
              .pipe(catchError(() => of({ trailers: new Array<TrailerSearchResult>() })));
          } else {
            return of({ trailers: new Array<TrailerSearchResult>() });
          }
        }),
      )
      .subscribe((results) => {
        this.trailerLoading$$.next(false);
        this.trailerSearchResults$$.next(results?.trailers || []);
      });
  }
}
