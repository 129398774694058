import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RecordCallService {
  constructor(private http: HttpClient) {}

  public async recordCall(driverId: string) {
    try {
      await lastValueFrom(
        this.http.put(`${environment.api}/v2/max_payloop/dispatcher/my_drivers/call`, null, {
          params: {
            driverId,
          },
        }),
      );
      return true;
    } catch (e) {
      return false;
    }
  }
}
