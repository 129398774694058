import { Pipe, PipeTransform } from '@angular/core';
import { addMinutesIfNotNull } from '../utilities/dateTime';

@Pipe({
  name: 'addMinutes',
})
export class AddMinutesPipe implements PipeTransform {
  public transform(value: Date | number | string, minutes: number): Date {
    return addMinutesIfNotNull(value, minutes);
  }
}
