import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, combineLatestWith, lastValueFrom, Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ReloadableSubject } from '../../../shared/utilities/reloadableSubject';
import { map } from 'rxjs/operators';
import { LohiLoadWithRateconChargesAndTerms, Stipulation, TrailerType } from '../../../shared/global-types';
import { isBefore } from 'date-fns';
import { SimpleFinn } from '../../../shared/utilities/simpleFinn';

const STATE_FILTER_KEY = 'PENDING_LOADS_LIST_STATE_FILTER_KEY';

export interface OTRPendingLoadCounts {
  exclusiveCount: number;
  upForGrabsCount: number;
  signatureNeededCount: number;
  awardedCount: number;
  waitlistCount: number;
  driverNeededCount: number;
}

export interface RouteGuideExclusiveLoadListItem {
  id: string;
  emptyAssetPickupName: string;
  emptyAssetPickupTimezone: string;
  emptyAssetPickupArrivalWindowStartsAt: string;
  emptyAssetPickupArrivalWindowEndsAt: string;
  originName: string;
  originStateCode: string;
  originTimezone: string;
  originArrivalWindowStartsAt: string;
  originArrivalWindowEndsAt: string;
  earliestExportPickupAt: string; //RTL
  lastestExportPickupAt: string; //latest pickup
  destinationName: string;
  destinationStateCode: string;
  destinationTimezone: string;
  destinationArrivalWindowStartsAt: string;
  destinationArrivalWindowEndsAt: string;
  earliestExportDropoffAt: string; //ERD
  exportRampCutoffDate: string;
  exportPortCutoffDate: string;
  assetType: TrailerType;
  rateCents: number;
  expiresAt: string;
  exportBookingNumber: string;
  steamshipLine?: string;
}

export interface RouteGuideExclusiveLoadListItemFlat extends RouteGuideExclusiveLoadListItem {
  latestExportDropoffAt: string;
  latestExportDropoffType: 'Ramp Cutoff Date' | 'Port Cutoff Date';
}

export interface RouteGuideUpForGrabsLoadListItem {
  id: string;
  emptyAssetPickupName: string;
  emptyAssetPickupTimezone: string;
  emptyAssetPickupArrivalWindowStartsAt: string;
  emptyAssetPickupArrivalWindowEndsAt: string;
  originName: string;
  originStateCode: string;
  originTimezone: string;
  originArrivalWindowStartsAt: string;
  originArrivalWindowEndsAt: string;
  earliestExportPickupAt: string; //RTL
  lastestExportPickupAt: string;
  destinationName: string;
  destinationStateCode: string;
  destinationTimezone: string;
  destinationArrivalWindowStartsAt: string;
  destinationArrivalWindowEndsAt: string;
  earliestExportDropoffAt: string; //ERD
  exportRampCutoffDate: string;
  exportPortCutoffDate: string;
  assetType: TrailerType;
  rateCents: number;
  isFromRouteGuide: boolean;
  exportBookingNumber: string;
  expiresAt?: string;
  steamshipLine?: string;
  seen?: boolean;
}

export interface RouteGuideUpForGrabsLoadListItemFlat extends RouteGuideUpForGrabsLoadListItem {
  latestExportDropoffAt: string;
  latestExportDropoffType: 'Ramp Cutoff Date' | 'Port Cutoff Date';
}

export interface OTRPendingLoadListItem {
  id: string;
  driverName?: string;
  emptyAssetPickupName: string;
  emptyAssetPickupTimezone: string;
  emptyAssetPickupArrivalWindowStartsAt: string;
  emptyAssetPickupArrivalWindowEndsAt: string;
  originName: string;
  originStateCode: string;
  originTimezone: string;
  originArrivalWindowStartsAt: string;
  originArrivalWindowEndsAt: string;
  earliestExportPickupAt: string; //RTL
  lastestExportPickupAt: string;
  destinationName: string;
  destinationStateCode: string;
  destinationTimezone: string;
  destinationArrivalWindowStartsAt: string;
  destinationArrivalWindowEndsAt: string;
  earliestExportDropoffAt: string; //ERD
  exportRampCutoffDate: string;
  exportPortCutoffDate: string;
  stopCount: number;
  trailerType: TrailerType;
}

export interface OTRPendingLoadListItemFlat extends OTRPendingLoadListItem {
  latestExportDropoffAt: string;
  latestExportDropoffType: 'Ramp Cutoff Date' | 'Port Cutoff Date';
}

@Injectable({
  providedIn: 'root',
})
export class CarrierPendingLoadsService {
  private statesFilter$$ = new BehaviorSubject<string[]>([]);

  private pendingSummary$$ = new ReloadableSubject<null, OTRPendingLoadCounts>(
    {
      exclusiveCount: null,
      upForGrabsCount: null,
      signatureNeededCount: null,
      awardedCount: null,
      waitlistCount: null,
      driverNeededCount: null,
    },
    () => this.getPendingSummary$(),
    of(null),
  );
  public pendingSummary$: Observable<OTRPendingLoadCounts> = this.pendingSummary$$.asObservable();
  public pendingSummaryNetworkActive$ = this.pendingSummary$$.networkActive$;

  private exclusiveLoads$$ = new SimpleFinn<RouteGuideExclusiveLoadListItem[]>([], this.getExclusiveLoads.bind(this));

  public get exclusiveLoads$(): Observable<RouteGuideExclusiveLoadListItem[]> {
    return this.exclusiveLoads$$.get$();
  }

  public exclusiveLoadsFlat$ = this.flattenRouteGuideExclusiveLoadListItems$(this.exclusiveLoads$);
  public exclusiveNetworkActive$ = this.exclusiveLoads$$.networkActive$;

  private upForGrabsLoads$$ = new SimpleFinn<RouteGuideUpForGrabsLoadListItem[]>(
    [],
    this.getUpForGrabsLoads$.bind(this),
  );
  public get upForGrabsLoads$(): Observable<RouteGuideUpForGrabsLoadListItem[]> {
    return this.upForGrabsLoads$$.get$();
  }

  public upForGrabsLoadsFlat$ = this.flattenRouteGuideUpForGrabsLoadListItems$(this.upForGrabsLoads$);
  public upForGrabsNetworkActive$ = this.upForGrabsLoads$$.networkActive$;

  private signatureNeededLoads$$ = new ReloadableSubject<null, OTRPendingLoadListItem[]>(
    [],
    () => this.getSignatureNeededLoads$(),
    of(null),
  );
  public signatureNeededLoads$: Observable<OTRPendingLoadListItem[]> = this.signatureNeededLoads$$.asObservable();
  public signatureNeededLoadsFlat$ = this.flattenOTRPendingLoadListItems$(this.signatureNeededLoads$);
  public signatureNeededLoadsFlatFiltered$ = this.signatureNeededLoadsFlat$.pipe(
    combineLatestWith(this.statesFilter$$),
    map(([loads, states]) => {
      if (states?.length === 0) {
        return loads;
      }
      return loads.filter(
        (load) => states.includes(load.originStateCode) || states.includes(load.destinationStateCode),
      );
    }),
  );
  public signatureNeededNetworkActive$ = this.signatureNeededLoads$$.networkActive$;

  private waitlistedLoads$$ = new ReloadableSubject<null, OTRPendingLoadListItem[]>(
    [],
    () => this.getWaitlistedLoads$(),
    of(null),
  );

  constructor(private httpClient: HttpClient) {}

  private stateFilterToLocalStorage(stateFilters: string[]) {
    localStorage.setItem(STATE_FILTER_KEY, JSON.stringify(stateFilters));
  }

  private getPendingSummary$(): Observable<OTRPendingLoadCounts> {
    return this.httpClient
      .get<{ counts: OTRPendingLoadCounts }>(`${environment.api}/v2/load/otr_list_v2/pending/summary_info`)
      .pipe(map((res) => res.counts));
  }

  private getExclusiveLoads(): Promise<RouteGuideExclusiveLoadListItem[]> {
    return lastValueFrom(
      this.httpClient
        .get<{ loads: RouteGuideExclusiveLoadListItem[] }>(
          `${environment.api}/v2/load/otr_list_v2/pending/route_guide_exclusive`,
        )
        .pipe(map((res) => res.loads || [])),
    );
  }

  private getUpForGrabsLoads$(): Observable<RouteGuideUpForGrabsLoadListItem[]> {
    return this.httpClient
      .get<{ loads: RouteGuideUpForGrabsLoadListItem[] }>(
        `${environment.api}/v2/load/otr_list_v2/pending/route_guide_up_for_grabs`,
      )
      .pipe(map((res) => res.loads || []));
  }

  private getSignatureNeededLoads$(): Observable<OTRPendingLoadListItem[]> {
    return this.httpClient
      .get<{ loads: OTRPendingLoadListItem[] }>(`${environment.api}/v2/load/otr_list_v2/pending/signature_needed`)
      .pipe(map((res) => res.loads || []));
  }
  private getWaitlistedLoads$(): Observable<OTRPendingLoadListItem[]> {
    return this.httpClient
      .get<{ loads: OTRPendingLoadListItem[] }>(`${environment.api}/v2/load/otr_list_v2/pending/waitlisted`)
      .pipe(map((res) => res.loads || []));
  }

  private flattenOTRPendingLoadListItems$(
    list$: Observable<OTRPendingLoadListItem[]>,
  ): Observable<OTRPendingLoadListItemFlat[]> {
    return list$.pipe(map((loads) => loads.map(this.flattenLoadLatestExportDropoff)));
  }

  private flattenRouteGuideExclusiveLoadListItems$(
    list$: Observable<RouteGuideExclusiveLoadListItem[]>,
  ): Observable<RouteGuideExclusiveLoadListItemFlat[]> {
    return list$.pipe(map((loads) => loads.map(this.flattenLoadLatestExportDropoff)));
  }

  private flattenRouteGuideUpForGrabsLoadListItems$(
    list$: Observable<RouteGuideUpForGrabsLoadListItem[]>,
  ): Observable<RouteGuideUpForGrabsLoadListItemFlat[]> {
    return list$.pipe(map((loads) => loads.map(this.flattenLoadLatestExportDropoff)));
  }

  private flattenLoadLatestExportDropoff = (load) => {
    let latestExportDropoffAt: string;
    let latestExportDropoffType: 'Ramp Cutoff Date' | 'Port Cutoff Date';
    if (load.exportRampCutoffDate && !load.exportPortCutoffDate) {
      latestExportDropoffAt = load.exportRampCutoffDate;
      latestExportDropoffType = 'Ramp Cutoff Date';
    } else if (!load.exportRampCutoffDate && load.exportPortCutoffDate) {
      latestExportDropoffAt = load.exportPortCutoffDate;
      latestExportDropoffType = 'Port Cutoff Date';
    } else if (load.exportRampCutoffDate && load.exportPortCutoffDate) {
      if (isBefore(new Date(load.exportRampCutoffDate), new Date(load.exportPortCutoffDate))) {
        latestExportDropoffAt = load.exportRampCutoffDate;
        latestExportDropoffType = 'Ramp Cutoff Date';
      } else {
        latestExportDropoffAt = load.exportPortCutoffDate;
        latestExportDropoffType = 'Port Cutoff Date';
      }
    }
    return {
      ...load,
      latestExportDropoffAt,
      latestExportDropoffType,
    };
  };

  public getBulkRatecons(loadIDs: string[]) {
    this.upForGrabsLoads$$.get$();
    this.exclusiveLoads$$.get$();
    return new SimpleFinn<{
      stipulations: Stipulation[];
      availableLoads: LohiLoadWithRateconChargesAndTerms[];
      unavailableLoads: string[];
    }>(null, () =>
      lastValueFrom(
        this.httpClient.post<{
          stipulations: Stipulation[];
          availableLoads: LohiLoadWithRateconChargesAndTerms[];
          unavailableLoads: string[];
        }>(`${environment.api}/v2/load/route_guide/bulk_sign_ratecon`, {
          loadIDs,
        }),
      ),
    );
  }

  public async signRatecons(loadIDs: string[]) {
    try {
      const s = await lastValueFrom(
        this.httpClient.post<{ signed: string[]; failed: string[] }>(
          `${environment.api}/v2/load/route_guide/bulk_sign_ratecon/sign`,
          {
            loadIDs,
          },
        ),
      );
      const signed = s?.signed ?? [];
      this.exclusiveLoads$$.next(this.exclusiveLoads$$.currentValue().filter((value) => !signed.includes(value.id)));
      this.exclusiveLoads$$.get$();
      this.upForGrabsLoads$$.next(this.upForGrabsLoads$$.currentValue().filter((value) => !signed.includes(value.id)));
      this.upForGrabsLoads$$.get$();
      this.signatureNeededLoads$$.refresh(true);
      this.waitlistedLoads$$.refresh(true);
      return s.signed;
    } catch (e) {
      return false;
    }
  }

  public async bulkReject(loadIDs: string[]) {
    try {
      const s = await lastValueFrom(
        this.httpClient.post<{ signed: string[]; failed: string[] }>(
          `${environment.api}/v2/load/route_guide/bulk_reject_exclusive`,
          {
            loadIDs,
          },
        ),
      );
      this.exclusiveLoads$$.get$();
      return true;
    } catch (e) {
      return false;
    }
  }
}
