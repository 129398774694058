<mat-form-field class="w-full">
  <mat-label>{{ label }}</mat-label>
  <input [formControl]="addressSearch" matInput autocomplete="off" [matAutocomplete]="auto" />
</mat-form-field>
<mat-autocomplete #auto="matAutocomplete" (optionSelected)="lookupAndWrite($event)">
  <mat-option
    *ngFor="let address of addresses$ | async; trackBy: addresses$ | trackByKey: 'id'"
    [value]="address"
    style="height: unset; min-height: 42px; line-height: 24px; font-size: 14px"
  >
    <div class="break-words whitespace-normal">
      {{ address.address.label }}
    </div>
  </mat-option>
</mat-autocomplete>
