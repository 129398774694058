import { Pipe, PipeTransform } from '@angular/core';
import { toReadableFileUploadType } from '../utilities/toReadableFileUploadType';

@Pipe({
  name: 'fileUploadTypes',
})
export class FileUploadTypesPipe implements PipeTransform {
  public transform(value: string[]): string[] {
    return value?.map(toReadableFileUploadType);
  }
}
