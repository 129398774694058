import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ConfirmationModalData {
  content: string;
  title: string;
  yesLabel: string;
  noLabel: string;
}

@Component({
  selector: 'td-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: ConfirmationModalData,
  ) {}
}
